import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

const EnterCycleCountsLayout = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");

  const { openAlert } = useGeneralAlert();

  return (
    <Outlet
      context={{ openAlert, user, navigate, navigation, dispatch, matches }}
    />
  );
};
export default EnterCycleCountsLayout;
