import { Alert, Snackbar, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  closeGeneralAlert,
  resetGeneralAlert,
} from "../redux/features/alert/alertSlice";

const GeneralAlert = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  const handleCloseAlert = () => {
    dispatch(closeGeneralAlert());
  };

  return (
    <Snackbar
      open={alert.isGeneralAlertOpen}
      autoHideDuration={parseInt(alert.generalAlertDuration)}
      onClose={handleCloseAlert}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      hidden={!alert.isGeneralAlertOpen}
      sx={{
        bottom: alert.generalAlertBottomOffset,
      }}
      TransitionProps={{ onExited: () => dispatch(resetGeneralAlert()) }}
    >
      <Alert
        severity={alert.generalAlertType}
        sx={{ width: "100%", zIndex: 999, py: 0 }}
      >
        <Typography variant="caption">{alert.generalAlertMessage}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default GeneralAlert;
