import { useState } from "react";
import { useNavigate, useNavigation, useOutletContext } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice.js";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { equipmentReportsColumns } from "../../../utils/columns/multi-page/equipment/equipmentColumns";
import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../components/FlexWrapper.jsx";
import Loading from "../../../components/Loading.jsx";
import axios from "../../../axios/axios.config.js";

const EquipmentReportsPage = () => {
  const [selectedEquipment, setSelectedEquipment] = useState(null);

  const { equipmentData, setEquipmentData } = useOutletContext();
  const navData = useNavigation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const outOfOrderEquipment = equipmentData
    .filter((eq) => eq.status === "out of order")
    .sort((a, b) => {
      // Compare the alphabetical part of equipmentid
      const alphabeticComparison = a.equipmentid.localeCompare(
        b.equipmentid,
        undefined,
        { numeric: true }
      );

      // If the alphabetical part is equal, compare the numerical part
      if (alphabeticComparison === 0) {
        const numA = parseInt(a.equipmentid.substring(2), 10);
        const numB = parseInt(b.equipmentid.substring(2), 10);
        return numA - numB;
      }

      return alphabeticComparison;
    });

  const needsInspection = equipmentData
    .filter(
      (eq) =>
        eq.status === "active" &&
        (!eq.lastinspected || checkLastInspectedDateOver6Hours(eq))
    )
    .sort((a, b) => {
      // Compare the alphabetical part of equipmentid
      const alphabeticComparison = a.equipmentid.localeCompare(
        b.equipmentid,
        undefined,
        { numeric: true }
      );

      // If the alphabetical part is equal, compare the numerical part
      if (alphabeticComparison === 0) {
        const numA = parseInt(a.equipmentid.substring(2), 10);
        const numB = parseInt(b.equipmentid.substring(2), 10);
        return numA - numB;
      }

      return alphabeticComparison;
    });

  function checkLastInspectedDateOver6Hours(eq) {
    if (eq.lastinspected) {
      //check if the equipment has been inspected in the last 10 hours
      // Get the current time
      const currentTime = new Date();
      // Convert ISO string to Date object
      const lastInspectedDate = new Date(eq.lastinspected);

      // Calculate the difference in milliseconds
      const timeDifference = currentTime - lastInspectedDate;

      // Convert milliseconds to hours
      const hoursDifference = timeDifference / (1000 * 60 * 60);

      if (hoursDifference > 6) {
        return true;
      } else {
        return false;
      }
    }
  }

  const handleRowSelection = (rowId) => {
    setSelectedEquipment(rowId);
  };

  const handleViewEquipment = (id) => {
    navigate(id);
  };

  const updateStatus = async (status, id) => {
    const newStatus = status === "active" ? "out of order" : "active";
    try {
      const updateResponse = await axios.patch(
        `equipment/admin/update/status/${id}`,
        { newStatus }
      );

      setEquipmentData(
        updateResponse.data.allEquipment.sort((a, b) => {
          // Compare the alphabetical part of equipmentid
          const alphabeticComparison = a.equipmentid.localeCompare(
            b.equipmentid,
            undefined,
            { numeric: true }
          );

          // If the alphabetical part is equal, compare the numerical part
          if (alphabeticComparison === 0) {
            const numA = parseInt(a.equipmentid.substring(2), 10);
            const numB = parseInt(b.equipmentid.substring(2), 10);
            return numA - numB;
          }

          return alphabeticComparison;
        })
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Activated Equipment",
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Update Status: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 8000,
        })
      );
    }
  };

  // const createEquipment = async () => {
  //   try {
  //   } catch (error) {}
  // };

  if (!equipmentData)
    return (
      <Typography textAlign="center" variant="h3">
        No Data To Display
      </Typography>
    );

  if (navData.state === "loading")
    return <Loading message="Loading Report..." />;

  return (
    <Container maxWidth="xl">
      <Box mb={2}>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Equipment Reports
        </Typography>
        <FlexWrapper justifyContent="flex-end">
          {selectedEquipment && (
            <Button
              variant="contained"
              sx={{ mr: 1, mb: 1 }}
              onClick={() => handleViewEquipment(selectedEquipment[0])}
            >
              View Equipment
            </Button>
          )}
          {/* <Button
            color="success"
            variant="contained"
            startIcon={<Add />}
            sx={{ mb: 1 }}
          >
            Add Equipment
          </Button> */}
        </FlexWrapper>
        <DataGrid
          sx={{ height: "80vh" }}
          onRowSelectionModelChange={handleRowSelection}
          rows={equipmentData}
          columns={equipmentReportsColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
            sorting: {
              sortModel: [{ field: "equipmentid", sort: "asc" }],
            },
          }}
          slots={{ toolbar: GridToolbar }}
          pageSizeOptions={[25, 50, 100]}
          disableDensitySelector
          disableColumnSelector
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              csvOptions: {
                fileName: `equipment-report-${Date.now()}`,
              },
            },
          }}
          density="compact"
          getRowId={(row) => row._id}
        />
      </Box>

      <FlexWrapper justifyContent="space-between">
        <Paper
          elevation={2}
          sx={{
            width: "45%",
            padding: "1rem",
            mb: 1,
          }}
        >
          <Typography variant="h5" gutterBottom textAlign="center">
            Out Of Order
          </Typography>
          <Stack
            sx={{ height: "31vh", overflow: "scroll" }}
            spacing={2}
            divider={<Divider />}
          >
            {outOfOrderEquipment.map((eq) => {
              return (
                <FlexWrapper
                  key={eq._id}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">{eq.equipmentid}</Typography>
                  <FlexWrapper gap={1}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleViewEquipment(eq._id)}
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={() => updateStatus(eq.status, eq._id)}
                    >
                      Activate
                    </Button>
                  </FlexWrapper>
                </FlexWrapper>
              );
            })}
          </Stack>
        </Paper>

        <Paper
          elevation={2}
          style={{
            width: "45%",
            padding: "1rem",
          }}
        >
          <Typography variant="h5" gutterBottom textAlign="center">
            Needs Inspection
          </Typography>
          {/* Render failed equipment data here */}
          <Stack
            sx={{ height: "31vh", overflow: "scroll" }}
            spacing={2}
            divider={<Divider />}
          >
            {needsInspection.map((eq) => {
              return (
                <FlexWrapper
                  key={eq._id}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    {eq.equipmentid}{" "}
                    <strong>
                      <Typography
                        component="span"
                        variant="caption"
                        fontWeight="bold"
                      >
                        (Last Inspection:{" "}
                        {new Date(eq.lastinspected).toLocaleDateString()})
                      </Typography>
                    </strong>
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleViewEquipment(eq._id)}
                  >
                    View
                  </Button>
                </FlexWrapper>
              );
            })}
          </Stack>
        </Paper>
      </FlexWrapper>
    </Container>
  );
};
export default EquipmentReportsPage;
