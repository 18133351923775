import { Box, IconButton, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

import GeneralModalV3 from "../../general/GeneralModalV3";

const ViewVehicleApplicationsModal = ({ data }) => {
  return (
    <GeneralModalV3
      openComponent={
        <IconButton>
          <Info color="primary" />
        </IconButton>
      }
    >
      <Box p={2} maxHeight={"500px"} overflow="scroll">
        <ul>
          {data?.split("\n")?.map((line) => (
            <Typography lineHeight={2.5} component="li">
              {line}
            </Typography>
          ))}
        </ul>
      </Box>
    </GeneralModalV3>
  );
};
export default ViewVehicleApplicationsModal;
