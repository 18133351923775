import { Container, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Confirmation = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        pt: 5,
      }}
    >
      <Typography variant="h2" gutterBottom>
        Thank You!
      </Typography>
      <CheckCircleIcon sx={{ color: "green", fontSize: "60px", mb: 2 }} />
      <Typography
        sx={{ typography: { xs: "body1", md: "h6" } }}
        lineHeight={1.5}
        gutterBottom
      >
        Your account has been created and is now pending approval. Your manager
        will alert you when your account is ready.
      </Typography>
      <Link to="/">
        <Button variant="contained" sx={{ mt: 3 }}>
          Return to Login
        </Button>
      </Link>
    </Container>
  );
};

export default Confirmation;
