import { useState } from "react";
import { Search } from "@mui/icons-material";
import { useNavigate, useNavigation } from "react-router-dom";
import {
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import Loading from "../../../../components/Loading";
import axios from "../../../../axios/axios.config";
import { useSelector } from "react-redux";

const UpdatePackSearch = () => {
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [error, setError] = useState("");

  const navigation = useNavigation();
  const navigate = useNavigate();
  const { currentSubsidiary } = useSelector((state) => state.user);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSearchLoading(true);
    try {
      const response = await axios.get(
        `pack-process/${search}/subsidiary/${currentSubsidiary}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!Object.keys(response.data.packdetails).length) {
        throw new Error("Order Does Not Have Pallet Data.");
      }

      //check to see if any fulfillments are marked as ship and error out if true
      const shippedFulfillmentResponse = await axios.get(
        `netsuite/pack-process/get/shipped-fulfillments/${response.data.createdfrominternalid}/${response.data.shipmentsequence}`
      );

      if (parseInt(shippedFulfillmentResponse.data.count) > 0) {
        throw new Error(
          "One or more fulfillments have already been shipped. Updating pallet information once shipped is not allowed. Need to manually update related Transaction Packages record in NetSuite."
        );
      }

      setError("");
      setSearch("");
      navigate(response.data._id);
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
    } finally {
      setSearchLoading("");
    }
  };

  if (searchLoading || navigation?.state === "loading")
    return (
      <Loading message={searchLoading ? "Searching..." : "Loading Order..."} />
    );

  return (
    <Container maxWidth="md">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Update Pallet Information
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          autoFocus
          required
          size="large"
          label="Search Order"
          error={Boolean(error)}
          helperText={error}
          value={search}
          onChange={(event) => setSearch(event.target.value.toUpperCase())}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton type="submit">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Container>
  );
};
export default UpdatePackSearch;
