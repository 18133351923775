import { Modal, Box, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, md: 500 },
  bgcolor: "rgb(253, 237, 237)",
  color: "rgb(211, 47, 47)",
  borderRadius: "4px",
  boxShadow: 10,
  border: " 1px solid red",
  p: { xs: 4, md: 7 },
};

function InfoModal({ open, setOpen, modalText }) {
  return (
    <Box>
      <Modal
        sx={{ border: "none" }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ display: "flex", alignItems: "center", gap: "5px" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <ErrorIcon />
            <Typography
              sx={{ fontWeight: "bold", fontSize: "18px" }}
              component="strong"
            >
              {modalText.title}
            </Typography>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalText.description}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}

export default InfoModal;
