export const equipmentReportsColumns = [
  {
    field: "equipmentid",
    headerName: "Equipment ID",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
    sortComparator: (a, b) => {
      // Compare the alphabetical part of equipmentid
      const alphabeticComparison = a.localeCompare(b, undefined, {
        numeric: true,
      });

      // If the alphabetical part is equal, compare the numerical part
      if (alphabeticComparison === 0) {
        const numA = parseInt(a.substring(2), 10);
        const numB = parseInt(b.substring(2), 10);
        return numA - numB;
      }

      return alphabeticComparison;
    },
  },
  {
    field: "location",
    headerName: "Location",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "manufacturer",
    headerName: "Manufacturer",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "model",
    headerName: "Model",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "serial",
    headerName: "Serial",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "servicer",
    headerName: "Servicer",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "type",
    headerName: "Type",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "subtype",
    headerName: "Sub Type",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "fueltype",
    headerName: "Fuel Type",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "status",
    headerName: "Status",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "lastinspectedresult",
    headerName: "Last Inspection Result",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.lastinspectedresult ? "Pass" : "Failed";
    },
  },
  {
    field: "lastinspected",
    headerName: "Last Inspection Date",
    type: "date",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return new Date(params.row.lastinspected);
    },
  },
];
