import { useEffect } from "react";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { OVERSTOCKANALYSISCOLUMNS } from "../../utils/columns/procurement/procurementColumns";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { createOverstockAnalysis } from "../../redux/features/procurement/procurementSlice";

const Overstock = () => {
  const dispatch = useDispatch();
  const procurement = useSelector((state) => state.procurement);

  useEffect(() => {
    if (
      procurement.overstockAnalysis.length === 0 &&
      !procurement.overstockAnalysisLastUpdatedDate
    ) {
      dispatch(createOverstockAnalysis());
    }
  }, []);

  if (procurement?.overstockAnalysisLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          height: "80vh",
        }}
      >
        <CircularProgress variant="indeterminate" value={10} />
        <Typography variant="body1" color="primary">
          Loading Overstock Analysis Data...May Take A Few Minutes
        </Typography>
      </Box>
    );
  }

  if (procurement?.overstockAnalysisError)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Typography color="error">{procurement.procurementError}</Typography>
      </Box>
    );

  if (procurement?.overstockAnalysis?.length > 0) {
    return (
      <Container maxWidth="xl" sx={{ py: 3 }}>
        <Typography component="h1" variant="h3" gutterBottom textAlign="center">
          Overstock Analysis
        </Typography>

        <>
          <Typography variant="body2" gutterBottom>
            Data Last Updated On:{" "}
            {new Date(
              procurement.overstockAnalysisLastUpdatedDate
            ).toLocaleString()}
          </Typography>

          <DataGrid
            sx={{ maxHeight: "75vh" }}
            columns={OVERSTOCKANALYSISCOLUMNS}
            rows={procurement.filteredOverstockAnalysis}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                csvOptions: { fileName: `Overstock-${Date.now()}` },
              },
            }}
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 100 },
              },
            }}
            pageSizeOptions={[100, 250, 500, 1000]}
            density="compact"
            getRowId={(row) => row._id}
          />
        </>
      </Container>
    );
  }
};

export default Overstock;
