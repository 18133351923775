import { Box, Paper, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ToteItem = (props) => {
  const { rolelevel } = useSelector((state) => state.user);
  const matches = useMediaQuery("(max-width:600px)");

  const handleEdit = () => {
    props.setModalInfo({
      title: "Enter New Total Quantity",
      action: "edit",
      item: props.item,
      originalQty: props.qty,
    });
    //Sets Open Action Modal With Item Info
    props.setOpen(true);
  };

  const handleDelete = () => {
    props.setModalInfo({
      title: "Are You Sure You Want To Delete This Item?",
      action: "delete",
      item: props.item,
      originalQty: props.qty,
    });
    //Sets Open Action Modal With Item Info
    props.setOpen(true);
  };

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          pointerEvents:
            props.toteStatus === "active" ||
            (props.toteStatus !== "active" &&
              rolelevel != null &&
              rolelevel <= 3)
              ? "initial"
              : "none",
          justifyContent: "space-between",
          alignItems: "center",
          p: "2px 8px",
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            display: "flex",

            alignItems: "center",
          }}
        >
          <Typography sx={{ typography: { md: "h5" } }}>
            {props.toteStatus === "validated" ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CheckCircleIcon
                  color="success"
                  fontSize="small"
                  sx={{ mr: "2px" }}
                />
                {props.item}
              </Box>
            ) : (
              props.item
            )}
          </Typography>
          {props.interchange &&
          props.interchange.interchange &&
          props.interchange.interchange !== props.item ? (
            <Tooltip title="NAPA Number">
              <Typography
                sx={{ alignSelf: "end" }}
                variant="caption"
                component="span"
                color="#001489"
                pl={matches ? "5px" : "10px"}
              >
                ({props.interchange.interchange})
              </Typography>
            </Tooltip>
          ) : null}
          <Typography
            component="span"
            sx={{ typography: { md: "h5" }, pl: matches ? "5px" : "10px" }}
          >
            QTY: {props.qty}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: { xs: 3, md: 5 },
            color: "#d9d9d9",
          }}
        >
          <EditIcon
            sx={{
              cursor: "pointer",
              color:
                props.toteStatus === "active" ||
                (props.toteStatus !== "active" &&
                  rolelevel != null &&
                  rolelevel <= 3)
                  ? "#1976d2"
                  : "#d9d9d9",
            }}
            onClick={handleEdit}
          />
          <DeleteIcon
            sx={{
              cursor: "pointer",
              color:
                props.toteStatus === "active" ||
                (props.toteStatus !== "active" &&
                  rolelevel != null &&
                  rolelevel <= 3)
                  ? "#d32f2f"
                  : "#d9d9d9",
            }}
            onClick={handleDelete}
          />
        </Box>
      </Paper>
    </>
  );
};

export default ToteItem;
