import { Container, Typography } from "@mui/material";
import { useRouteError, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    if (error.status === 403) {
      setTimeout(() => {
        navigate(`/portal`, { replace: true });
      }, 5000);
    }

    if (error.status === 401) {
      localStorage.clear();
      setTimeout(() => {
        navigate(`/`, { replace: true });
      }, 5000);
    }
  }, []);

  return (
    <Container
      maxWidth="md"
      sx={{
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <>
        <Typography variant="h1">{error.status || "Error"}</Typography>
        <Typography
          color="GrayText"
          variant="h3"
          textAlign="center"
          gutterBottom
        >
          {error.statusText ||
            error.message ||
            "An unexpected Error has occured"}
        </Typography>
        {error.status === 403 && (
          <Typography variant="caption">Redirecting to Portal...</Typography>
        )}
        {error.status === 401 && (
          <Typography variant="caption">Redirecting to Login...</Typography>
        )}
      </>
    </Container>
  );
};
export default ErrorPage;
