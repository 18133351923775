import axios from "../../axios/axios.config";
import { redirect } from "react-router-dom";

//Finish logic
export const labelAndBoxRequestFormViewLoader = async ({ params }) => {
  try {
    const formResponse = await axios.get(
      `/forms/labels-box-requests/${params.formid}`
    );

    const urlResponse = await axios.post("files/signedURL", {
      Key: formResponse.data.versions[0].file,
    });

    return { form: formResponse.data, url: urlResponse.data };
  } catch (error) {
    //no data
    if (error.response?.status === 404) {
      return redirect("/portal/customer-service/forms/labels-box-requests");
    }
    //default error
    throw new Response("Error", {
      status: error.status || 500,
      statusText: error.statusText || error.message,
    });
  }
};
