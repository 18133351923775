import { useEffect, useState } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";
import { toggleNav } from "../../../../redux/features/nav/navBarSlice";
import { CheckCircle, ExitToApp, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../../components/FlexWrapper";
import Loading from "../../../../components/Loading";
import EnterCycleCountsStartCountPageComponent from "../../../../components/warehouse/cycle-counts/enter-cycle-counts/EnterCycleCountsStartCountPageComponent";
import axios from "../../../../axios/axios.config";

const EnterCycleCountsIndividualJobPage = () => {
  const { headers, jobs } = useLoaderData();
  const { navigate, navigation, openAlert, user, dispatch, matches } =
    useOutletContext();

  const [jobData, setJobData] = useState(jobs);
  const [headerData, setHeaderData] = useState(headers);
  const [loading, setLoading] = useState("Loaidng Bin Details...");
  const [currentBinPath, setCurrentBinPath] = useState(null); //current bin data (all, remaining, bin)
  const [binNumberMap, setBinNumberMap] = useState(false); //bin number map
  const [uniqueItemsMap, setUniqueItemsMap] = useState(null); //unique items map
  const [binNumberInput, setBinNumberInput] = useState("");

  let isFullyCounted = false;
  //check if fully counted
  if (!currentBinPath) {
    let completeCheck = true;
    for (const bin of Object.keys(binNumberMap)) {
      if (binNumberMap[bin].remaining.length) {
        completeCheck = false;
        break;
      }
    }

    isFullyCounted = completeCheck;
  }

  //create a new map of unique bins that conatin all items and remaing items to count
  useEffect(() => {
    generateData();
  }, [jobData]);

  useEffect(() => {
    dispatch(toggleNav(true));

    return () => {
      dispatch(toggleNav(false));
    };
  }, []);

  const generateData = () => {
    setLoading("Loading Bin Details...");

    if (!jobData) {
      setLoading("");
      return;
    }

    const map = {};
    const itemMap = new Set(); //gathers all unique items

    for (const obj of jobData) {
      if (!map[obj.itemData.itembinnumber]) {
        map[obj.itemData.itembinnumber] = {
          all: [],
          remaining: [],
          counted: [],
        };
      }

      map[obj.itemData.itembinnumber].all.push(obj);

      if (obj.status !== "finished") {
        map[obj.itemData.itembinnumber].remaining.push(obj);
      }
      if (obj.status === "counted") {
        map[obj.itemData.itembinnumber].counted.push(obj);
      }

      //store item name in itemMap
      itemMap.add(obj.itemData.itemname);
    }

    if (currentBinPath) {
      setCurrentBinPath({
        remaining: map[currentBinPath.binNumber].remaining,
        all: map[currentBinPath.binNumber].all,
        binNumber: currentBinPath.binNumber,
      });
    }

    setUniqueItemsMap(itemMap);
    setBinNumberMap(map);
    setLoading("");
  };

  //logs into bin
  const handleSubmitBin = ({ event, binNumber }) => {
    if (event) {
      event.preventDefault();
    }

    if (!binNumberMap[binNumber]) {
      openAlert({ type: "error", message: "Bin Not Found", duration: 5000 });
      return;
    }

    if (headerData.lockedby && headerData.lockedby._id !== user._id) {
      openAlert({
        type: "error",
        message: `Error: This cycle count has already been started by ${headerData.lockedby?.username}.`,
        duration: 5000,
      });
      return;
    }

    //check if we want to re enter bin once finalized
    if (binNumberMap[binNumber].remaining?.length === 0) {
      openAlert({
        type: "error",
        message: "Bin has already been completely counted",
        duration: 5000,
      });
      return;
    }
    //clear input when setting bin
    if (binNumberInput) {
      setBinNumberInput("");
    }

    //set the current bin path if exists
    setCurrentBinPath({
      remaining: binNumberMap[binNumber].remaining,
      all: binNumberMap[binNumber].all,
      binNumber,
    });
  };

  //Changes status to pendingapproval
  const handleCompleteCycleCount = async () => {
    setLoading("Completing Cycle Count...");
    try {
      await axios.patch(
        `cycle-counts-jobs/update/${headerData._id}/complete-count`,
        { userid: user._id }
      );

      openAlert({
        type: "success",
        message: `Successfully Completed Cycle Count: ${headerData.name}`,
        duration: 3000,
      });

      navigate(
        `/portal/${user.currentDepartment?.toLowerCase()}/enter-cycle-counts`
      );
    } catch (error) {
      openAlert({
        type: "error",
        message: error.response?.data?.msg || error.message,
        duration: 10000,
      });
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading) || navigation.state === "loading") {
    return <Loading message={Boolean(loading) ? loading : "Navigating..."} />;
  }

  // Page for when user starts count
  if (currentBinPath) {
    return (
      <EnterCycleCountsStartCountPageComponent
        binPath={currentBinPath}
        setCurrentBinPath={setCurrentBinPath}
        setJobData={setJobData}
        parentId={headerData._id}
        setLoading={setLoading}
        headerData={headerData}
        setHeaderData={setHeaderData}
        uniqueItemsMap={uniqueItemsMap}
      />
    );
  }
  // Displays the bin data and allows user to start count
  return (
    <Container maxWidth="md">
      <Typography variant="h4" textAlign="center" py={2}>
        {headerData.name}
      </Typography>
      <FlexWrapper
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        flexWrap="nowrap"
        width="100%"
        gap={1}
      >
        <Box width="100%">
          <FlexWrapper
            alignItems="center"
            gap={1}
            width="100%"
            justifyContent="space-between"
          >
            <FlexWrapper alignItems="center">
              <Tooltip title="Back" arrow placement="top">
                <IconButton
                  onClick={() =>
                    navigate(
                      `/portal/${user.currentDepartment?.toLowerCase()}/enter-cycle-counts`
                    )
                  }
                >
                  <ExitToApp color="error" />
                </IconButton>
              </Tooltip>

              {isFullyCounted && (
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  onClick={handleCompleteCycleCount}
                >
                  Complete Cycle Count
                </Button>
              )}
            </FlexWrapper>
            <FlexWrapper gap={1}>
              <Typography>Bins: {headerData.uniquebins}</Typography>
              <Typography>Items: {headerData.uniqueitems}</Typography>
            </FlexWrapper>
          </FlexWrapper>
        </Box>

        <Box width="100%">
          {headerData.status !== "complete" && (
            <form
              style={matches ? { width: "100%" } : {}}
              onSubmit={(event) =>
                handleSubmitBin({ event, binNumber: binNumberInput })
              }
            >
              <TextField
                autoFocus
                fullWidth
                required
                label="Scan Bin"
                size="small"
                value={binNumberInput}
                onChange={(event) =>
                  setBinNumberInput(event.target.value?.toUpperCase())
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          )}
        </Box>
      </FlexWrapper>
      <Box
        sx={{
          maxHeight: "calc(100vh - 180px)",
          overflowY: "auto",
          py: 1,
        }}
      >
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    textAlign: "center",
                  }}
                >
                  Bin Number
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    textAlign: "center",
                  }}
                >
                  Items
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    textAlign: "center",
                  }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(binNumberMap)?.map((bin) => (
                <TableRow key={bin}>
                  <TableCell
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => handleSubmitBin({ binNumber: bin })}
                    >
                      {bin}
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      textAlign: "center",
                    }}
                  >
                    {binNumberMap[bin].all[0].status === "finished"
                      ? binNumberMap[bin].all.length
                      : binNumberMap[bin].counted.length}{" "}
                    / {binNumberMap[bin].all.length}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      textAlign: "center",
                    }}
                  >
                    {binNumberMap[bin].remaining?.length === 0 ? (
                      <CheckCircle color="success" />
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default EnterCycleCountsIndividualJobPage;
