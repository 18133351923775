import { useState } from "react";
import { Folder } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Link as MuiLink,
  Divider,
} from "@mui/material";

import GeneralModal from "../../GeneralModal";
import FlexWrapper from "../../FlexWrapper";
import GeneralPDFDownload from "../../PDF/GeneralPDFDownload";
import LaborClaimWRMAPDF from "../../PDF/multi-page/LaborClaimWRMAPDF";
import LaborClaimCreditMemoPDF from "../../PDF/multi-page/LaborClaimCreditMemoPDF";
import NingboCreditMemoPDF from "../../PDF/ningbo/NingboCreditMemoPDF";

const Attachments = ({ form, fileURL, formid, nsPDFLinks }) => {
  const [open, setOpen] = useState(false);

  const { currentDepartment } = useSelector((state) => state.user);

  return (
    <>
      <Tooltip title="Attachments" placement="top" arrow>
        <IconButton onClick={() => setOpen(true)}>
          <Folder color="info" />
        </IconButton>
      </Tooltip>

      {/* Attachments Modal */}
      <GeneralModal open={open} handleClose={() => setOpen(false)}>
        <Box p={2}>
          <FlexWrapper justifyContent="center" alignItems="baseline" gap={1}>
            <Typography variant="h4">Attachments</Typography>

            {(currentDepartment === "RETURNS" ||
              currentDepartment === "PRODUCT" ||
              (currentDepartment === "NINGBO" &&
                form.status === "processed")) && (
              <Link
                to={`/portal/${currentDepartment.toLowerCase()}/forms/labor-claim/form/${formid}/edit/attachments`}
                style={{ color: "#1976d2" }}
              >
                Edit Attachments
              </Link>
            )}
          </FlexWrapper>

          <ul>
            {form ? (
              form.file.map((key, index) => (
                <li key={fileURL[index]}>
                  <MuiLink href={fileURL[index]} target="_blank">
                    {key}
                  </MuiLink>
                </li>
              ))
            ) : (
              <Typography>No Attachments To Display</Typography>
            )}
            {form.status === "complete" && (
              <li>
                <GeneralPDFDownload
                  component={() => (
                    <LaborClaimCreditMemoPDF
                      rma={{
                        number: form.rmaNum.replace("RMA", ""),
                        date: new Date(form.rmaissuedate).toLocaleDateString(),
                        name: form.rmaissuedbyname,
                      }}
                      rga={{ number: form.rgaNum.replace("RGA", "") }}
                      claimDetail={{
                        date: new Date(
                          form.processedbydate
                        ).toLocaleDateString(),
                        reviewed:
                          form.partNumbers?.length &&
                          form.partNumbers
                            .map(
                              (partObj) =>
                                `${partObj.partnumber} x ${partObj.qty}`
                            )
                            .join(", "),
                        company: form.customer,
                        by: "AIT",
                      }}
                      detail={{
                        detail: form.detail,
                        note: form.note,
                      }}
                      internalUse={{
                        restockingFee: form.restockingfee,
                        dateReceived: form.datereceived,
                        receivedBy: form.receivedbyname,
                        result: form.finalresponse,
                        creditMemoNumber: form.creditmemonumber,
                        creditIssuedDate: new Date(
                          form.completeddate
                        ).toLocaleDateString(),
                      }}
                      createdDate={form.date}
                    />
                  )}
                  name={`${form.rmaNum.replace("RMA", "RMA ")}-RESPONSE`}
                  urlView={true}
                  download={false}
                />
              </li>
            )}
            {Boolean(form.rmaNum) && !form.rmainternalid && (
              <li>
                <GeneralPDFDownload
                  component={() => (
                    <LaborClaimWRMAPDF
                      date={form.date}
                      rga={{ number: form.rgaNum }}
                      productDetails={{
                        products: form.partNumbers,
                        total: form.totalClaim,
                      }}
                      rma={{
                        number: form.rmaNum,
                        issuedBy: form.rmaissuedbyname,
                        issuedOn: new Date(
                          form.rmaissuedate
                        ).toLocaleDateString(),
                      }}
                    />
                  )}
                  name={`${form.rmaNum}-RETURN_AUTH`}
                  urlView={true}
                  download={false}
                />
              </li>
            )}
            {form.status === "complete" &&
              form.rmaNum?.startsWith("W") &&
              !form.rmainternalid && (
                <li>
                  <GeneralPDFDownload
                    component={() => (
                      <NingboCreditMemoPDF
                        creditNumber={form.creditmemonumber}
                        creditAmount={form.creditamountfee}
                        creditDate={new Date(
                          form.completeddate
                        ).toLocaleDateString()}
                        rgs={form.rgaNum}
                        items={form.partNumbers?.map(
                          (itemObj) => itemObj.partnumber
                        )}
                        totalItemQty={form.partNumbers?.reduce(
                          (accumulator, item) =>
                            accumulator + parseInt(item.qty),
                          0
                        )}
                      />
                    )}
                    name={`${form.creditmemonumber}-Credit_Memo_Ningbo`}
                    urlView={true}
                    download={false}
                  />
                </li>
              )}
            {((form?.rmaNum && !form.rmaNum.startsWith("W")) ||
              (form?.creditmemonumber &&
                !form.creditmemonumber.startsWith("W"))) && (
              <>
                <Divider
                  sx={{
                    my: 1,
                  }}
                />
                {nsPDFLinks?.rma ? (
                  <li>
                    <MuiLink href={nsPDFLinks.rma} target="_blank">
                      Netsuite Return Auth
                    </MuiLink>
                  </li>
                ) : (
                  <Typography variant="body1">
                    {form.rmaNum ? "Loading Netsuite Return Auth..." : ""}
                  </Typography>
                )}
                {nsPDFLinks?.creditMemo ? (
                  <li>
                    <MuiLink href={nsPDFLinks.creditMemo} target="_blank">
                      Netsuite Credit Memo
                    </MuiLink>
                  </li>
                ) : (
                  <Typography variant="body1">
                    {form.creditmemonumber
                      ? "Loading Netsuite Credit Memo..."
                      : ""}
                  </Typography>
                )}
              </>
            )}
          </ul>
        </Box>
      </GeneralModal>
    </>
  );
};
export default Attachments;
