import axios from "../../axios/axios.config";

export const laborClaimViewLoader = async ({ params }) => {
  try {
    const { formid } = params;

    const formResponse = await axios.get(`forms/labor-claim/${formid}`);
    const fileKeys = formResponse.data.form.file.map(
      (filename) => `forms/laborclaim/${formid}/${filename}`
    );
    const fileURLResponse = await axios.post("files/multi/signedURL", {
      options: { expiresIn: 3600 },
      fileKeys,
    });

    return { data: formResponse.data, fileURL: fileURLResponse.data };
  } catch (error) {
    throw new Response("", {
      status: error.response?.status || 500,
      statusText: error.response?.data?.msg || error.message,
    });
  }
};

export const laborClaimMultiViewLoader = async ({ params }) => {
  const { formid } = params;
  try {
    const [formResponse1, formResponse2] = await Promise.all([
      axios.get(`forms/get/labor-claim/${formid}/form-details`),
      axios.get(`forms/get/labor-claim/${formid}/submission-details`),
    ]);
    return {
      details: formResponse1.data,
      submissionDetails: formResponse2.data,
    };
  } catch (error) {
    return null;
  }
};

export const laborClaimNingboPageLoader = async () => {
  try {
    const formResponse = await axios.get("forms/get/labor-claim/ningbo");

    return formResponse.data;
  } catch (error) {
    return null;
  }
};
