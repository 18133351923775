import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Icon,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import ItemBinTransferWarningModal from "./ItemBinTransferWarningModal";
import axios from "../../../axios/axios.config";
import Loading from "../../Loading";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import QtyErrorModal from "./QtyErrorModal";

const ItemBinDataScanInventoryMove = ({
  itemBinData,
  transferFn,
  setMongoData,
  mongoData,
  binMoveError,
  setBinMoveError,
  setFilteredBins,
  filteredBins,
}) => {
  const [binInput, setBinInput] = useState(mongoData?.sourcebin || "");
  const [itemQtyInput, setItemQtyInput] = useState(mongoData?.qty || "");
  const [destinationBinInput, setDestinationBinInput] = useState("");
  const [open, setOpen] = useState(false);
  const [loadingBinSubmit, setLoadingBinSubmit] = useState(false);
  const [openQtyErrorModal, setOpenQtyErrorModal] = useState("");

  const dispatch = useDispatch();
  const { username, currentSubsidiaryInternalId, currentSubsidiary } =
    useSelector((state) => state.user);

  const existingBin = itemBinData.find((item) => item.binnumber === binInput);
  const isValidQty =
    itemQtyInput > 0 &&
    itemQtyInput <= parseInt(existingBin?.available) &&
    !itemQtyInput.toString().includes(".");
  const existingDestinationBin =
    existingBin && isValidQty && filteredBins
      ? filteredBins.find((item) => item.binnumber === destinationBinInput)
      : null;

  //first paramter is for the ns call and second parameter is for mongo call
  const handleSubmit = async () => {
    await transferFn(
      {
        username,
        currentSubsidiaryInternalId,
        fromBin: existingBin.binnumber,
        toBin: existingDestinationBin.binnumber,
        fromLocation: existingBin.binlocationid,
        toLocation: existingDestinationBin.binlocationid,
        item: itemBinData[0].iteminternalid,
        itemQty: itemQtyInput,
        externalid: `INVMOV_${mongoData?._id}`,
      },
      {
        binnumber: existingDestinationBin.binnumber,
        available: existingDestinationBin.available,
        binid: existingDestinationBin.binid,
        binlocation: existingDestinationBin.binlocation,
        binlocationid: existingDestinationBin.binlocationid,
        preferredbin: existingDestinationBin.preferredbin,
      }
    );
  };

  const handleSubmitSourceBin = async () => {
    // setLoadingBinSubmit(true);
    try {
      const currentNSQuantity = parseInt(existingBin["available"]);
      const currentPickedQty = await getTotalItemQtyCurrentlyBeingPicked();
      const currentMoveQty = await getTotalItemQtyCurrentlyBeingMoved();
      const currentVirtualQty =
        currentNSQuantity - currentPickedQty - currentMoveQty;
      const userCount = parseInt(itemQtyInput);

      //throw error to open qty erro modal
      if (userCount > currentVirtualQty)
        throw new Error(
          `Quantity entered exceeds net available quantity (${currentVirtualQty})`
        );

      const mongoResponse = await axios.patch(
        `inventory-move/${mongoData._id}`,
        {
          sourcebin: {
            binnumber: existingBin.binnumber,
            available: existingBin.available,
            binid: existingBin.binid,
            binlocation: existingBin.binlocation,
            binboxsizetype: existingBin.binboxsizetype,
            binlocationid: existingBin.binlocationid,
            binbrand: existingBin.binbrand,
            preferredbin: existingBin.preferredbin,
            itembrand: existingBin.itembrand,
            itemboxsizetype: existingBin.itemboxsizetype,
          },
          qtytomove: parseInt(itemQtyInput),
          status: "incomplete",
        }
      );

      //filter by brand only
      setFilteredBins(
        itemBinData.filter((item) => {
          if (item.binnumber === existingBin.binnumber) return false;

          return (
            !item.binbrand ||
            !existingBin.itembrand ||
            !item.binboxsizetype ||
            !existingBin.itemboxsizetype ||
            item.binbrand?.includes(existingBin.itembrand)
          );
        })
      );

      //Filter by brand and bin type (old code)
      // setFilteredBins(
      //   itemBinData.filter((item) => {
      //     if (item.binnumber === existingBin.binnumber) return false;

      //     return (
      //       !item.binbrand ||
      //       !existingBin.itembrand ||
      //       !item.binboxsizetype ||
      //       !existingBin.itemboxsizetype ||
      //       (item.binbrand?.includes(existingBin.itembrand) &&
      //         item.binboxsizetype?.includes(existingBin.itemboxsizetype))
      //     );
      //   })
      // );

      setMongoData({
        ...mongoData,
        status: mongoResponse.data.status,
        sourcebin: mongoResponse.data.sourcebin.binnumber,
        qty: mongoResponse.data.qtytomove,
      });
    } catch (error) {
      if (
        error.message.includes(
          "Quantity entered exceeds net available quantity"
        )
      ) {
        //set value to open modal
        setOpenQtyErrorModal(error.message);
      } else {
        dispatch(
          openGeneralAlert({
            type: "error",
            message: error.message,
            duration: 5000,
          })
        );
      }
    } finally {
      setLoadingBinSubmit(false);
    }
  };

  const getTotalItemQtyCurrentlyBeingPicked = async () => {
    try {
      const { data } = await axios.get(
        `pick-order/get/total-item-bin-qty?subsidiary=${currentSubsidiary}&locationinternalid=${parseInt(
          existingBin.binlocationid
        )}&lineiteminternalid=${parseInt(
          existingBin.iteminternalid
        )}&bininternalid=${parseInt(existingBin.binid)}`
      );

      if (data?.totalQty) {
        return data.totalQty;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const getTotalItemQtyCurrentlyBeingMoved = async () => {
    try {
      const { data } = await axios.get(
        `pick-order/get/total-inventory-move-qty?subsidiary=${currentSubsidiary}&locationinternalid=${parseInt(
          existingBin.binlocationid
        )}&lineiteminternalid=${parseInt(
          existingBin.iteminternalid
        )}&bininternalid=${parseInt(existingBin.binid)}`
      );

      if (data?.totalQtyBeingMoved) {
        return data.totalQtyBeingMoved;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  if (loadingBinSubmit)
    return (
      <Box width="100%">
        <Loading message="Submitting Source Bin" />
      </Box>
    );

  return (
    <>
      <ItemBinTransferWarningModal
        open={open}
        setOpen={setOpen}
        destinationBin={destinationBinInput}
        setBinMoveError={setBinMoveError}
        existingBin={existingBin}
        itemQty={itemQtyInput}
        item={itemBinData[0].iteminternalid}
        itemName={itemBinData[0].itemname}
        transferFn={transferFn}
        setFilteredBins={setFilteredBins}
        mongo_id={mongoData?._id}
      />

      <Stack direction="column" spacing={1} width="100%">
        <TextField
          disabled={mongoData.status === "incomplete"}
          autoFocus={Boolean(!existingBin)}
          label="Source Bin"
          value={binInput}
          onChange={(event) => setBinInput(event.target.value.toUpperCase())}
          error={binInput !== "" && !existingBin}
          helperText={
            binInput !== "" && !existingBin
              ? "Please Scan A Valid Source Bin"
              : ""
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {binInput !== "" && existingBin ? (
                  <Icon>
                    <CheckCircle color="success" />
                  </Icon>
                ) : null}
              </InputAdornment>
            ),
          }}
        />
        {existingBin && (
          <>
            <QtyErrorModal
              openVal={openQtyErrorModal}
              setOpenQtyErrorModal={setOpenQtyErrorModal}
            />
            <TextField
              disabled={mongoData.status === "incomplete"}
              autoFocus={Boolean(existingBin)}
              type="number"
              inputMode="numeric"
              label="Item Quantity"
              fullWidth
              value={itemQtyInput}
              onChange={(event) => setItemQtyInput(event.target.value)}
              inputProps={{
                min: 1,
                max: parseInt(existingBin.available),
                step: 1,
                pattern: /^\d*$/,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isValidQty ? (
                      <Icon>
                        <CheckCircle color="success" />
                      </Icon>
                    ) : null}
                  </InputAdornment>
                ),
              }}
              error={itemQtyInput !== "" && !isValidQty}
              helperText={
                itemQtyInput !== "" && !isValidQty ? "Invalid Item Qty" : ""
              }
            />

            {existingBin && isValidQty && mongoData.status === "new" && (
              <Button variant="contained" onClick={handleSubmitSourceBin}>
                Continue
              </Button>
            )}

            {existingBin && isValidQty && mongoData.status === "incomplete" && (
              <TextField
                autoFocus={mongoData.status === "incomplete"}
                fullWidth
                label="Destination Bin"
                value={destinationBinInput}
                onChange={(event) => {
                  setDestinationBinInput(event.target.value.toUpperCase());
                  if (binMoveError) setBinMoveError("");
                }}
                error={Boolean(binMoveError)}
                helperText={
                  binMoveError
                    ? binMoveError
                    : destinationBinInput !== "" && !existingDestinationBin
                    ? "Bin is not in current item bins"
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {destinationBinInput !== "" && existingDestinationBin ? (
                        <Icon>
                          <CheckCircle color="success" />
                        </Icon>
                      ) : null}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </>
        )}
        {mongoData.status === "incomplete" &&
          destinationBinInput !== "" &&
          existingBin &&
          isValidQty &&
          !binMoveError &&
          (!existingDestinationBin ? (
            <Button
              variant="contained"
              onClick={() => {
                setOpen(true);
              }}
            >
              Complete Move
            </Button>
          ) : (
            <Button variant="contained" onClick={handleSubmit}>
              Complete Move
            </Button>
          ))}
      </Stack>
    </>
  );
};
export default ItemBinDataScanInventoryMove;
