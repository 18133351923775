import { Container } from "@mui/material";

import ToteResults from "../components/ToteResults";

const ToteSearch = () => {
  return (
    <Container maxWidth="xl">
      <ToteResults />
    </Container>
  );
};

export default ToteSearch;
