import { useDispatch, useSelector } from "react-redux";
import { removeItemFromPalletV2 } from "../../../../redux/features/pack-process-v2/packProcessV2Slice";
import { Delete } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";

import GeneralModalV3 from "../../../general/GeneralModalV3";
import FlexWrapper from "../../../FlexWrapper";

const PackProcessDeleteItemModal = ({ itemObj }) => {
  const { packdetails, palletnumber, _id } = useSelector(
    (state) => state.packProcessV2
  );
  const dispatch = useDispatch();

  const handleDelete = (closeFn) => {
    dispatch(
      removeItemFromPalletV2({
        _id,
        palletId: packdetails[palletnumber]?.palletId,
        itemName: itemObj.item.item,
        palletItemId: itemObj.palletitemid,
        itemId: itemObj.item._id,
        removeQty: itemObj.packed,
      })
    );
    closeFn();
  };

  return (
    <GeneralModalV3
      openComponent={
        <IconButton size="small">
          <Delete color="error" />
        </IconButton>
      }
    >
      {(handleClose) => (
        <Box p={1}>
          <Typography variant="h4" textAlign="center">
            Delete {itemObj?.item?.item}?
          </Typography>
          <Divider />
          <Typography variant="h6" fontWeight="bold" textAlign="center" py={1}>
            You will be removing Qty ({itemObj.packed}) from Pallet{" "}
            {packdetails[palletnumber]?.palletId}. This action cannot be undone.
          </Typography>
          <Divider />
          <FlexWrapper p="8px 0" justifyContent="flex-end">
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                handleDelete(handleClose);
              }}
            >
              Delete
            </Button>
          </FlexWrapper>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default PackProcessDeleteItemModal;
