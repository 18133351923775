import { useState } from "react";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { useLoaderData, useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  CheckCircle,
  ExitToApp,
  ExpandMore,
  HighlightOff,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../components/FlexWrapper";
import axios from "../../../axios/axios.config";

const SingleEquipmentReport = () => {
  const checklistData = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setEquipmentData } = useOutletContext();

  const [status, setStatus] = useState(checklistData.equipment.status);

  const updateStatus = async () => {
    const newStatus = status === "active" ? "out of order" : "active";
    try {
      const updateResponse = await axios.patch(
        `equipment/admin/update/status/${checklistData.equipment._id}`,
        { newStatus }
      );

      setStatus(updateResponse.data.updatedEquipment.status);
      setEquipmentData(
        updateResponse.data.allEquipment.sort((a, b) => {
          // Compare the alphabetical part of equipmentid
          const alphabeticComparison = a.equipmentid.localeCompare(
            b.equipmentid,
            undefined,
            { numeric: true }
          );

          // If the alphabetical part is equal, compare the numerical part
          if (alphabeticComparison === 0) {
            const numA = parseInt(a.equipmentid.substring(2), 10);
            const numB = parseInt(b.equipmentid.substring(2), 10);
            return numA - numB;
          }

          return alphabeticComparison;
        })
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Updated Status",
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Update Status: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 8000,
        })
      );
    }
  };

  return (
    <Container maxWidth="xl">
      <FlexWrapper justifyContent="space-between">
        <FlexWrapper justifyContent="center">
          <IconButton onClick={() => navigate(-1)} color="error">
            <ExitToApp />
          </IconButton>
          <Typography pl={1} component="span" variant="h6" fontWeight="bold">
            {checklistData.equipment.equipmentid}{" "}
            <Typography
              component="span"
              variant="body2"
              textTransform="capitalize"
              color={status === "active" ? "green" : "error"}
            >
              ({status})
            </Typography>
          </Typography>
        </FlexWrapper>

        {status !== "active" && (
          <Button
            variant="contained"
            color="success"
            onClick={() => updateStatus()}
          >
            {status === "active" ? "Mark as out of order" : "Mark as active"}
          </Button>
        )}
      </FlexWrapper>
      <Container
        maxWidth="md"
        sx={{ height: "86vh", overflow: "scroll", py: 1 }}
      >
        {checklistData.checklists?.length ? (
          checklistData.checklists.map((list, index) => (
            <Accordion defaultExpanded={index === 0} key={list._id}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {list.user} - {new Date(list.createdAt).toLocaleString()}
                </Typography>
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  color={list.result ? "green" : "error"}
                >
                  {list.result ? "PASS" : "FAIL"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Question</TableCell>
                        <TableCell>Result</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list.questions.map((item) => (
                        <TableRow key={item.question}>
                          <TableCell>{item.question}</TableCell>
                          <TableCell>
                            {item.value ? (
                              <CheckCircle style={{ color: "green" }} />
                            ) : (
                              <HighlightOff style={{ color: "red" }} />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {list.comments ? (
                  <>
                    <Typography pt={2} variant="h6">
                      Comments
                    </Typography>
                    <Typography variant="body2" pt={1}>
                      {list.comments}
                    </Typography>
                  </>
                ) : null}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography variant="h4" textAlign="center">
            No Checklists To Display
          </Typography>
        )}
      </Container>
    </Container>
  );
};
export default SingleEquipmentReport;
