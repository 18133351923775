import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import toteSlice from "./features/tote/toteSlice";
import alertSlice from "./features/alert/alertSlice";
import modalSlice from "./features/modal/modalSlice";
import batchesSlice from "./features/batches/batchesSlice";
import errorsSlice from "./features/errors/errorsSlice";
import printerSlice from "./features/printers/printerSlice";
import communicationsSlice from "./features/communications/communicationsSlice";
import formsSlice from "./features/forms/formsSlice";
import procurementSlice from "./features/procurement/procurementSlice";
import packProcessSlice from "./features/pack-process/packProcessSlice";
import packProcessV2Slice from "./features/pack-process-v2/packProcessV2Slice";
import pickOrderSlice from "./features/pick-order/pickOrderSlice";
import navBarSlice from "./features/nav/navBarSlice";
import ec2Slice from "./features/ec2/ec2Slice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    tote: toteSlice,
    alert: alertSlice,
    modal: modalSlice,
    batches: batchesSlice,
    errors: errorsSlice,
    printers: printerSlice,
    communications: communicationsSlice,
    forms: formsSlice,
    procurement: procurementSlice,
    packProcess: packProcessSlice,
    packProcessV2: packProcessV2Slice,
    pickOrder: pickOrderSlice,
    nav: navBarSlice,
    ec2: ec2Slice,
  },
});
