import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { findTote } from "../redux/features/tote/toteSlice";
import { Container } from "@mui/material";
import { setToteError } from "../redux/features/errors/errorsSlice";

import ToteView from "../views/ToteView";
import GeneralAlert from "../components/GeneralAlert";

const ToteResult = () => {
  const { toteid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentTote, setCurrentTote] = useState(false);
  const { currentSubsidiary } = useSelector((state) => state.user);

  useEffect(() => {
    if (currentSubsidiary) {
      getTote();
    }
  }, [currentSubsidiary]);

  const getTote = async () => {
    try {
      const tote = await dispatch(findTote({ toteid })).unwrap();

      if (tote.subsidiary !== currentSubsidiary) {
        dispatch(setToteError("No Tote Access"));
        navigate("/portal/reverse-logistics/tote-manager");
        return;
      }
      setCurrentTote(true);
    } catch (error) {
      dispatch(setToteError(error));
      navigate("/portal/reverse-logistics/tote-manager");
    }
  };

  return (
    currentTote && (
      <Container
        sx={{
          maxWidth: { xs: "xl", md: "md" },
          height: { xs: "calc(100vh - 81px)", md: "calc(100vh - 92.5px)" },
          display: "flex",
          pt: 3,
        }}
      >
        <ToteView />
      </Container>
    )
  );
};

export default ToteResult;
