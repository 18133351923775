import { Info } from "@mui/icons-material";
import {
  IconButton,
  Typography,
  Box,
  Divider,
  Stack,
  Button,
} from "@mui/material";

import GeneralModalV3 from "../../general/GeneralModalV3";
import FlexWrapper from "../../FlexWrapper";

const ReceiveTransactionInfoModal = ({ transactionData }) => {
  return (
    <GeneralModalV3
      openComponent={
        <IconButton>
          <Info color="primary" />
        </IconButton>
      }
    >
      {(handleClose) => (
        <Box>
          <Typography variant="h5" textAlign="center" gutterBottom pt={1}>
            {transactionData.tranid}
          </Typography>
          <Divider />
          <Box px={2} py={1}>
            <Stack spacing={1}>
              <Typography>
                <strong>Transaction Type:</strong>{" "}
                {transactionData.trantype?.toUpperCase()}
              </Typography>
              <Typography>
                <strong>Transaction Date:</strong> {transactionData.date}
              </Typography>
              <Typography>
                <strong>Brand:</strong> {transactionData.brandpackaging}
              </Typography>
              <Typography>
                <strong>Location:</strong> {transactionData.location}
              </Typography>
              <Typography>
                <strong>Entity:</strong> {transactionData.entity}
              </Typography>
              {transactionData.memo && (
                <Typography>
                  <strong>Memo:</strong> {transactionData.memo}
                </Typography>
              )}
              {transactionData.warehousenotes && (
                <Typography>
                  <strong>Warehouse Notes:</strong>{" "}
                  {transactionData.warehousenotes}
                </Typography>
              )}
              {transactionData.tranref && (
                <Typography>
                  <strong>Reference:</strong> {transactionData.tranref}
                </Typography>
              )}
              {transactionData.condition && (
                <Typography>
                  <strong>Condition:</strong>{" "}
                  {transactionData.condition?.toUpperCase()}
                </Typography>
              )}
            </Stack>
          </Box>
          <Divider />
          <Box p={1}>
            <FlexWrapper justifyContent="flex-end">
              <Button variant="contained" color="error" onClick={handleClose}>
                close
              </Button>
            </FlexWrapper>
          </Box>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default ReceiveTransactionInfoModal;
