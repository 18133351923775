import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";

const PickViewOrdersLayout = () => {
  const dispatch = useDispatch();

  const dispatchAlert = ({ type, message, duration }) => {
    dispatch(openGeneralAlert({ type, message, duration }));
  };

  return <Outlet context={{ dispatchAlert }} />;
};
export default PickViewOrdersLayout;
