import axios from "../../../axios/axios.config";

export const userLoader = async () => {
  try {
    const userResponse = await axios.get("users/all");
    const subsidiariesResponse = await axios.get("subsidiaries");
    const printersResponse = await axios.get("printers");
    const locationsResponse = await axios.get("locations");

    return {
      loadedUsers: userResponse.data,
      subsidiaries: subsidiariesResponse.data,
      printers: printersResponse.data,
      locations: locationsResponse.data,
    };
  } catch (error) {
    throw new Response("User Error", {
      status: 500,
      statusText: error.message,
    });
  }
};
