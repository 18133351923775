import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { useNavigate } from "react-router-dom";
import {
  refreshBinData,
  resetAllItems,
  resetPickData,
  reverseWalkRoute,
  updateErrors,
  updateLoading,
} from "../../../redux/features/pick-order/pickOrderSlice";
import { updatePickLockedBy } from "../../../utils/helper-functions/pick-order/updatePickLockedBy";

import axios from "../../../axios/axios.config";
import PickOrderActionsWarningModal from "./PickOrderActionsWarningModal";

const PickOrderActions = ({ value }) => {
  const [actionModal, setActionModal] = useState({
    isOpen: false,
    type: "",
    action: () => {},
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {
    path,
    pick,
    internalid,
    tranid,
    _id,
    trantype,
    onefulfillment,
    lastpicktime,
    lastpickedby,
    firstpicktime,
  } = useSelector((state) => state.pickOrder);
  const { username, currentDepartment } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenActionsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePartialFulfillAction = async () => {
    dispatch(updateLoading("Fulfilling Order..."));
    try {
      const time = new Date().toISOString();

      const storage = {}; // will be final object we use in api call to NetSuite
      const currentItemBinStorage = {}; //will store bins and quantities for our line ids

      for (const item of pick) {
        if (!storage[item.lineid]) {
          storage[item.lineid] = {
            itemreceive: true,
            orderLine: item.lineid,
          };
        }

        //store bin numbers in binstorage
        //each lineid will have an object containing bins as keys and quantities as values
        if (!currentItemBinStorage[item.lineid]) {
          currentItemBinStorage[item.lineid] = {
            [item.binnumber]: parseInt(item.binqtypicked),
          };
        } else {
          if (!currentItemBinStorage[item.lineid][item.binnumber]) {
            currentItemBinStorage[item.lineid][item.binnumber] = parseInt(
              item.binqtypicked
            );
          } else {
            currentItemBinStorage[item.lineid][item.binnumber] += parseInt(
              item.binqtypicked
            );
          }
        }
      }
      //loop through each line id in bin storage
      for (const lineid of Object.keys(currentItemBinStorage)) {
        const currentBinsObj = currentItemBinStorage[lineid];
        const binsArr = Object.keys(currentBinsObj);

        if (binsArr.length > 1) {
          //loop and create a string to update binnumbers and qty
          let finalString = "";
          let finalQty = 0;

          for (const bin of binsArr) {
            finalString =
              finalString === ""
                ? `${bin}(${currentBinsObj[bin]})`
                : finalString + ` ${bin}(${currentBinsObj[bin]})`;

            finalQty += currentBinsObj[bin];
          }

          storage[lineid].binnumbers = finalString;
          storage[lineid].quantity = finalQty;
        } else {
          //update qty and binnumber in storage since only one bin exists
          storage[lineid].quantity = currentBinsObj[binsArr[0]];
          storage[lineid].binnumbers = binsArr[0];
        }
      }

      const nsResponse = await axios.post(
        `netsuite/post/pick-order/order/${internalid}/partial-fulfill`,
        {
          externalid: `${tranid}_${_id}`,
          username,
          items: Object.values(storage),
          trantype,
          tranid,
          _id,
          lastpicktime,
          lastpickedby,
          firstpicktime,
        }
      );

      const { fulfillmentid } = nsResponse.data;
      //update mongo
      await axios.patch(`pick-order/update/order/${_id}/fulfilled`, {
        fulfillmentid,
        time,
      });
      //update pickLockedBy field in netsuite
      updatePickLockedBy({ username: "", internalid, trantype });

      dispatch(resetPickData());
      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully Partially Fulfilled ${tranid}`,
          duration: 6000,
        })
      );
      navigate(`/portal/${currentDepartment.toLowerCase()}/pick-order`, {
        replace: true,
      });
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Fulfill ${tranid}: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 6000,
        })
      );

      //check if netsuite returned erros data and update order
      if (error.response?.data?.data) {
        dispatch(updateErrors(error.response.data.data));
      }

      dispatch(updateLoading(""));
    }
  };

  const handleReverseWalkRouteAction = () => {
    dispatch(reverseWalkRoute());
    handleClose();
  };

  const handleResetAllItemsAction = () => {
    dispatch(resetAllItems());
    handleClose();
  };

  const handleExitPickingAction = () => {
    //update pickLockedBy field in netsuite
    updatePickLockedBy({ username: "", internalid, trantype });
    handleClose();
    dispatch(resetPickData());
    navigate(`/portal/${currentDepartment.toLowerCase()}/pick-order`, {
      replace: true,
    });
    dispatch(
      openGeneralAlert({
        type: "success",
        message: `Successfully Exited ${tranid}`,
        duration: 3000,
      })
    );
  };

  const handleRefreshBinDataAction = async () => {
    try {
      dispatch(refreshBinData());
    } catch (error) {
      console.log(error, "error in refresh bin data action");
    }

    handleClose();
  };

  return (
    <Box sx={{ alignSelf: "flex-end" }}>
      <PickOrderActionsWarningModal
        open={actionModal.isOpen}
        closeFn={() => {
          setActionModal({ type: "", action: () => {}, isOpen: false });
          handleClose();
        }}
        type={actionModal.type}
        action={actionModal.action}
      />
      <Button
        variant="contained"
        size="small"
        onClick={handleOpenActionsMenu}
        endIcon={<KeyboardArrowDown />}
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ textTransform: "uppercase" }}
      >
        {path.length !== 0 && onefulfillment !== "T" && (
          <MenuItem
            onClick={() =>
              setActionModal({
                isOpen: true,
                type: "partial-fulfill",
                action: handlePartialFulfillAction,
              })
            }
          >
            Partial Fulfill
          </MenuItem>
        )}
        {/* Reverse path only */}
        {value === "current" && (
          <MenuItem onClick={handleReverseWalkRouteAction}>
            Reverse Walkroute
          </MenuItem>
        )}
        {/* Call ns and get path and filter out already picked data, and update picked amount */}
        {value === "current" && (
          <MenuItem
            onClick={() =>
              setActionModal({
                isOpen: true,
                type: "refresh-bin",
                action: handleRefreshBinDataAction,
              })
            }
          >
            Refresh Bin Data
          </MenuItem>
        )}
        {/* Call ns and get path and clear pick */}
        {value === "scanned" && (
          <MenuItem
            onClick={() =>
              setActionModal({
                isOpen: true,
                type: "reset-all-items",
                action: handleResetAllItemsAction,
              })
            }
          >
            Reset All Items
          </MenuItem>
        )}
        {/* Clear picklockedby field and return to search page */}
        <MenuItem
          onClick={() =>
            setActionModal({
              isOpen: true,
              type: "cancel-pick",
              action: handleExitPickingAction,
            })
          }
        >
          Cancel Picking
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default PickOrderActions;
