import { Box, Button, Divider, Typography } from "@mui/material";
import GeneralModalV2 from "../../general/GeneralModalV2";
import FlexWrapper from "../../FlexWrapper";
import GeneralModalV3 from "../../general/GeneralModalV3";

const PalletWarningModal = ({ handleClearPallet }) => {
  return (
    <GeneralModalV3
      openComponent={
        <Button variant="contained" color="error" size="small">
          Clear Pallet
        </Button>
      }
    >
      {(handleClose) => (
        <Box>
          <Typography textAlign="center" variant="h4" p={1}>
            Are you sure you want to clear pallet?
          </Typography>
          <Divider />

          <Box p={2}>
            <Typography variant="body1" fontWeight="bold" textAlign="center">
              This action is irreversible!
            </Typography>
          </Box>

          <Divider />
          <Box p={2}>
            <FlexWrapper justifyContent="center" gap={2}>
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleClearPallet}
              >
                Clear Pallet
              </Button>
            </FlexWrapper>
          </Box>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default PalletWarningModal;
