import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { WARRANTYITEMBREAKDOWN } from "../../../utils/columns/multi-page/multiPageColumns";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";

import FlexWrapper from "../../FlexWrapper";
import WarrantyClaimFormPDF from "../../PDF/multi-page/WarrantyClaimFormPDF";
import { pdf } from "@react-pdf/renderer";
import { Download } from "@mui/icons-material";
import { utils, writeFile } from "xlsx";

function CustomToolbar({ form, createdby }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const exportMenuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();

  /*  export to csv */
  const handleCSVExport = useCallback(() => {
    const data = [
      {
        "External ID": `RMA-WR ${form.customerrefrencenumber}`,
        Date: form.date,
        Customer: form.customerparentname
          ? `${form.customerparentname} : ${form.customer}`
          : form.customer,
        Status: "Pending Receipt",
        "PO #": `WR ${form.customerrefrencenumber}`,
        Discount: form.customerdefaultdiscount,
        Item: "Warranty-Pending Approval",
        Quantity: form.totalQty,
        "Price Level": " CUSTOM",
        "Unit Price": 0,
        "Labor Claim Requested Amount": form.discountedprice,
        "RMA Special Notes": "",
        "RMA Item Number": "DIF",
        Description: "DIF",
        Location: "AIT CA : QC CA",
        "RMA TYPE": "WR (Warranty Return)",
        "AIT Dept. Code (Item)": "",
        "Entered By": createdby.name,
      },
    ];

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Create RMA Export");
    writeFile(wb, `Create_RMA_Export_${form.customerrefrencenumber}.csv`, {
      bookType: "csv",
    });
    setAnchorEl(null);
  }, [form]);

  const handlePDFDownload = () => {
    setAnchorEl(null);
    dispatch(
      openGeneralAlert({
        type: "success",
        message: "Downloading File...",
        duration: 3000,
      })
    );

    // Delay the execution of executeDownloadPDF to allow the browser to close the menu
    setTimeout(() => {
      executeDownloadPDF();
    }, 0);
  };

  const executeDownloadPDF = async () => {
    const customerInfo = {
      date: form.date,
      company: form.customer,
      address: form.customerstreetaddress,
      city: form.customercity,
      state: form.customerstate,
      zip: form.customerzip,
      agency: form.customeragency,
      contactname: form.customercontactname,
      phone: form.customerphone,
      ref: form.customerrefrencenumber,
      totalqty: parseInt(form.totalQty).toLocaleString("en-US"),
      totalPrice: form.totalprice
        ? parseFloat(form.totalprice).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "",
      totalDiscountedPrice: form.discountedprice
        ? parseFloat(form.discountedprice).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "",
      discountAmount:
        form.totalprice && form.discountedprice
          ? (
              parseFloat(form.totalprice) - parseFloat(form.discountedprice)
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
      discount: form.customerdefaultdiscount,
      priceLevel: form.customerpricelevel,
    };

    const blob = await pdf(
      <WarrantyClaimFormPDF customerInfo={customerInfo} items={form.items} />
    ).toBlob();

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `Warranty_Claim_${
      customerInfo.company
    }_${Date.now()}.pdf`; // Set the desired filename
    downloadLink.click();

    // Clean up: Revoke the URL to release resources
    URL.revokeObjectURL(url);

    dispatch(
      openGeneralAlert({
        type: "success",
        message: "Successfully Downloaded File",
        duration: 3000,
      })
    );
  };

  return (
    <GridToolbarContainer>
      <FlexWrapper
        justifyContent="center"
        gap={1}
        alignItems="center"
        width="100%"
      >
        <Typography variant="h4" gutterBottom textAlign="center">
          Claim Breakdown
        </Typography>
        <Tooltip title="Warranty Claim Exports">
          <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
            <Download />
          </IconButton>
        </Tooltip>

        <Menu
          id="export-menu"
          anchorEl={anchorEl}
          open={exportMenuOpen}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={handleCSVExport}>Create RMA CSV</MenuItem>
          <MenuItem onClick={handlePDFDownload}>Download PDF</MenuItem>
        </Menu>
      </FlexWrapper>

      <FlexWrapper
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <FlexWrapper gap={3} alignItems="center">
          <GridToolbarFilterButton />
          {/* <GridToolbarExport
            csvOptions={{
              fileName: `WR-Items-${Date.now()}`,
            }}
            printOptions={{
              disableToolbarButton: true,
            }}
          /> */}
        </FlexWrapper>

        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const WarrantyClaimBreakdownComponent = memo(function WarrantyClaimBreakdown({
  form,
  createdby,
}) {
  const getRowClassName = (params) => {
    const row = params.row;

    if (!row.unitprice) return "no-price-highlight";

    return "";
  };

  return (
    <Container maxWidth="xl">
      <Box py={2}>
        <FlexWrapper justifyContent="space-between" flexWrap="wrap" gap={3}>
          <Box>
            <Typography variant="h6">
              Total QTY:{" "}
              <strong>{parseInt(form.totalQty).toLocaleString("en-US")}</strong>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Subtotal:{" "}
              <strong>
                {form.totalprice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </strong>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Discount Amount:{" "}
              <strong>
                {form.totalprice - form.discountedprice === 0 ? "" : "-"}
                {(form.totalprice - form.discountedprice).toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </strong>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Total:{" "}
              <strong>
                {form.discountedprice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </strong>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Discount:{" "}
              <strong>
                {form.customerdefaultdiscount.replace("Discount", "")}
              </strong>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Price Level: <strong>{form.customerpricelevel}</strong>
            </Typography>
          </Box>
        </FlexWrapper>
      </Box>

      <DataGrid
        autoHeight={form.items.length === 0}
        sx={{ maxHeight: "70vh" }}
        rows={form.items}
        slots={{
          toolbar: () => <CustomToolbar form={form} createdby={createdby} />,
        }}
        columns={WARRANTYITEMBREAKDOWN}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        density="compact"
        disableColumnMenu
        disableDensitySelector
        disableColumnSelector
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            csvOptions: { fileName: `WR-Items-${Date.now()}` },
          },
        }}
        getRowId={(row) => row.item}
        getRowClassName={getRowClassName}
      />
    </Container>
  );
});
export default WarrantyClaimBreakdownComponent;
