import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { openGeneralAlert } from "../alert/alertSlice";
import { closeCloseTotesModal } from "../modal/modalSlice";
import { getAllTotes } from "../batches/batchesSlice";

import axios from "../../../axios/axios.config";

const initialState = {
  _id: "",
  toteid: "",
  lastupdatedby: "",
  createdby: "",
  items: [],
  totalitemsqty: 0,
  tranref: "",
  memo: "",
  createdAt: "",
  updatedAt: "",
  subsidiary: "",
  location: "",
  container: "",
  palletnumber: "",
  palletdimensions: "",
  palletweight: "",
};

export const addItem = createAsyncThunk(
  "tote/addItem",
  async ({ itemInfo, itemQty, memo, subsidiary }, thunkAPI) => {
    const { username } = thunkAPI.getState().user;
    const { toteid, items } = thunkAPI.getState().tote;
    try {
      const response = await axios.patch(
        `totes/${toteid}`,
        { itemInfo, itemQty, username, items, memo, subsidiary },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const replaceToteItems = createAsyncThunk(
  "tote/replaceToteItems",
  async ({ newItems, toteID }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `totes/${toteID}/replace/items`,
        { newItems },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const replaceToteItemsAndQty = createAsyncThunk(
  "tote/replaceToteItemsAndQty",
  async ({ newItems, toteID, newToteQTY }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `totes/${toteID}/replace/qty/items`,
        { newItems, newToteQTY },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const findTote = createAsyncThunk(
  "tote/findTote",
  async ({ toteid }, thunkAPI) => {
    try {
      const response = await axios.get(`totes/${toteid}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const createNewTote = createAsyncThunk(
  "tote/createNewTote",
  async (userToteData, thunkAPI) => {
    try {
      const response = await axios.post("totes", userToteData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const changeToteInfo = createAsyncThunk(
  "tote/changeToteInfo",
  async ({ type, input }, thunkAPI) => {
    const { username } = thunkAPI.getState().user;
    const { toteid } = thunkAPI.getState().tote;

    try {
      const response = await axios.patch(
        `totes/${toteid}/toteinfo/${type}`,
        { newInfo: input, username },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const editItemQty = createAsyncThunk(
  "tote/EditItemQty",
  async ({ updatedQty, action, originalQty, item }, thunkAPI) => {
    const { username } = thunkAPI.getState().user;
    const { toteid } = thunkAPI.getState().tote;
    try {
      const response = await axios.patch(
        `totes/${toteid}/${item}`,
        { updatedQty, action, originalQty, username },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const deleteItem = createAsyncThunk(
  "tote/deleteItem",
  async ({ action, item, originalQty, updatedQty }, thunkAPI) => {
    const { username } = thunkAPI.getState().user;
    const { toteid } = thunkAPI.getState().tote;
    try {
      const response = await axios.patch(
        `totes/${toteid}/${item}`,
        { updatedQty, action, originalQty, username },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const closeSelected = createAsyncThunk(
  "tote/closeSelected",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(closeCloseTotesModal());
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "info",
          message: "Updating Selected Totes",
          duration: 5000,
        })
      );

      await Promise.all(
        data.map(async (toteid) => {
          return await axios.patch(
            `totes/${toteid}/update/status`,
            { newStatus: "closed" },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        })
      );

      await thunkAPI.dispatch(getAllTotes());

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Updated Totes",
          duration: 5000,
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: "There was a problem updating totes",
          duration: 5000,
        })
      );
    }
  }
);

export const updatedToteStatus = createAsyncThunk(
  "totes/updateToteStatus",
  async ({ newStatus, toteid }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `totes/${toteid}/update/status`,
        { newStatus: newStatus },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: "There was a problem updating status",
          duration: 5000,
        })
      );
    }
  }
);

export const updateToteInformation = createAsyncThunk(
  "tote/updateToteInformation",
  async (
    {
      container,
      palletnumber,
      palletdimensions,
      palletweight,
      tranref,
      memo,
      _id,
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.patch(
        `totes/${_id}/update/tote-information`,
        {
          container,
          palletnumber,
          palletdimensions,
          palletweight,
          tranref,
          memo,
        }
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.msg || error.message
      );
    }
  }
);

const toteSlice = createSlice({
  name: "tote",
  initialState,
  reducers: {
    resetTote: (state) => initialState,
    setTote: (state, { payload }) => {
      return payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(findTote.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(createNewTote.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(changeToteInfo.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(editItemQty.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(deleteItem.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(addItem.fulfilled, (state, { payload }) => {
        return payload.updatedTote;
      })
      .addCase(replaceToteItems.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(replaceToteItemsAndQty.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(updatedToteStatus.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(updateToteInformation.fulfilled, (state, { payload }) => {
        return {
          ...state,
          memo: payload.memo,
          tranref: payload.tranref,
          container: payload.container,
          palletnumber: payload.palletnumber,
          palletdimensions: payload.palletdimensions,
          palletweight: payload.palletweight,
        };
      });
  },
});
export const { resetTote, setTote } = toteSlice.actions;
export default toteSlice.reducer;
