import { Error } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";

import GeneralModalV3 from "../../general/GeneralModalV3";
import FlexWrapper from "../../FlexWrapper";

const ReceiveTransactionErrorModal = ({ open, setInputError }) => {
  return (
    <GeneralModalV3 openVal={Boolean(open)} onClose={() => setInputError("")}>
      {(handleClose) => (
        <FlexWrapper flexDirection="column" alignItems="center">
          <Error fontSize="large" color="error" sx={{ mt: 1 }} />

          <Typography variant="h5" fontWeight="bold" textAlign="center" py={1}>
            Could Not Receive Item
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            textAlign="center"
            pb={2}
            px={1}
          >
            {open}
          </Typography>

          <Button
            color="error"
            variant="contained"
            size="large"
            onClick={() => {
              setInputError("");
              handleClose();
            }}
            sx={{ mb: 2 }}
          >
            OK
          </Button>
        </FlexWrapper>
      )}
    </GeneralModalV3>
  );
};
export default ReceiveTransactionErrorModal;
