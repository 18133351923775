import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPalletV2 } from "../../../../redux/features/pack-process-v2/packProcessV2Slice";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../FlexWrapper";

const PackProcessInfoModal = ({ close }) => {
  const {
    _id,
    createdfromtype,
    createdfrom,
    itemfulfillments,
    brand,
    createdfromdate,
    location,
    customer,
    ponumber,
    totalitemstopack,
    createdfrommemo,
    createdfromnotes,
    createdfromprioritystatus,
    warehousenotes,
    relatedtos,
    createdfromsoreference,
    packdetails,
  } = useSelector((state) => state.packProcessV2);
  const dispatch = useDispatch();

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(true);

  const handleClose = () => {
    close(false);
  };

  const handleStartPack = () => {
    close(false);
    console.log("starting pack");
    dispatch(createPalletV2({ _id, details: { palletId: 1 } }));
  };

  return (
    <>
      <Dialog fullScreen open={true} disableEscapeKeyDown>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Typography variant="h4" textAlign="center">
              {createdfromtype?.toUpperCase()}
            </Typography>
            <Typography variant="h5" textAlign="center">
              {createdfrom}
            </Typography>
            <Typography variant="h5" textAlign="center">
              {Object.keys(itemfulfillments).join(" - ")}
            </Typography>
            <Typography variant="h5" textAlign="center">
              {brand}
            </Typography>

            <Stack
              bgcolor="#e8f2fc"
              spacing={1}
              p={1}
              borderRadius={2}
              sx={{ width: { xs: "100%", md: "35%" } }}
            >
              {createdfromdate && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Date: </span>
                  {createdfromdate}
                </Typography>
              )}
              {location && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Location: </span>
                  {location}
                </Typography>
              )}
              {customer && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Customer: </span>
                  {customer}
                </Typography>
              )}
              {ponumber && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>PO #: </span>
                  {ponumber}
                </Typography>
              )}
              {totalitemstopack && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Total Qty: </span>
                  {totalitemstopack}
                </Typography>
              )}
              {createdfrommemo && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Memo: </span>
                  {createdfrommemo}
                </Typography>
              )}
              {createdfromnotes && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Notes: </span>
                  {createdfromnotes}
                </Typography>
              )}
              {createdfromprioritystatus && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Priority Status: </span>
                  {createdfromprioritystatus}
                </Typography>
              )}
              {warehousenotes && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Warehouse Notes: </span>
                  {warehousenotes}
                </Typography>
              )}
              {relatedtos && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Related TO: </span>
                  {relatedtos}
                </Typography>
              )}
              {Boolean(createdfromsoreference.trim()) && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>SO Reference: </span>
                  {createdfromsoreference}
                </Typography>
              )}
            </Stack>
          </Box>

          <FlexWrapper justifyContent="flex-end" p="10px 0">
            {packdetails.length ? (
              <Button size="large" onClick={handleClose}>
                Continue
              </Button>
            ) : (
              <Button size="large" onClick={handleStartPack}>
                Start Pack
              </Button>
            )}
          </FlexWrapper>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default PackProcessInfoModal;
