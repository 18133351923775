import axios from "../../axios/axios.config";

export const registerLoader = async () => {
  try {
    const printersResponse = await axios.get("printers");

    return printersResponse.data;
  } catch (error) {
    throw new Response("", {
      statusText: error.response?.data?.msg || error.message,
      status: error.response?.status || 500,
    });
  }
};
