import { isAuthenticated } from "../../utils/auth/verifyAuth";
import { store } from "../../redux/store";
import { redirect } from "react-router-dom";
import { getUserFromStorage } from "../../redux/features/user/userSlice";
import { getEc2InstanceId } from "../../redux/features/ec2/ec2Slice";

export const adminLoader = async () => {
  try {
    await isAuthenticated();
    let user = store.getState().user;
    const { instanceId } = store.getState().ec2;

    if (!user._id) {
      store.dispatch(getUserFromStorage());
      user = JSON.parse(localStorage.getItem("user"));
    }

    if (user.rolelevel !== 0) {
      throw new Response("Unauthorized", {
        status: 403,
        statusText: "You do not have access to this page.",
      });
    }

    if (!instanceId) {
      //dispatch action to get ec2 instance id
      store.dispatch(getEc2InstanceId());
    }

    return null;
  } catch (error) {
    if (error.response?.status === 401 || error.status === 401) {
      localStorage.clear();
      return redirect("/");
    }

    //default error
    throw new Response("Error", {
      status: error.status || 500,
      statusText: error.statusText || error.message,
    });
  }
};
