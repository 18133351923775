import { Button, Container, Typography } from "@mui/material";
import { ErrorOutline, CheckCircleOutline, Report } from "@mui/icons-material";

import FlexWrapper from "../../../components/FlexWrapper";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const EquipmentChecklistResult = ({ inspectionPass }) => {
  const navigate = useNavigate();
  const { currentDepartment } = useSelector((state) => state.user);

  return (
    <Container maxWidth="md">
      <FlexWrapper
        width="100%"
        height="40vh"
        alignItems="center"
        justifyContent="center"
      >
        <FlexWrapper alignItems="center" flexDirection="column" gap={1}>
          {inspectionPass ? (
            <CheckCircleOutline fontSize="large" color="success" />
          ) : (
            <Report fontSize="large" color="error" />
          )}

          <Typography variant="h5" pl={1} py={1} textAlign="center">
            {inspectionPass
              ? "Machine has passed validation and is safe to use."
              : "Machine has not passed inspection and it is not safe to use. Please see your supervisor."}
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              navigate(`/portal/${currentDepartment.toLowerCase()}`)
            }
          >
            Confirm
          </Button>
        </FlexWrapper>
      </FlexWrapper>
    </Container>
  );
};
export default EquipmentChecklistResult;
