import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { resetUser, updatePassword } from "../../redux/features/user/userSlice";
import { Business, Check, LocationOn, Work } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import axios from "../../axios/axios.config";

const ProfileGeneral = () => {
  const [error, setError] = useState("");
  const [input, setInput] = useState({
    pass: "",
    reenterpass: "",
    newpass: "",
  });
  const { user, matches, navigate, dispatch } = useOutletContext();

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    try {
      if (!input.pass || !input.reenterpass || !input.newpass) {
        throw new Error("Fields Cannot Be Empty");
      }

      if (input.newpass !== input.reenterpass) {
        throw new Error("New Passwords Do Not Match");
      }

      await axios.post(`users/${user.username}/check-password`, {
        password: input.pass,
      });

      await dispatch(
        updatePassword({ input: input.newpass, username: user.username })
      );
      dispatch(resetUser());
      navigate("/");
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
    }
  };

  const handleChanges = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setError("");
  };

  return (
    <>
      <Box pb={4}>
        <Typography variant="h5" gutterBottom>
          User Information
        </Typography>
        <Divider />
        <Box sx={{ pt: 2, display: "flex", gap: 1 }}>
          <Avatar alt="User Default" />

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1">
              {user.firstname} {user.lastname}
            </Typography>
            <Typography sx={{ color: "#808080" }} variant="caption">
              {user.username}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          gap: matches ? 1 : 3,
          pb: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Business />
          <Typography variant="subtitle1">Subsidiaries: </Typography>
        </Box>
        <Typography component="div">
          {user.subsidiary.map((s) => (
            <Chip
              component="span"
              key={s}
              icon={s === user.currentSubsidiary ? <Check /> : null}
              variant={s === user.currentSubsidiary ? "filled" : "outlined"}
              label={s}
            />
          ))}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          gap: matches ? 1 : 3,
          pb: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <LocationOn />
          <Typography variant="subtitle1">Locations: </Typography>
        </Box>
        <Typography component="div">
          {user.location.map((l) => (
            <Chip
              component="span"
              key={l.location}
              icon={l.location === user.currentLocation ? <Check /> : null}
              variant={
                l.location === user.currentLocation ? "filled" : "outlined"
              }
              label={l.location}
            />
          ))}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          gap: matches ? 1 : 3,
          pb: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Work />
          <Typography variant="subtitle1">Departments: </Typography>
        </Box>
        <Typography component="div">
          {user.department.map((d) => (
            <Chip
              component="p"
              key={d}
              icon={d === user.currentDepartment ? <Check /> : null}
              variant={d === user.currentDepartment ? "filled" : "outlined"}
              label={d}
            />
          ))}
        </Typography>
      </Box>

      <Box pt={3}>
        <Typography variant="h5" gutterBottom>
          Password
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid
          container
          spacing={1}
          direction="column"
          component="form"
          onSubmit={handleSubmitPassword}
        >
          <Grid item xs={5}>
            <TextField
              fullWidth={matches}
              type="password"
              id="password"
              error={Boolean(error)}
              name="pass"
              label="Password"
              required
              value={input.pass}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth={matches}
              type="password"
              id="reenterpass"
              error={Boolean(error)}
              name="reenterpass"
              label="New Password"
              value={input.reenterpass}
              required
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth={matches}
              type="password"
              id="newpass"
              name="newpass"
              label="Confirm New Password"
              required
              value={input.newpass}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography color="error" variant="caption">
              {error}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Button fullWidth={matches} type="submit" variant="contained">
              Submit
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Typography
              textAlign={matches ? "center" : "left"}
              variant="caption"
            >
              (You will be signed out after submitting)
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default ProfileGeneral;
