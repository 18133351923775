import axios from "../../axios/axios.config";

export const cycleCountsPageLoader = async () => {
  try {
    const subsidiary = localStorage.getItem("subsidiary");
    const locations = localStorage.getItem("netsuitelocations");
    const statusArr = ["new", "pending-cycle-count", "need-recount"];
    const sort = { bin: 1 };
    const response = await axios.get(
      `pick-shorts/status/multi?statusArr=${JSON.stringify(
        statusArr
      )}&sort=${JSON.stringify(
        sort
      )}&subsidiary=${subsidiary}&locations=${locations}`
    );

    return response.data;
  } catch (error) {
    return null;
  }
};

export const addCycleCountPageLoader = async ({ params }) => {
  const { id } = params;

  try {
    const response = await axios.get(`pick-shorts/get/${id}`);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const createCycleCountsPageLoader = async () => {
  const location = localStorage.getItem("location");
  try {
    const response = await axios.get(
      `cycle-counts-jobs/get/status/new?location=${location}`
    );

    return response.data;
  } catch (error) {
    return [];
  }
};

export const enterCycleCountsIndividualJobLoader = async ({ params }) => {
  try {
    const response = await axios.get(`cycle-counts-jobs/${params.jobid}`);

    return {
      headers: {
        _id: response.data._id,
        name: response.data.name,
        uniquebins: response.data.uniquebins,
        uniqueitems: response.data.uniqueitems,
        status: response.data.status,
        startedby: response.data.startedby,
        startedon: response.data.startedon,
        lockedby: response.data.lockedby,
        creationtype: response.data.creationtype,
      },
      jobs: response.data.jobs,
    };
  } catch (error) {
    return null;
  }
};

export const reviewCycleCountsPageLoader = async () => {
  const location = localStorage.getItem("location");
  try {
    const pendingResponse = await axios.get(
      `cycle-counts-jobs/get/status/pendingapproval?location=${location}`
    );
    const completeResponse = await axios.get(
      `cycle-counts-jobs/get/status/complete?location=${location}`
    );

    return [...pendingResponse.data, ...completeResponse.data];
  } catch (error) {
    return null;
  }
};

export const reviewCycleCountsCountDetailsPageLoader = async ({ params }) => {
  try {
    const response = await axios.get(
      `cycle-counts-jobs/get/review-count-details/${params.jobid}`
    );

    return response.data;
  } catch (error) {
    return null;
  }
};
