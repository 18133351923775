import { useState } from "react";
import { Add } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { CYCLECOUNTSNEWJOBSCOLUMNS } from "../../../../utils/columns/cycle-counts/cycleCountsColumns";
import { useLoaderData } from "react-router-dom";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { Button, Container, Menu, MenuItem, Typography } from "@mui/material";

import FlexWrapper from "../../../../components/FlexWrapper";
import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import CreateCycleCountUploadCSVModal from "../../../../components/warehouse/cycle-counts/create-cycle-count/CreateCycleCountUploadCSVModal";
import CreateCycleCountsFullscreenModal from "../../../../components/warehouse/cycle-counts/create-cycle-count/CreateCycleCountsFullscreenModal";

const CreateCycleCounts = () => {
  const loaderJobsData = useLoaderData();
  const user = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();

  const [jobsData, setJobsData] = useState(loaderJobsData); //data that will be displayed in grid
  const [loading, setLoading] = useState("");
  const [selected, setSelected] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);

  const selectedJob = selected
    ? jobsData.find((item) => item._id === selected)
    : null;

  const handleDeleteJob = async () => {
    setLoading("Deleting...");
    try {
      //delete jobs data
      await axios.delete(`cycle-counts-jobs/delete/${selectedJob._id}`);

      //get new data and update UI
      const newDataResponse = await axios.get(
        `cycle-counts-jobs/get/status/new?location=${user.currentLocation}`
      );

      setJobsData(newDataResponse.data);
      setSelected(null);
      openAlert({
        type: "success",
        duration: 3000,
        message: `Successfully deleted ${selectedJob.name}`,
      });
    } catch (error) {
      openAlert({
        type: "error",
        duration: 5000,
        message: `Could not ${selectedJob.name}`,
      });
    } finally {
      setLoading("");
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setOpenModal("");
  };

  if (loading) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="xl">
      <CreateCycleCountsFullscreenModal
        open={openModal}
        handleClose={handleClose}
        setJobsData={setJobsData}
      />
      <Typography variant="h4" textAlign="center" gutterBottom>
        Create Cycle Count Jobs
      </Typography>
      <FlexWrapper justifyContent="flex-end" gap={2}>
        <Button
          variant="contained"
          color="success"
          startIcon={<Add />}
          sx={{ mb: 1 }}
          onClick={handleClick}
        >
          New Job
        </Button>
        <Menu
          id="craete-cyclecount-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setOpenModal("Item");
            }}
          >
            Create by Item
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setOpenModal("Bin");
            }}
          >
            Create by Bin
          </MenuItem>
          <CreateCycleCountUploadCSVModal
            handleMenuClose={handleMenuClose}
            user={user}
            openAlert={openAlert}
            axios={axios}
            setLoading={setLoading}
            setJobsData={setJobsData}
          />
        </Menu>

        {selected ? (
          <Button color="error" variant="contained" onClick={handleDeleteJob}>
            Delete {selectedJob ? selectedJob.name : ""}
          </Button>
        ) : null}
      </FlexWrapper>
      <DataGrid
        rows={jobsData}
        columns={CYCLECOUNTSNEWJOBSCOLUMNS}
        sx={{ height: "70vh", mt: 1 }}
        getRowId={(row) => row._id}
        density="compact"
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        onRowSelectionModelChange={(row) => setSelected(row[0])}
      />
    </Container>
  );
};

export default CreateCycleCounts;
