import { getUserFromStorage } from "../../redux/features/user/userSlice";
import { store } from "../../redux/store";
import { isAuthenticated } from "../../utils/auth/verifyAuth";
import { redirect } from "react-router-dom";

export const sessionLoader = async () => {
  try {
    const response = await isAuthenticated();
    if (response.data === "Token Validated") {
      let user = store.getState().user;
      if (!user._id) {
        store.dispatch(getUserFromStorage());
        return JSON.parse(localStorage.getItem("user"));
      }
      return null;
    }
  } catch (error) {
    if (error.response?.status === 401 || error.status === 401) {
      return redirect("/");
    }
  }
};
