import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toteError: "",
};

const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setToteError: (state, { payload }) => {
      state.toteError = payload;
    },
  },
});

export const { setToteError } = errorsSlice.actions;
export default errorsSlice.reducer;
