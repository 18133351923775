import { useState } from "react";
import { read, utils } from "xlsx";
import { useSalesRepCustomerMatch } from "../../../hooks/useSalesRepCustomerMatch";
import { Download, ExitToApp } from "@mui/icons-material";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import GeneralFileUpload from "../../../components/GeneralFileUpload";
import axios from "../../../axios/axios.config";
import Loading from "../../../components/Loading";
import FlexWrapper from "../../../components/FlexWrapper";

const WarrantyFormAddMulti = () => {
  const templateURL = useLoaderData();
  const [files, setFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const [formInput, setFormInput] = useState({
    date: new Date().toLocaleDateString(),
    customer: "",
    customeragency: "",
    customercontactname: "",
    customerphone: "",
    customeremail: "",
    customerstreetaddress: "",
    customerstreetaddress2: "",
    customercity: "",
    customerstate: "",
    customerzip: "",
    customerdefaultdiscount: "",
    customerdiscount: "",
    customerpricelevel: "",
    customerrefrencenumber: "",
    customerfullbillingaddress: "",
    pricelevelid: null,
    items: [],
    totalQty: 0,
    customerparentid: "",
    customerparentname: "",
    customergroup: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { filteredCustomers } = useSalesRepCustomerMatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      if (!files.length) {
        throw new Error("File is required");
      }

      //create warranty claim on mongo
      const { data } = await axios.post("forms/create/form", {
        type: "warranty-claim",
        status: "active",
        subsidiary: user.currentSubsidiary,
        createdby: {
          username: user.username,
          name: `${user.firstname} ${user.lastname}`,
          department: user.currentDepartment,
          usertype: user.usertype,
          email: user.email,
        },
        form: {
          ...formInput,
          file: files[0].name,
          status: "created",
        },
      });

      //save files to S3 using mongoid
      const formData = new FormData();

      formData.append("files", files[0]);
      formData.append("folder", `forms/warrantyclaim/${data._id}/`);

      await axios.post("/files/upload/multi", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const discountRateResponse = await axios.get(
        `netsuite/get/warranty-claim/rate/${data.form.customerdefaultdiscountid}`
      );

      const rate = discountRateResponse.data.rate;

      //call to add price to each item and set status to new
      axios.patch(
        `netsuite/post/items/price/${data.form.pricelevelid}/warranty/${data._id}`,
        {
          items: data.form.items,
          discount: rate,
        }
      );

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/warranty`
      );
      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Created Warranty Claim",
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Create Warranty Claim: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleChanges = (event) => {
    setFormInput({
      ...formInput,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddCustomerData = (c) => {
    let customerdefaultdiscount = c.defaultdiscount;
    let customerdefaultdiscountid = c.defaultdiscountid;
    let customerdiscount = c.customerdiscount;

    if (c.companyname === "FACTORY MOTOR PARTS") {
      customerdefaultdiscount = "18% Discount";
      customerdefaultdiscountid = 12156;
      customerdiscount = "18%";
    }

    if (c.companyname === "UNITED AUTO SUPPLY OF SYRACUSE WEST, INC") {
      customerdefaultdiscount = "21.5% Discount";
      customerdefaultdiscountid = 72918;
      customerdiscount = "21.5%";
    }

    if (
      c.companyname === "FISHER AUTO PARTS" ||
      c.parentname === "FISHER AUTO PARTS"
    ) {
      customerdefaultdiscount = "16.27% Discount";
      customerdefaultdiscountid = 11240;
      customerdiscount = "16.27%";
    }

    setFormInput({
      ...formInput,
      customer: c.companyname,
      customeragency: c.partner,
      customerstreetaddress: c.billingaddress1,
      customerstreetaddress2: c.billingaddress2,
      customercity: c.billingcity,
      customerstate: c.billingstate,
      customerzip: c.billingzip,
      customerdefaultdiscount,
      customerdiscount,
      customerdefaultdiscountid,
      customerpricelevel: c.pricelevel,
      customerfullbillingaddress: c.billingaddress,
      customergroup: c.customergroup,
      customerparentname: c.parentname,
      customerparentid: c.parentid,
      pricelevelid: c.pricelevelid,
      customerinternalid: c.internalid,
    });
  };

  /* FILE FUNCTIONS */
  const readFileAsync = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        try {
          const arrayBuffer = e.target.result;
          const workbook = read(arrayBuffer, { type: "array" });

          // read from the one sheet
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          // Parse the sheet data as an array of objects
          const excelData = utils.sheet_to_json(worksheet, { header: 1 });
          resolve(excelData); // Resolve the Promise with the data
        } catch (error) {
          reject(new Error("Error Reading file: " + error.message)); // Reject the Promise with an error
        }
      };

      fileReader.readAsArrayBuffer(file);
    });
  };

  const validateFileParts = async (excelData, fileItems) => {
    let totalQuantity = 0;

    for (let i = 1; i < excelData.length; i++) {
      const [item, qty, reason, reference] = excelData[i];

      if (!item) throw new Error("File Error: ITEM field is required.");
      if (!qty) throw new Error("File Error: QTY field is required.");
      if (!reason) throw new Error("File Error: REASON field is required.");

      totalQuantity += parseInt(qty);

      const response = await axios.get(`items/${item}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { subsidiary: user.currentSubsidiary },
      });

      if (!response.data.length) {
        throw new Error(`File Error: ${item} is not a valid part number.`);
      }

      const {
        internalid,
        class: itemClass,
        catalogstatus,
        sspart,
        item: mongoItem,
      } = response.data[0];

      fileItems.push({
        item: mongoItem,
        qty,
        reason,
        reference: reference ? reference : null,
        internalid,
        class: itemClass,
        catalogstatus,
        sspart,
      });
    }
    setFormInput({ ...formInput, items: fileItems, totalQty: totalQuantity });
  };

  const handleSaveFiles = async (dropzoneFiles) => {
    setLoadingFile(true);
    setFiles([]);

    try {
      for (const file of dropzoneFiles) {
        if (
          ![
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/csv",
          ].includes(file.type)
        ) {
          throw new Error(
            "Invalid File Format: Please ensure file is in csv or excel format."
          );
        }

        const excelData = await readFileAsync(file);
        const headers = excelData[0];
        //validate headers
        if (
          headers.length < 3 ||
          headers.length > 4 ||
          !headers.includes("PART NUMBER") ||
          !headers.includes("QTY") ||
          !headers.includes("REASON")
        ) {
          throw new Error("File Error: Missing or Invalid Headers");
        }

        const fileItems = [];

        //validate part numbers
        await validateFileParts(excelData, fileItems);

        setFiles([file]);
      }

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Validated File",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: error.message,
          duration: 7000,
        })
      );
    } finally {
      setLoadingFile(false);
    }
  };

  const handleRemoveFile = (file) => {
    setFiles(files.filter((f) => f.path !== file.path));
  };

  if (loadingFile)
    return (
      <Loading message="Loading File Please Wait, Could Take Up To A Minute..." />
    );

  if (submitting) return <Loading message="Submitting Warranty Form..." />;

  return (
    <Container maxWidth="md" sx={{ pb: 2 }}>
      <Box>
        <Tooltip title="Back To Warranty Forms" placement="top" arrow>
          <IconButton
            onClick={() =>
              navigate(
                `/portal/${user.currentDepartment.toLowerCase()}/forms/warranty`
              )
            }
          >
            <ExitToApp color="error" />
          </IconButton>
        </Tooltip>

        <Typography variant="h3" textAlign="center" gutterBottom>
          Warranty Claim
        </Typography>
      </Box>

      <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            label="Created Date"
            name="date"
            value={formInput.date}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
            Customer Details
          </Typography>

          <FormControl fullWidth required>
            <InputLabel id="select-label-comapnies">
              {filteredCustomers.length === 0
                ? "Loading Companies..."
                : "Customer Name"}
            </InputLabel>
            <Select
              labelId="select-label-companies"
              id="select-company"
              label={
                filteredCustomers.length === 0
                  ? "Loading Companies..."
                  : "Customer Name"
              }
              disabled={filteredCustomers.length === 0}
              name="customer"
              value={formInput.customer}
            >
              {filteredCustomers.map((c) => (
                <MenuItem
                  key={c._id}
                  value={c.companyname}
                  onClick={() => handleAddCustomerData(c)}
                >
                  {c.companyname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="customeragency"
            label="Customer Agency"
            value={formInput.customeragency}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="customerstreetaddress"
            label="Street Address"
            value={formInput.customerstreetaddress}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            name="customerstreetaddress2"
            label="Street Address 2"
            value={formInput.customerstreetaddress2}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="customercity"
            label="City"
            value={formInput.customercity}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="text"
            name="customerstate"
            label="State"
            value={formInput.customerstate}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="text"
            name="customerzip"
            label="Zip Code"
            value={formInput.customerzip}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="customercontactname"
            label="Contact Name"
            value={formInput.customercontactname}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            helperText={
              <Typography variant="caption" color="error">
                No Special Characters
              </Typography>
            }
            type="tel"
            inputMode="numeric"
            name="customerphone"
            label="Phone"
            value={formInput.customerphone}
            onChange={handleChanges}
            inputProps={{
              pattern: "[0-9]*",
              title: "Phone number must only contain numbers.",
              type: "tel",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="email"
            name="customeremail"
            label="E-mail"
            value={formInput.customeremail}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="customerrefrencenumber"
            label="Customer Reference Number"
            value={formInput.customerrefrencenumber}
            onChange={handleChanges}
          />
        </Grid>

        <>
          <Grid item xs={12}>
            <FlexWrapper justifyContent="space-between" alignItems="center">
              <Typography gutterBottom variant="h5" sx={{ mt: 2 }}>
                Attatchments
              </Typography>

              <Tooltip
                title="Click Here To View Example Template"
                placement="top"
                arrow
              >
                <Link
                  href={templateURL}
                  download={"WC-Template.xlsx"}
                  target="_blank"
                >
                  <IconButton>
                    <Download color="primary" />
                  </IconButton>
                </Link>
              </Tooltip>
            </FlexWrapper>

            <FlexWrapper gap={1}>
              <Typography variant="body1" component="li">
                Ensure the following headers are present in your file: "PART
                NUMBER", "QTY", "REASON", "REFERENCE NUMBER" (OPTIONAL).
              </Typography>
              <Typography variant="body1" component="li" mb={1}>
                Ensure all parts entered are valid.
              </Typography>
            </FlexWrapper>

            <GeneralFileUpload
              required
              handleSaveFiles={handleSaveFiles}
              handleRemoveFile={handleRemoveFile}
              files={files}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ py: 1 }}
              disabled={submitting || loadingFile}
            >
              {submitting ? "Submitting Form..." : "Create Warranty Form"}
            </Button>
          </Grid>
        </>
      </Grid>
    </Container>
  );
};

export default WarrantyFormAddMulti;
