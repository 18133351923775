const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isNavBarHidden: false,
};

const pickOrderSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    toggleNav: (state, { payload }) => {
      state.isNavBarHidden = payload;
    },
  },
});

export const { toggleNav } = pickOrderSlice.actions;
export default pickOrderSlice.reducer;
