import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axios/axios.config";

const initialState = {
  dealSheetRows: [],
  dealSheetLoading: false,
  dealSheetError: false,
  formRows: [],
};
export const getFormRows = createAsyncThunk(
  "forms/getFormRows",
  async ({ type }, { rejectWithValue, getState }) => {
    try {
      const { currentSubsidiary } = getState().user;
      const response = await axios.get(
        `/forms/${type}?subsidiary=${currentSubsidiary}`
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (!error.response.data.msg) {
        return rejectWithValue("Server Error");
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);
export const getFilteredFormRows = createAsyncThunk(
  "forms/getFilteredFormRows",
  async ({ filter }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/forms/get/filtered-forms`, filter);
      const data = await response.data;

      return data;
    } catch (error) {
      if (!error.response.data.msg) {
        return rejectWithValue("Server Error");
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

export const createForm = createAsyncThunk(
  "forms/createProposal",
  async ({ newForm, type }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`/forms/${type}`, { newForm });
      await dispatch(getFormRows({ type }));

      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return rejectWithValue("Server Error");
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

export const createFormNoVersion = createAsyncThunk(
  "forms/createFormNoVersion",
  async ({ newForm, type }, { rejectWithValue, dispatch, getState }) => {
    try {
      const { currentSubsidiary } = getState().user;

      await axios.post(`/forms/${type}/no-version`, {
        newForm,
        subsidiary: currentSubsidiary,
      });
      // await dispatch(getFormRows({ type }));
    } catch (error) {
      if (!error.response.data.msg) {
        return rejectWithValue("Server Error");
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

export const addNewVersion = createAsyncThunk(
  "forms/addNewVersion",
  async ({ id, version, type }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(`/forms/${type}/${id}`, {
        version,
      });

      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return rejectWithValue("Server Error");
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

export const updateForm = createAsyncThunk(
  "forms/updateForm",
  async ({ id, updateData, type }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(`/forms/update/no-version/${id}`, {
        updateData,
      });

      await dispatch(getFormRows({ type }));
      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return rejectWithValue("Server Error");
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

const formSlice = createSlice({
  name: "form",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getFormRows.fulfilled, (state, { payload }) => {
        state.formRows = payload;
      })
      .addCase(getFilteredFormRows.fulfilled, (state, { payload }) => {
        state.formRows = payload;
      });
  },
});

export default formSlice.reducer;
