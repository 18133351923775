import { Box, Button, Divider, Typography } from "@mui/material";
import { useOutletContext, useParams } from "react-router-dom";

import GeneralModalV3 from "../../general/GeneralModalV3";
import FlexWrapper from "../../FlexWrapper";
import axios from "../../../axios/axios.config";

const ReceiveTransactionDeleteConfirmationModal = ({
  item,
  setItemToDelete,
  setTransactionData,
}) => {
  const { setLoading } = useOutletContext();
  const { transaction } = useParams();

  const handleDeleteItem = async (handleClose) => {
    setLoading("Removing Item...");
    try {
      const response = await axios.patch(
        `receive-transaction/${transaction}/remove-item/${item._id}/${item.itemsid}`,
        { qtyReceived: item.qtyreceived }
      );

      setTransactionData(response.data);
    } catch (error) {
    } finally {
      setItemToDelete(null);
      handleClose();
      setLoading("");
    }
  };

  return (
    <GeneralModalV3
      openVal={Boolean(item)}
      onClose={() => setItemToDelete(null)}
    >
      {(handleClose) => (
        <Box>
          <Typography variant="h5" textAlign="center" py={2}>
            Are you sure you want to delete <strong>{item?.item}</strong>?
          </Typography>
          <Divider />
          <Typography textAlign="center" py={3} variant="subtitle1">
            You will remove quantity of <strong>{item?.qtyreceived}</strong>{" "}
            from this order.
            <br />
            <strong>Action cannot be undone.</strong>
          </Typography>
          <Divider />
          <FlexWrapper
            gap={2}
            height="10vh"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              onClick={() => {
                setItemToDelete(null);
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => handleDeleteItem(handleClose)}
              color="error"
            >
              Confirm And Delete
            </Button>
          </FlexWrapper>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default ReceiveTransactionDeleteConfirmationModal;
