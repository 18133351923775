import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import axios from "../../../axios/axios.config";
import { useSelector } from "react-redux";
import Loading from "../../Loading";

const ReceiveTransactionViewActiveOrders = ({ setTransaction }) => {
  const [userOrders, setUserOrders] = useState(null);
  const [loading, setLoading] = useState("");

  const { username } = useSelector((state) => state.user);
  const matches = useMediaQuery("(max-width:600px)");
  const { openAlert } = useGeneralAlert();

  useEffect(() => {
    handleGetUserOrders();
  }, []);

  const handleGetUserOrders = async () => {
    setLoading("Loading Orders...");
    try {
      const response = await axios.get(
        `receive-transaction/get/active-orders/${username}`
      );

      setUserOrders(response.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get orders: ${
          error.response?.data?.msg || error.message
        }`,
      });
    } finally {
      setLoading("");
    }
  };
  const handleCardClick = (order) => {
    setTransaction(order?.toUpperCase());
  };

  if (loading) {
    return <Loading message={loading} />;
  }

  return (
    <Box py={2}>
      <Typography variant="h6" textAlign="center">
        Your Orders
      </Typography>

      <Box sx={matches ? { height: "55vh", overflow: "scroll" } : {}}>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          pb={1}
          gap={1}
        >
          {userOrders?.length ? (
            userOrders.map((order) => (
              <Grid key={order._id} item xs={12} sm={6} md={4}>
                <Card
                  style={{
                    height: "100%",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  variant="outlined"
                  onClick={() => handleCardClick(order.tranid)}
                >
                  <CardContent style={{ paddingBottom: "16px" }}>
                    <Typography variant="subtitle1" textAlign="center">
                      {order.tranid}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Craeted Date:</strong>{" "}
                      {new Date(order.createdAt).toLocaleDateString()}
                    </Typography>

                    {order.entity && (
                      <Typography variant="body2">
                        <strong>Customer:</strong> {order.entity}
                      </Typography>
                    )}
                    {order.location && (
                      <Typography variant="body2">
                        <strong>Location:</strong> {order.location}
                      </Typography>
                    )}
                    <Typography variant="body2">
                      <strong>Total To Receive:</strong>{" "}
                      {order.totalitemstoreceive}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Total Received :</strong> {order.totalreceived}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography textAlign="center">No orders to display</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default ReceiveTransactionViewActiveOrders;
