import { useState } from "react";
import { Menu, MenuItem, Typography, Tooltip, IconButton } from "@mui/material";
import { CSVLink } from "react-csv";
import { exports } from "../utils/exports/exportData";
import { useMultiCSVS } from "../hooks/useMultiCSVS";

import FileOpenIcon from "@mui/icons-material/FileOpen";

const ExportMultiCSVS = ({ type, selected, disabled = false, color }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [exportData, formatArray] = useMultiCSVS(selected);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {type === "button" ? (
        <Typography
          onClick={handleClick}
          id="csv-button"
          aria-controls={open ? "csv-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={{ p: 1 }}
        >
          EXPORTS
        </Typography>
      ) : (
        <IconButton
          disabled={disabled}
          onClick={handleClick}
          id="csv-button"
          aria-controls={open ? "csv-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Tooltip title="Export Totes" placement="top" arrow>
            <FileOpenIcon color={color} />
          </Tooltip>
        </IconButton>
      )}
      <Menu
        id="csv-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "csv-button",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {exports.map((exportType) => {
          return (
            <CSVLink
              onClick={(e) => {
                formatArray(e, exportType.name);
                handleClose();
              }}
              filename={
                `${exportType.name}-${selected.join("_")}.csv`.length > 207
                  ? `${exportType.name}-Multi_Tote_Export_${Date.now()}.csv`
                  : `${exportType.name}-${selected.join("_")}.csv`
              }
              key={exportType.name}
              data={exportData}
              headers={exportType.headers}
            >
              <MenuItem>
                <Typography variant="body1" color="black">
                  {exportType.name}
                </Typography>
              </MenuItem>
            </CSVLink>
          );
        })}
      </Menu>
    </div>
  );
};

export default ExportMultiCSVS;
