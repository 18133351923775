import { useState } from "react";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  TextField,
  Button,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

const Filter = ({
  filters,
  resetFilters,
  isFiltered,
  setIsFiltered,
  updateFn,
  arrayToFilter,
  filterTypes,
  customFilterFn,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(filters[0].name);
  const [currentComparison, setCurrentComparison] = useState("equals");
  const [currentValue, setCurrentValue] = useState("");
  const [match, setMatch] = useState({ type: false, msg: "" });
  const matches = useMediaQuery("(max-width:600px)");
  const open = Boolean(anchorEl);

  const openFilterMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setCurrentFilter(filters[0].name);
    setCurrentComparison("equals");
    setCurrentValue("");
    setMatch({ type: false, msg: "" });
    setAnchorEl(null);
  };

  const applyFilters = (e) => {
    e.preventDefault();
    let filteredArr = [];
    if (currentValue === "") {
      setMatch({ type: true, msg: "Value Cannot Be Empty" });
      return;
    }
    if (customFilterFn) {
      filteredArr = customFilterFn(
        arrayToFilter,
        currentValue,
        currentComparison
      );
    } else {
      if (filterTypes[currentFilter] === "date") {
        switch (currentComparison) {
          case "greaterthan":
            filteredArr = arrayToFilter.filter((item) => {
              return new Date(item[currentFilter]) > new Date(currentValue);
            });
            break;
          case "lessthan":
            filteredArr = arrayToFilter.filter((item) => {
              return new Date(item[currentFilter]) < new Date(currentValue);
            });
            break;
          default:
            filteredArr = arrayToFilter.filter((item) => {
              return (
                new Date(item[currentFilter]).toLocaleDateString() ===
                new Date(currentValue).toLocaleDateString()
              );
            });
        }
      } else if (filterTypes[currentFilter] === "number") {
        switch (currentComparison) {
          case "greaterthan":
            filteredArr = arrayToFilter.filter((item) => {
              return item[currentFilter] > parseInt(currentValue);
            });
            break;
          case "lessthan":
            filteredArr = arrayToFilter.filter((item) => {
              return item[currentFilter] < parseInt(currentValue);
            });
            break;
          default:
            filteredArr = arrayToFilter.filter((item) => {
              return item[currentFilter] === parseInt(currentValue);
            });
        }
      } else {
        filteredArr = arrayToFilter.filter((item) => {
          if (currentComparison === "equals") {
            return (
              item[currentFilter].toLowerCase() === currentValue.toLowerCase()
            );
          }
          return item[currentFilter]
            .toLowerCase()
            .includes(currentValue.toLowerCase());
        });
      }
    }

    if (filteredArr.length === 0) {
      setMatch({ type: true, msg: "No Matches" });
      return;
    }
    updateFn(filteredArr);
    setIsFiltered(true);
    handleClose();
  };

  return (
    <div>
      <Tooltip title="Filter list">
        <span>
          <IconButton
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={openFilterMenu}
            sx={{ display: isFiltered ? "none" : "block" }}
          >
            <FilterListIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Clear Filters">
        <span>
          <IconButton
            id="clear-filters"
            sx={{ display: !isFiltered ? "none" : "block" }}
            onClick={() => {
              resetFilters();
              setIsFiltered(false);
            }}
          >
            <FilterListOffIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          sx={{
            cursor: "default",
            "&:hover": { backgroundColor: "white" },
          }}
        >
          <Box
            component="form"
            type="submit"
            onSubmit={(e) => {
              applyFilters(e);
            }}
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: matches ? "column" : "row",
            }}
          >
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="select-label-filters">Filters</InputLabel>
              <Select
                labelId="select-label-filters"
                id="simple-select-filters"
                label="Filters"
                value={currentFilter}
                onChange={(e) => {
                  setCurrentFilter(e.target.value);
                }}
              >
                {filters.map((filter) => {
                  return (
                    <MenuItem
                      key={filter.name}
                      value={filter.name}
                      name={`${filter.value}-${filter.value}`}
                    >
                      {filter.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="select-label-comparison">Comparison</InputLabel>
              <Select
                labelId="select-label-comparison"
                id="simple-select-comparison"
                label="Comparison"
                value={currentComparison}
                onChange={(e) => {
                  setCurrentComparison(e.target.value);
                }}
              >
                <MenuItem key="equals" value="equals">
                  Equals
                </MenuItem>
                {filterTypes[currentFilter] === "string" && (
                  <MenuItem key="contains" value="contains">
                    Contains
                  </MenuItem>
                )}
                <MenuItem
                  key="less"
                  value="lessthan"
                  sx={{
                    display:
                      filterTypes[currentFilter] !== "string"
                        ? "block"
                        : "none",
                  }}
                >
                  Less Than
                </MenuItem>
                <MenuItem
                  key="greater"
                  value="greaterthan"
                  sx={{
                    display:
                      filterTypes[currentFilter] !== "string"
                        ? "block"
                        : "none",
                  }}
                >
                  Greater Than
                </MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="outlined-value"
              label="Value"
              variant="outlined"
              size="small"
              value={currentValue}
              onFocus={() => setMatch({ type: false, msg: "" })}
              onChange={(e) => setCurrentValue(e.target.value)}
            />

            <Button
              size="small"
              type="submit"
              variant="contained"
              color="success"
              onClick={(e) => {
                applyFilters(e);
              }}
            >
              Apply
            </Button>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ display: match.type ? "block" : "none", pointerEvents: "none" }}
        >
          <Typography color="error" textAlign="center">
            {match.msg}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Filter;
