import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatedToteStatus } from "../redux/features/tote/toteSlice";
import { openGeneralAlert } from "../redux/features/alert/alertSlice";
import {
  MenuItem,
  Typography,
  Menu,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";

const UpdateToteStatus = ({ currentTote, currentStatus }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [toteStatus, setToteStatus] = useState(currentStatus);
  const dispatch = useDispatch();
  const { rolelevel } = useSelector((state) => state.user);
  const open = Boolean(anchorEl);

  const openFilterMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChanges = async (e) => {
    try {
      await dispatch(
        updatedToteStatus({ newStatus: e.target.value, toteid: currentTote })
      ).unwrap();

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Updated Status To ${e.target.value.toUpperCase()}`,
          duration: 5000,
        })
      );
      setToteStatus(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MenuItem
      sx={{
        textAlign: "center",
        display: "block",
        p: 0,
        display:
          toteStatus !== "active" && rolelevel != null && rolelevel > 3
            ? "none"
            : "block",
      }}
    >
      <Typography sx={{ p: 1 }} onClick={openFilterMenu}>
        UPDATE TOTE STATUS
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          sx={{ "&:hover": { backgroundColor: "white" } }}
          onClick={handleClose}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="tote-status-group-label"
              value={toteStatus}
              name="radio-buttons-group"
              onChange={handleChanges}
            >
              <FormControlLabel
                value="active"
                control={<Radio />}
                label="Active"
                sx={{
                  display:
                    rolelevel != null && rolelevel <= 3 ? "block" : "none",
                }}
              />
              <FormControlLabel
                value="pending"
                control={<Radio />}
                label="Pending"
              />
              <FormControlLabel
                value="closed"
                control={<Radio />}
                label="Closed"
                sx={{
                  display:
                    rolelevel != null && rolelevel <= 3 ? "block" : "none",
                }}
              />
            </RadioGroup>
          </FormControl>
        </MenuItem>
      </Menu>
    </MenuItem>
  );
};

export default UpdateToteStatus;
