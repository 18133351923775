import axios from "axios";
let URL = "//192.168.5.26:8000/";

if (process.env.REACT_APP_DOMAIN_URL) {
  URL = process.env.REACT_APP_DOMAIN_URL;
} else {
  if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {
    URL = "//app.trakmotive.com/";
  }
  if (process.env.REACT_APP_NODE_ENV === "DEVELOPMENT") {
    URL = "//dev.trakmotive.co/";
  }
}

const axiosInstance = axios.create({
  baseURL: URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
