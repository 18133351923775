import { useState } from "react";
import { updateLanguage } from "../../redux/features/user/userSlice";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const ProfileLanguagePage = () => {
  const { language } = useSelector((state) => state.user);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const handleChangeLanguage = async (event) => {
    event.preventDefault();
    try {
      await dispatch(updateLanguage({ language: selectedLanguage })).unwrap();
      i18n.changeLanguage(selectedLanguage);
      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Updated Language",
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  };

  return (
    <Box pb={4}>
      <Typography variant="h5" gutterBottom>
        Language
      </Typography>
      <Divider />

      <Typography variant="body1" py={2}>
        Current Language:{" "}
        <Typography component="span" fontWeight="bold">
          {language.toUpperCase()}
        </Typography>
      </Typography>

      <Grid
        component="form"
        container
        alignItems="stretch"
        spacing={1}
        onSubmit={handleChangeLanguage}
      >
        <Grid item xs={12} md={8}>
          <FormControl fullWidth required>
            <InputLabel id="select-language">Select Language</InputLabel>
            <Select
              labelId="select-language"
              id="select-language"
              label="Select Language"
              name="language"
              value={selectedLanguage}
              onChange={(event) => setSelectedLanguage(event.target.value)}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="es">Spanish</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ height: "100%" }}
            disabled={language === selectedLanguage}
          >
            Change
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProfileLanguagePage;
