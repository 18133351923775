import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  useMediaQuery,
  Box,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { printLabel } from "../utils/printer-functions/printLabelFunctions";
import { printToteItems } from "../utils/printer-functions/printToteItems";
import { openGeneralAlert } from "../redux/features/alert/alertSlice";
import ExportCSVS from "./ExportCSVS";
import UpdateToteStatus from "./UpdateToteStatus";
import { Link } from "react-router-dom";

export default function SplitActionToteActions() {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const matches = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const tote = useSelector((state) => state.tote);
  const { currentLocation, rolelevel } = useSelector((state) => state.user);
  const { currentPrinter } = useSelector((state) => state.printers);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box>
      <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button">
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select tote action"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          Tote Actions
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                  sx={{
                    textAlign: "center",
                    display: matches ? "flex" : "initial",
                    flexDirection: "column",
                  }}
                >
                  {/* <MenuItem
                    key="Merge"
                    sx={{
                      display: "block",
                      pointerEvents:
                        tote.status !== "active" ? "none" : "initial",
                    }}
                  >
                    MERGE
                  </MenuItem> */}

                  <UpdateToteStatus
                    currentTote={tote.toteid}
                    currentStatus={tote.status}
                  />
                  {(tote.status !== "validated" ||
                    (tote.status === "validated" && rolelevel <= 3)) && (
                    <Link
                      to={"validate"}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <MenuItem sx={{ display: "block" }}>
                        VALIDATE TOTE
                      </MenuItem>
                    </Link>
                  )}

                  <ExportCSVS type="button" arrayToExport={tote.items} />

                  <MenuItem
                    key="reprint"
                    sx={{ display: "block" }}
                    onClick={() => {
                      printLabel(tote, currentPrinter.printnodeid);
                      setOpen(false);
                      dispatch(
                        openGeneralAlert({
                          type: "success",
                          message: "Reprinting Tote Label",
                          duration: 3000,
                        })
                      );
                    }}
                  >
                    REPRINT TOTE LABEL
                  </MenuItem>
                  <MenuItem
                    key="print item labels"
                    sx={{ display: "block" }}
                    onClick={() => {
                      printToteItems(tote, currentLocation);
                      setOpen(false);
                      dispatch(
                        openGeneralAlert({
                          type: "success",
                          message: "Printing Item Labels",
                          duration: 3000,
                        })
                      );
                    }}
                  >
                    PRINT ITEM LABELS
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
