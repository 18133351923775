import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeMemoModalOnCreate } from "../redux/features/modal/modalSlice";
import { createNewTote } from "../redux/features/tote/toteSlice";
import { printLabel } from "../utils/printer-functions/printLabelFunctions";
import {
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { removeLocalStorage } from "../utils/logout/logout";

export default function RadioModal() {
  const [input, setInput] = useState("");
  const [tranRef, setTranRef] = useState("");
  const [isRadioError, setIsRadioError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMemoModalOpenOnCreate } = useSelector((state) => state.modal);
  const { username, currentLocation, currentSubsidiary } = useSelector(
    (state) => state.user
  );
  const { currentPrinter } = useSelector((state) => state.printers);

  const handleClose = () => {
    dispatch(closeMemoModalOnCreate());
    setInput("");
    setIsRadioError(false);
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const createTote = async (e) => {
    e.preventDefault();

    try {
      if (input === "") {
        setIsRadioError(true);
        return;
      }
      const userToteData = {
        createdby: username,
        lastupdatedby: username,
        location: currentLocation,
        subsidiary: currentSubsidiary,
        memo: input,
        tranref: tranRef ? tranRef : "",
      };

      const tote = await dispatch(createNewTote(userToteData)).unwrap();

      printLabel(tote, currentPrinter.printnodeid);
      navigate(`/portal/reverse-logistics/tote-manager/${tote.toteid}`);
      setInput("");
    } catch (error) {
      if (error === "Unauthorized, Please Log In") {
        removeLocalStorage();
        navigate("/");
      }
    }
    dispatch(closeMemoModalOnCreate());
  };

  return (
    <Box>
      <Dialog
        open={isMemoModalOpenOnCreate}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
      >
        <DialogTitle>Select Memo</DialogTitle>
        <DialogContent>
          <FormControl type="submit" onSubmit={createTote} component="form">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={input}
              onChange={handleChange}
            >
              <FormControlLabel
                value="TrakMotive-CA"
                control={<Radio />}
                label="TrakMotive-CA"
              />
              <FormControlLabel
                value="TrakMotive-NC"
                control={<Radio />}
                label="TrakMotive-NC"
              />
              <FormControlLabel
                value="NAPA-CA"
                control={<Radio />}
                label="NAPA-CA"
              />
              <FormControlLabel
                value="O'Reilly-CA"
                control={<Radio />}
                label="O'Reilly-CA"
              />
              <FormControlLabel
                value="AutoZone-NC"
                control={<Radio />}
                label="AutoZone-NC"
              />
              <FormControlLabel
                value="Scrap"
                control={<Radio />}
                label="Scrap"
              />
              <FormControlLabel
                value="Return To Vendor"
                control={<Radio />}
                label="Return To Vendor"
              />
              <FormControlLabel
                value="Manual-Adjust"
                control={<Radio />}
                label="Manual-Adjust"
              />
              <FormControlLabel
                value="Return To Vendor-NWI"
                control={<Radio />}
                label="Return To Vendor-NWI"
              />
            </RadioGroup>
            {input === "Manual-Adjust" && (
              <TextField
                sx={{ mt: 2 }}
                value={tranRef}
                onChange={(event) => setTranRef(event.target.value)}
                label="Enter Tran Ref"
                size="small"
                fullWidth
                helperText={
                  <Typography variant="caption">
                    Tran Ref is required for Manual-Adjust totes
                  </Typography>
                }
              />
            )}
            <Typography
              variant="caption"
              sx={{
                color: "#D32F2F",
                pt: 2,
                display: isRadioError ? "block" : "none",
              }}
            >
              Memo is required
            </Typography>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={input === "Manual-Adjust" && tranRef === ""}
            onClick={createTote}
          >
            Create Tote
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
