import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../components/FlexWrapper";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../axios/axios.config";
import Loading from "../../components/Loading";
import PackProcessDetailsResults from "../../components/warehouse/PackProcessDetailsResults";
import { useSelector } from "react-redux";

const PackProcessDetails = () => {
  const [order, setOrder] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderShipmentSequences, setOrderShipmentSequences] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentSubsidiary } = useSelector((state) => state.user);

  useEffect(() => {
    if (searchParams.get("order")) {
      handleSearch();
    }
  }, [searchParams]);

  const handleInputChanges = (event) => {
    setOrder(event.target.value.trim().toUpperCase());
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setSearchParams({ order });
  };

  const handleSearch = async () => {
    setLoading(true);
    setError(false);
    try {
      const orderNumber = searchParams.get("order");
      let shipmentSequence = searchParams.get("shipmentsequence");

      if (!orderNumber) {
        throw new Error("Order Number is a required field");
      }

      if (!shipmentSequence) {
        shipmentSequence = "none";
      }

      const orderResponse = await axios.get(
        `pack-process/order/${orderNumber}/shipmentsequence/${shipmentSequence}/subsidiary/${currentSubsidiary}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (!orderResponse.data.order.packdetails) {
        throw new Error("Order Does Not Have Any Pallet Data");
      }
      setSearchParams({
        order: orderNumber,
        shipmentsequence:
          shipmentSequence === "none"
            ? orderResponse.data.shipmentSequences[
                orderResponse.data.shipmentSequences.length - 1
              ]
            : orderResponse.data.shipmentSequences.find(
                (sequence) => sequence === parseInt(shipmentSequence)
              ),
      });
      setOrderShipmentSequences(orderResponse.data.shipmentSequences);
      setOrderDetails(orderResponse.data.order);
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
      setSearchParams({});
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading message="Loading Order Details..." />;
  }

  //search results
  if (
    !loading &&
    !error &&
    searchParams.get("order") &&
    searchParams.get("shipmentsequence") &&
    orderDetails
  ) {
    return (
      <PackProcessDetailsResults
        orderDetails={orderDetails}
        shipmentSequences={orderShipmentSequences}
      />
    );
  }

  //search
  if (
    !loading &&
    !searchParams.get("order") &&
    !searchParams.get("shipmentsequence")
  ) {
    return (
      <Container maxWidth="md">
        <form onSubmit={handleFormSubmit}>
          <FlexWrapper alignItems="center" flexDirection="column">
            <Typography variant="h4" component="h1" gutterBottom>
              View Pallet Details
            </Typography>
            <TextField
              autoFocus
              required
              fullWidth
              error={Boolean(error)}
              helperText={error}
              value={order}
              onChange={handleInputChanges}
              label="Enter Order Number"
              name="order"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{ cursor: "pointer" }}
                    position="start"
                    onClick={handleFormSubmit}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FlexWrapper>
        </form>
      </Container>
    );
  }
};
export default PackProcessDetails;
