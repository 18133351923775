import { store } from "../../redux/store";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";

import axios from "../../axios/axios.config";

export const bugReportAction = async ({ request }) => {
  try {
    const formData = Object.fromEntries(await request.formData());

    await axios.post("feedback/bugreports", formData);

    store.dispatch(
      openGeneralAlert({
        type: "success",
        message: "Thank You! Report Successfully Submitted",
        duration: 3000,
      })
    );

    return null;
  } catch (error) {
    store.dispatch(
      openGeneralAlert({
        type: "error",
        message: `Could not submit report: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      })
    );

    return null;
  }
};
