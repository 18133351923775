import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Folder } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Link as MUILink,
  Tooltip,
  Typography,
} from "@mui/material";

import GeneralModalV3 from "../../general/GeneralModalV3";
import axios from "../../../axios/axios.config";
import FlexWrapper from "../../FlexWrapper";
import Loading from "../../Loading";
import GeneralPDFDownload from "../../PDF/GeneralPDFDownload";
import LaborClaimWRMAPDF from "../../PDF/multi-page/LaborClaimWRMAPDF";
import LaborClaimCreditMemoPDF from "../../PDF/multi-page/LaborClaimCreditMemoPDF";

const AttachmentsLC = ({ form, formid, handleAlert, fileURL }) => {
  const [nsPDFLinks, setNSPDFLinks] = useState({ rma: "", creditMemo: "" });
  const [loading, setLoading] = useState(false);
  const [fileLinks, setFileLinks] = useState(null);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (form?.file?.length) {
      //get file links from amazon S3
      getAttachmentLinks();
    }

    if (form?.rmaNum && !form.rmaNum.startsWith("W")) {
      //get Netsuite links
      getNSPDFSLinks();
    }
  }, [form]);

  const getAttachmentLinks = async () => {
    try {
      const fileObj = form?.file?.map((fileKey, index) => {
        return { name: fileKey, url: fileURL[index] };
      });

      setFileLinks(fileObj);
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Could not get attachments: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    }
  };

  const getNSPDFSLinks = async () => {
    let rmaBlobUrl = "";
    let creditMemoBlobUrl = "";
    setLoading(true);

    try {
      if (!user.currentDepartment === "NINGBO") {
        if (form.creditmemonumber) {
          //Credit Memo
          const nsCreditMemoPDFResponse = await axios.get(
            `netsuite/labor-claim/get/rma/${form.creditmemointernalid}/pdf`
          );
          const creditMemoBase64PDF = nsCreditMemoPDFResponse.data.data64;

          // Convert base64 to Blob
          const cmByteCharacters = atob(creditMemoBase64PDF);
          const cmByteNumbers = new Array(cmByteCharacters.length);
          for (let i = 0; i < cmByteCharacters.length; i++) {
            cmByteNumbers[i] = cmByteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(cmByteNumbers);
          const cmBlob = new Blob([byteArray], { type: "application/pdf" });

          // Create a Blob URL
          creditMemoBlobUrl = URL.createObjectURL(cmBlob);
        }

        if (form.rmaNum) {
          // RMA
          const nsRMAPDFResponse = await axios.get(
            `netsuite/labor-claim/get/rma/${form.rmainternalid}/pdf`
          );

          const rmaBase64 = nsRMAPDFResponse.data.data64;

          // Convert base64 to Blob
          const rmaByteCharacters = atob(rmaBase64);
          const rmaByteNumbers = new Array(rmaByteCharacters.length);
          for (let i = 0; i < rmaByteCharacters.length; i++) {
            rmaByteNumbers[i] = rmaByteCharacters.charCodeAt(i);
          }
          const rmaByteArray = new Uint8Array(rmaByteNumbers);
          const rmaBlob = new Blob([rmaByteArray], {
            type: "application/pdf",
          });

          // Create a Blob URL
          rmaBlobUrl = URL.createObjectURL(rmaBlob);
        }

        setNSPDFLinks({ creditMemo: creditMemoBlobUrl, rma: rmaBlobUrl });
      }
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Could not get Netsuite links: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  if (!form) return <Loading message="Loading Atatchments..." />;

  return (
    <GeneralModalV3
      openComponent={
        <Tooltip arrow placement="top" title="Attachments">
          <IconButton>
            <Folder color="primary" />
          </IconButton>
        </Tooltip>
      }
    >
      <Box>
        <FlexWrapper justifyContent="center" alignItems="baseline" gap={1}>
          <Typography variant="h4">Attachments</Typography>

          {(user.currentDepartment === "RETURNS" ||
            user.currentDepartment === "PRODUCT" ||
            (user.currentDepartment === "NINGBO" &&
              form.status === "processed")) && (
            <Link
              to={`/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim/form/${formid}/edit/attachments`}
              style={{ color: "#1976d2" }}
            >
              Edit Attachments
            </Link>
          )}
        </FlexWrapper>
        {Boolean(fileLinks) && (
          <Box component="ul">
            {fileLinks.map((obj) => {
              return (
                <li key={obj.name}>
                  <MUILink href={obj.url} target="_blank">
                    {obj.name}
                  </MUILink>
                </li>
              );
            })}

            {form.status === "complete" && (
              <li>
                <GeneralPDFDownload
                  component={() => (
                    <LaborClaimCreditMemoPDF
                      rma={{
                        number: form.rmaNum.replace("RMA", ""),
                        date: new Date(form.rmaissuedate).toLocaleDateString(),
                        name: form.rmaissuedbyname,
                      }}
                      rga={{ number: form.rgaNum.replace("RGA", "") }}
                      claimDetail={{
                        date: new Date(
                          form.processedbydate
                        ).toLocaleDateString(),
                        reviewed:
                          form.partNumbers?.length &&
                          form.partNumbers
                            .map(
                              (partObj) =>
                                `${partObj.partnumber} x ${partObj.qty}`
                            )
                            .join(", "),
                        company: form.customer,
                        by: "AIT",
                      }}
                      detail={{
                        detail: form.detail,
                        note: form.note,
                      }}
                      internalUse={{
                        restockingFee: form.restockingfee,
                        dateReceived: form.datereceived,
                        receivedBy: form.receivedbyname,
                        result: form.finalresponse,
                        creditMemoNumber: form.creditmemonumber,
                        creditIssuedDate: new Date(
                          form.completeddate
                        ).toLocaleDateString(),
                      }}
                      createdDate={form.date}
                    />
                  )}
                  name={`${form.rmaNum.replace("RMA", "RMA ")}-RESPONSE`}
                  urlView={true}
                  download={false}
                />
              </li>
            )}
            {!form?.rmainternalid && form.status !== "new" && (
              <li>
                <GeneralPDFDownload
                  component={() => (
                    <LaborClaimWRMAPDF
                      date={form.date}
                      rga={{ number: form.rgaNum }}
                      productDetails={{
                        products: form.partNumbers,
                        total: form.totalClaim,
                      }}
                      rma={{
                        number: form.rmaNum,
                        issuedBy: form.rmaissuedbyname,
                        issuedOn: new Date(
                          form.rmaissuedate
                        ).toLocaleDateString(),
                      }}
                    />
                  )}
                  name={`${form.rmaNum}-RETURN_AUTH`}
                  urlView={true}
                  download={false}
                />
              </li>
            )}

            {form?.rmainternalid ? (
              <Box>
                {loading ? (
                  <Typography variant="subtitle2">
                    Loading Netsuite Documents...
                  </Typography>
                ) : (
                  <>
                    <Divider sx={{ my: 1 }} />
                    {nsPDFLinks?.rma && (
                      <li>
                        <MUILink href={nsPDFLinks.rma} target="_blank">
                          Netsuite Return Auth
                        </MUILink>
                      </li>
                    )}

                    {nsPDFLinks?.creditMemo && (
                      <li>
                        <MUILink href={nsPDFLinks.creditMemo} target="_blank">
                          Netsuite Credit Memo
                        </MUILink>
                      </li>
                    )}
                  </>
                )}
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
    </GeneralModalV3>
  );
};
export default AttachmentsLC;
