import { LABELSANDBOXREQUESTCOLUMNS } from "../../utils/columns/multi-page/multiPageColumns";
import { Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useSalesRepCustomerMatch } from "../../hooks/useSalesRepCustomerMatch";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import FlexWrapper from "../../components/FlexWrapper";
import Loading from "../../components/Loading";
import axios from "../../axios/axios.config";

function CustomToolbar({ type }) {
  return (
    <GridToolbarContainer>
      <Typography variant="h4">{type}</Typography>
      <FlexWrapper
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <FlexWrapper gap={1} alignItems="flex-end">
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{
              fileName: `${type}-labelsandboxrequests-${Date.now()}`,
            }}
            printOptions={{
              disableToolbarButton: true,
            }}
          />
        </FlexWrapper>
        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const LabelsAndBoxRequestCustomerServicePage = () => {
  const [loading, setLoading] = useState(true);
  const [pendingRows, setPendingRows] = useState([]);
  const [shippedRows, setShippedRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { filteredCustomersList } = useSalesRepCustomerMatch();
  const { currentSubsidiary } = useSelector((state) => state.user);

  useEffect(() => {
    if (currentSubsidiary && filteredCustomersList?.length > 0) {
      axios
        .post(`/forms/get/filtered-forms`, {
          type: "labels-box-requests",
          "versions.customer": { $in: filteredCustomersList },
          subsidiary: currentSubsidiary,
        })
        .then((filteredRows) => {
          setPendingRows(
            filteredRows.data.filter(
              (row) => row.versions[0].status === "pending"
            )
          );
          setShippedRows(
            filteredRows.data.filter(
              (row) => row.versions[0].status === "shipped"
            )
          );
        })
        .catch((error) => {
          dispatch(
            openGeneralAlert({
              type: "error",
              message: `Could not get label and box request data: ${error.message}`,
            })
          );
        })
        .finally(setLoading(false));
    }
  }, [currentSubsidiary, filteredCustomersList]);

  if (loading) {
    return <Loading message="Loading Label and Box Requests" />;
  }

  return (
    <>
      <Typography variant="h4" textAlign="center">
        Labels and Box Requests
      </Typography>

      <FlexWrapper justifyContent="flex-end">
        <Button
          variant="outlined"
          endIcon={<Add />}
          sx={{ mb: 1 }}
          onClick={() => navigate("add")}
        >
          Add New Record
        </Button>
      </FlexWrapper>

      <DataGrid
        sx={{ maxHeight: "40vh", mb: 2 }}
        rows={pendingRows}
        columns={LABELSANDBOXREQUESTCOLUMNS}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
          sorting: {
            sortModel: [{ field: "date", sort: "asc" }],
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        density="compact"
        disableColumnMenu
        getRowId={(row) => row._id}
        slots={{ toolbar: () => <CustomToolbar type="Pending" /> }}
      />
      <DataGrid
        sx={{ maxHeight: "40vh" }}
        rows={shippedRows}
        columns={LABELSANDBOXREQUESTCOLUMNS}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
          sorting: {
            sortModel: [{ field: "shippedDate", sort: "desc" }],
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        density="compact"
        disableColumnMenu
        getRowId={(row) => row._id}
        slots={{ toolbar: () => <CustomToolbar type="Shipped" /> }}
      />
    </>
  );
};
export default LabelsAndBoxRequestCustomerServicePage;
