import {
  Document,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  customerInfoRow: { flexDirection: "row", borderBottom: "1px solid black" },
  customerInfoDynamicText: {
    paddingLeft: "2px",
    width: "98%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "12px",
  },
  customerInfoRowLeft: {
    width: "50%",
    flexDirection: "row",
    borderRight: "1px solid black",
    alignItems: "center",
  },
  customerInfoRowRight: {
    width: "50%",
    flexDirection: "row",
    alignItems: "center",
  },
  customerInfoRowLeftDataLeft: {
    width: "20%",
    borderRight: "1px solid black",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },
  customerInfoRowLeftDataRight: { paddingLeft: "5px", width: "80%" },
  tableTitle: {
    fontFamily: "Helvetica-Bold",
    fontSize: "15px",
    paddingVertical: "3px",
    color: "white",
  },
  headerRow: {
    flexDirection: "row",
    textAlign: "center",
    alignItems: "stretch",
    fontFamily: "Helvetica-Bold",
  },
  headerRowText: {
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    fontSize: "11px",
  },
  dataRow: {
    flexDirection: "row",
    textAlign: "center",
    alignItems: "stretch",
  },
  dataRowText: {
    flex: 1,
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    height: "18px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "95%",
    paddingVertical: "1px",
  },
});

const WarrantyClaimFormPDF = ({ customerInfo, items }) => {
  return (
    <Document>
      <Page
        size="A3"
        style={{
          fontSize: "10px",
          fontFamily: "Helvetica",
          padding: "11px",
        }}
      >
        <View id="date">
          <Text
            style={{
              fontSize: "12px",
              textAlign: "left",
              fontFamily: "Helvetica",
            }}
          >
            {customerInfo.date}
          </Text>
        </View>
        <View
          id="header"
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Helvetica-Bold",
            paddingBottom: "10px",
          }}
        >
          <Text
            style={{ fontSize: "24px", width: "100%", textAlign: "center" }}
          >
            Warranty Claim Form
          </Text>
        </View>

        <View
          id="Table-Container"
          style={{
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            id="Customer-Info"
            style={{
              border: "2px solid black",
              width: "100%",
            }}
          >
            <Text
              style={{
                backgroundColor: "#d9d9d9",
                textAlign: "center",
                padding: "3px 0",
                borderTop: "1px solid black",
                borderBottom: "1pxc solid black",
                fontFamily: "Helvetica-Bold",
              }}
            >
              CUSTOMER INFO
            </Text>

            <View id="row-1" style={styles.customerInfoRow}>
              <View style={styles.customerInfoRowLeft}>
                <View style={styles.customerInfoRowLeftDataLeft}>
                  <Text style={{ padding: "2px" }}>*Company:</Text>
                </View>

                <View style={styles.customerInfoRowLeftDataRight}>
                  <Text style={styles.customerInfoDynamicText}>
                    {customerInfo.company}
                  </Text>
                </View>
              </View>

              <View style={styles.customerInfoRowRight}>
                <View style={styles.customerInfoRowLeftDataLeft}>
                  <Text style={{ padding: "2px" }}>*Contact:</Text>
                </View>

                <View style={styles.customerInfoRowLeftDataRight}>
                  <Text style={styles.customerInfoDynamicText}>
                    {customerInfo.contactname}
                  </Text>
                </View>
              </View>
            </View>

            <View id="row-2" style={styles.customerInfoRow}>
              <View style={styles.customerInfoRowLeft}>
                <View style={styles.customerInfoRowLeftDataLeft}>
                  <Text style={{ padding: "2px" }}>*Address:</Text>
                </View>

                <View style={styles.customerInfoRowLeftDataRight}>
                  <Text style={styles.customerInfoDynamicText}>
                    {customerInfo.address}
                  </Text>
                </View>
              </View>

              <View style={styles.customerInfoRowRight}>
                <View style={styles.customerInfoRowLeftDataLeft}>
                  <Text style={{ padding: "2px" }}>*Phone:</Text>
                </View>

                <View style={styles.customerInfoRowLeftDataRight}>
                  <Text style={styles.customerInfoDynamicText}>
                    {customerInfo.phone}
                  </Text>
                </View>
              </View>
            </View>

            <View id="row-3" style={styles.customerInfoRow}>
              <View style={styles.customerInfoRowLeft}>
                <View style={styles.customerInfoRowLeftDataLeft}>
                  <Text style={{ padding: "2px" }}>*City:</Text>
                </View>

                <View style={styles.customerInfoRowLeftDataRight}>
                  <Text style={styles.customerInfoDynamicText}>
                    {customerInfo.city}
                  </Text>
                </View>
              </View>

              <View style={styles.customerInfoRowRight}>
                <View style={styles.customerInfoRowLeftDataLeft}>
                  <Text style={{ padding: "2px" }}>*Ref#:</Text>
                </View>

                <View style={styles.customerInfoRowLeftDataRight}>
                  <Text style={styles.customerInfoDynamicText}>
                    {customerInfo.ref}
                  </Text>
                </View>
              </View>
            </View>

            <View id="row-4" style={styles.customerInfoRow}>
              <View style={styles.customerInfoRowLeft}>
                <View style={styles.customerInfoRowLeftDataLeft}>
                  <Text style={{ padding: "2px" }}>*State:</Text>
                </View>

                <View
                  style={[
                    styles.customerInfoRowLeftDataRight,
                    {
                      width: "60%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  <Text style={styles.customerInfoDynamicText}>
                    {customerInfo.state}
                  </Text>
                  <View
                    style={{
                      width: "50%",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Helvetica-Bold",
                        borderRight: "1px solid black",
                        paddingRight: "5px",
                      }}
                    >
                      *Zip:
                    </Text>
                    <Text
                      style={{
                        paddingLeft: "5px",
                      }}
                    >
                      {customerInfo.zip}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.customerInfoRowRight}>
                <View style={styles.customerInfoRowLeftDataLeft}>
                  <Text style={{ padding: "2px" }}>*Total Qty:</Text>
                </View>

                <View style={styles.customerInfoRowLeftDataRight}>
                  <Text style={styles.customerInfoDynamicText}>
                    {customerInfo.totalqty}
                  </Text>
                </View>
              </View>
            </View>

            <View id="row-5" style={styles.customerInfoRow}>
              <View style={styles.customerInfoRowLeft}>
                <View style={styles.customerInfoRowLeftDataLeft}>
                  <Text style={{ padding: "2px" }}>*Agency:</Text>
                </View>

                <View style={styles.customerInfoRowLeftDataRight}>
                  <Text style={styles.customerInfoDynamicText}>
                    {customerInfo.agency}
                  </Text>
                </View>
              </View>

              <View style={styles.customerInfoRowRight}>
                <View style={styles.customerInfoRowLeftDataLeft}>
                  <Text style={{ padding: "2px" }}>*Rep:</Text>
                </View>

                <View style={styles.customerInfoRowLeftDataRight}>
                  <Text style={styles.customerInfoDynamicText}></Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View id="space" style={{ height: "10px" }}></View>
        <View
          id="claim-details"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "1px",
            fontSize: "12px",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <Text>Total Parts: </Text>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              {customerInfo.totalqty}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text>Price Level: </Text>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              {customerInfo.priceLevel}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text>Subtotal: </Text>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              {customerInfo.totalPrice}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text>Discount Amount: </Text>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              {customerInfo.discountAmount
                ? `-${customerInfo.discountAmount}`
                : ""}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text>Total Price: </Text>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              {customerInfo.totalDiscountedPrice}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text>Discount: </Text>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              {customerInfo.discount.replace("Discount", "")}
            </Text>
          </View>
        </View>

        <View
          id="Table-Breakdown-Container"
          wrap
          style={{
            width: "100%",
            border: "1px solid black",
            flexDirection: "row",
            textAlign: "center",
            fontSize: "12px",
          }}
        >
          <View id="alleged-defective-breakdown" style={{ width: "40%" }}>
            <View
              style={[
                styles.tableTitle,
                {
                  backgroundColor: "midnightblue",
                },
              ]}
            >
              <Text>Alleged Defective Breakdown</Text>
            </View>

            <View id="header-row" style={styles.headerRow}>
              <View
                style={[
                  styles.headerRowText,
                  {
                    flex: 1,
                  },
                ]}
              >
                <Text>PART</Text>
              </View>
              <View
                style={[
                  styles.headerRowText,
                  {
                    flex: 2,
                  },
                ]}
              >
                <Text>Problem</Text>
              </View>
              <View
                style={[
                  styles.headerRowText,
                  {
                    flex: 1,
                    flexShrink: 2,
                  },
                ]}
              >
                <Text>QTY</Text>
              </View>
            </View>

            {items.map((item) => {
              return (
                <View
                  key={item.item}
                  id="data-row"
                  style={styles.dataRow}
                  wrap={false}
                >
                  <View style={styles.dataRowText}>
                    <Text>{item.item}</Text>
                  </View>
                  <View style={[styles.dataRowText, { flex: 2 }]}>
                    <Text>{item.reason}</Text>
                  </View>
                  <View style={styles.dataRowText}>
                    <Text>{item.qty}</Text>
                  </View>
                </View>
              );
            })}
          </View>

          <View id="inernal-use-red" style={{ width: "30%" }}>
            <View
              style={[
                styles.tableTitle,
                {
                  backgroundColor: "red",
                },
              ]}
            >
              <Text>Internal Use Only</Text>
            </View>
            <View id="header-row" style={styles.headerRow}>
              <View
                style={[
                  styles.headerRowText,
                  {
                    flex: 1,
                  },
                ]}
              >
                <Text>PRICE</Text>
              </View>
              <View
                style={[
                  styles.headerRowText,
                  {
                    flex: 1,
                  },
                ]}
              >
                <Text>PRICE W/QTY</Text>
              </View>
              <View
                style={[
                  styles.headerRowText,
                  {
                    flex: 1,
                    flexShrink: 2,
                  },
                ]}
              >
                <Text>TOTAL W/DISC</Text>
              </View>
            </View>

            {items.map((item) => {
              const totalPrice = item.unitprice
                ? parseFloat(item.unitprice) * item.qty
                : null;
              const percentageMultiplier =
                item.defaultdiscount !== null &&
                item.defaultdiscount !== undefined &&
                !isNaN(item.defaultdiscount) &&
                typeof item.defaultdiscount === "number"
                  ? parseFloat(item.defaultdiscount / 100)
                  : null;
              const discountedPrice =
                item.unitprice && percentageMultiplier !== null
                  ? parseFloat(totalPrice) -
                    parseFloat(totalPrice) * percentageMultiplier
                  : null;

              return (
                <View
                  key={item.item}
                  id="data-row"
                  style={styles.dataRow}
                  wrap={false}
                >
                  <View style={styles.dataRowText}>
                    <Text>
                      {item.unitprice
                        ? parseFloat(item.unitprice).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : ""}
                    </Text>
                  </View>
                  <View style={[styles.dataRowText]}>
                    <Text>
                      {totalPrice
                        ? totalPrice.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : ""}
                    </Text>
                  </View>
                  <View style={styles.dataRowText}>
                    <Text>
                      {!discountedPrice || discountedPrice === "NaN"
                        ? ""
                        : `${discountedPrice.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>

          <View id="inernal-use-green" style={{ width: "30%" }}>
            <View
              style={[
                styles.tableTitle,
                {
                  backgroundColor: "green",
                },
              ]}
            >
              <Text>Internal Use Only</Text>
            </View>
            <View id="header-row" style={styles.headerRow}>
              <View
                style={[
                  styles.headerRowText,
                  {
                    flex: 1,
                    flexShrink: 1,
                  },
                ]}
              >
                <Text>STATUS</Text>
              </View>
              <View
                style={[
                  styles.headerRowText,
                  {
                    flex: 1,
                  },
                ]}
              >
                <Text>NEW PART</Text>
              </View>
            </View>

            {items.map((item) => {
              return (
                <View
                  key={item.item}
                  id="data-row"
                  style={styles.dataRow}
                  wrap={false}
                >
                  <View style={styles.dataRowText}>
                    <Text>{item.catalogstatus}</Text>
                  </View>
                  <View style={styles.dataRowText}>
                    <Text>{item.sspart}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default WarrantyClaimFormPDF;
