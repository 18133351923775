import { useState, useEffect } from "react";
import { useSalesRepCustomerMatch } from "../../../hooks/useSalesRepCustomerMatch";
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import {
  WARRANTYCLAIMCOLUMNS,
  WARRANTYCLAIMCOLUMNSAPPROVED,
  WARRANTYCLAIMCOLUMNSCOMPLETE,
  WARRANTYCLAIMCOLUMNSPENDINGAPPROVAL,
} from "../../../utils/columns/multi-page/multiPageColumns";

import Loading from "../../../components/Loading";
import axios from "../../../axios/axios.config";
import FlexWrapper from "../../../components/FlexWrapper";

function CustomToolbar({ type }) {
  return (
    <GridToolbarContainer>
      <Typography textAlign="center" variant="h4">
        {type}
      </Typography>
      <FlexWrapper justifyContent="space-between" width="100%">
        <Box>
          <GridToolbarExport
            csvOptions={{
              fileName: `WR-Items-${type}-${Date.now()}`,
            }}
            printOptions={{
              disableToolbarButton: true,
            }}
          />
          <GridToolbarFilterButton />
        </Box>
        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const WarrantyFormPageMulti = () => {
  const [warrantyRows, setWarrantyRows] = useState({
    new: [],
    pending: [],
    approved: [],
    complete: [],
  });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filteredCustomersList } = useSalesRepCustomerMatch();
  const { currentSubsidiary } = useSelector((state) => state.user);

  useEffect(() => {
    if (filteredCustomersList?.length > 0) {
      getRows();
    }
  }, [filteredCustomersList]);

  const getRows = async () => {
    try {
      const filter = {
        type: "warranty-claim",
        "form.customer": { $in: filteredCustomersList },
        subsidiary: currentSubsidiary,
      };

      const response = await axios.post(`/forms/get/filtered-forms`, filter);
      const newClaims = [];
      const pendingClaims = [];
      const approvedClaims = [];
      const completeClaims = [];

      for (const claim of response.data) {
        if (claim.form.status === "new" || claim.form.status === "created") {
          newClaims.push(claim);
        }
        if (claim.form.status === "pendingapproval") {
          pendingClaims.push(claim);
        }
        if (claim.form.status === "approved") {
          approvedClaims.push(claim);
        }
        if (claim.form.status === "complete") {
          completeClaims.push(claim);
        }
      }
      setWarrantyRows({
        new: newClaims,
        pending: pendingClaims,
        approved: approvedClaims,
        complete: completeClaims,
      });
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not get warranty claims: ${error.message}`,
          duration: 3000,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading message="Loading Warranty Claims" />;
  }

  return (
    <>
      <Typography variant="h4" textAlign="center">
        Warranty Claims
      </Typography>
      <FlexWrapper justifyContent="flex-end">
        <Button
          variant="outlined"
          endIcon={<Add />}
          onClick={() => {
            navigate("add");
          }}
          sx={{ mb: 3 }}
        >
          Add New Warranty Claim
        </Button>
      </FlexWrapper>

      <DataGrid
        rows={warrantyRows.new}
        slots={{ toolbar: () => <CustomToolbar type="New" /> }}
        columns={WARRANTYCLAIMCOLUMNS}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
          sortModel: [
            {
              field: "date",
              sort: "asc", // 'asc' for ascending, 'desc' for descending
            },
          ],
        }}
        pageSizeOptions={[25, 50, 100]}
        autoHeight={!warrantyRows.new.length}
        density="compact"
        disableColumnMenu
        disableDensitySelector
        disableColumnSelector
        getRowId={(row) => row._id}
        sx={{ maxHeight: "40vh", mb: 2 }}
      />

      <DataGrid
        rows={warrantyRows.pending}
        slots={{ toolbar: () => <CustomToolbar type="Pending Approval" /> }}
        columns={WARRANTYCLAIMCOLUMNSPENDINGAPPROVAL}
        autoHeight={!warrantyRows.pending.length}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
          sortModel: [
            {
              field: "date",
              sort: "asc", // 'asc' for ascending, 'desc' for descending
            },
          ],
        }}
        pageSizeOptions={[25, 50, 100]}
        density="compact"
        disableColumnMenu
        disableDensitySelector
        disableColumnSelector
        getRowId={(row) => row._id}
        sx={{ maxHeigh: "40vh", mb: 2 }}
      />

      <DataGrid
        rows={warrantyRows.approved}
        slots={{ toolbar: () => <CustomToolbar type="Approved" /> }}
        columns={WARRANTYCLAIMCOLUMNSAPPROVED}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
          sortModel: [
            {
              field: "date",
              sort: "asc", // 'asc' for ascending, 'desc' for descending
            },
          ],
        }}
        pageSizeOptions={[25, 50, 100]}
        autoHeight={!warrantyRows.approved.length}
        density="compact"
        disableColumnMenu
        disableDensitySelector
        disableColumnSelector
        getRowId={(row) => row._id}
        sx={{ maxHeigh: "40vh", mb: 2 }}
      />

      <DataGrid
        rows={warrantyRows.complete}
        slots={{ toolbar: () => <CustomToolbar type="Complete" /> }}
        columns={WARRANTYCLAIMCOLUMNSCOMPLETE}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
          sortModel: [
            {
              field: "date",
              sort: "asc", // 'asc' for ascending, 'desc' for descending
            },
          ],
        }}
        pageSizeOptions={[25, 50, 100]}
        autoHeight={!warrantyRows.complete.length}
        density="compact"
        disableColumnMenu
        disableDensitySelector
        disableColumnSelector
        getRowId={(row) => row._id}
        sx={{ maxHeigh: "40vh", mb: 2 }}
      />
    </>
  );
};

export default WarrantyFormPageMulti;
