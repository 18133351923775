import { useSelector } from "react-redux";
import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SyncProblem } from "@mui/icons-material";

import FlexWrapper from "../../FlexWrapper";

const PickOrderErrorsView = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const { nserrors } = useSelector((state) => state.pickOrder);

  if (!nserrors?.length)
    return (
      <FlexWrapper height="50vh" justifyContent="center" alignItems="center">
        <Typography variant="h5" textAlign="center">
          No Errors To Display
        </Typography>
      </FlexWrapper>
    );

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxHeight: matches ? "75vh" : "77vh",
          overflowY: "scroll",
        }}
      >
        <Stepper alternativeLabel={false} orientation="vertical" sx={{ py: 2 }}>
          {nserrors.map((item) => (
            <Step key={item._id}>
              <StepLabel
                error
                optional={
                  <Box>
                    <Typography color="error" variant="subtitle1">
                      {item.user} - {new Date(item.createdAt).toLocaleString()}
                    </Typography>
                    <Typography color="error" variant="body2">
                      {item.errormessage}
                    </Typography>
                  </Box>
                }
                icon={<SyncProblem color="error" />}
              >
                <Typography variant="h5">{item.refnumber} </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Container>
  );
};
export default PickOrderErrorsView;
