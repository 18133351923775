import { Box, Button, Dialog } from "@mui/material";
import { useState } from "react";

const GeneralFullScreenModalV3 = ({
  onOpen = null,
  onClose = null,
  keepMounted = false,
  openComponent = null,
  children,
  disableEscapeKeyDown = false,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
    if (onClose && typeof onClose === "function") {
      onClose();
    }
  };

  const handleOpen = () => {
    setOpenModal(true);
    if (onOpen && typeof onOpen === "function") {
      onOpen();
    }
  };
  return (
    <>
      <Box onClick={handleOpen}>{openComponent}</Box>
      <Dialog
        open={openModal}
        onClose={handleClose}
        fullScreen
        keepMounted={keepMounted}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        {/* pass in handleClose if needed */}
        {openModal
          ? typeof children === "function"
            ? children(handleClose, openModal)
            : children
          : null}
      </Dialog>
    </>
  );
};
export default GeneralFullScreenModalV3;
