import { Link as MUILink } from "@mui/material";
import { Link } from "react-router-dom";

export const LABELSANDBOXREQUESTCOLUMNS = [
  {
    field: "_id",
    headerName: "ID",
    width: 250,
    sortable: false,
    renderCell: (params) => (
      <Link to={`form/${params.row._id}`} style={{ textDecoration: "none" }}>
        <MUILink component="p" id={params.row._id}>
          {params.row._id}
        </MUILink>
      </Link>
    ),
  },
  {
    field: "createdby",
    headerName: "Created By",
    type: "string",
    width: 250,
    valueGetter: (params) => {
      return `${params.row.createdby.username} 
        ${params.row.createdby.email ? `(${params.row.createdby.email})` : ""}`;
    },
  },
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return new Date(params.row.createdAt);
    },
    valueFormatter: (params) => {
      return params.value.toLocaleDateString();
    },
  },

  {
    field: "customer",
    headerName: "Customer",
    width: 280,
    valueGetter: (params) => {
      return params.row.versions[params.row.versions.length - 1].customer;
    },
  },

  {
    field: "totalBoxes",
    headerName: "Total Boxes",
    type: "number",
    width: 120,
    valueGetter: (params) => {
      return params.row.versions[params.row.versions.length - 1].totalBoxes;
    },
  },
  {
    field: "totalLabels",
    headerName: "Total Labels",
    type: "number",
    width: 120,
    valueGetter: (params) => {
      return params.row.versions[params.row.versions.length - 1].totalLabels;
    },
  },
  {
    field: "carrier",
    headerName: "Shipping Carrier",
    width: 200,
    valueGetter: (params) => {
      return params.row.versions[params.row.versions.length - 1].carrier;
    },
  },
  {
    field: "tracking",
    headerName: "Tracking Number",
    width: 210,
    valueGetter: (params) => {
      return params.row.versions[params.row.versions.length - 1].tracking;
    },
  },
  {
    field: "shippedDate",
    headerName: "Shipped Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return params.row.versions[params.row.versions.length - 1].shippedDate
        ? new Date(
            params.row.versions[params.row.versions.length - 1].shippedDate
          )
        : "";
    },
    valueFormatter: (params) => {
      return params.value ? params.value.toLocaleDateString() : "";
    },
  },
];

export const WARRANTYCLAIMCOLUMNS = [
  {
    field: "_id",
    headerName: "ID",
    flex: 3,
    sortable: false,
    renderCell: (params) => {
      return params.row.form.status === "created" ? (
        params.row._id
      ) : (
        <Link to={`form/${params.row._id}`} style={{ textDecoration: "none" }}>
          <MUILink component="p" id={params.row._id}>
            {params.row._id}
          </MUILink>
        </Link>
      );
    },
  },
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    flex: 1.8,
    valueGetter: (params) => new Date(params.row.createdAt),
  },
  {
    field: "createdby",
    headerName: "Created By",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.createdby.name;
    },
  },
  {
    field: "refernceNum",
    headerName: "Reference Number",
    type: "text",
    align: "center",
    headerAlign: "center",
    flex: 2.5,
    valueGetter: (params) =>
      params.row.form.customerrefrencenumber
        ? params.row.form.customerrefrencenumber
        : null,
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "text",
    flex: 3,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return params.row.form.customer;
    },
  },
  {
    field: "totalQty",
    headerName: "Total QTY",
    type: "number",
    flex: 1.5,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form.totalQty;
    },
  },
  {
    field: "totalPrice",
    headerName: "Subtotal",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form.totalprice || null;
    },
    valueFormatter: (params) => {
      return params.value
        ? params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null;
    },
  },
  {
    field: "discountedPrice",
    headerName: "Total",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form.discountedprice || null;
    },
    valueFormatter: (params) => {
      return params.value
        ? params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null;
    },
  },
  // {
  //   field: "defaultDiscount",
  //   headerName: "Discount",
  //   type: "number",
  //   flex: 1,
  //   align: "center",
  //   headerAlign: "center",
  //   valueGetter: (params) => {
  //     return params.row.form.customerdefaultdiscount || null;
  //   },
  //   valueFormatter: (params) =>
  //     params.value ? params.value.replace("Discount", "") : null,
  // },
];

export const WARRANTYCLAIMCOLUMNSPENDINGAPPROVAL = [
  {
    field: "_id",
    headerName: "ID",
    flex: 3,
    sortable: false,
    renderCell: (params) => {
      return params.row.form.status === "created" ? (
        params.row._id
      ) : (
        <Link to={`form/${params.row._id}`} style={{ textDecoration: "none" }}>
          <MUILink component="p" id={params.row._id}>
            {params.row._id}
          </MUILink>
        </Link>
      );
    },
  },
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    flex: 1.8,
    valueGetter: (params) => new Date(params.row.createdAt),
  },
  {
    field: "createdby",
    headerName: "Created By",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.createdby.name;
    },
  },
  {
    field: "refernceNum",
    headerName: "Reference Number",
    type: "text",
    align: "center",
    headerAlign: "center",
    flex: 2.5,
    valueGetter: (params) =>
      params.row.form.customerrefrencenumber
        ? params.row.form.customerrefrencenumber
        : null,
  },
  {
    field: "rmaNum",
    headerName: "RMA Number",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form?.rma?.rmaNum ? params.row.form.rma.rmaNum : "";
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "text",
    flex: 3,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return params.row.form.customer;
    },
  },
  {
    field: "totalQty",
    headerName: "Total QTY",
    type: "number",
    flex: 1.5,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form.totalQty;
    },
  },
  {
    field: "totalPrice",
    headerName: "Subtotal",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form.totalprice || null;
    },
    valueFormatter: (params) => {
      return params.value
        ? params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null;
    },
  },
  {
    field: "discountedPrice",
    headerName: "Total",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form.discountedprice || null;
    },
    valueFormatter: (params) => {
      return params.value
        ? params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null;
    },
  },
  // {
  //   field: "defaultDiscount",
  //   headerName: "Discount",
  //   type: "number",
  //   flex: 1,
  //   align: "center",
  //   headerAlign: "center",
  //   valueGetter: (params) => {
  //     return params.row.form.customerdefaultdiscount || null;
  //   },
  //   valueFormatter: (params) =>
  //     params.value ? params.value.replace("Discount", "") : null,
  // },
];

export const WARRANTYCLAIMCOLUMNSAPPROVED = [
  {
    field: "_id",
    headerName: "ID",
    flex: 3,
    sortable: false,
    renderCell: (params) => {
      return params.row.form.status === "created" ? (
        params.row._id
      ) : (
        <Link to={`form/${params.row._id}`} style={{ textDecoration: "none" }}>
          <MUILink component="p" id={params.row._id}>
            {params.row._id}
          </MUILink>
        </Link>
      );
    },
  },
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    flex: 1.8,
    valueGetter: (params) => new Date(params.row.createdAt),
  },
  {
    field: "createdby",
    headerName: "Created By",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.createdby.name;
    },
  },
  {
    field: "refernceNum",
    headerName: "Reference Number",
    type: "text",
    align: "center",
    headerAlign: "center",
    flex: 2.5,
    valueGetter: (params) =>
      params.row.form.customerrefrencenumber
        ? params.row.form.customerrefrencenumber
        : null,
  },
  {
    field: "rmaNum",
    headerName: "RMA Number",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form?.rma?.rmaNum ? params.row.form.rma.rmaNum : "";
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "text",
    flex: 3,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return params.row.form.customer;
    },
  },
  {
    field: "totalQty",
    headerName: "Total QTY",
    type: "number",
    flex: 1.5,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form.totalQty;
    },
  },
  {
    field: "totalPrice",
    headerName: "Subtotal",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form.totalprice || null;
    },
    valueFormatter: (params) => {
      return params.value
        ? params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null;
    },
  },
  {
    field: "discountedPrice",
    headerName: "Total",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form.discountedprice || null;
    },
    valueFormatter: (params) => {
      return params.value
        ? params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null;
    },
  },
  // {
  //   field: "defaultDiscount",
  //   headerName: "Discount",
  //   type: "number",
  //   flex: 1,
  //   align: "center",
  //   headerAlign: "center",
  //   valueGetter: (params) => {
  //     return params.row.form.customerdefaultdiscount || null;
  //   },
  //   valueFormatter: (params) =>
  //     params.value ? params.value.replace("Discount", "") : null,
  // },
];

export const WARRANTYCLAIMCOLUMNSCOMPLETE = [
  {
    field: "_id",
    headerName: "ID",
    flex: 3,
    sortable: false,
    renderCell: (params) => {
      return params.row.form.status === "created" ? (
        params.row._id
      ) : (
        <Link to={`form/${params.row._id}`} style={{ textDecoration: "none" }}>
          <MUILink component="p" id={params.row._id}>
            {params.row._id}
          </MUILink>
        </Link>
      );
    },
  },
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    flex: 1.8,
    valueGetter: (params) => new Date(params.row.createdAt),
  },
  {
    field: "createdby",
    headerName: "Completed By",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form?.creditmemo?.createdbyname
        ? params.row.form.creditmemo.createdbyname
        : "";
    },
  },
  {
    field: "completedDate",
    headerName: "Completed Date",
    type: "date",
    flex: 1.8,
    valueGetter: (params) =>
      params.row.form.creditmemo
        ? new Date(params.row.form.creditmemo.createdbydate)
        : null,
  },
  {
    field: "refernceNum",
    headerName: "Reference Number",
    type: "text",
    align: "center",
    headerAlign: "center",
    flex: 2.5,
    valueGetter: (params) =>
      params.row.form.customerrefrencenumber
        ? params.row.form.customerrefrencenumber
        : null,
  },
  {
    field: "rmaNum",
    headerName: "RMA Number",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form?.rma?.rmaNum ? params.row.form.rma.rmaNum : "";
    },
  },
  {
    field: "creditmemonumber",
    headerName: "Credit Memo Number",
    type: "text",
    flex: 2.5,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form?.creditmemo?.creditmemonumber
        ? params.row.form.creditmemo.creditmemonumber
        : "";
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "text",
    flex: 3,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return params.row.form.customer;
    },
  },
  {
    field: "totalQty",
    headerName: "Total QTY",
    type: "number",
    flex: 1.5,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form.totalQty;
    },
  },
  {
    field: "totalPrice",
    headerName: "Subtotal",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form.totalprice || null;
    },
    valueFormatter: (params) => {
      return params.value
        ? params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null;
    },
  },
  {
    field: "discountedPrice",
    headerName: "Total",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.form?.creditmemo?.total || null;
    },
    valueFormatter: (params) => {
      return params.value
        ? params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null;
    },
  },
];

export const WARRANTYITEMBREAKDOWN = [
  {
    field: "item",
    headerName: "Part Number",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "qty",
    headerName: "QTY",
    type: "number",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "reason",
    headerName: "Problem",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
    sortable: false,
  },
  {
    field: "unitprice",
    headerName: "Price",
    type: "number",
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueFormatter: (params) => {
      return params.value
        ? parseFloat(params.value).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null;
    },
  },
  {
    field: "unitpriceqty",
    headerName: "Price W/ QTY",
    type: "number",
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.unitprice
        ? params.row.qty * parseFloat(params.row.unitprice)
        : null;
    },
    valueFormatter: (params) => {
      return params.value
        ? params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null;
    },
  },
  {
    field: "unitpriceqtydiscount",
    headerName: "Total W/ Discount",
    type: "number",
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      if (!params.row.unitprice) return null;

      const priceQty = parseFloat(
        params.row.qty * parseFloat(params.row.unitprice)
      );

      if (params.row.defaultdiscount === 0) {
        return priceQty;
      }

      return params.row.defaultdiscount && params.row.unitprice
        ? priceQty - priceQty * (params.row.defaultdiscount / 100)
        : null;
    },
    valueFormatter: (params) => {
      return params.value
        ? params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null;
    },
  },
  {
    field: "catalogstatus",
    headerName: "Status",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.catalogstatus;
    },
  },
  {
    field: "newpart",
    headerName: "New Part",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.sspart;
    },
  },
];
