import { useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { Add, Delete, ExitToApp } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

import { resizeFile } from "../../utils/files/resizeFile";

import FlexWrapper from "../../components/FlexWrapper";
import GeneralModal from "../../components/GeneralModal";
import GeneralFileUpload from "../../components/GeneralFileUpload";
import axios from "../../axios/axios.config";
import Loading from "../../components/Loading";

const LaborClaimEditAttachments = () => {
  const { mongoFiles, fileURLS } = useLoaderData();

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [displayFiles, setDisplayFiles] = useState(
    mongoFiles.map((filename, index) => {
      return { filename, url: fileURLS[index] };
    })
  );
  const [submittingFiles, setSubmittingFiles] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const { formid } = useParams();

  const handleSaveFiles = async (dropzoneFiles) => {
    const finalFiles = [];

    for (const file of dropzoneFiles) {
      let fileToUpload = file;

      if (file.type.includes("image")) {
        //resize image
        fileToUpload = await resizeFile({
          file: file,
          maxWidth: 2500,
          maxHeight: 2500,
          compressFormat: "JPEG",
          quality: 80,
          outputType: "file",
        });
      }

      finalFiles.push(fileToUpload);
    }

    setFiles([...files, ...finalFiles]);
  };

  const handleRemoveFile = (file) => {
    setFiles(files.filter((f) => f.path !== file.path));
  };

  const handleSubmitFiles = async () => {
    setSubmittingFiles(true);
    try {
      const fileKeys = files.map((file) => file.name);

      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          $set: {
            "form.file": [
              ...displayFiles.map((fileObj) => fileObj.filename),
              ...fileKeys,
            ],
          },
        },
      });

      //save files to S3 using mongoid
      const formData = new FormData();

      files.forEach((file) => formData.append("files", file));
      formData.append("folder", `forms/laborclaim/${data._id}/`);

      await axios.post("/files/upload/multi", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const urlfileKeys = data.form.file.map(
        (filename) => `forms/laborclaim/${formid}/${filename}`
      );

      //retrieve signed URLs from updated form
      const fileURLResponse = await axios.post("files/multi/signedURL", {
        options: { expiresIn: 3600 },
        fileKeys: urlfileKeys,
      });

      const urls = fileURLResponse.data;
      const newDisplayFiles = data.form.file.map((filename, index) => {
        return { filename, url: urls[index] };
      });

      console.log(newDisplayFiles, "new Display files");

      setDisplayFiles(newDisplayFiles);
      setFiles([]);
      setOpenModal(false);

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Added Files",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not add files: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 3000,
        })
      );
    } finally {
      setSubmittingFiles(false);
    }
  };

  const handleDeleteFile = async () => {
    setSubmittingFiles(true);
    try {
      console.log(fileToDelete, "file to delete");
      await axios.delete(
        `files/delete/file?Key=forms/laborclaim/${formid}/${fileToDelete.filename}`
      );

      const filteredFiles = displayFiles.filter(
        (file) => file.filename !== fileToDelete.filename
      );

      console.log(filteredFiles, "filetred files");

      await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          $set: { "form.file": filteredFiles.map((file) => file.filename) },
        },
      });

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully Deleted ${fileToDelete.filename}`,
          duration: 3000,
        })
      );
      setDisplayFiles(filteredFiles);
      setOpenDeleteModal(false);
      setFileToDelete(null);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not delete file: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 3000,
        })
      );
    } finally {
      setSubmittingFiles(false);
    }
  };

  return (
    <Container maxWidth="xl">
      {/* Add Modal */}
      <GeneralModal
        open={openModal}
        handleClose={() => {
          if (submittingFiles) return;
          setOpenModal(false);
        }}
      >
        <Typography variant="h4" textAlign="center" pt={2}>
          Add Files
        </Typography>
        {submittingFiles ? (
          <Loading message={"Adding Files..."} />
        ) : (
          <Box sx={{ px: "24px", py: "20px" }}>
            <Typography variant="body2">
              Please ensure the following attachments are present:
            </Typography>
            <ul>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Original receipt with date of pruchase
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Copy of original work order with parts and labor bill
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Copy of warranty (redo) work order
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Failed parts(s)
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Technician comments/statement
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Relevant picture of damage
                </Typography>
              </li>
            </ul>

            <GeneralFileUpload
              multiple
              required
              handleSaveFiles={handleSaveFiles}
              handleRemoveFile={handleRemoveFile}
              files={files}
            />

            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              onClick={handleSubmitFiles}
            >
              Submit
            </Button>
          </Box>
        )}
      </GeneralModal>

      {/* Delete Modal */}
      <GeneralModal
        open={openDeleteModal && fileToDelete !== null}
        handleClose={() => {
          setFileToDelete(null);
          setOpenDeleteModal(false);
        }}
      >
        <Typography textAlign="center" variant="h4" gutterBottom p={3}>
          Delete <strong>{fileToDelete?.filename}</strong>?
        </Typography>

        <Divider />
        {submittingFiles ? (
          <Loading message="Deleting File..." />
        ) : (
          <>
            <Box p={3} height="100%">
              <Typography variant="h6" color="error">
                This action is permanent. File cannot be recovered after
                deleting.
              </Typography>
            </Box>
            <Divider />
            <Box p={3}>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={handleDeleteFile}
              >
                Delete
              </Button>
            </Box>
          </>
        )}
      </GeneralModal>

      <Tooltip title="Back To Labor Claim" placement="top" arrow>
        <IconButton onClick={() => navigate(-1)}>
          <ExitToApp color="error" />
        </IconButton>
      </Tooltip>

      <Container maxWidth="md">
        <FlexWrapper justifyContent="flex-end">
          <Button
            variant="contained"
            endIcon={<Add />}
            size="small"
            onClick={() => setOpenModal(true)}
          >
            Add
          </Button>
        </FlexWrapper>

        <Paper elevation={3} style={{ padding: "16px", marginTop: "16px" }}>
          <Typography textAlign="center" variant="h4">
            Attachments
          </Typography>
          <List
            sx={{
              minHeight: "20vh",
              maxHeight: "70vh",
              overflow: "scroll",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            {displayFiles.length ? (
              displayFiles.map((fileObj) => (
                <ListItem key={fileObj.filename} alignItems="flex-start">
                  <ListItemText
                    primary={
                      <Link href={fileObj.url} target="_blank">
                        <Typography variant="h6">{fileObj.filename}</Typography>
                      </Link>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        setFileToDelete(fileObj);
                        setOpenDeleteModal(true);
                      }}
                    >
                      <Delete color="error" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            ) : (
              <Typography
                textAlign="center"
                variant="body1"
                color="error"
                pt={2}
              >
                File Is Required: Please Ensure A File Is Added to Process Labor
                Claim.
              </Typography>
            )}
          </List>
        </Paper>
      </Container>
    </Container>
  );
};
export default LaborClaimEditAttachments;
