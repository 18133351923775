import { IconButton, Link, Typography } from "@mui/material";
import { usePDF } from "@react-pdf/renderer";
import { FileDownload } from "@mui/icons-material";

const PDFDoc = ({ component: Component }) => {
  return <Component />;
};

const GeneralPDFDownload = ({
  component,
  name,
  onBlobLoad = null,
  viewOnly = false,
  urlView = false,
  download = true,
  customComponent = null,
  noDecoration = false,
}) => {
  const [instance] = usePDF({
    document: <PDFDoc component={component} />,
  });

  if (!instance && !instance.loading && !instance.blob && !instance.error) {
    return null;
  }

  if (instance.loading)
    return (
      <Typography variant="body2" color="primary">
        Loading PDF...
      </Typography>
    );

  if (instance.error)
    return (
      <Typography variant="body2" color="error">
        PDF Error
      </Typography>
    );

  //Exposes the blob data to the parent component
  if (onBlobLoad && typeof onBlobLoad === "function") {
    onBlobLoad(instance.blob);
  }

  if (viewOnly) return null;

  return !instance.loading && !instance.error ? (
    urlView ? (
      <Link
        sx={{ textDecoration: noDecoration ? "none" : "underline" }}
        href={instance.url}
        download={download ? `${name || "download"}.pdf` : false}
        target="_blank"
      >
        {customComponent || name || "download"}
      </Link>
    ) : (
      <Link
        sx={{ textDecoration: noDecoration ? "none" : "underline" }}
        href={instance.url}
        download={download ? `${name || "download"}.pdf` : false}
        target="_blank"
      >
        <IconButton aria-label="download">
          <FileDownload />
        </IconButton>
      </Link>
    )
  ) : null;
};

export default GeneralPDFDownload;
