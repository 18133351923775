import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { openGeneralAlert } from "../redux/features/alert/alertSlice";
import {
  addItem,
  findTote,
  replaceToteItems,
  replaceToteItemsAndQty,
  updatedToteStatus,
} from "../redux/features/tote/toteSlice";
import {
  openValidateItemWarningModal,
  closeValidateItemWarningModal,
} from "../redux/features/modal/modalSlice";
import {
  Container,
  Stack,
  Button,
  InputAdornment,
  TextField,
  Box,
  useMediaQuery,
  Typography,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import ToteItemBasic from "../components/ToteItemBasic";
import WarningActionModal from "../components/WarningActionModal";
import GeneralAlert from "../components/GeneralAlert";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

const ValidateTote = () => {
  const [validatedItems, setValidatedItems] = useState([]);
  const [itemInput, setItemInput] = useState("");
  const [itemError, setItemError] = useState({ error: false, msg: "" });
  const [warningModalInfo, setWarningModalInfo] = useState({
    title: "",
    text: "",
    modalAction: null,
  });
  const matches = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toteid } = useParams();
  const {
    items,
    toteid: toteID,
    memo,
    totalitemsqty,
  } = useSelector((state) => state.tote);
  const { currentSubsidiary } = useSelector((state) => state.user);
  const { isValidateItemWarningModalOpen } = useSelector(
    (state) => state.modal
  );
  const validateInputRef = useRef(null);

  useEffect(() => {
    if (currentSubsidiary) {
      getTote();
    }
  }, [currentSubsidiary]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const getTote = async () => {
    try {
      const tote = await dispatch(findTote({ toteid })).unwrap();

      if (tote.subsidiary !== currentSubsidiary || tote.items.length === 0) {
        navigate("/portal/reverse-logistics/tote-manager");
        return;
      }
      setValidatedItems(tote.items);
    } catch (error) {
      navigate("/portal/reverse-logistics/tote-manager");
    }
  };

  const validateItem = (e) => {
    e.preventDefault();
    const newItems = [];
    let found = false;

    for (let el of validatedItems) {
      if (
        el.item === itemInput ||
        el.upc === itemInput ||
        el.item.replace("-", "") === itemInput.replace("-", "")
      ) {
        found = true;

        if (el.qty > 1) {
          newItems.push({ ...el, qty: el.qty - 1 });
        }
        continue;
      }
      newItems.push(el);
    }

    if (!found) {
      const itemObj = items.find((item) => {
        if (
          item.item === itemInput ||
          item.upc === itemInput ||
          item.item.replace("-", "") === itemInput.replace("-", "")
        ) {
          return true;
        }
      });

      if (itemObj) {
        setWarningModalInfo({
          title: `Add ${itemObj.item} to Tote?`,
          text: `Item has already been processed and you are exceeding the original quantity of ${itemObj.qty}. Ensure you have the correct quantity before proceeding. By continuing, the item will be added to ${toteid} and will be immediately processed.`,
          modalAction: addNewValidatedItem,
        });
        dispatch(openValidateItemWarningModal());
      } else {
        setWarningModalInfo({
          title: `Add ${itemInput} to Tote?`,
          text: `This Item is not in the original list of items. Ensure you have the correct item and entered a valid item number. By continuing, the item will be added to ${toteid} and will be immediately processed.`,
          modalAction: addNewValidatedItem,
        });
        dispatch(openValidateItemWarningModal());
      }
      setItemInput("");
      validateInputRef.current.focus();
      validateInputRef.current.inputMode = "none";
      return;
    }
    validateInputRef.current.focus();
    validateInputRef.current.inputMode = "none";
    setValidatedItems(newItems);
    setItemInput("");
  };

  //remove leftover items and update tote with resulting array
  const adjustToteAndValidate = async () => {
    const adjustedItems = [];

    for (let el of items) {
      const itemObj = validatedItems.find((item) => {
        if (item.item === el.item) {
          return true;
        }
      });
      if (itemObj) {
        //check quantities - we dont push item to array if no leftover qty
        if (Math.abs(el.qty - itemObj.qty) !== 0) {
          adjustedItems.push({
            ...itemObj,
            qty: Math.abs(el.qty - itemObj.qty),
          });
        }
      } else {
        adjustedItems.push(el);
      }
    }
    //dispatch action to update tote items with new array
    try {
      await dispatch(
        replaceToteItemsAndQty({
          newItems: adjustedItems,
          toteID: toteid,
          newToteQTY: adjustedItems.reduce((acc, curr) => acc + curr.qty, 0),
        })
      ).unwrap();
      await dispatch(
        updatedToteStatus({ newStatus: "validated", toteid })
      ).unwrap();
      dispatch(closeValidateItemWarningModal());
      navigate(`/portal/reverse-logistics/tote-manager/${toteid}`);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${error}.Could Not Validate Please Try Again.`,
          duration: 3000,
        })
      );
    }
  };

  const adjustToteAndValidateModal = () => {
    setWarningModalInfo({
      title: "Remove Remaining Items From Tote?",
      text: `There are still items in the tote. Please double check that the following items are not in the tote before validating:
      ${validatedItems
        .map((item) => `${item.item} (-${item.qty})`)
        .join(", ")}`,
      modalAction: adjustToteAndValidate,
    });
    dispatch(openValidateItemWarningModal());
  };

  const validateTote = () => {
    dispatch(updatedToteStatus({ newStatus: "validated", toteid }));
    navigate(`/portal/reverse-logistics/tote-manager/${toteid}`);
  };

  const addNewValidatedItem = async () => {
    try {
      const response = await dispatch(
        addItem({
          itemInfo: itemInput,
          itemQty: 1,
          memo,
          subsidiary: currentSubsidiary,
        })
      ).unwrap();

      //no need to update UI since the part will get validated anyways
      // setValidatedItems(response.updatedTote.items);

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `${
            response ? response.returnItem : "Item"
          } successfully added to ${toteid} and automatically processed.`,
          duration: 3000,
        })
      );
      setItemError({ error: false, msg: "" });
    } catch (error) {
      setItemError({ error: true, msg: error });
    }

    dispatch(closeValidateItemWarningModal());
  };

  const handleChanges = (e) => {
    setItemInput(e.target.value.toUpperCase());
    setItemError("");
  };

  const resetValidation = () => {
    setWarningModalInfo({
      title: "Reset Validation?",
      text: `You will restart from the beginning and lose all your progress. Action cannot be undone.`,
      modalAction: () => {
        setValidatedItems(items);
        dispatch(closeValidateItemWarningModal());
      },
    });
    dispatch(openValidateItemWarningModal());
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: { xs: "calc(100vh - 81px)", md: "calc(100vh - 92.5px)" },
        pt: 1,
        mb: 3,
      }}
    >
      <WarningActionModal
        dividers={false}
        open={isValidateItemWarningModalOpen}
        title={warningModalInfo.title}
        closeFn={() => dispatch(closeValidateItemWarningModal())}
        text={warningModalInfo.text}
        modalAction={warningModalInfo.modalAction}
      />
      {/* <span>
        <ArrowCircleUpIcon
          fontSize="small"
          sx={{ mb: 1, transform: "rotate(270deg)" }}
        />
      </span> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          component={"form"}
          onSubmit={validateItem}
          sx={{
            pb: 1,
            display: "flex",
            alignItems: "center",
            gap: 2,
            justifyContent: { md: "space-between" },
          }}
        >
          <TextField
            fullWidth={matches}
            onClick={() => (validateInputRef.current.inputMode = "text")}
            inputRef={validateInputRef}
            autoFocus
            error={itemError.error}
            size="small"
            id="component-outlined"
            label="Enter Item"
            required
            value={itemInput}
            onChange={handleChanges}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  onClick={validateItem}
                  position="end"
                  sx={{ cursor: "pointer", height: "100%" }}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            sx={{ alignSelf: { xs: "stretch" }, px: 2 }}
            size="small"
            variant="outlined"
            endIcon={<RefreshIcon />}
            onClick={resetValidation}
          >
            Reset
          </Button>
        </Box>
        <Typography variant="caption" color="error">
          {itemError.msg}
        </Typography>
        {items && validatedItems.length > 0 ? (
          <Box
            sx={{
              overflow: "auto",
              height: "80%",
              borderBottom: "1px solid #e7ebf0",
              pt: 2,
            }}
          >
            <Stack spacing={1}>
              {validatedItems.map((item) => {
                return <ToteItemBasic key={item.mongoid} item={item} />;
              })}
            </Stack>
          </Box>
        ) : (
          <Box
            sx={{
              height: "85%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ typography: { xs: "h5", md: "h3" } }}>
              Tote is ready to be validated
            </Typography>
          </Box>
        )}
        {validatedItems.length > 0 ? (
          <Button
            onClick={adjustToteAndValidateModal}
            fullWidth
            variant="contained"
            size="large"
            sx={{ mt: 3 }}
          >
            Adjust And Validate Tote
          </Button>
        ) : (
          <Button
            onClick={validateTote}
            fullWidth
            variant="contained"
            size="large"
            sx={{ mt: 3 }}
          >
            Validate Tote
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default ValidateTote;
