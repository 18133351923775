import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import axios from "../../../axios/axios.config";
import Loading from "../../Loading";
import FlexWrapper from "../../FlexWrapper";

const IncompleteMoves = ({
  setLoading,
  setItemBinData,
  setMongoData,
  setFilteredBins,
}) => {
  const [getMovesLoading, setGetMovesLoading] = useState(false);
  const [userMoves, setUserMoves] = useState([]);

  const { username, currentNetsuiteLocationIds } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    getUserMoves();
  }, []);

  const getUserMoves = async () => {
    setGetMovesLoading(true);

    const response = await axios.get(`inventory-move/${username}`);

    if (response.data.length) {
      setUserMoves(response.data);
    }

    setGetMovesLoading(false);
  };

  const handleEnterTransfer = async ({ _id, item }) => {
    setLoading(true);

    const response = await axios.post(
      `netsuite/post/inventory-move/item/${item}/bin-data`,
      { nsIds: currentNetsuiteLocationIds }
    );

    //get data from mongo
    const mongoResponse = await axios.get(`inventory-move/incomplete/${_id}`);

    setItemBinData(response.data);

    //filter by brand only
    setFilteredBins(
      response.data.filter((item) => {
        if (item.binnumber === mongoResponse.data.sourcebin.binnumber)
          return false;

        return (
          !item.binbrand ||
          !mongoResponse.data.sourcebin.itembrand ||
          !item.binboxsizetype ||
          !mongoResponse.data.sourcebin.itemboxsizetype ||
          item.binbrand?.includes(mongoResponse.data.sourcebin.itembrand)
        );
      })
    );

    //Filter by brand and bin type
    // setFilteredBins(
    //   itemBinData.filter((item) => {
    //     if (item.binnumber === existingBin.binnumber) return false;

    //     return (
    //       !item.binbrand ||
    //       !existingBin.itembrand ||
    //       !item.binboxsizetype ||
    //       !existingBin.itemboxsizetype ||
    //       (item.binbrand?.includes(existingBin.itembrand) &&
    //         item.binboxsizetype?.includes(existingBin.itemboxsizetype))
    //     );
    //   })
    // );

    setMongoData({
      sourcebin: mongoResponse.data.sourcebin.binnumber,
      qty: mongoResponse.data.qtytomove,
      _id: mongoResponse.data._id,
      status: mongoResponse.data.status,
    });
    setLoading(false);
  };

  if (getMovesLoading) return <Loading message="Loading Inventory Moves..." />;

  return (
    <Box mt={3}>
      <Typography variant="h5" textAlign="center" gutterBottom>
        Incomplete Moves
      </Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        pb={1}
        gap={1}
      >
        {userMoves.length ? (
          userMoves.map((move) => (
            <Grid key={move._id} item xs={12} sm={6} md={5}>
              <Card
                style={{
                  height: "100%",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                variant="outlined"
                onClick={() =>
                  handleEnterTransfer({ _id: move._id, item: move.item })
                }
              >
                <CardContent style={{ paddingBottom: "16px" }}>
                  <FlexWrapper justifyContent="space-between">
                    <Typography fontWeight="bold">Item</Typography>
                    <Typography fontWeight="bold">Class</Typography>
                  </FlexWrapper>
                  <Divider />
                  <FlexWrapper justifyContent="space-between">
                    <Typography variant="subtitle1" pb={1}>
                      {move.item}
                    </Typography>
                    <Typography variant="subtitle1" pb={1}>
                      {move.itemclass}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper justifyContent="space-between">
                    <Typography fontWeight="bold">Source Bin</Typography>
                    <Typography fontWeight="bold">Qty To Move</Typography>
                  </FlexWrapper>
                  <Divider />
                  <FlexWrapper justifyContent="space-between">
                    <Typography>{move.sourcebin.binnumber}</Typography>
                    <Typography variant="subtitle1" pb={1}>
                      {move.qtytomove}
                    </Typography>
                  </FlexWrapper>
                  <Typography variant="caption" textAlign="center">
                    {new Date(move.createdAt).toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography textAlign="center">No data to display</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default IncompleteMoves;
