export const equipmentQuestions = {
  "Electric Pallet Jack": [
    "Brakes good working order",
    "Hydraulics - No leaks / hoses in good condition",
    "Tire condition good",
    "No visible damage",
    "Forks, hydraulics & carriage operate properly",
    "Steering working properly",
    "Battery in good condition",
  ],
  "Electric Lift": [
    "Lights working properly",
    "Brakes good working order",
    "Hydraulics - No leaks / hoses in good condition",
    "Tire condition good",
    "No damage, bends or looseness to chains",
    "Mast, forks, lift chains, hydraulics & carriage operate properly",
    "Steering working properly",
    "Battery in good condition",
  ],
  Propane: [
    "Lights working properly",
    "Brakes good working order",
    "Hydraulics - No leaks / hoses in good condition",
    "Tire condition good",
    "No damage, bends or looseness to chains",
    "Mast, forks, lift chains, hydraulics & carriage operate properly",
    "Parking brake working properly",
    "Propane tank secured and connected properly",
    "Steering working properly",
    "Battery in good condition",
  ],
};
