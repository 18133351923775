import { Container } from "@mui/material";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import Loading from "../../components/Loading";
import LaborClaimHeader from "../../components/multi-page/labor-claim/LaborClaimHeader";
import LaborClaimFormDetails from "../../components/multi-page/labor-claim/LaborClaimFormDetails";
import SubmitFormReturnsProcessedView from "../../components/multi-page/labor-claim/SubmitFormReturnsProcessedView";

const LaborClaimReturnsProcessedView = () => {
  const { data, fileURL } = useLoaderData();
  const [loading, setLoading] = useState("");
  const [form, setForm] = useState(data.form);

  if (loading) return <Loading message={loading} />;

  return (
    <Container maxWidth="md" sx={{ pb: 2 }}>
      <LaborClaimHeader
        loading={loading}
        setLoading={setLoading}
        fileURL={fileURL}
        form={form}
        setForm={setForm}
        createdby={data.createdby}
      />
      <LaborClaimFormDetails
        isReadOnly={false}
        isDefaultOpen={false}
        setLoading={setLoading}
        setForm={setForm}
        form={form}
      />
      <SubmitFormReturnsProcessedView
        form={form}
        setLoading={setLoading}
        fileURL={fileURL}
      />
    </Container>
  );
};

export default LaborClaimReturnsProcessedView;
