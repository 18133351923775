import { Container, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useLoaderData, useNavigation } from "react-router-dom";
import FlexWrapper from "../../../components/FlexWrapper";
import {
  laborClaimCompleteNingboColumns,
  laborClaimProcessedNingboColumns,
} from "../../../utils/columns/ningbo/labor-claim/laborClaimNingboColumns";
import Loading from "../../../components/Loading";

function CustomToolbar({ type }) {
  return (
    <GridToolbarContainer>
      <Typography variant="h4" gutterBottom pl={1}>
        {type}
      </Typography>
      <FlexWrapper
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <FlexWrapper gap={1} alignItems="flex-end">
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{
              fileName: `${type}-LC-${Date.now()}`,
            }}
            printOptions={{
              disableToolbarButton: true,
            }}
          />
        </FlexWrapper>
        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const LaborClaimNingboPage = () => {
  const data = useLoaderData();
  const navigation = useNavigation();

  if (navigation.state === "loading") {
    return <Loading message="Loading Form..." />;
  }

  const processedLaborClaims = data.filter(
    (lc) => lc.form.status === "processed"
  );
  const completeLaborClaims = data.filter(
    (lc) => lc.form.status === "complete"
  );
  return (
    <Container maxWidth="xl">
      <Typography textAlign="center" variant="h4" gutterBottom>
        Labor Claims
      </Typography>
      <FlexWrapper
        flexDirection="column"
        justifyContent="space-between"
        gap={1}
      >
        <DataGrid
          autoHeight
          sx={{ width: "100%", height: "45vh" }}
          rows={processedLaborClaims}
          columns={laborClaimProcessedNingboColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
              sortModel: [
                {
                  field: "completeddate",
                  sort: "desc", // 'asc' for ascending, 'desc' for descending
                },
              ],
            },
          }}
          pageSizeOptions={[100, 150, 200]}
          density="compact"
          disableColumnMenu
          getRowId={(row) => row._id}
          slots={{ toolbar: () => <CustomToolbar type="Processed" /> }}
          disableDensitySelector
          disableColumnSelector
        />

        <DataGrid
          autoHeight
          sx={{ width: "100%", height: "45vh" }}
          rows={completeLaborClaims}
          columns={laborClaimCompleteNingboColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
              sortModel: [
                {
                  field: "date",
                  sort: "desc", // 'asc' for ascending, 'desc' for descending
                },
              ],
            },
          }}
          pageSizeOptions={[100, 150, 200]}
          density="compact"
          disableColumnMenu
          getRowId={(row) => row._id}
          slots={{ toolbar: () => <CustomToolbar type="Complete" /> }}
          disableDensitySelector
          disableColumnSelector
        />
      </FlexWrapper>
    </Container>
  );
};
export default LaborClaimNingboPage;
