import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { pdf } from "@react-pdf/renderer";
import { sendGeneralEmail } from "../../../utils/email/generalEmailFunction";
import { Button, Grid, TextField, Typography } from "@mui/material";

import CreditMemoAndRMANetsuiteLinks from "../netsuite/CreditMemoAndRMANetsuiteLinks";
import FlexWrapper from "../../FlexWrapper";
import LaborClaimCreditMemoPDF from "../../PDF/multi-page/LaborClaimCreditMemoPDF";
import NingboCreditMemoPDF from "../../PDF/ningbo/NingboCreditMemoPDF";
import axios from "../../../axios/axios.config";

const SubmitFormNingboProcessedView = ({ form, setLoading }) => {
  const { currentDepartment, username, firstname, lastname, email } =
    useSelector((state) => state.user);
  const { formid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cm, setCM] = useState({ creditmemonumber: "", creditamountfee: "" });

  const handleChanges = (event) => {
    setCM({
      ...cm,
      [event.target.name]: event.target.value?.toUpperCase(),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("Submitting Labor Claim...");
    try {
      const attachments = [];
      const completedDate = new Date().toLocaleString();

      if (!cm.creditmemonumber.startsWith("W")) {
        throw new Error("Credit Memos must start with W for NINGBO.");
      }

      //transform state to send to backend
      const transformedObject = {};
      for (const key in form) {
        if (Object.hasOwnProperty.call(form, key)) {
          transformedObject[`form.${key}`] = form[key];
        }
      }

      //update creditmemointernalid and status
      transformedObject["form.status"] = "complete";
      transformedObject["form.completedbyusername"] = username;
      transformedObject["form.completedbyname"] = `${firstname} ${lastname}`;
      transformedObject["form.completedbyemail"] = email;
      transformedObject["form.completeddate"] = completedDate;
      transformedObject["form.creditmemointernalid"] = null;
      transformedObject["form.creditmemonumber"] = cm.creditmemonumber;
      transformedObject["form.creditamountfee"] = cm.creditamountfee;

      const { data } = await axios.patch(
        `forms/update/labor-claim/${formid}/ningbo/processed-view`,
        {
          updateData: transformedObject,
        }
      );

      console.log(data, "data we are getting back");

      const createdDate = data.form.date;
      const rma = {
        number: data.form.rmaNum.replace("RMA", ""),
        date: new Date(data.form.rmaissuedate).toLocaleDateString(),
        name: data.form.rmaissuedbyname,
      };
      const rga = {
        number: data.form.rgaNum.replace("RGA", ""),
      };
      const claimDetail = {
        date: new Date(data.form.processedbydate).toLocaleDateString(),
        reviewed: data.form.partNumbers
          .map((partObj) => `${partObj.partnumber} x ${partObj.qty}`)
          .join(", "),
        company: data.form.customer,
        by: "AIT",
      };
      const detail = {
        detail: data.form.detail,
        note: data.form.note,
      };
      const internalUse = {
        restockingFee: data.form.restockingfee,
        dateReceived: data.form.datereceived,
        receivedBy: data.form.receivedbyname,
        result: data.form.finalresponse,
        creditMemoNumber: data.form.creditmemonumber,
        creditIssuedDate: new Date().toLocaleDateString(),
      };

      const laborClaimMemoResponseBlob = await pdf(
        <LaborClaimCreditMemoPDF
          rma={rma}
          rga={rga}
          claimDetail={claimDetail}
          detail={detail}
          internalUse={internalUse}
          createdDate={createdDate}
        />
      ).toBlob();

      await addBlobToAttachments(
        laborClaimMemoResponseBlob,
        `${data.form.rmaNum.replace("RMA", "RMA ")}-RESPONSE.pdf`,
        attachments
      );

      // add new ningbo response to email attachments
      const laborClaimMemoBlob = await pdf(
        <NingboCreditMemoPDF
          rgs={data.form.rgaNum}
          creditDate={
            data.form?.completeddate
              ? new Date(data.form.completeddate).toLocaleDateString()
              : ""
          }
          creditAmount={data.form.creditamountfee}
          creditNumber={data.form.creditmemonumber}
          items={
            data?.form?.partNumbers
              ? data.form.partNumbers.map((item) => item.partnumber)
              : []
          }
          totalItemQty={
            data?.form?.partNumbers
              ? data.form.partNumbers.reduce(
                  (accumulator, item) => accumulator + parseInt(item.qty),
                  0
                )
              : ""
          }
        />
      ).toBlob();

      await addBlobToAttachments(
        laborClaimMemoBlob,
        `${data.form.creditmemonumber}-CREDIT_MEMO_NINGBO.pdf`,
        attachments
      );

      const fileKeys = data.form.file.map(
        (filename) => `forms/laborclaim/${formid}/${filename}`
      );

      const fileURLResponse = await axios.post("files/multi/signedURL", {
        options: { expiresIn: 3600 },
        fileKeys,
      });

      const fileURL = fileURLResponse.data;

      //get attachment objects from S3 files
      for (let i = 0; i < fileURL.length; i++) {
        const attachment = await getAttchmentObjects(fileURL[i], i);
        attachments.push(attachment);
      }

      await sendGeneralEmail({
        to: process.env.REACT_APP_TEST_EMAIL || "laborclaims@oreillyauto.com",
        from: "returns@trakmotive.com",
        fromname: "AIT Returns",
        cc: process.env.REACT_APP_TEST_EMAIL
          ? ""
          : data.form.additionalEmails || "",
        bcc: process.env.REACT_APP_TEST_EMAIL ? "" : "returns@trakmotive.com",
        subject: `Labor Claim Final Response`,
        body: `<p>87116 Ningbo Wonh</p></br><p>Please find attached the response form and credit for ${data.form.rmaNum.replace(
          "RMA",
          "RMA# "
        )} for ${data.form.customer}.</p>
            </span></i></b></p><p class=MsoNormal><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>Returns Clerk<o:p>
              <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Advanced Innovative Technology Corp.</span>
            `,
        isHtml: true,
        attachments,
      });

      // Update notes
      const newNotes = `${
        data.form.rmanotes
          ? `${data.form.rmanotes}\n${completedDate} (${username}) - ${data.form.finalresponse} - ${data.form.lcresponse}`
          : ""
      }`;

      await axios.patch(`forms/update/labor-claim/${formid}/notes`, {
        updateData: {
          "form.rmanotes": newNotes,
        },
      });

      navigate(`/portal/${currentDepartment?.toLowerCase()}/forms/labor-claim`);

      handleAlert({
        type: "success",
        message: `Successfully Completed Labor Claim`,
        duration: 3000,
      });
    } catch (error) {
      console.log(error);
      handleAlert({
        type: "error",
        message: `Could Not Complete Labor Claim: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      setLoading("");
    }
  };

  const handleAlert = ({ type, message, duration }) => {
    dispatch(openGeneralAlert({ type, message, duration }));
  };

  const getAttchmentObjects = async (url, index) => {
    try {
      const fileName = form.file[index];
      const fileExtension = fileName.split(".").pop();
      let fileType = "application/octet-stream";

      if (fileExtension === "pdf") {
        fileType = "application/pdf";
      }

      if (fileExtension === "png") {
        fileType = "image/png";
      }

      if (fileExtension === "jpeg") {
        fileType = "image/jpeg";
      }

      const fileResponse = await axios.get(url, {
        responseType: "blob",
      });
      const blob = fileResponse.data;

      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const string = reader.result.split(",")[1];
          resolve({
            content: string, // Convert the file buffer to base64 string
            filename: fileName, // Set the original file name as the attachment filename
            type: fileType, // Set the file's mimetype
            disposition: "attachment", // Set the disposition as attachment
          });
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw error;
    }
  };

  const convertBlobToBase64 = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const addBlobToAttachments = async (blob, filename, attachments) => {
    try {
      const base64String = await convertBlobToBase64(blob);
      attachments.push({
        content: base64String,
        filename: filename,
        type: "application/pdf",
        disposition: "attachment",
      });
    } catch (error) {
      console.error("Error converting blob to base64:", error);
    }
  };

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
      {form?.status === "incomplete" && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">Incomplete</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Closed By"
              InputProps={{ readOnly: true }}
              value={`${form?.closedbyname} (${form?.closedbyusername})`}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Closed By Email"
              InputProps={{ readOnly: true }}
              value={form?.closedbyemail}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Closed Date"
              value={new Date(form?.closeddate).toLocaleDateString()}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Closed Time"
              value={new Date(form?.closeddate).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              required
              minRows={4}
              type="text"
              label="Reason For Closure"
              value={form?.closedreason}
              InputProps={{ readOnly: true }}
              onChange={handleChanges}
            />
          </Grid>
        </>
      )}

      {form?.rmaNum && form.status !== "new" && (
        <>
          <Grid item xs={12}>
            <FlexWrapper alignItems="center" gap={1}>
              <Typography variant="h6">RMA</Typography>
              {(currentDepartment === "RETURNS" ||
                currentDepartment === "CUSTOMER-SERVICE" ||
                currentDepartment === "PRODUCT") &&
                form?.rmainternalid && (
                  <CreditMemoAndRMANetsuiteLinks
                    internalid={form?.rmainternalid}
                  />
                )}
            </FlexWrapper>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              name="rmaNum"
              label="RMA Number"
              InputProps={{ readOnly: true }}
              value={form?.rmaNum}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              name="rmaissuedby"
              label="RMA Issued By"
              value={`${form?.rmaissuedbyname} (${form?.rmaissuedby})`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              name="rmaissuedbyemail"
              label="RMA Issued By Email"
              value={form?.rmaissuedbyemail}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              label="RMA Issued By Department"
              value={form?.rmaissueddepartment}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="RMA Issued Date"
              value={new Date(form?.rmaissuedate).toLocaleDateString()}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="RMA Issued Time"
              value={new Date(form?.rmaissuedate).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </>
      )}

      {form?.receivedby && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5">LC Received</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              name="receivedby"
              label="Received By"
              value={`${form?.receivedbyname} (${form?.receivedby})`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              name="receivedbyemail"
              label="Received By Email"
              value={form?.receivedbyemail}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              name="datereceived"
              label="Date Received"
              value={form?.datereceived}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="time"
              name="timereceived"
              label="Time Received"
              value={form?.timereceived}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="bin"
              label="Bin Number"
              value={form?.bin}
              InputProps={{ readOnly: true }}
              onChange={handleChanges}
            />
          </Grid>
        </>
      )}

      {form?.status === "processed" && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5">Processed</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label="Response"
              name="lcresponse"
              value={form?.lcresponse}
              InputProps={{ readOnly: true }}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              required
              minRows={4}
              type="text"
              name="detail"
              label="Detail"
              value={form.detail}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="note"
              label="Note"
              value={form.note}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
              onChange={handleChanges}
            />
          </Grid>
          {form.finalresponse && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                label="Result"
                value={form.finalresponse}
                InputProps={{ readOnly: true }}
                onChange={handleChanges}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Processed By"
              value={`${form.processedbyname} (${form.processedby})`}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Processed By Email"
              value={form.processedbyemail}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          {form.processedbydate && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="text"
                  label="Processed Date"
                  value={new Date(form.processedbydate).toLocaleDateString()}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="text"
                  label="Processed Time"
                  value={new Date(form.processedbydate).toLocaleTimeString(
                    "en",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Internal Use Only</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  name="creditamountfee"
                  label="Credit Amount"
                  value={cm.creditamountfee || ""}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="creditmemonumber"
                  label="Credit Memo Number"
                  value={cm.creditmemonumber || ""}
                  onChange={handleChanges}
                />
              </Grid>
            </>
          )}
        </>
      )}

      <Grid item xs={12}>
        <Button type="submit" variant="contained" fullWidth>
          Submit Labor Claim
        </Button>
      </Grid>
    </Grid>
  );
};

export default SubmitFormNingboProcessedView;
