import { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  Tooltip,
  Button,
  IconButton,
  Divider,
  TextField,
  Link,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { replaceToteItems } from "../redux/features/tote/toteSlice";
import { closeGeneralAlert } from "../redux/features/alert/alertSlice";
import { statusColorMap } from "../utils/tote-status/toteStatus";
import { useNavigate } from "react-router-dom";
import { Edit, Info } from "@mui/icons-material";

import ToteItem from "./ToteItem";
import ActionModal from "./ActionModal";
import SplitActionToteInfo from "./SplitActionToteInfo";
import SplitActionToteActions from "./SplitActionToteActions";
import CircleIcon from "@mui/icons-material/Circle";
import axios from "../axios/axios.config";
import ToteManagerInfoModal from "./reverse-logistics/tote-manager/ToteManagerInfoModal";
import FlexWrapper from "./FlexWrapper";

const ToteItems = ({ bottomRef }) => {
  const [open, setOpen] = useState(false); //opens Action Modal (edit/delete)
  const [modalInfo, setModalInfo] = useState({});

  const navigate = useNavigate();
  const tote = useSelector((state) => state.tote);
  const dispatch = useDispatch();

  useEffect(() => {
    getInterchange()
      .then((newItems) =>
        dispatch(replaceToteItems({ newItems, toteID: tote.toteid }))
      )
      .catch((error) => console.log(error));
  }, [tote.memo]);

  const getInterchange = async () => {
    const defaultRes = { interchange: "", interchangeUPC: "" };
    const newItemsArr = await Promise.all(
      tote.items.map(async (item) => {
        try {
          let interchange = await axios.get(
            `totes/${
              tote.memo.includes("NAPA") ? `${item.item}` : `${item.basenumber}`
            }/interchange/${tote.memo}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          return {
            ...item,
            interchange:
              interchange.data.interchange === item.item
                ? defaultRes
                : interchange.data,
          };
        } catch (error) {
          console.log(error);
        }
      })
    );
    return newItemsArr;
  };

  return (
    <>
      <ActionModal modalInfo={modalInfo} open={open} setOpen={setOpen} />

      <FlexWrapper justifyContent="space-between" alignItems="baseline">
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <FlexWrapper alignItems="center" gap={2}>
            <FlexWrapper gap={1} alignItems="baseline">
              <CircleIcon
                color={statusColorMap[tote.status]}
                sx={{ fontSize: "1.2rem" }}
              />

              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "12px", md: "1.5rem" },
                }}
              >
                {tote.toteid}
              </Typography>

              <Typography
                variant="caption"
                component={Link}
                onClick={() => {
                  dispatch(closeGeneralAlert());
                  navigate("/portal/reverse-logistics/tote-manager");
                }}
                sx={{ cursor: "pointer" }}
              >
                Change
              </Typography>
            </FlexWrapper>
            <Typography sx={{ fontSize: { xs: "12px", md: "1.2rem" } }}>
              Qty: <strong>{tote.totalitemsqty}</strong>
            </Typography>

            <Typography sx={{ fontSize: { xs: "12px", md: "1.2rem" } }}>
              Unique: <strong>{tote.items.length}</strong>
            </Typography>
          </FlexWrapper>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: { xs: "100%", md: "initial" },
          }}
        >
          <FlexWrapper alignItems="center">
            <ToteManagerInfoModal />
            {/* <SplitActionToteInfo /> */}
            <SplitActionToteActions />
          </FlexWrapper>
        </Box>
      </FlexWrapper>
      <Box
        sx={{
          overflow: "auto",
          "&::-webkit-scrollbar": { display: "none" },
          py: 1,
          height: "100%",
          borderBottom: "1px solid lightgrey",
        }}
      >
        {tote.items.length > 0 ? (
          <Stack spacing={{ xs: 1, md: 2 }} sx={{ flex: 1 }}>
            {tote.items.map((item) => (
              <ToteItem
                key={item.item}
                toteStatus={tote.status}
                setOpen={setOpen}
                setModalInfo={setModalInfo}
                {...item}
              />
            ))}
            <div ref={bottomRef}></div>
          </Stack>
        ) : (
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            No Items In Tote
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ToteItems;
