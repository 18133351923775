import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import CancelIcon from "@mui/icons-material/Cancel";
import FlexWrapper from "./FlexWrapper";

const File = ({ file, handleRemoveFile }) => {
  const removeFile = (event) => {
    event.stopPropagation();
    handleRemoveFile(file);
  };

  return (
    <Box py={1} sx={{ height: "100%", maxWidth: { xs: "110px", md: "120px" } }}>
      <FlexWrapper>
        <Box sx={{ position: "relative" }}>
          <FilePresentIcon fontSize="large" />
          <Box
            sx={{ position: "absolute", top: -10, right: 0, cursor: "pointer" }}
            onClick={removeFile}
          >
            <CancelIcon fontSize="small" color="error" />
          </Box>
        </Box>

        <Typography noWrap>{file.name}</Typography>
      </FlexWrapper>
    </Box>
  );
};

const GeneralFileUpload = ({
  multiple = false,
  required = false,
  files = [],
  handleSaveFiles,
  handleRemoveFile,
  accept = "",
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple,
    disabled: loading,
  });

  //function runs when files are added
  async function handleDrop(acceptedFiles) {
    try {
      setLoading(true);
      setError(false);
      //function to save and display files
      await handleSaveFiles(acceptedFiles);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      {...getRootProps({ onDragEnd: (e) => e.preventDefault() })}
      className={`dropzone ${isDragActive ? "active" : ""}`}
      sx={{
        border: "1px dashed #1E81D8",
        borderRadius: "8px",
        textAlign: "center",
        py: 2,
        px: 1,
        pointerEvents: loading ? "none" : "initial",
      }}
    >
      <input
        {...getInputProps()}
        accept={Array.isArray(accept) ? accept.join(",") : accept}
        type="file"
        name="file-upload"
        multiple={multiple}
      />
      {loading && <Typography variant="body1">Uploading Files...</Typography>}
      {Boolean(error) && !loading && (
        <Typography color="error" variant="body1">
          {error}
        </Typography>
      )}
      {!loading &&
        !Boolean(error) &&
        (files.length ? (
          <FlexWrapper justifyContent="center">
            <Stack
              direction="row"
              flexWrap="wrap"
              sx={{ columnGap: { xs: 0, md: 3 }, rowGap: { xs: 0, md: 2 } }}
              maxWidth={"90%"}
              alignItems="center"
            >
              {files.map((file) => (
                <File
                  key={file.name}
                  file={file}
                  handleRemoveFile={handleRemoveFile}
                />
              ))}
            </Stack>
          </FlexWrapper>
        ) : (
          <Typography>Click to add or drag files here</Typography>
        ))}
    </Box>
  );
};
export default GeneralFileUpload;
