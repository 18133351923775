import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { useSearchParams } from "react-router-dom";
import { useSalesRepCustomerMatch } from "../../../hooks/useSalesRepCustomerMatch";
import {
  createForm,
  updateForm,
} from "../../../redux/features/forms/formsSlice";
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import WarningActionModal from "../../../components/WarningActionModal";
import FileUpload from "../../../components/sales/FileUpload";
import axios from "../../../axios/axios.config";
import Loading from "../../../components/Loading";

const DealSheet = ({
  setFilteredDealsheetRows,
  setDealsheetRows,
  dealsheetRows,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalInputValue, setModalInputValue] = useState(""); //used for form deletion
  const [modalInputError, setModalInputError] = useState(false); //used for form deletion
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [files, setFiles] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [fileURL, setFileURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [input, setInput] = useState({
    date: new Date().toISOString().slice(0, 10),
    accountName: "",
    repAgency: "",
    accountManager: "",
    specialReason: "",
    billingAddress: "",
    nextDate: new Date().toISOString().slice(0, 10),
    status: "",
    reference: "",
    frequency: "",
    approvalStatus: "pending",
  });

  const { filteredCustomers } = useSalesRepCustomerMatch();

  useEffect(() => {
    //updates form with deal sheet data
    const id = searchParams.get("deal-sheet");
    if (id && searchParams.get("editing") === "true") {
      setFormLoading(true);
      let form = null;
      axios
        .get(`/forms/deal-sheet/${id}`)
        .then((response) => {
          form = response.data;
          if (form?.versions[0]?.file) {
            getFileURL(form);
          }
          setInput({ ...form.versions[0] });
          setEditing(true);
        })
        .catch((error) => console.log(error))
        .finally(setFormLoading(false));
      return;
    }
    //updates account manager name
    if (user.firstname && user.lastname) {
      setInput({
        ...input,
        accountManager: `${user.firstname} ${user.lastname}`,
      });
    }
  }, [searchParams, user]);

  async function getFileURL(form) {
    try {
      const response = await axios.post("files/signedURL", {
        Key: "forms/dealsheet/" + form.versions[0].file,
      });
      setFileURL(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleChanges = (event) => {
    if (event.target.name === "accountName") {
      const currentAccount = filteredCustomers.find(
        (acc) => acc.companyname === event.target.value
      );

      setInput({
        ...input,
        [event.target.name]: event.target.value,
        repAgency: currentAccount.partner,
        billingAddress: currentAccount.billingaddress,
      });

      return;
    }
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (files) {
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("folder", "forms/dealsheet/");
        await axios.post("/files/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      const form = await dispatch(
        createForm({
          newForm: {
            version: {
              ...input,
              status: "new",
              reference: "",
              nextDate: "",
              file: files ? files[0].name : null,
            },
            createdby: {
              username: user.username,
              department: user.currentDepartment,
            },
            subsidiary: user.currentSubsidiary,
          },
          type: "deal-sheet",
        })
      ).unwrap();

      sendEmail(form._id);

      setFilteredDealsheetRows([...dealsheetRows, form]);
      setDealsheetRows([...dealsheetRows, form]);

      setSearchParams({});

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully added new form",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${error}`,
          duration: 3000,
        })
      );
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      //changes status when approval status changes from denied
      let newStatus = input.status;
      if (input.status === "denied" && input.approvalStatus !== "denied") {
        newStatus = "new";
      }
      const updatedForm = await dispatch(
        updateForm({
          id: searchParams.get("deal-sheet"),
          updateData: {
            versions: [
              {
                ...input,
                status:
                  input.approvalStatus === "denied" ? "denied" : newStatus,
              },
            ],
          },
          type: "deal-sheet",
        })
      ).unwrap();

      setFilteredDealsheetRows(
        dealsheetRows.map((row) => {
          if (row._id === updatedForm._id) return updatedForm;
          return row;
        })
      );
      setDealsheetRows(
        dealsheetRows.map((row) => {
          if (row._id === updatedForm._id) return updatedForm;
          return row;
        })
      );
      //removes full screen modal
      setSearchParams({});

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully updated form",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${error}`,
          duration: 3000,
        })
      );
    }
  };

  const modalAction = async () => {
    if (modalInputValue === "") {
      setModalInputError(true);
      return;
    }

    try {
      await dispatch(
        updateForm({
          id: searchParams.get("deal-sheet"),
          updateData: {
            status: "deleted",
            deletedby: {
              username: user.username,
              department: user.currentDepartment,
              usertype: user.usertype,
              reason: modalInputValue,
            },
          },
          type: "deal-sheet",
        })
      ).unwrap();

      setFilteredDealsheetRows(
        dealsheetRows.filter(
          (row) => row._id !== searchParams.get("deal-sheet")
        )
      );
      setDealsheetRows(
        dealsheetRows.filter(
          (row) => row._id !== searchParams.get("deal-sheet")
        )
      );

      dispatch(
        openGeneralAlert({
          type: "info",
          message: `Form ${searchParams.get(
            "deal-sheet"
          )} has been successfully deleted`,
          duration: 3000,
        })
      );
      setSearchParams({});
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${error}`,
          duration: 3000,
        })
      );
    }
  };

  const validateFileData = async (jsonData, file) => {
    return file;
  };

  const sendEmail = async (_id) => {
    const dynamicTemplateData = {
      ...input,
      _id,
      username: user.username,
    };

    const email = {
      dynamicTemplateData,
      templateId: "d-cb836b4dff7b4ee996a9c6a84992029a",
      cc: [
        "evelin@trakmotive.com",
        "bcrawford@trakmotive.com, ktalley@trakmotive.com",
      ].includes(user.email)
        ? ""
        : user.email,
      to: process.env.REACT_APP_TEST_EMAIL
        ? process.env.REACT_APP_TEST_EMAIL
        : [
            "evelin@trakmotive.com",
            "bcrawford@trakmotive.com, ktalley@trakmotive.com",
          ],
    };

    try {
      await axios.post("/email", email, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${
            error.response.data ? error.response.data.msg : error.message
          }`,
          duration: 5000,
        })
      );
    }
  };

  if (formLoading && searchParams.get("editing") === "true") {
    return <Loading message="Loading Deal Sheet Form" />;
  }

  return (
    <Container maxWidth="lg" sx={{ pb: 3 }}>
      <WarningActionModal
        open={openWarningModal}
        closeFn={() => {
          setModalInputError(false);
          setModalInputValue("");
          setOpenWarningModal(false);
        }}
        modalAction={modalAction}
        body={
          <TextField
            required
            fullWidth
            error={modalInputError}
            name="reasonForDelete"
            label="Reason For Deletion"
            type="text"
            value={modalInputValue}
            onChange={(e) => setModalInputValue(e.target.value)}
            helperText={modalInputError ? "Field Is Required" : ""}
          />
        }
        title={"Are you sure you want to delete form?"}
        dividers={true}
      />
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={
          searchParams.get("deal-sheet") ? handleEditSubmit : handleSubmit
        }
      >
        {editing && (
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpenWarningModal(true)}
            >
              Delete Form
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          {editing && input.file && (
            <Typography>
              {fileURL ? (
                <>
                  <span style={{ fontWeight: "bold" }}>File:</span>
                  <a href={fileURL}>{input.file}</a>
                </>
              ) : (
                "Could Not Get File"
              )}
            </Typography>
          )}
        </Grid>
        {!editing && (
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              type="date"
              label="Date"
              name="date"
              value={input.date}
              onChange={handleChanges}
              InputProps={{
                readOnly: editing,
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {editing ? (
            <TextField
              fullWidth
              required
              type="text"
              label="Account Name"
              name="accountName"
              value={input.accountName}
              InputProps={{
                readOnly: true,
              }}
            />
          ) : (
            <FormControl fullWidth required>
              <InputLabel id="select-accounts">Account Name</InputLabel>
              <Select
                labelId="select-accounts"
                id="select-accounts-id"
                name="accountName"
                value={input.accountName}
                onChange={handleChanges}
                disabled={filteredCustomers.length === 0}
                inputprops={{
                  readOnly: editing,
                }}
                label="Account Name"
              >
                {filteredCustomers.length > 0 &&
                  filteredCustomers
                    .sort((a, b) => {
                      if (a.companyname < b.companyname) {
                        return -1; // a should come before b
                      }
                      if (a.companyname > b.companyname) {
                        return 1; // a should come after b
                      }
                      return 0;
                    })
                    .map((acc) => {
                      return (
                        <MenuItem key={acc._id} value={acc.companyname}>
                          {acc.companyname}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          )}
        </Grid>
        {editing && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="date"
              label="Date"
              name="date"
              value={input.date}
              onChange={handleChanges}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            label="Billing Address"
            value={input.billingAddress}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            label="Rep Agency"
            name="repAgency"
            value={input.repAgency}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            label="AIT Account Manager"
            name="accountManager"
            value={input.accountManager}
            onChange={handleChanges}
            InputProps={{
              readOnly: editing,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            multiline
            minRows={4}
            type="text"
            label="Special Program Details"
            name="specialReason"
            value={input.specialReason}
            onChange={handleChanges}
            InputProps={{
              readOnly: editing,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel id="select-frequency">Frequency</InputLabel>
            <Select
              labelId="select-frequency"
              id="select-frequency-id"
              name="frequency"
              value={input.frequency}
              onChange={handleChanges}
              label="Frequency"
            >
              <MenuItem value="one-time">One Time</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="quarterly">Quarterly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* Only visible to specific roles */}
        {editing && (
          <>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="select-status">Status</InputLabel>
                <Select
                  labelId="select-status"
                  id="select-status-id"
                  name="status"
                  value={input.status}
                  onChange={handleChanges}
                  label="Status"
                  inputProps={{ readOnly: user.rolelevel > 1 }}
                >
                  <MenuItem value="new">New</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="complete">Complete</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={4}
                type="text"
                label="Reference"
                name="reference"
                value={input.reference}
                onChange={handleChanges}
                inputProps={{ readOnly: user.rolelevel > 1 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="Next Issue Date"
                name="nextDate"
                value={input.nextDate}
                onChange={handleChanges}
                InputLabelProps={{ shrink: true }}
                inputProps={{ readOnly: user.rolelevel > 1 }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth disabled={user.username !== "KST-7971"}>
                <InputLabel id="select-approval-status">
                  Approval Status
                </InputLabel>
                <Select
                  labelId="select-approval-status"
                  id="select-approval-status-id"
                  name="approvalStatus"
                  value={input.approvalStatus}
                  onChange={handleChanges}
                  label="Approval Status"
                >
                  <MenuItem value="pending">Pending Aproval</MenuItem>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="denied">Denied</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
        {/* File upload */}
        {!editing && (
          <Grid item xs={12}>
            <FileUpload
              setFileError={setFileError}
              setFiles={setFiles}
              validateFileData={validateFileData}
              setLoading={setLoading}
              loading={loading}
            />
          </Grid>
        )}
        {!editing && (
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ py: 1 }}
              disabled={loading || fileError}
            >
              Submit Deal Sheet Form
            </Button>
          </Grid>
        )}
        {/* Editing for rolelevel 1 and 0 */}
        {editing && user.rolelevel <= 1 && (
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" sx={{ py: 1 }}>
              Edit and Submit Form
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default DealSheet;
