export const toteFilters = [
  { name: "toteid", label: "Tote ID" },
  { name: "tranref", label: "TranRef" },
  { name: "memo", label: "Memo" },
  { name: "subsidiary", label: "Subsidiary" },
  { name: "location", label: "Location" },
  { name: "createdby", label: "Created By" },
  { name: "lastupdatedby", label: "Last Updated By" },
  { name: "totalitemsqty", label: "QTY" },
  { name: "status", label: "Status" },
];

export const toteFiltersTypeMap = {
  toteid: "string",
  tranref: "string",
  memo: "string",
  subsidiary: "string",
  location: "string",
  createdby: "string",
  lastupdatedby: "string",
  status: "string",
  totalitemsqty: "number",
};

export const communicationFilters = [
  { name: "_id", label: "ID" },
  { name: "category", label: "Category" },
  { name: "customer", label: "Customer" },
  { name: "message", label: "Message" },
  { name: "date", label: "Date" },
];

export const communicationTypesMap = {
  _id: "string",
  category: "string",
  customer: "string",
  message: "string",
  date: "date",
};

//Replenishment Analysis
export const replenishmentAnalysisFilters = [
  { name: "Item Number", label: "Item Number" },
  { name: "Item Class", label: "Item Class" },
  { name: "Item Interchange", label: "Item Interchange" },
  { name: "brand", label: "Brand" },
  { name: "Qty", label: "Quantity" },
  { name: "From Location", label: "From Location" },
  { name: "To Location", label: "To Location" },
  { name: "action", label: "Action" },
  { name: "daysOfSupply", label: "Days Of Supply" },
];

export const replenishmentAnalysisTypeMap = {
  "Item Class": "string",
  "Item Number": "string",
  "Item Interchange": "string",
  brand: "string",
  Qty: "number",
  "From Location": "string",
  "To Location": "string",
  action: "string",
  daysOfSupply: "number",
};

//Understock Analysis
export const understockAnalysisFilters = [
  { name: "item", label: "Item Number" },
  { name: "itemClass", label: "Item Class" },
  { name: "itemInterchange", label: "Item Interchange" },
  { name: "brand", label: "Brand" },
  { name: "qty", label: "Quantity" },
  { name: "monthsOfSupply", label: "Months of Supply" },
  { name: "fromLocation", label: "From Location" },
  { name: "toLocation", label: "To Location" },
  { name: "action", label: "Action" },
  { name: "line", label: "Line" },
];

export const understockAnalysisTypeMap = {
  item: "string",
  itemClass: "string",
  itemInterchange: "string",
  brand: "string",
  qty: "number",
  monthsOfSupply: "number",
  fromLocation: "string",
  toLocation: "string",
  action: "string",
  line: "number",
};

//Overstock
export const overstockFilters = [
  { name: "itemNumber", label: "Item Number" },
  { name: "baseNumber", label: "Base Number" },
  { name: "overstock", label: "Overstock" },
  { name: "dcLocation", label: "DC Location" },
  { name: "needTrakMotiveCA", label: "Need TrakMotive CA" },
  { name: "needTrakMotiveNC", label: "Need TrakMotive NC" },
  { name: "needAutozone", label: "Need AutoZone" },
  { name: "needOreilly", label: "Need O'Reilly" },
  { name: "needNapa", label: "Need Napa" },
  { name: "totalUnderstock", label: "Total Understock" },
  { name: "netOverstock", label: "Net Overstock" },
];

export const overstockTypeMap = {
  itemNumber: "string",
  baseNumber: "string",
  overstock: "number",
  dcLocation: "string",
  needTrakMotiveCA: "number",
  needTrakMotiveNC: "number",
  needAutozone: "number",
  needOreilly: "number",
  needNapa: "number",
  totalUnderstock: "number",
  netOverstock: "number",
};

//Understock
export const understockFilters = [
  { name: "Item Number", label: "Item Number" },
  { name: "Item Class", label: "Item Class" },
  { name: "Brand", label: "Brand" },
  { name: "Understock", label: "Understock Quantity" },
  { name: "Location", label: "Location" },
  { name: "Available", label: "Available Quantity" },
  { name: "Days of Supply", label: "Days Of Supply" },
];

export const understockTypeMap = {
  "Item Class": "string",
  "Item Number": "string",
  Brand: "string",
  Understock: "number",
  Location: "string",
  Available: "string",
  "Days of Supply": "number",
};

//ALL ITEMS FILTERS
export const allItemsFilter = [
  { name: "Item Number", label: "Item Number" },
  { name: "Item Class", label: "Item Class" },
  { name: "Brand", label: "Brand" },
  { name: "Overstock", label: "Overstock Quantity" },
  { name: "Understock", label: "Understock Quantity" },
  { name: "Location", label: "Location" },
  { name: "Available", label: "Available Quantity" },
  { name: "Days of Supply", label: "Days Of Supply" },
];

export const allItemsTypeMap = {
  "Item Class": "string",
  "Item Number": "string",
  Brand: "string",
  Overstock: "number",
  Understock: "number",
  Location: "string",
  Available: "string",
  "Days of Supply": "number",
};
