import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  heading: {
    fontSize: "20px",
  },
  container: {
    border: "1px solid black",
    width: "100%",
    fontFamily: "Helvetica",
  },
  title: {
    backgroundColor: "#d9d9d9",
    fontFamily: "Helvetica-Bold",
    fontSize: "18px",
    marginBottom: "10px",
    padding: "4px",
  },
  columnsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    fontSize: "10px",
  },
  staticColumn: {
    flexDirection: "column",
    width: "15%",
  },
  dynamicColumn: {
    flexDirection: "column",
    width: "18%",
  },
  staticText: { marginBottom: "10px", height: "13px" },
  textHeight: { height: "17px", marginBottom: "12px" },
  dynamicText: {
    borderBottom: "1px solid black",
    marginBottom: "10px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    flexWrap: "nowrap",
    height: "13px", //setting height and disallowing wrapping triggers ellipses
  },
});

const LaborClaimWRMAPDF = ({ date, rga, productDetails, rma }) => {
  return (
    <Document>
      <Page
        size="A4"
        style={{
          fontSize: "14px",
          fontFamily: "Helvetica",
          padding: "16px 24px",
        }}
      >
        <View
          id="heading-section"
          style={{
            textAlign: "center",
            marginBottom: "10px",
            fontFamily: "Helvetica-Bold",
            fontSize: "20px",
          }}
        >
          <Text
            style={[
              styles.text,
              {
                fontWeight: "bold",
              },
            ]}
          >
            <Text
              style={
                ([styles.heading],
                { fontFamily: "Helvetica-Bold", fontSize: "40px" })
              }
            >
              A
            </Text>
            dvanced{" "}
            <Text
              style={
                ([styles.heading],
                { fontFamily: "Helvetica-Bold", fontSize: "40px" })
              }
            >
              I
            </Text>
            nnovative{" "}
            <Text
              style={
                ([styles.heading],
                { fontFamily: "Helvetica-Bold", fontSize: "40px" })
              }
            >
              T
            </Text>
            echnology <Text>Corp.</Text>
          </Text>

          <Text
            style={{
              paddingTop: "7px",
              textAlign: "center",
              fontSize: "12px",
              borderBottom: "3px solid red",
              paddingBottom: "15px",
              marginBottom: "15px",
              fontFamily: "Helvetica",
            }}
          ></Text>
          <Text style={{ borderBottom: "2px solid grey" }}></Text>
        </View>
        <View id="body">
          <Text
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontFamily: "Helvetica-Bold",
              marginBottom: "25px",
            }}
          >
            Customer Return Materials Authorization
          </Text>

          <View
            id="body-order-details"
            style={{
              fontSize: "12px",
              flexDirection: "row",
              justifyContent: "space-around",
              marginBottom: "20px",
            }}
          >
            <View>
              <Text style={{ fontFamily: "Helvetica-Bold", height: "18px" }}>
                Request received by{" "}
                <Text
                  style={{
                    fontFamily: "Helvetica",
                    textDecoration: "underline",
                  }}
                >
                  AIT
                </Text>
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                  height: "18px",
                }}
              >
                Date Received{" "}
                <Text
                  style={{
                    fontFamily: "Helvetica",
                    textDecoration: "underline",
                  }}
                >
                  {date}
                </Text>
              </Text>
            </View>

            {/* <View>
              <Text style={{ fontFamily: "Helvetica-Bold", height: "18px" }}>
                RGS #{" "}
                <Text
                  style={{
                    fontFamily: "Helvetica",
                    textDecoration: "underline",
                  }}
                >
                  rga num
                </Text>
              </Text>
            </View> */}
          </View>

          <View
            id="body-claim-detail"
            style={{
              border: "1px solid black",
              width: "100%",
              marginBottom: "25px",
              fontSize: "12px",
            }}
          >
            <View>
              <Text
                style={{
                  padding: "4px",
                  backgroundColor: "#4c4c4c",
                  color: "white",
                  fontFamily: "Helvetica-Bold",
                  fontSize: "18px",
                }}
              >
                Customer Details
              </Text>
            </View>

            <View id="Claim-Detail-Container" style={{ width: "100%" }}>
              <View
                id="row1"
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "50%",
                    flexDirection: "row",
                    borderBottom: "1px solid black",
                    borderRight: "1px solid black",
                    padding: "5px",
                    overflow: "hidden",
                  }}
                >
                  <Text style={{ paddingRight: "5px" }}>Company:</Text>
                  <Text
                    style={{
                      fontFamily: "Helvetica-Bold",
                      width: "80%",
                      height: "15px ",
                      overflow: "hidden",
                    }}
                  >
                    O'REILLY AUTO PARTS
                  </Text>
                </View>
                <View
                  style={{
                    width: "50%",
                    flexDirection: "row",
                    borderBottom: "1px solid black",
                    padding: "5px",
                    overflow: "hidden",
                  }}
                >
                  <Text style={{ paddingRight: "5px" }}>Contact:</Text>
                  <Text
                    style={{
                      width: "80%",
                      height: "15px ",
                      overflow: "hidden",
                    }}
                  >
                    MAIN OFFICE
                  </Text>
                </View>
              </View>

              <View
                id="row2"
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "50%",
                    flexDirection: "row",
                    borderBottom: "1px solid black",
                    borderRight: "1px solid black",
                    padding: "5px",
                    overflow: "hidden",
                  }}
                >
                  <Text style={{ paddingRight: "5px" }}>Address:</Text>
                  <Text
                    style={{
                      width: "80%",
                      height: "15px ",
                      overflow: "hidden",
                    }}
                  >
                    PO BOX 1897
                  </Text>
                </View>
                <View
                  style={{
                    width: "50%",
                    flexDirection: "row",
                    borderBottom: "1px solid black",
                    padding: "5px",
                  }}
                >
                  <Text>Phone:</Text>
                  <Text style={{ paddingLeft: "5px" }}>417-862-2675</Text>
                </View>
              </View>

              <View
                id="row3"
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "50%",
                    flexDirection: "row",
                    borderBottom: "1px solid black",
                    borderRight: "1px solid black",
                    padding: "5px",
                    overflow: "hidden",
                  }}
                >
                  <Text style={{ paddingRight: "5px" }}>City:</Text>
                  <Text
                    style={{
                      width: "80%",
                      height: "15px ",
                      overflow: "hidden",
                    }}
                  >
                    SPRINGFIELD
                  </Text>
                </View>
                <View
                  style={{
                    width: "50%",
                    flexDirection: "row",
                    borderBottom: "1px solid black",
                    padding: "5px",
                    overflow: "hidden",
                  }}
                >
                  <Text>Labor Claim#:</Text>
                  <Text
                    style={{
                      width: "80%",
                      height: "15px ",
                      overflow: "hidden",
                      paddingLeft: "5px",
                    }}
                  ></Text>
                </View>
              </View>

              <View
                id="row3"
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "50%",
                    flexDirection: "row",
                    borderRight: "1px solid black",
                    padding: "5px",
                    overflow: "hidden",
                  }}
                >
                  <View style={{ width: "50%", flexDirection: "row" }}>
                    <Text style={{ paddingRight: "5px" }}>State:</Text>
                    <Text>MO</Text>
                  </View>
                  <View style={{ width: "50%", flexDirection: "row" }}>
                    <Text style={{ paddingRight: "5px" }}>Zip:</Text>
                    <Text>65801</Text>
                  </View>
                </View>
                <View
                  style={{
                    width: "50%",
                    flexDirection: "row",
                    padding: "5px",
                    overflow: "hidden",
                  }}
                >
                  <Text style={{ paddingRight: "5px" }}>RGS#:</Text>
                  <Text
                    style={{
                      width: "80%",
                      height: "15px ",
                      overflow: "hidden",
                    }}
                  >
                    {rga.number}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            id="body-detail"
            style={{
              border: "1px solid black",
              width: "100%",
              marginBottom: "25px",
            }}
          >
            <Text
              style={{
                padding: "4px",
                backgroundColor: "#4c4c4c",
                color: "white",
                fontFamily: "Helvetica-Bold",
                fontSize: "18px",
              }}
            >
              Product Details
            </Text>

            <View id="product-details-table">
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: 1,
                  alignItems: "center",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                <Text
                  style={{
                    width: "40%",
                    borderRight: 1,
                    paddingLeft: 8,
                  }}
                >
                  Item
                </Text>
                <Text
                  style={{ width: "20%", borderRight: 1, textAlign: "center" }}
                >
                  Qty
                </Text>
                <Text style={{ width: "40%", paddingLeft: 8 }}>
                  Reason for Return
                </Text>
              </View>

              {productDetails.products?.length &&
                productDetails.products?.map((product) => {
                  return (
                    // row
                    <View
                      key={product.partnumber}
                      style={{
                        flexDirection: "row",
                        borderBottom: 1,
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Text style={{ width: "40%", borderRight: 1 }}>
                        {product.partnumber}
                      </Text>
                      <Text style={{ width: "20%", borderRight: 1 }}>
                        {product.qty}
                      </Text>
                      <Text style={{ width: "40%" }}>LABOR CLAIM</Text>
                    </View>
                  );
                })}

              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 10,
                  paddingLeft: 5,
                }}
              >
                <Text style={{ fontFamily: "Helvetica-Bold", paddingRight: 5 }}>
                  Total Request:
                </Text>
                <Text>{productDetails.total}</Text>
              </View>
            </View>
          </View>

          <View id="internal-use-only" style={styles.container}>
            <View style={styles.title}>
              <Text>For Internal Use Only</Text>
            </View>

            <View style={styles.columnsContainer}>
              {/* First column */}
              <View
                style={[
                  styles.staticColumn,
                  { width: "10%", paddingLeft: "4px" },
                ]}
              >
                <Text style={styles.staticText}>RMA#</Text>
                <Text style={styles.staticText}>Issued By</Text>
                <Text style={styles.staticText}>Issued On</Text>
              </View>

              {/* Second column */}
              <View style={styles.dynamicColumn}>
                <Text style={styles.dynamicText}>{rma.number}</Text>
                <Text style={styles.dynamicText}>{rma.issuedBy}</Text>
                <Text style={styles.dynamicText}>{rma.issuedOn}</Text>
              </View>

              {/* Third column */}
              <View style={styles.staticColumn}>
                <Text style={styles.staticText}>Return Rec'd On</Text>
                <Text style={styles.staticText}>Return Rec'd By</Text>
                <Text style={styles.staticText}>Response</Text>
              </View>

              {/* Fourth column */}
              <View style={styles.dynamicColumn}>
                <Text style={styles.dynamicText}></Text>
                <Text style={styles.dynamicText}></Text>
                <Text style={styles.dynamicText}></Text>
              </View>
              {/* Fifth column */}
              <View style={styles.staticColumn}>
                <Text style={styles.staticText}>Credit Amount</Text>
                <Text style={styles.staticText}>Credit Issued By</Text>
                <Text style={styles.staticText}>Credit Issued On</Text>
                <Text style={styles.staticText}>Credit Memo</Text>
              </View>

              {/* Sixth column */}
              <View style={styles.dynamicColumn}>
                <Text style={styles.dynamicText}></Text>
                <Text style={styles.dynamicText}></Text>
                <Text style={styles.dynamicText}></Text>
                <Text style={styles.dynamicText}></Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default LaborClaimWRMAPDF;
