import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import ItemBinDataScanInventoryMove from "./ItemBinDataScanInventoryMove";

const ItemBinDataView = ({
  itemBinData,
  transferFn,
  setMongoData,
  mongoData,
  binMoveError,
  setBinMoveError,
  filteredBins,
  setFilteredBins,
}) => {
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <>
      <FlexWrapper
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="space-between"
        height="93%"
      >
        <ItemBinDataScanInventoryMove
          itemBinData={itemBinData}
          transferFn={transferFn}
          setMongoData={setMongoData}
          mongoData={mongoData}
          binMoveError={binMoveError}
          setBinMoveError={setBinMoveError}
          setFilteredBins={setFilteredBins}
          filteredBins={filteredBins}
        />
        <TableContainer component={Paper} sx={{ height: "50vh" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Available</TableCell>
                <TableCell align="center">Bin Number</TableCell>
                {!matches && (
                  <>
                    <TableCell align="center">Bin Size Type</TableCell>
                    <TableCell align="center">Bin Brand</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBins
                ? filteredBins.map((row, index) => (
                    <TableRow key={index + row.binid}>
                      <TableCell align="center">{row.available}</TableCell>
                      <TableCell align="center">{row.binnumber}</TableCell>
                      {!matches && (
                        <>
                          <TableCell align="center">
                            {row.binboxsizetype}
                          </TableCell>
                          <TableCell align="center">{row.binbrand}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))
                : itemBinData?.map((row, index) => (
                    <TableRow key={index + row.binid}>
                      <TableCell align="center">{row.available}</TableCell>
                      <TableCell align="center">{row.binnumber}</TableCell>
                      {!matches && (
                        <>
                          <TableCell align="center">
                            {row.binboxsizetype}
                          </TableCell>
                          <TableCell align="center">{row.binbrand}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FlexWrapper>
    </>
  );
};
export default ItemBinDataView;
