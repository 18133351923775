import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";

const GeneralFullScreenModalV2 = ({
  customOpenVal = null,
  children,
  onCloseFn = null,
}) => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (customOpenVal) {
      setOpenModal(true);
    }

    if (customOpenVal === false && openModal) {
      handleClose();
    }
  }, [customOpenVal]);

  const handleClose = () => {
    setOpenModal(false);

    if (onCloseFn && typeof onCloseFn === "function") {
      onCloseFn();
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      fullScreen
      keepMounted={false}
    >
      {/* pass in handleClose if needed */}
      {typeof children === "function" ? children(handleClose) : children}
    </Dialog>
  );
};
export default GeneralFullScreenModalV2;
