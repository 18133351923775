import { useState, useRef } from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { printIndividualItemLabel } from "../../utils/printer-functions/printLabelFunctions";

import axios from "../../axios/axios.config";
import GeneralAlert from "../../components/GeneralAlert";

const PaddingTextField = styled(TextField)(({ theme }) => ({
  "& input": {
    paddingY: 12,
  },
}));

const PrintItemLabel = () => {
  const [input, setInput] = useState({ item: "", qty: "1" });
  const [error, setError] = useState({ item: "", qty: "" });
  const [crossRefItem, setCrossRefItem] = useState(null);
  const dispatch = useDispatch();
  const { currentPrinter } = useSelector((state) => state.printers);
  const { currentSubsidiary } = useSelector((state) => state.user);
  const itemInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({ item: "", qty: "" });

    if (input.item === "") {
      setError({ ...error, item: "Item Field Cannot Be Blank" });
      return;
    }

    if (
      !parseInt(input.qty) ||
      input.qty.includes(".") ||
      parseInt(input.qty) < 0
    ) {
      setError({ ...error, qty: "Invalid QTY" });
      return;
    }

    try {
      let item = null;
      const itemResponse = await axios.get(`items/${input.item}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          subsidiary: currentSubsidiary,
        },
      });

      item = itemResponse.data.length ? itemResponse.data[0] : null;
      let crossReferencedItem = null;

      if (!item) {
        //check item crossref
        const { data } = await axios.get(
          `item-cross-refs/item/${input.item}/${currentSubsidiary}`
        );

        if (!data.length) {
          setError({ ...error, item: "Item Does Not Exist" });
          return;
        }
        //array is length 1 or more
        if (data.length === 1) {
          setCrossRefItem(data[0]);
          crossReferencedItem = data[0];
        } else {
          const firstItem = data[0].item;
          for (let i = 1; i < data.length; i++) {
            if (data[i].item !== firstItem) {
              setError({
                ...error,
                item: `More than one item found for: ${
                  input.item
                }\nThe following part numbers returned a match: ${data
                  .map((item) => item.item)
                  .join(",")}`,
              });
              return;
            }
          }
          // If all items are the same, return the first object in the array
          setCrossRefItem(data[0]);
          crossReferencedItem = data[0];
        }

        //if cross ref found, check against item collection again and pass in item
        const secondItemResponse = await axios.get(`items/${data[0].item}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            subsidiary: currentSubsidiary,
          },
        });

        if (
          !secondItemResponse.data.length ||
          secondItemResponse.data[0].subsidiary !== currentSubsidiary
        ) {
          setError({ ...error, item: "Item Does Not Exist" });
          return;
        }

        item = secondItemResponse.data[0];
      }

      printIndividualItemLabel(
        item,
        input.qty,
        currentPrinter.printnodeid,
        crossReferencedItem
      );

      itemInputRef.current.focus();
      itemInputRef.current.inputMode = "none";

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Printing Item: ${item.item}`,
          duration: 3000,
        })
      );
    } catch (error) {
      setError({ item: "Server Error", qty: "Server Error" });
    } finally {
      setInput({ item: "", qty: "1" });
    }
  };

  const handleChanges = (e) => {
    setError({ item: "", qty: "" });
    setInput({ ...input, [e.target.name]: e.target.value.toUpperCase() });
  };

  return (
    <Container sx={{ pt: 3, maxWidth: { xs: "xl", md: "md" } }}>
      <Typography gutterBottom variant="h4" sx={{ textAlign: "center", pb: 2 }}>
        Print Plain Item Label
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <PaddingTextField
          autoComplete="off"
          inputProps={{ autoComplete: "off" }}
          error={Boolean(error.item)}
          autoFocus
          sx={{ whiteSpace: "pre-line" }}
          helperText={error.item}
          id="item-input"
          name="item"
          fullWidth
          required
          type="text"
          label="Item"
          value={input.item}
          onChange={handleChanges}
          onClick={() => (itemInputRef.current.inputMode = "text")}
          inputRef={itemInputRef}
        />
        <PaddingTextField
          error={Boolean(error.qty)}
          helperText={error.qty}
          id="item-input"
          name="qty"
          fullWidth
          required
          label="QTY"
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            type: "number",
            step: 1,
            min: 1,
            max: 100,
          }}
          value={input.qty}
          onChange={handleChanges}
        />
        <PaddingTextField
          id="printer"
          fullWidth
          disabled
          label="printer"
          value={currentPrinter.printername}
        />
        <Button
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          size="large"
        >
          Print Label
        </Button>
      </Box>
    </Container>
  );
};

export default PrintItemLabel;
