export const exports = [
  {
    name: "INFO",
    headers: [
      { label: "Tote Label", key: "toteid" },
      { label: "Item", key: "item" },
      { label: "Class", key: "class" },
      { label: "QTY", key: "qty" },
      { label: "Box Size", key: "boxsize" },
      { label: "Interchange", key: "interchange" },
      { label: "Tranref", key: "tranref" },
      { label: "Container", key: "container" },
      { label: "Memo", key: "memo" },
      { label: "Pallet Number", key: "palletnumber" },
      { label: "Pallet Weight", key: "palletweight" },
      { label: "Pallet Dimensions", key: "palletdimensions" },
      { label: "Subsidiary", key: "subsidiary" },
      { label: "Location", key: "location" },
      { label: "Created By", key: "createdby" },
      { label: "Created At", key: "createdat" },
      { label: "Last Updated By", key: "lastupdatedby" },
      { label: "Updated At", key: "updatedat" },
    ],
  },
  {
    name: "IADJ",
    headers: [
      { label: "EXTERNAL ID", key: "externalid" },
      { label: "DATE", key: "date" },
      { label: "ADJUSTMENT ACCOUNT", key: "adjustmentaccount" },
      { label: "LOCATION ", key: "location" },
      { label: "MEMO", key: "memo" },
      {
        label: "Inventory Adjustment Adjustments : Location",
        key: "inventoryadjustmentlocation",
      },
      { label: "ITEM", key: "item" },
      { label: "ITEM : ADJUST QTY. BY", key: "qty" },
      { label: "ITEM : BIN NUMBERS", key: "binnumbers" },
    ],
  },
  {
    name: "TO",
    headers: [
      { label: "EXTERNAL ID", key: "externalid" },
      { label: "DATE", key: "date" },
      { label: "FROM LOCATION", key: "fromlocation" },
      { label: "TO LOCATION", key: "tolocation" },
      { label: "ITEM", key: "item" },
      { label: "QTY", key: "qty" },
      { label: "INTERCHANGE", key: "interchange" },
      { label: "INCOTERM", key: "incoterm" },
      { label: "STATUS", key: "satus" },
      { label: "MEMO", key: "memo" },
      { label: "PRIORITY STATUS", key: "prioritystatus" },
      { label: "NOTES", key: "notes" },
      {
        label: "BRAND TRANSACTION PACKAGING",
        key: "trandtransactionpackaging",
      },
    ],
  },
  {
    name: "RMA",
    headers: [
      { label: "EXTERNAL ID", key: "externalid" },
      { label: "DATE", key: "date" },
      { label: "Customer", key: "customer" },
      { label: "LOCATION", key: "location" },
      { label: "STATUS", key: "status" },
      { label: "PO #", key: "ponum" },
      { label: "RMA TYPE", key: "rmatype" },
      { label: "RMA SPECIAL NOTES", key: "rmanotes" },
      { label: "ITEM", key: "item" },
      { label: "QTY", key: "qty" },
      { label: "PRICE LEVEL", key: "pricelevel" },
      { label: "UNIT PRICE", key: "unitprice" },
      { label: "DISCOUNT", key: "discount" },
    ],
  },
  {
    name: "INVTRAN",
    headers: [
      { label: "EXTERNAL ID", key: "externalid" },
      { label: "DATE", key: "date" },
      { label: "FROM LOCATION", key: "fromlocation" },
      { label: "TO LOCATION", key: "tolocation" },
      { label: "ITEM", key: "item" },
      { label: "QTY. TO TRANSFER", key: "qty" },
      { label: "FROM BINS", key: "frombins" },
      { label: "TO BINS", key: "tobins" },
      { label: "ITEM : BIN NUMBERS", key: "binnumbers" },
    ],
  },
];
