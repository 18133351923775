import { useState } from "react";
import { Outlet, useLoaderData } from "react-router-dom";

const PackProcessViewOrdersLayout = () => {
  const data = useLoaderData();
  const [orders, setOrders] = useState(data);

  return <Outlet context={{ orders, setOrders }} />;
};

export default PackProcessViewOrdersLayout;
