import {
  Assignment,
  FactCheck,
  Info,
  RemoveCircle,
  Warning,
} from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import PackProcessViewPalletItems from "../../../components/multi-page/pack-process-view/PackProcessViewPalletItems";

const PackProcessViewSingleOrderPage = () => {
  const data = useLoaderData();

  const [bottomNavigation, setBottomNavigation] = useState("scanned");

  console.log(data);
  if (!data)
    return (
      <Typography variant="h4" textAlign="center">
        No Data To Display
      </Typography>
    );

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" textAlign="center">
        {data.createdfrom} - {data.status?.toUpperCase()}
        <span>
          <IconButton>
            <Info color="primary" />
          </IconButton>
        </span>
      </Typography>

      <PackProcessViewPalletItems
        palletData={data.packdetails}
        palletNumbers={Object.keys(data.packdetails)}
      />

      <Paper
        elevation={3}
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      >
        <BottomNavigation
          sx={{ width: "100%" }}
          showLabels
          value={bottomNavigation}
          onChange={(event) => setBottomNavigation(event.target.value)}
        >
          <BottomNavigationAction
            label="Scanned"
            value="scanned"
            icon={<FactCheck />}
          />
          <BottomNavigationAction
            label="Items"
            value="items"
            icon={<Assignment />}
          />

          <BottomNavigationAction
            label="Errors"
            value="errors"
            icon={<Warning />}
          />
        </BottomNavigation>
      </Paper>
    </Container>
  );
};
export default PackProcessViewSingleOrderPage;
