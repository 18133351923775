import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { useSalesRepCustomerMatch } from "../../../hooks/useSalesRepCustomerMatch";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import axios from "../../../axios/axios.config";
import FileUpload from "../../../components/sales/FileUpload";

const LabelsAndBoxRequestCustomerServiceFormAdd = () => {
  const [fileTemplateURL, setFileTemplateURL] = useState(null);
  const [files, setFiles] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState([]);
  const [input, setInput] = useState({
    date: new Date().toISOString().slice(0, 10),
    customer: "",
    customerEmail: "",
    address: "",
    shippingMethod: "Ground",
    neededBy: "",
    notes: "",
    boxes: false,
    reasonForLabels: "",
    file: null,
    status: "pending",
    carrier: "",
    tracking: "",
    totalBoxes: "",
    totalLabels: "",
    liftGateRequired: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const { filteredCustomers } = useSalesRepCustomerMatch();

  //gets example template URL from s3
  useEffect(() => {
    getTemplateURL();
  }, []);

  const getTemplateURL = async () => {
    try {
      const templateResponse = await axios.post("files/signedURL", {
        Key: "box-requests/template/item-labels-request-template.csv",
      });
      setFileTemplateURL(templateResponse.data);
    } catch (error) {
      setFileTemplateURL(null);
    }
  };

  const handleChanges = (event) => {
    if (event.target.name === "boxes") {
      setInput({ ...input, boxes: !input.boxes });
      return;
    }
    if (event.target.name === "liftGateRequired") {
      setInput({ ...input, liftGateRequired: !input.liftGateRequired });
      return;
    }
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //Disallow form submisison if there is no file present
      if (!files || !files[0] || fileError || loading) {
        throw new Error("File Error: File is missing or invalid");
      }

      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("folder", "box-requests/");
      const response = await axios.post("/files/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      //set total labels and total boxes
      let totalLabels = 0;
      let totalBoxQty = 0;

      for (const el of analytics) {
        if (el.boxSize && el.boxSize !== "NOBOX") {
          totalBoxQty += el.qty;
        }
        totalLabels += el.qty;
      }

      const formResponse = await axios.post("/forms/labels-box-requests", {
        newForm: {
          version: {
            ...input,
            file: response.data.file,
            analytics,
            totalLabels,
            totalBoxes: input.boxes ? totalBoxQty : 0,
            customerEmail: input.customerEmail.trim().split(","),
          },
          createdby: {
            username: user.username,
            email: user.email,
            department: user.currentDepartment,
            usertype: user.usertype,
          },
          subsidiary: user.currentSubsidiary,
        },
      });

      const form = formResponse.data;

      //Sends email with attatchment
      sendEmail(
        form._id,
        form.versions[0].totalBoxes,
        form.versions[0].totalLabels
      );

      navigate("/portal/customer-service/forms/labels-box-requests");

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully added new record",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `${error.message}`,
          duration: 5000,
        })
      );
    }
  };
  //send email with attacthments
  const sendEmail = async (_id, totalBoxes, totalLabels) => {
    const dynamicTemplateData = {
      ...input,
      _id,
      username: user.username,
      totalBoxes,
      totalLabels,
    };
    const email = {
      dynamicTemplateData,
      templateId: "d-3d2bb484924446bb93fbb11b3cfdf7c5",
      to: process.env.REACT_APP_TEST_EMAIL || "packaging@trakmotive.com",
    };
    const formData = new FormData(); //files sent to server must use formData object

    files.forEach((file) => formData.append("files", file));
    formData.append("email", JSON.stringify(email));

    try {
      await axios.post("/email/attatchments", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${
            error.response.data ? error.response.data.msg : error.message
          }`,
          duration: 5000,
        })
      );
    }
  };
  //Validates to ensure submitted file follows the required format
  async function validateFileData(fileData, file) {
    //Check File Type
    if (
      file.type !== "application/vnd.ms-excel" &&
      file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      file.type !== "text/csv"
    ) {
      throw new Error("File Error: Unsupported File Type");
    }

    const headers = fileData[0];
    const itemIndex = headers.indexOf("ITEM");
    const qtyIndex = headers.indexOf("QTY");
    const compIndex = headers.indexOf("COMPETITOR");
    const quantities = fileData.slice(1).map((dataArr) => dataArr[qtyIndex]);
    const items = fileData.slice(1).map((dataArr) => dataArr[itemIndex]);
    const competitors = fileData.slice(1).map((dataArr) => dataArr[compIndex]);
    const itemAnalytics = [];

    //Check Headers
    if (itemIndex === -1 || qtyIndex === -1) {
      throw new Error("Invalid Format: ITEM or QTY headers missing");
    }

    //Check File Data : Quantity and Part Numbers
    for (let el of quantities) {
      if (!parseInt(el)) {
        throw new Error(`Invalid Data: ${el} is not a valid quantity`);
      }
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const response = await axios.get(`items/${item}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { subsidiary: user.currentSubsidiary },
      });

      const itemArray = await response.data;
      if (itemArray.length === 0) {
        throw new Error(`Invalid Data: ${item} is not a valid part number`);
      }
      const analyticsObj = {
        item,
        qty: parseInt(quantities[i]),
        boxSize: itemArray[0].boxsize,
        competitor: competitors[i] ? competitors[i] : "",
      };
      itemAnalytics.push(analyticsObj);
    }

    setAnalytics(itemAnalytics);

    //We return the original file so original file name can display and not cause confusion
    return file;
  }

  return (
    <Container maxWidth="lg">
      <Tooltip title="Close Form">
        <IconButton
          sx={{ pb: 2 }}
          onClick={() =>
            navigate("/portal/customer-service/forms/labels-box-requests")
          }
        >
          <Close color="error" />
        </IconButton>
      </Tooltip>

      <Container maxWidth="md" sx={{ my: 2 }}>
        <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              type="date"
              label="Date"
              name="date"
              value={input.date}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {filteredCustomers && (
              <FormControl fullWidth required>
                <InputLabel id="select-customers">Customer</InputLabel>
                <Select
                  labelId="select-customers"
                  id="select-customers-id"
                  name="customer"
                  value={input.customer}
                  onChange={handleChanges}
                  disabled={filteredCustomers.length === 0}
                  label="Customer"
                >
                  {filteredCustomers.length > 0 &&
                    filteredCustomers.map((c) => {
                      return (
                        <MenuItem key={c._id} value={c.companyname}>
                          {c.companyname}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              label="Customer Email"
              name="customerEmail"
              value={input.customerEmail}
              onChange={(event) =>
                setInput({
                  ...input,
                  [event.target.name]: event.target.value.trim(),
                })
              }
              helperText={
                <Typography variant="caption" color="primary">
                  Please enter emails seperated by a comma (ex.
                  test1@email.com,test2@email.com)
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              multiline
              minRows={4}
              type="text"
              label="Shipping Address"
              name="address"
              value={input.address}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              label="Shipping Method"
              name="shippingMethod"
              value={input.shippingMethod}
              onChange={handleChanges}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Tooltip
                    placement="top"
                    title={
                      <Typography variant="body1">
                        For larger orders it may require shipping the order via
                        Freight. If so, does this Shipping Address require a
                        liftgate?
                      </Typography>
                    }
                  >
                    <Checkbox
                      name="liftGateRequired"
                      checked={input.liftGateRequired}
                      onChange={handleChanges}
                    />
                  </Tooltip>
                }
                label="Lift Gate Required"
              />
              <FormHelperText error>
                {input.liftGateRequired
                  ? "An additional fee will be charged for liftgate delivery"
                  : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="date"
              label="Needed By"
              name="neededBy"
              value={input.neededBy}
              onChange={handleChanges}
              InputLabelProps={{ shrink: true }}
            />

            <FormHelperText error sx={{ typography: "body1" }}>
              All requests have a minimum processing time of 3 days*. Requests
              over 100 pieces will require additional processing time, please
              reach out to Customer Service{" "}
              <span>
                <Link href="mailto:customerservice@trakmotive.com">
                  (customerservice@trakmotive.com){" "}
                </Link>
              </span>
              for current processing times
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="select-reasons-for-labels">
                Reason For Labels Needed
              </InputLabel>
              <Select
                labelId="select-reasons-for-labels"
                id="select-reasos-for-labels-id"
                name="reasonForLabels"
                value={input.reasonForLabels}
                onChange={handleChanges}
                label="Reason For Labels Needed"
              >
                <MenuItem value="changeover">Changeover</MenuItem>
                <MenuItem value="replacements">Replacements</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Tooltip
                    placement="top"
                    title={
                      <Typography variant="body1">
                        Check this box if you need physical boxes included with
                        each label. The corresponding box size for each label
                        will be included. Ex. 100 labels will include 100 boxes.
                        If only a few boxes are needed, please include in the
                        additional notes section below instead.
                      </Typography>
                    }
                  >
                    <Checkbox
                      name="boxes"
                      checked={input.boxes}
                      onChange={handleChanges}
                    />
                  </Tooltip>
                }
                label="Boxes"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              minRows={4}
              type="text"
              label="Additional Notes"
              name="notes"
              value={input.notes}
              onChange={handleChanges}
            />
          </Grid>

          <Grid item xs={12}>
            {fileTemplateURL && (
              <>
                <Link href={fileTemplateURL}>
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{ pb: 1, textAlign: "center" }}
                  >
                    File should be in CSV or Excel format and should include the
                    following headers: ITEM,QTY,COMPETITOR (optional). Click
                    here to download a sample template.
                  </Typography>
                </Link>

                <Typography variant="body1" color="error" textAlign="center">
                  Make sure your file is in the sorting in which you want the
                  labels printed. Labels will print in the order your file was
                  submitted.
                </Typography>

                <FileUpload
                  setFiles={setFiles}
                  setFileError={setFileError}
                  validateFileData={validateFileData}
                  setLoading={setLoading}
                  loading={loading}
                />
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              disabled={Boolean(fileError)}
              type="submit"
              variant="contained"
              sx={{ py: 1 }}
            >
              Create Form
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default LabelsAndBoxRequestCustomerServiceFormAdd;
