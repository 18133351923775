import axios from "../../../axios/axios.config";

export const updatePickLockedBy = async ({
  internalid,
  username,
  trantype,
}) => {
  try {
    // Make the initial Axios patch request
    await axios.patch(`netsuite/update/pick-order/${internalid}/pickedby`, {
      username,
      trantype,
    });
  } catch (error) {
    console.error("Error updating netsuite pickedby field:", error);

    // Retry the request only once after a delay of 60 seconds
    await new Promise((resolve) => setTimeout(resolve, 60000));

    // Retry the request only once
    try {
      await axios.patch(`netsuite/update/pick-order/${internalid}/pickedby`, {
        username,
      });

      // Request succeeded after retry
    } catch (retryError) {
      // Log the retry error
      console.error(
        "Error updating netsuite pickedby field(retry):",
        retryError
      );
    }
  }
};

export const updatePickLockedByMongo = async ({ id, value }) => {
  try {
    const updatedResponse = await axios.patch(
      `pick-order/update/order/${id}/picklockedby`,
      {
        picklockedby: value,
      }
    );

    return updatedResponse;
  } catch (error) {
    console.log("could Not update pick locked by field: ", error.message);
  }
};
