import { Box, Button, Divider, Typography } from "@mui/material";
import GeneralModalV3 from "../../general/GeneralModalV3";
import FlexWrapper from "../../FlexWrapper";

const QtyErrorModal = ({ openVal, setOpenQtyErrorModal }) => {
  return (
    <GeneralModalV3
      keepMounted={false}
      openComponent={null}
      openVal={Boolean(openVal)}
      onClose={() => setOpenQtyErrorModal("")}
    >
      {(handleClose) => (
        <Box>
          <Typography variant="h4" textAlign="center" py={0.5} color="error">
            Quantity Error
          </Typography>
          <Divider />
          <Typography p={2} variant="h6">
            {openVal}.
            <br /> Please ensure you counted items correctly and try again. If
            problem persists, please contact your supervisor.
          </Typography>
          <Divider />
          <FlexWrapper justifyContent="flex-end" p={1}>
            <Button
              variant="contained"
              onClick={() => {
                setOpenQtyErrorModal("");
                handleClose();
              }}
            >
              OK
            </Button>
          </FlexWrapper>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default QtyErrorModal;
