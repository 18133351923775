import { isAuthenticated } from "../../utils/auth/verifyAuth";
import { store } from "../../redux/store";
import { redirect } from "react-router-dom";
import { getPrinterObjectFromUser } from "../../redux/features/printers/printerSlice";
import {
  getUserFromStorage,
  setCurrentDepartment,
} from "../../redux/features/user/userSlice";

export const portalLoader = async ({ request }) => {
  const url = new URL(request.url);
  const searchParams = url.search;
  const path = url.pathname + searchParams;
  const pathArray = url.pathname.split("/");
  const department =
    pathArray.length >= 4 ? url.pathname.split("/")[2].toUpperCase() : null;

  try {
    const res = await isAuthenticated(); //checks token validity
    const { currentPrinter } = store.getState().printers;
    const reduxUser = store.getState().user;
    //all data is present proceed to page
    if (
      res.data === "Token Validated" &&
      reduxUser?.currentDepartment &&
      reduxUser?.currentLocation &&
      reduxUser?.currentSubsidiary &&
      currentPrinter
    ) {
      return null;
    }

    //not all data is present
    if (res.data === "Token Validated" && localStorage.getItem("user")) {
      store.dispatch(getUserFromStorage());
      //retrieve user from local storage and save to redux
      const user = JSON.parse(localStorage.getItem("user"));

      //retrive printer if user has a printer set
      if (user.printer) {
        store.dispatch(getPrinterObjectFromUser(user.printer));
      }

      //authenticated and user exists so either go to main menu or session select or if url present return to url
      if (
        !user.currentDepartment ||
        !user.currentLocation ||
        !user.currentSubsidiary
      ) {
        //update dept. if user has access else throw forbidden error
        if (department) {
          if (department !== localStorage.getItem("department")) {
            if (!user.department.includes(department)) {
              throw new Response("", {
                status: 403,
                statusText: "You do not have access to this page.",
              });
            } else {
              store.dispatch(setCurrentDepartment(department));
            }
          }
        }

        return null;
      } else {
        return redirect("/session");
      }
    } else {
      localStorage.clear();
      throw new Response("Not Logged In", {
        status: 401,
        statusText: "Please Log In",
      });
    }
  } catch (error) {
    if (error.response?.status === 401 || error.status === 401) {
      return redirect(
        path.length >= 4
          ? `/?redirect-path=${encodeURIComponent(
              path
            )}&department=${department}`
          : "/"
      );
    }

    if (error.status && error.statusText) {
      throw new Response("Error", {
        status: error.status,
        statusText: error.statusText,
      });
    }
    //default error
    throw new Response("Error", {
      status: error.status || 500,
      statusText: error.message,
    });
  }
};
