import axios from "../../axios/axios.config";

export const warrantyClaimViewLoader = async ({ params }) => {
  try {
    const { formid } = params;

    const formResponse = await axios.get(`forms/labor-claim/${formid}`);
    const fileURLResponse = await axios.post("files/multi/signedURL", {
      options: { expiresIn: 3600 },
      fileKeys: [
        `forms/warrantyclaim/${formid}/${formResponse.data.form.file}`,
      ],
    });

    return { data: formResponse.data, fileURL: fileURLResponse.data };
  } catch (error) {
    throw new Response("", {
      status: error.response?.status || 500,
      statusText: error.response?.data?.msg || error.message,
    });
  }
};

export const warrantyClaimAddLoader = async () => {
  const fileURLResponse = await axios.post("files/multi/signedURL", {
    options: { expiresIn: 3600 },
    fileKeys: ["forms/warrantyclaim/template/Warranty Claim Template.xlsx"],
  });

  return fileURLResponse.data;
};
