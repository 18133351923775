import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: "12px",
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    marginBottom: 10,
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    padding: 1,
    fontSize: "12px",
  },
});

const LabelsAndBoxRequestsItemBreakdownV2 = ({
  data,
  totalQty,
  date,
  customer,
  address,
  notes,
  id,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View>
            <Text style={{ textAlign: "right", fontSize: "12px" }}>{id}</Text>
          </View>

          <View
            style={{
              paddingVertical: "5px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                textDecoration: "underline",
                paddingRight: "10px",
                fontSize: "18px",
              }}
            >
              Date:
            </Text>
            <Text style={{ fontSize: "14px" }}>{date}</Text>
          </View>

          <View
            style={{
              paddingVertical: "5px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                textDecoration: "underline",
                fontSize: "18px",
                paddingRight: "10px",
              }}
            >
              Customer:
            </Text>
            <Text style={{ fontSize: "14px" }}>{customer}</Text>
          </View>

          <View
            style={{
              paddingVertical: "5px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                textDecoration: "underline",
                fontSize: "18px",
                paddingRight: "10px",
              }}
            >
              Shipping Address:
            </Text>
            <Text style={{ fontSize: "14px" }}>{address}</Text>
          </View>

          <View
            style={{
              paddingVertical: "5px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                textDecoration: "underline",
                fontSize: "18px",
                paddingRight: "10px",
              }}
            >
              Additional Notes:
            </Text>
            <Text style={{ fontSize: "14px" }}>{notes}</Text>
          </View>
        </View>

        <View>
          <Text
            style={{
              fontSize: "24px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Item Breakdown
          </Text>
        </View>

        <View style={styles.table}>
          {/* Headers */}
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={{ textAlign: "center" }}>Item</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={{ textAlign: "center" }}>Box Size</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={{ textAlign: "center" }}>Quantity</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={{ textAlign: "center" }}>Competitor</Text>
            </View>
          </View>

          {/* Table Data */}
          {data.map((item) => {
            return (
              <View key={item.item} style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text style={{ textAlign: "center" }}>{item.item}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={{ textAlign: "center" }}>{item.boxSize}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={{ textAlign: "center" }}>{item.qty}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={{ textAlign: "center" }}>{item.competitor}</Text>
                </View>
              </View>
            );
          })}
        </View>

        <View>
          <Text>Total: {totalQty}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default LabelsAndBoxRequestsItemBreakdownV2;
