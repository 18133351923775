import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEc2InstanceId } from "../../redux/features/ec2/ec2Slice";

export const useEC2 = () => {
  const dispatch = useDispatch();
  const { instanceId } = useSelector((state) => state.ec2);

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV !== "LOCAL") {
      dispatch(getEc2InstanceId());
    }
  }, []);

  return { instanceId };
};
