import { Button, Container, Typography } from "@mui/material";
import { createShort } from "../../../redux/features/pick-order/pickOrderSlice";

import FlexWrapper from "../../FlexWrapper";
import GeneralFullScreenModalV2 from "../../general/GeneralFullScreenModalV2";

const PickOrderEmptyBinValidationFullScreenModal = ({
  openVal,
  bin,
  setOpenVal,
  shortData,
  item,
  dispatch,
  _id,
  handlePickItem,
}) => {
  const handleAction = async (handleClose, type) => {
    //update short type
    shortData.shorttype = `EMPTY-${type}`;
    shortData.status = type === "OK" ? "complete" : "new";
    //close modal
    handleClose();
    //log short
    await dispatch(createShort({ shortData, orderid: _id })).unwrap();

    //call pick function
    handlePickItem();
  };

  return (
    <GeneralFullScreenModalV2
      customOpenVal={openVal}
      onCloseFn={() => {
        setOpenVal(false);
      }}
    >
      {(handleClose) => (
        <Container maxWidth="sm" sx={{ py: 1 }}>
          <Typography variant="h4" textAlign="center" gutterBottom py={2}>
            Empty Bin Validation
          </Typography>
          <Typography variant="h6" gutterBottom pb={3}>
            Bin <strong>{bin}</strong> should not have any quantity remaining
            of: <strong style={{ whiteSpace: "nowrap" }}>{item}</strong>
          </Typography>

          <Typography
            gutterBottom
            py={2}
            fontWeight="bold"
            fontSize="18px"
            pt={3}
          >
            Is Bin Empty?
          </Typography>
          <FlexWrapper gap={4}>
            <Button
              variant="contained"
              size="large"
              onClick={() => handleAction(handleClose, "OK")}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              size="large"
              color="error"
              onClick={() => handleAction(handleClose, "ISSUE")}
            >
              No
            </Button>
          </FlexWrapper>
        </Container>
      )}
    </GeneralFullScreenModalV2>
  );
};
export default PickOrderEmptyBinValidationFullScreenModal;
