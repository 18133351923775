import { useEffect, useState } from "react";
import { getAllSalesCommunications } from "../../redux/features/communications/communicationsSlice";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { removeLocalStorage } from "../../utils/logout/logout";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

import {
  closeFullScreenModal,
  openFullScreenModal,
} from "../../redux/features/modal/modalSlice";
import {
  communicationFilters,
  communicationTypesMap,
} from "../../utils/filters/filterOptions";
import {
  Container,
  Typography,
  Box,
  TextField,
  Grid,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Filter from "../../components/Filter";
import FullScreenModal from "../../components/FullScreenModal";
import CloseIcon from "@mui/icons-material/Close";
import CommunicationLog from "../../components/sales/CommunicationLog";
import GeneralAlert from "../../components/GeneralAlert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "../../axios/axios.config";

const CommunicationDashboard = () => {
  const [changeViewAnchor, setChangeViewAnchor] = useState(null);
  const [selectedItem, setSelectedItem] = useState("Mine");
  const [searchInput, setSearchInput] = useState("");
  const [completedCommunications, setCompletedCommunications] = useState([]);
  const [inProgressFilter, setInProgressFilter] = useState([]);
  const [followUpFilter, setFollowUpFilter] = useState([]);
  const [editedRecord, setEditedRecord] = useState(null);
  const [isFilteredCompleted, setIsFilteredCompleted] = useState(false);
  const [isFilteredInProgress, setIsFilteredInProgress] = useState(false);
  const [isFilteredFollowUp, setIsFilteredFollowUp] = useState(false);
  const [completedFiltered, setCompletedFiltered] = useState([]);
  const [managedUsers, setManagedUsers] = useState([]);
  const { username, rolelevel } = useSelector((state) => state.user);

  const { salesCommunications } = useSelector((state) => state.communications);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //CREATE A BUTTON DROPWDOWN WITH THREE VALUES. THE VALUE WILL DICTATE WHICH VIEW YOU WANT TO BE ON. PASS IN AN ARRAY AND USE THE $in OPERATOR IN MONGOOSE FIND QUERY

  const isChangeMenuOpen = Boolean(changeViewAnchor);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width: 300,
      sortable: false,
      renderCell: (params) => (
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => openEditCommunication(params.row._id)}
        >
          {params.row._id}
        </Link>
      ),
    },
    { field: "customer", headerName: "Customer", width: 400 },
    rolelevel !== 5
      ? {
          field: "createdby",
          headerName: "Created By",
          width: 200,
          type: "string",
          valueGetter: (params) => params.row.createdby.username,
        }
      : {
          field: "priority",
          headerName: "Priority",
          width: 200,
          type: "string",
        },
    { field: "category", headerName: "Category", width: 150 },

    {
      field: "message",
      headerName: "Message",
      width: 250,
    },
    {
      field: "date",
      headerName: "Date Created",
      type: "string",
      width: 150,
      valueGetter: (params) => {
        return new Date(params.row.date).toISOString().slice(0, 10);
      },
    },
  ];

  useEffect(() => {
    if (username && selectedItem) {
      getCommunications();
    }
  }, [username, selectedItem]);

  useEffect(() => {
    if (salesCommunications && salesCommunications.length > 0) {
      setCompletedCommunications(
        salesCommunications.filter((log) => log.status === "complete")
      );
      setCompletedFiltered(
        salesCommunications.filter((log) => log.status === "complete")
      );
      setInProgressFilter(
        salesCommunications.filter((log) => log.status === "inprogress")
      );
      setFollowUpFilter(
        salesCommunications.filter((log) => log.status === "followup")
      );
    }
  }, [salesCommunications]);

  useEffect(() => {
    return () => {
      dispatch(closeFullScreenModal());
    };
  }, []);

  const getCommunications = async () => {
    let managedUsers = [];
    try {
      if (selectedItem === "My Team") {
        const users = await axios.get("users/", {
          params: { manager: username },
        });

        managedUsers = users.data.map((user) => user.username);
        setManagedUsers(managedUsers);
      }

      await dispatch(
        getAllSalesCommunications({
          username,
          managedUsers,
          viewType: selectedItem,
        })
      ).unwrap();
    } catch (error) {
      if (error === "Unauthorized, Please Log In") {
        removeLocalStorage();
        navigate("/");
        return;
      }
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error Could Not Get Communications: ${error}`,
          duration: 3000,
        })
      );
    }
  };

  const handleChanges = (event) => {
    if (event.target.value === "") {
      setCompletedFiltered(completedCommunications);
    }
    setSearchInput(event.target.value);
    setCompletedFiltered(
      completedCommunications.filter(
        (c) =>
          c._id.toLowerCase().search(event.target.value.toLowerCase()) != -1 ||
          c.customer.toLowerCase().search(event.target.value.toLowerCase()) !=
            -1 ||
          c.message.toLowerCase().search(event.target.value.toLowerCase()) !=
            -1 ||
          c.category.toLowerCase().search(event.target.value.toLowerCase()) !=
            -1
      )
    );
  };

  const resetFilters = (type) => {
    if (type === "completed") {
      setCompletedFiltered(completedCommunications);
    } else if (type === "inprogress") {
      setInProgressFilter(
        salesCommunications.filter((log) => log.status === "inprogress")
      );
    } else {
      setFollowUpFilter(
        salesCommunications.filter((log) => log.status === "followup")
      );
    }
  };

  function openEditCommunication(id) {
    const record = salesCommunications.find((c) => {
      return c._id === id;
    });

    setEditedRecord({
      ...record,
      alert: record.alerttype,
      date: new Date(record.date).toISOString().slice(0, 10),
    });
    dispatch(openFullScreenModal());
  }

  const handleMenuItemChange = (option) => {
    setSelectedItem(option);
    setChangeViewAnchor(null);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 2 }}>
      <FullScreenModal>
        <Container maxWidth="xl" sx={{ pt: 2 }}>
          <IconButton
            onClick={() => {
              dispatch(closeFullScreenModal());
              setEditedRecord(null);
            }}
          >
            <CloseIcon />
          </IconButton>
          <CommunicationLog
            closeModal={() => {
              dispatch(closeFullScreenModal());
              setEditedRecord(null);
            }}
            editedRecord={editedRecord}
            setEditedRecord={setEditedRecord}
            viewType={selectedItem}
            managedUsers={managedUsers}
          />
        </Container>
      </FullScreenModal>
      <Typography variant="h3" textAlign="center" gutterBottom>
        Activity Dashboard
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "flex-end", pb: 2, gap: 1 }}>
        {rolelevel <= 2 ? (
          <Box>
            <Button
              variant="contained"
              sx={{ width: "150px" }}
              endIcon={<KeyboardArrowDownIcon />}
              disableElevation
              onClick={(event) => setChangeViewAnchor(event.currentTarget)}
            >
              {selectedItem}
            </Button>

            <Menu
              id="changeViewMneu"
              anchorEl={changeViewAnchor}
              open={isChangeMenuOpen}
              onClose={() => setChangeViewAnchor(null)}
              PaperProps={{
                style: {
                  width: changeViewAnchor ? changeViewAnchor.clientWidth : null, // Set the width based on the target element's width
                },
              }}
            >
              <MenuItem
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                }}
                onClick={() => handleMenuItemChange("Mine")}
              >
                Mine
              </MenuItem>
              <MenuItem
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                }}
                onClick={() => handleMenuItemChange("My Team")}
              >
                My Team
              </MenuItem>
              {rolelevel === 0 && (
                <MenuItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",
                  }}
                  onClick={() => handleMenuItemChange("All")}
                >
                  All
                </MenuItem>
              )}
            </Menu>
          </Box>
        ) : null}
        <Button
          variant="outlined"
          endIcon={<AddIcon />}
          onClick={() => dispatch(openFullScreenModal())}
        >
          Add New Record
        </Button>
      </Box>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-in-progress"
          id="panel-in-progress"
        >
          <Typography variant="h5">In Progress</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Filter
              filters={communicationFilters}
              resetFilters={() => resetFilters("inprogress")}
              isFiltered={isFilteredInProgress}
              setIsFiltered={setIsFilteredInProgress}
              updateFn={setInProgressFilter}
              arrayToFilter={salesCommunications.filter(
                (log) => log.status === "inprogress"
              )}
              filterTypes={communicationTypesMap}
            />
          </Box>
          {salesCommunications.filter((log) => log.status === "inprogress")
            .length > 0 ? (
            <DataGrid
              rows={inProgressFilter}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 15 },
                },
              }}
              pageSizeOptions={[15, 25]}
              autoHeight
              density="compact"
              disableColumnMenu
              columnBuffer={columns.length}
              getRowId={(row) => row._id}
            />
          ) : (
            <Typography textAlign="center" variant="h4">
              No Records To display
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-follow-up"
          id="panel-follow-up"
        >
          <Typography variant="h5">Follow Up</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Filter
              filters={communicationFilters}
              resetFilters={() => resetFilters("followup")}
              isFiltered={isFilteredFollowUp}
              setIsFiltered={setIsFilteredFollowUp}
              updateFn={setFollowUpFilter}
              arrayToFilter={salesCommunications.filter(
                (log) => log.status === "followup"
              )}
              filterTypes={communicationTypesMap}
            />
          </Box>
          {salesCommunications.filter((log) => log.status === "followup")
            .length > 0 ? (
            <DataGrid
              rows={followUpFilter}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 15 },
                },
              }}
              pageSizeOptions={[15, 25]}
              autoHeight
              density="compact"
              disableColumnMenu
              columnBuffer={columns.length}
              getRowId={(row) => row._id}
            />
          ) : (
            <Typography textAlign="center" variant="h4">
              No Records To display
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-completed"
          id="panel-completed"
        >
          <Typography variant="h5">Completed</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 3,
              }}
            >
              <Grid container>
                <Grid item xs={10} md={4}>
                  <TextField
                    id="component-outlined-search-records"
                    label="Search Completed Records"
                    size="small"
                    value={searchInput}
                    onChange={handleChanges}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Box>
                <Filter
                  filters={communicationFilters}
                  resetFilters={() => resetFilters("completed")}
                  isFiltered={isFilteredCompleted}
                  setIsFiltered={setIsFilteredCompleted}
                  updateFn={setCompletedFiltered}
                  arrayToFilter={completedFiltered}
                  filterTypes={communicationTypesMap}
                />
              </Box>
            </Box>
            {completedFiltered.length > 0 ? (
              <DataGrid
                rows={completedFiltered}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 15 },
                  },
                }}
                pageSizeOptions={[15, 25]}
                autoHeight
                density="compact"
                disableColumnMenu
                columnBuffer={columns.length}
                getRowId={(row) => row._id}
              />
            ) : (
              <Typography textAlign="center" variant="h4">
                No Records To display
              </Typography>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default CommunicationDashboard;
