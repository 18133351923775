import axios from "../../axios/axios.config";

export const sendGeneralEmail = async ({
  to,
  from = "webservices@trakmotive.com",
  fromname = null,
  subject,
  cc = null,
  bcc = null,
  body,
  isHtml = false,
  templateId = null,
  dynamicTemplateData = null,
  attachments = [], //array of sendgrid attachment objects
}) => {
  try {
    if (!to || !subject) {
      throw new Error(
        "Missing one or more required fields: 'to', or 'subject'"
      );
    }

    if (templateId && !dynamicTemplateData) {
      throw new Error(
        "A template id exists but no template data provided. Please provide template data or remove template id."
      );
    }

    if (!templateId && !body) {
      throw new Error(
        "No template id is present and no body has been provided. Please provide a body or include a template id with corresponding template data."
      );
    }

    const msg = {
      to,
      subject,
      from,
    };

    if (fromname) {
      msg.from = { email: from, name: fromname };
    }

    if (cc) {
      msg["cc"] = cc === to || (Array.isArray(to) && to.includes(cc)) ? "" : cc;
    }

    if (bcc) {
      msg["bcc"] =
        bcc === to || (Array.isArray(to) && to.includes(bcc)) ? "" : bcc;
    }

    if (templateId) {
      msg.templateId = templateId;
      msg.dynamicTemplateData = dynamicTemplateData;
    } else {
      if (isHtml) {
        msg.html = body;
      } else {
        msg.text = body;
      }
    }

    if (attachments.length) {
      msg.attachments = attachments;
    }

    return await axios.post("email/general", msg);
  } catch (error) {
    throw error;
  }
};
