import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMemoModalOpenOnCreate: false,
  isToteInfoModalOpen: false,
  isCloseTotesModalOpen: false,
  isValidateItemWarningModalOpen: false,
  isFullScreenModalOpen: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openMemoModalOnCreate: (state) => {
      state.isMemoModalOpenOnCreate = true;
    },
    closeMemoModalOnCreate: (state) => {
      state.isMemoModalOpenOnCreate = false;
    },
    openToteInfoModal: (state) => {
      state.isToteInfoModalOpen = true;
    },
    closeToteInfoModal: (state) => {
      state.isToteInfoModalOpen = false;
    },
    openCloseTotesModal: (state) => {
      state.isCloseTotesModalOpen = true;
    },
    closeCloseTotesModal: (state) => {
      state.isCloseTotesModalOpen = false;
    },
    openValidateItemWarningModal: (state) => {
      state.isValidateItemWarningModalOpen = true;
    },
    closeValidateItemWarningModal: (state) => {
      state.isValidateItemWarningModalOpen = false;
    },
    openFullScreenModal: (state) => {
      state.isFullScreenModalOpen = true;
    },
    closeFullScreenModal: (state) => {
      state.isFullScreenModalOpen = false;
    },
  },
});

export const {
  openMemoModalOnCreate,
  closeMemoModalOnCreate,
  openToteInfoModal,
  closeToteInfoModal,
  openCloseTotesModal,
  closeCloseTotesModal,
  openValidateItemWarningModal,
  closeValidateItemWarningModal,
  openFullScreenModal,
  closeFullScreenModal,
} = modalSlice.actions;
export default modalSlice.reducer;
