import { Error } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";

import GeneralModalV3 from "../../general/GeneralModalV3";
import FlexWrapper from "../../FlexWrapper";

const ReceiveTransactionOverageErrorModal = ({ open, setOpen }) => {
  return (
    <GeneralModalV3
      openComponent={null}
      openVal={Boolean(open)}
      onClose={() => setOpen("")}
    >
      <FlexWrapper justifyContent="center" alignItems="center">
        <Error color="error" fontSize="large" />
        <Typography variant="h4" textAlign="center" p={1}>
          Overage Error{" "}
        </Typography>
      </FlexWrapper>
      <Divider />

      <Box py={2} px={1}>
        <Typography
          variant="h6"
          fontWeight="bold"
          textAlign="center"
          sx={{ whiteSpace: "break-spaces" }}
        >
          {open}
        </Typography>
      </Box>
    </GeneralModalV3>
  );
};
export default ReceiveTransactionOverageErrorModal;
