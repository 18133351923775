import { useState, useRef } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { statusColorMap } from "../utils/tote-status/toteStatus";
import { closeGeneralAlert } from "../redux/features/alert/alertSlice";

import ToteItems from "../components/ToteItems";
import ItemScan from "../components/ItemScan";
import CircleIcon from "@mui/icons-material/Circle";
import { useNavigate } from "react-router-dom";

const ToteView = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openPopper, setopenPopper] = useState(false);
  const [modalText, setModalText] = useState({ title: "", description: "" });
  const toteItemsBottomRef = useRef(null);
  const navigate = useNavigate();
  const tote = useSelector((state) => state.tote);
  const dispatch = useDispatch();

  const scrollToBottom = () => {
    toteItemsBottomRef !== null && toteItemsBottomRef.current.scrollIntoView();
  };

  return (
    <Box
      sx={{
        flexDirection: "column",
        minHeight: 0,
        display: "flex",
        width: "100%",
      }}
    >
      <ToteItems bottomRef={toteItemsBottomRef} />
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          py: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          alignItems="center"
          gap={1}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box alignItems="baseline" sx={{ display: "flex" }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                pr: "5px",
              }}
            >
              <Tooltip enterTouchDelay={0} title={tote.status}>
                <CircleIcon
                  color={statusColorMap[tote.status]}
                  sx={{ fontSize: "1rem" }}
                />
              </Tooltip>{" "}
              {tote.toteid}
            </Typography>

            <Typography
              onClick={() => {
                dispatch(closeGeneralAlert());
                navigate("/portal/reverse-logistics/tote-manager");
              }}
              sx={{
                color: "blueviolet",
                cursor: "pointer",
                fontSize: { xs: "10px", md: "1rem" },
                textDecoration: "underline",
              }}
            >
              Change
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 3 }}>
          <Typography sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}>
            Unique: <strong>{tote.items.length}</strong>
          </Typography>
          <Typography sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}>
            QTY: <strong>{tote.totalitemsqty}</strong>
          </Typography>
        </Box>
      </Box>

      <ItemScan
        scrollToBottom={scrollToBottom}
        setOpenModal={setOpenModal}
        openModal={openModal}
        modalText={modalText}
        setModalText={setModalText}
      />
    </Box>
  );
};

export default ToteView;
