import { useSelector } from "react-redux";
import { APP_VERSION } from "../../utils/constants/version";
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import {
  BugReport,
  Error,
  Link,
  People,
  PrintRounded,
  SsidChart,
} from "@mui/icons-material";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Box,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../components/FlexWrapper";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Loading from "../../components/Loading";

const menu = [
  { title: "Users", icon: <People />, to: "/admin", location: "/admin" },
  { title: "Logs", icon: <SsidChart />, to: "logs", location: "/admin/logs" },
  {
    title: "Printers",
    icon: <PrintRounded />,
    to: "printers",
    location: "/admin/printers",
  },
  {
    title: "Custom Links",
    icon: <Link />,
    to: "custom-links",
    location: "/admin/custom-links",
  },
  {
    title: "Integration Errors",
    icon: <Error />,
    to: "integration-errors",
    location: "/admin/integration-errors",
  },
  {
    title: "Bug Reports",
    icon: <BugReport />,
    to: "bug-reports",
    location: "/admin/bug-reports",
  },
];

const Admin = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { instanceId } = useSelector((state) => state.ec2);
  const location = useLocation();
  const navigation = useNavigation();

  if (navigation.state === "loading") {
    return <Loading message="Loading..." />;
  }

  return (
    <Box>
      <FlexWrapper>
        <Drawer
          variant="persistent"
          anchor="left"
          open
          sx={{
            flexShrink: 0,
            boxSizing: "border-box",
            width: 240,
            "& .MuiDrawer-paper": {
              width: 240,
            },
          }}
        >
          <FlexWrapper
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="space-between"
          >
            <List sx={{ width: "100%" }}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() =>
                    navigate(`/portal/${user.currentDepartment.toLowerCase()}`)
                  }
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText>Back Home</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
              {menu.map((menuItem) => (
                <ListItem
                  sx={{
                    background:
                      location.pathname === menuItem.location ? "#ededed" : "",
                  }}
                  key={menuItem.title}
                  disablePadding
                  onClick={() => navigate(menuItem.to)}
                >
                  <ListItemButton>
                    <ListItemIcon>{menuItem.icon}</ListItemIcon>
                    <ListItemText primary={menuItem.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>

            <Box width="100%" alignSelf="center" pb={1}>
              <Divider flexItem />
              <Box sx={{ textAlign: "center", pt: 1 }}>
                <Typography variant="caption" color="text.secondary">
                  Copyright © Advanced Innovative Technology
                  {"  " + new Date().getFullYear()}
                  <br /> {APP_VERSION} <br />
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {`${
                    process.env.REACT_APP_NODE_ENV &&
                    process.env.REACT_APP_NODE_ENV !== "PRODUCTION"
                      ? `${process.env.REACT_APP_NODE_ENV}`
                      : ""
                  }`}
                  <br />
                </Typography>
                <Typography
                  textAlign="center"
                  variant="caption"
                  color="text.secondary"
                >
                  {instanceId ? instanceId : ""}
                </Typography>
              </Box>
            </Box>
          </FlexWrapper>
        </Drawer>

        <Outlet />
      </FlexWrapper>
    </Box>
  );
};
export default Admin;
