import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Description, Search } from "@mui/icons-material";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import FlexWrapper from "../../../components/FlexWrapper";
import axios from "../../../axios/axios.config";
import BinDataCard from "../../../components/multi-page/inventory-move/BinDataCard";
import BinDataInfoModal from "../../../components/multi-page/inventory-move/BinDataInfoModal";
import GeneralExportV2 from "../../../components/general/GeneralExportV2";
import Loading from "../../../components/Loading";
import GeneralModalV2 from "../../../components/general/GeneralModalV2";

const PickDiscrepanciesPage = () => {
  const [originalData, setOriginalData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [binData, setBinData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("new");
  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef(null);
  const [sortOption, setSortOption] = useState("lastMonth"); // Initial sort option
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");

  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");
  const { openAlert } = useGeneralAlert();
  // //made export data into array of arrays because of duplicate header names
  // const exportHeaders = [
  //   "Internal ID",
  //   "Name",
  //   "Description",
  //   "UPC Code",
  //   "Bin Number",
  //   "Internal ID",
  // ];

  // const exportData = [];
  // exportData.push(exportHeaders);

  // for (let i = 0; i < binData.length; i++) {
  //   exportData.push([
  //     binData[i].lineiteminternalid,
  //     binData[i].item,
  //     binData[i].lineitemclass,
  //     String(binData[i].lineitemupc),
  //     binData[i].bin,
  //     binData[i].locationinternalid,
  //   ]);
  // }

  const { currentNetsuiteLocationIds, currentSubsidiary } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    handleFilterSort();
  }, [statusFilter, sortOption]);

  const filteredData = Boolean(search)
    ? binData.filter(
        (bin) =>
          bin.bin?.includes(search) ||
          bin.item?.includes(search) ||
          bin.order?.includes(search)
      )
    : originalData;

  const exportData =
    statusFilter === "complete" && filteredData?.length
      ? filteredData.map((obj) => {
          return {
            Type: obj.shorttype,
            Status: obj.status,
            Created: new Date(obj.createdAt).toLocaleString(),
            "Logged By": obj.loggedby,
            Order: obj.order,
            Item: obj.item,
            Bin: obj.bin,
            Location: obj.location,
            "Counted By": obj.counts.length
              ? obj.counts[obj.counts.length - 1].countedby?.username
              : "N/A",
            "Counted At": obj.counts.length
              ? new Date(
                  obj.counts[obj.counts.length - 1].time
                ).toLocaleString()
              : "N/A",
            "On-Hand": obj.counts.length
              ? obj.counts[obj.counts.length - 1].totalvirtualqty
              : "N/A",
            Counted: obj.counts.length
              ? obj.counts[obj.counts.length - 1].usercount
              : "N/A",
            Variance: obj.counts.length
              ? obj.counts[obj.counts.length - 1].adjustment
              : "N/A",
            "Auto Approve": obj.counts.length
              ? obj.counts[obj.counts.length - 1].autoapprove
                ? "YES"
                : "NO"
              : "N/A",
            "Number of Counts": obj.counts.length ? obj.counts.length : "N/A",
          };
        })
      : [];

  // Function to filter bin data by status
  const handleStatusFilter = (status) => {
    setStatusFilter(status);
    setSelected([]);
  };

  // Function to handle opening menu
  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  // Function to handle menu close
  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleFilterSort = async () => {
    setLoading("Loading Data...");
    try {
      const binShortsResponse = await axios.get(
        `pick-shorts?sortOption=${sortOption}&locations=${JSON.stringify(
          currentNetsuiteLocationIds
        )}&subsidiary=${currentSubsidiary}&status=${statusFilter}`
      );

      setBinData(binShortsResponse.data);
      setOriginalData(binShortsResponse.data);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not get data: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    } finally {
      setLoading("");
    }
  };

  if (loading) return <Loading message={loading} />;

  return (
    <Container maxWidth="xl" sx={{ pb: 1 }}>
      <FlexWrapper justifyContent="space-between" alignItems="baseline">
        <FlexWrapper>
          <Typography variant="h4" gutterBottom mr={1}>
            Pick Discrepancies
          </Typography>
          <BinDataInfoModal />
          {/* <GeneralExportV2
            exportData={exportData}
            exportTitle="Export Pick Data"
            fileName={`Pick_Discrepancies_${Date.now()}.csv`}
            exportType="csv"
            sheetName="Pick Discrepanices"
            dataType="aoa"
          /> */}
        </FlexWrapper>
        <Typography variant="h6" gutterBottom>
          Total: {binData.length}
        </Typography>
      </FlexWrapper>
      <Grid container spacing={2}>
        <Grid item xs={8} md={10}>
          <TextField
            fullWidth
            autoFocus
            label="Search"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(event) => setSearch(event.target.value.toUpperCase())}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            select
            label="Filter by Status"
            value={statusFilter}
            onChange={(e) => handleStatusFilter(e.target.value)}
            fullWidth
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="need-recount">Need Recount</MenuItem>
            <MenuItem value="pending-approval">Pending Approval</MenuItem>
            <MenuItem value="complete">Complete</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} justifyContent="space-between" display="flex">
          {(statusFilter === "all" || statusFilter === "complete") && (
            <Select
              size="small"
              value={sortOption}
              sx={{ width: matches ? "66%" : "20%" }}
            >
              <MenuItem
                value="lastWeek"
                onClick={() => {
                  setSortOption("lastWeek");
                }}
              >
                Last Week
              </MenuItem>
              <MenuItem
                value="lastTwoWeeks"
                onClick={() => {
                  setSortOption("lastTwoWeeks");
                }}
              >
                Last Two Weeks
              </MenuItem>
              <MenuItem
                value="lastMonth"
                onClick={() => {
                  setSortOption("lastMonth");
                }}
              >
                Last Month
              </MenuItem>
              <MenuItem
                value="lastYear"
                onClick={() => {
                  setSortOption("lastYear");
                }}
              >
                Last Year
              </MenuItem>
              <MenuItem
                value="allTime"
                onClick={() => {
                  setSortOption("allTime");
                }}
              >
                All Time
              </MenuItem>
            </Select>
          )}

          {statusFilter === "complete" && (
            <GeneralExportV2
              triggerComponent={
                <IconButton size="large">
                  <Description color="primary" />
                </IconButton>
              }
              fileName={`Pick-Discrepancies-${Date.now()}.csv`}
              exportTitle="Export Pick Discrepancies"
              exportType="csv"
              exportData={exportData}
            />
          )}
        </Grid>

        {filteredData.length ? (
          filteredData.map((data) => (
            <Grid item xs={12} key={data._id}>
              <BinDataCard
                data={data}
                setLoading={setLoading}
                setOriginalData={setOriginalData}
                originalData={originalData}
                setBinData={setBinData}
                selected={selected}
                setSelected={setSelected}
                statusFilter={statusFilter}
                sortOption={sortOption}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} pt={3}>
            <Typography variant="h5" textAlign="center">
              No Data To Display
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
export default PickDiscrepanciesPage;
