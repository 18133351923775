import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConsecutiveAlertPack } from "../redux/features/alert/alertSlice";

import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

const ConsecutiveAlerts = () => {
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  useEffect(() => {
    if (alert?.consecutiveAlertPack?.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...alert.consecutiveAlertPack[0] });
      dispatch(
        setConsecutiveAlertPack({ pack: alert.consecutiveAlertPack.slice(1) })
      );
      setOpen(true);
    } else if (alert?.consecutiveAlertPack?.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [alert.consecutiveAlertPack, messageInfo, open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={alert.consecutiveAlertAnchorOrigin}
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        autoHideDuration={alert.consecutiveAlertDuration}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
      >
        <Alert severity={alert.consecutiveAlertSeverity}>
          {messageInfo ? messageInfo.message : undefined}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ConsecutiveAlerts;
