import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Check, Loop } from "@mui/icons-material";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { handleApproveAndAdjust } from "../../../utils/helper-functions/pick-discrepancies/pickDiscrepanciesHelperFunctions";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";

import axios from "../../../axios/axios.config";
import Loading from "../../Loading";
import FlexWrapper from "../../FlexWrapper";

const PickDiscrepanciesViewCounts = ({
  data,
  handleClose,
  sortOption,
  statusFilter,
  setBinData,
  setOriginalData,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState("");
  const [counts, setCounts] = useState([]);

  const reversedCounts = [...counts].reverse();

  useEffect(() => {
    getUpdatedCounts();
  }, []);

  const getUpdatedCounts = async () => {
    setLoading("Getting Count Data..");
    try {
      const response = await axios.get(`pick-shorts/get/counts/${data._id}`);
      setCounts(response.data.counts);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    } finally {
      setLoading("");
    }
  };

  const handleApproveAndAdjustFN = async () => {
    setLoading("Approving And Unlocking Bin...");
    try {
      const username = reversedCounts[0].countedby.username;
      //make adjustment if needed
      const adjustment = reversedCounts[0]?.adjustment || 0;
      let items = null;

      if (adjustment !== 0) {
        items = [
          {
            item: data.lineiteminternalid,
            adjustQtyBy: adjustment,
            binnumbers: data.bin,
            location: data.locationinternalid,
            linememo: data.order,
          },
        ];
      }
      await handleApproveAndAdjust({
        subsidiaryinternalid: parseInt(user.currentSubsidiaryInternalId), //only needed for adjustments
        username, //only needed for adjustments
        items, //only needed for adjustments
        locationinternalid: data.locationinternalid, //only needed for adjustments
        _id: data._id, //only needed for adjustments
        adjustment,
        bininternalid: data.bininternalid,
        lineiteminternalid: data.lineiteminternalid,
      });

      //update status to complete
      await axios.patch(`pick-shorts/${data._id}/status`, {
        status: "complete",
        statusChangeData: {
          to: "complete",
          from: data.status,
          changedby: user._id,
          time: new Date().toISOString(),
        },
      });

      const binShortsResponse = await axios.get(
        `pick-shorts?sortOption=${sortOption}&locations=${JSON.stringify(
          user.currentNetsuiteLocationIds
        )}&subsidiary=${user.currentSubsidiary}&status=${statusFilter}`
      );

      setBinData(binShortsResponse.data);
      setOriginalData(binShortsResponse.data);

      handleClose();

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Approved And Unlocked Bin",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    } finally {
      setLoading("");
    }
  };

  const handleSetToNeedRecount = async () => {
    setLoading("Updating Status...");
    try {
      //update status to need-recount
      await axios.patch(`pick-shorts/${data._id}/status`, {
        status: "need-recount",
        statusChangeData: {
          to: "need-recount",
          from: data.status,
          changedby: user._id,
          time: new Date().toISOString(),
        },
      });

      const binShortsResponse = await axios.get(
        `pick-shorts?sortOption=${sortOption}&locations=${JSON.stringify(
          user.currentNetsuiteLocationIds
        )}&subsidiary=${user.currentSubsidiary}&status=${statusFilter}`
      );

      setBinData(binShortsResponse.data);
      setOriginalData(binShortsResponse.data);

      handleClose();

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Updated Status",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    } finally {
      setLoading("");
    }
  };

  if (loading) return <Loading message={loading} />;

  return (
    <Box sx={{ p: { xs: 1, md: 2 } }}>
      <Typography textAlign="right" gutterBottom>
        Number of Counts: {data.counts?.length}
      </Typography>

      <Box
        sx={{ overflowY: "scroll", maxHeight: { xs: "320px", md: "600px" } }}
        py={0.5}
      >
        <Stack direction="column" justifyContent="center" spacing={2}>
          {reversedCounts.map((count, index) => (
            <Card key={count._id} elevation={3}>
              <CardContent>
                <Box>
                  <FlexWrapper justifyContent="space-between">
                    {count.autoapprove ? (
                      <Typography
                        variant="caption"
                        component="p"
                        textAlign="right"
                        fontWeight="bold"
                        color="green"
                      >
                        Auto Approved
                      </Typography>
                    ) : (
                      <Typography></Typography>
                    )}
                    {index === 0 && (
                      <Typography
                        variant="caption"
                        component="p"
                        textAlign="right"
                        fontWeight="bold"
                      >
                        Latest Count
                      </Typography>
                    )}
                  </FlexWrapper>
                  <Typography variant="h6" component="h2" textAlign="center">
                    Counted By: {count.countedby?.username}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  component="p"
                  textAlign="center"
                  gutterBottom
                >
                  Counted At: {new Date(count.time).toLocaleString()}
                </Typography>
                <Typography component="p">
                  <strong>NetSuite Quantity:</strong> {count.nsqty}
                  <br />
                  <strong>Total Pick In Progress:</strong>{" "}
                  {count.totalpickinprogress}
                  <br />
                  <strong>Total Inventory Move In Progress:</strong>{" "}
                  {count.totalinventorymoveinprogress}
                  <br />
                  <strong>Total Virtual Quantity:</strong>{" "}
                  {count.totalvirtualqty}
                  <br />
                  <strong>User Count:</strong> {count.usercount}
                  <br />
                  <strong>Adjustment:</strong> {count.adjustment}
                </Typography>
                <Typography variant="caption"></Typography>
              </CardContent>
              {index === 0 && data.status === "pending-approval" && (
                <CardActions>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    endIcon={<Check />}
                    onClick={handleApproveAndAdjustFN}
                  >
                    {reversedCounts[0]?.adjustment ? "Adjust and" : ""} Approve
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<Loop />}
                    size="small"
                    onClick={handleSetToNeedRecount}
                  >
                    Set To Need Recount
                  </Button>
                </CardActions>
              )}
            </Card>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};
export default PickDiscrepanciesViewCounts;
