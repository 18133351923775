import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import {
  createSalesCommunication,
  updateSalesCommunication,
} from "../../redux/features/communications/communicationsSlice";
import {
  Container,
  Grid,
  Stack,
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Typography,
  Box,
} from "@mui/material";

import axios from "../../axios/axios.config";
import { useSalesRepCustomerMatch } from "../../hooks/useSalesRepCustomerMatch";

const CommunicationLog = ({
  closeModal,
  editedRecord,
  viewType,
  managedUsers,
}) => {
  const focusRef = useRef();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");
  const [uniqueGroups, setUniqueGroups] = useState([]);
  const { username, currentDepartment, email, firstname, lastname } =
    useSelector((state) => state.user);
  const [input, setInput] = useState({
    date: `${new Date().toISOString().slice(0, 10)}`,
    customer: "",
    contact: "",
    category: "",
    transaction: "",
    item: "",
    priority: "",
    status: "",
    message: "",
    alert: [],
  });

  const { filteredCustomers } = useSalesRepCustomerMatch();

  useEffect(() => {
    if (editedRecord) {
      setInput(editedRecord);
    }
  }, [editedRecord]);

  useEffect(() => {
    //get an array of unique customer groups
    if (filteredCustomers && filteredCustomers.length > 0) {
      const storage = new Set();
      filteredCustomers.forEach((fc) => storage.add(fc.customergroup));
      const keys = [...storage];

      setUniqueGroups(keys.sort());
    }
  }, [filteredCustomers]);

  const handleChanges = (e) => {
    if (e.target.name === "alert") {
      if (e.target.checked) {
        const newArr = [...input.alert, e.target.value];
        setInput({ ...input, alert: newArr });
      } else {
        setInput({
          ...input,
          alert: input.alert.filter((el) => el !== e.target.value),
        });
      }
      return;
    }
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //only users that created record can edit it
    if (editedRecord && editedRecord.createdby.username !== username) {
      return;
    }

    const newCommunication = {
      date: new Date(Date.parse(input.date)),
      customer: input.customer,
      contact: input.contact,
      category: input.category,
      transaction: input.transaction,
      item: input.item,
      priority: input.priority,
      status: input.status,
      message: input.message,
      alerttype: input.alert,
      createdby: { username, department: currentDepartment },
    };

    try {
      if (Boolean(editedRecord)) {
        //update the time string of a status thats being updated
        if (editedRecord.statusChangeLog[input.status] === "") {
          const log = {
            ...editedRecord.statusChangeLog,
            [input.status]: new Date().toISOString(),
          };

          newCommunication.statusChangeLog = log;
        }

        const cl = await dispatch(
          updateSalesCommunication({
            id: editedRecord._id,
            updated: newCommunication,
            username,
            viewType,
            managedUsers,
          })
        ).unwrap();

        if (input.alert.length > 0) {
          sendEmail(
            input.alert,
            cl,
            `${firstname} ${lastname} updated log on ${new Date(
              cl.updatedAt
            ).toLocaleDateString()}`
          );
        }
      } else {
        const log = {
          inprogress: "",
          followup: "",
          complete: "",
        };

        log[input.status] = new Date().toISOString();
        newCommunication.statusChangeLog = log;

        const cl = await dispatch(
          createSalesCommunication({
            newCommunication,
            username,
            viewType,
            managedUsers,
          })
        ).unwrap();

        if (input.alert.length > 0) {
          sendEmail(
            input.alert,
            cl._id,
            `${firstname} ${lastname} created new log on ${new Date(
              cl.createdAt
            ).toLocaleDateString()}`
          );
        }
      }

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully ${
            editedRecord ? "updated" : "logged"
          } communication`,
          duration: 3000,
        })
      );
      closeModal();
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: Could Not ${
            editedRecord ? "Update" : "Log"
          } Communication : ${error}`,
          duration: 3000,
        })
      );
    }
  };

  const sendEmail = async (emails, _id, description) => {
    try {
      await axios.post(
        "/email",
        {
          cc: email ? email : "",
          to: process.env.REACT_APP_TEST_EMAIL || emails,
          subject: input.customer,
          attachments: [],
          templateId: "d-648bfa5571654b91989f24fbcb22c1c6",
          dynamicTemplateData: {
            mongo_id: _id,
            description: description,
            message: input.message,
            customer: input.customer,
            category: input.category,
            status: input.status,
            contact: input.contact,
            transaction: input.transaction,
            item: input.item,
            priority: input.priority,
          },
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Send Email: ${error.message}`,
          duration: 3000,
        })
      );
    }
  };

  return (
    <Container
      maxWidth={matches ? "xl" : "md"}
      sx={{
        py: 2,
      }}
    >
      <Typography
        variant={matches ? "h4" : "h3"}
        gutterBottom
        textAlign="center"
      >
        {`${editedRecord ? "Update" : "Record"}`} Customer Activity
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} direction="column">
          <Grid item sx={{ width: "50%" }}>
            <TextField
              fullWidth
              type="date"
              required
              inputRef={focusRef}
              label="Date"
              name="date"
              value={input.date}
              onChange={handleChanges}
            />
          </Grid>

          {editedRecord ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                label="Customer"
                name="customer"
                value={input.customer}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          ) : (
            filteredCustomers &&
            filteredCustomers.length > 0 && (
              <Grid item xs={12} sx={{ width: "100%" }}>
                <FormControl required fullWidth>
                  <InputLabel id="select-label-customer">Customer</InputLabel>
                  <Select
                    labelId="select-label-customer"
                    id="select-customer"
                    name="customer"
                    label="Customer"
                    required
                    autoFocus
                    disabled={filteredCustomers.length === 0}
                    value={input.customer}
                    onChange={handleChanges}
                  >
                    {uniqueGroups.map((c) => {
                      return (
                        <MenuItem
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          key={c}
                          value={c}
                        >
                          {c}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )
          )}
          <Grid item xs={12} sx={{ display: "flex", gap: 1 }}>
            <FormControl required fullWidth>
              <InputLabel id="select-label-category">Category</InputLabel>
              <Select
                labelId="select-label-category"
                id="select-category"
                name="category"
                label="Category"
                value={input.category}
                onChange={handleChanges}
              >
                <MenuItem value="billing">Billing</MenuItem>
                <MenuItem value="question">Question</MenuItem>
                <MenuItem value="inventory">Inventory</MenuItem>
                <MenuItem value="product">Product</MenuItem>
                <MenuItem value="return">Return</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Contact"
              type="text"
              required
              name="contact"
              value={input.contact}
              onChange={handleChanges}
            />
          </Grid>

          <Grid item sx={{ display: "flex", gap: 2 }}>
            <TextField
              fullWidth
              label="Transaction"
              type="text"
              name="transaction"
              value={input.transaction}
              onChange={handleChanges}
            />

            <TextField
              fullWidth
              label="Item"
              type="text"
              name="item"
              value={input.item}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item sx={{ display: "flex", gap: 2 }}>
            <FormControl required fullWidth>
              <InputLabel id="select-label-priority">Priority</InputLabel>
              <Select
                labelId="select-label-priority"
                id="select-priority"
                name="priority"
                label="Priority"
                value={input.priority}
                onChange={handleChanges}
              >
                <MenuItem value="high">High</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="low">Low</MenuItem>
              </Select>
            </FormControl>

            <FormControl required fullWidth>
              <InputLabel id="select-label-status">Status</InputLabel>
              <Select
                labelId="select-label-status"
                id="select-status"
                name="status"
                label="Status"
                value={input.status}
                onChange={handleChanges}
              >
                <MenuItem value="inprogress">In Progress</MenuItem>
                <MenuItem value="followup">Follow up needed</MenuItem>
                <MenuItem value="complete">Complete</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label="Message"
              type="text"
              required
              multiline
              minRows={4}
              placeholder="More details regarding communication..."
              name="message"
              value={input.message}
              onChange={handleChanges}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: matches ? "column" : "row",
              justifyContent: matches ? "center" : "space-between",
            }}
          >
            <FormControl
              component="fieldset"
              variant="standard"
              sx={{ textAlign: "center", mb: 2 }}
              name="alert"
            >
              <FormLabel component="legend">Alert</FormLabel>
              <Divider sx={{ display: matches ? "block" : "none" }} />
              <Stack direction="row" justifyContent="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      value="activitydashboard-alert-product@trakmotive.com"
                      name="alert"
                      checked={Boolean(
                        input.alert.find(
                          (el) =>
                            el ===
                            "activitydashboard-alert-product@trakmotive.com"
                        )
                      )}
                      onChange={handleChanges}
                    />
                  }
                  labelPlacement="bottom"
                  label="Product"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="evelin@trakmotive.com"
                      name="alert"
                      checked={Boolean(
                        input.alert.find((el) => el === "evelin@trakmotive.com")
                      )}
                      onChange={handleChanges}
                    />
                  }
                  labelPlacement="bottom"
                  label="Operations"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="bwang@trakmotive.com"
                      name="alert"
                      checked={Boolean(
                        input.alert.find((el) => el === "bwang@trakmotive.com")
                      )}
                      onChange={handleChanges}
                    />
                  }
                  labelPlacement="bottom"
                  label="Finance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="ktalley@trakmotive.com"
                      name="alert"
                      checked={Boolean(
                        input.alert.find(
                          (el) => el === "ktalley@trakmotive.com"
                        )
                      )}
                      onChange={handleChanges}
                    />
                  }
                  labelPlacement="bottom"
                  label="Sales"
                />
              </Stack>
            </FormControl>

            {editedRecord ? (
              editedRecord.createdby.username === username && (
                <Button
                  size="large"
                  fullWidth={matches}
                  variant="contained"
                  sx={{ alignSelf: "center" }}
                  type="submit"
                >
                  Edit and Update
                </Button>
              )
            ) : (
              <Button
                size="large"
                fullWidth={matches}
                variant="contained"
                sx={{ alignSelf: "center" }}
                type="submit"
              >
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default CommunicationLog;
