import { useSelector, useDispatch } from "react-redux";
import { Delete, Preview } from "@mui/icons-material";
import { Card, CardContent, IconButton, Typography } from "@mui/material";
import { FixedSizeList } from "react-window";

import FlexWrapper from "../../../FlexWrapper";
import PackProcessItemInfoModal from "./PackProcessItemInfoModal";
import PackProcessDeleteItemModal from "./PackProcessDeleteItemModal";
import { toggleCurrentBox } from "../../../../redux/features/pack-process-v2/packProcessV2Slice";

const PackProcessItemDisplay = ({ tabValue }) => {
  const { packdetails, palletnumber, items } = useSelector(
    (state) => state.packProcessV2
  );
  const dispatch = useDispatch();

  let displayItems = [];
  switch (tabValue) {
    case 0:
      displayItems = [
        ...packdetails[palletnumber]?.items.map((itemObj) => {
          return {
            item: itemObj.item,
            packed: itemObj.palletpackedamount,
            timepacked: itemObj.timepacked, // Include the timepacked property
            palletitemid: itemObj._id,
          };
        }),
        ...(packdetails[palletnumber]?.boxes
          ? packdetails[palletnumber]?.boxes.map((boxObj) => {
              return {
                item: boxObj.boxId,
                packed: boxObj.boxpackedamount || 0,
                timepacked: boxObj.updatedAt, // Include the timepacked property
                palletitemid: boxObj._id,
                type: "box",
                items: boxObj.items || [],
              };
            })
          : []),
      ].sort((a, b) => new Date(b.timepacked) - new Date(a.timepacked)); // Sort by timepacked
      break;
    case 1:
      displayItems =
        items?.filter((item) => item.packedamount !== item.quantity) || [];
      break;
    case 2:
      displayItems = items || [];
      break;
    default:
      displayItems = [];
  }

  return displayItems.length > 0 ? (
    <>
      <FixedSizeList
        itemSize={tabValue === 0 ? 40 : 100}
        itemCount={displayItems.length}
        height={window.innerHeight - 176}
      >
        {({ index, style }) => {
          const itemObj = displayItems[index];
          return (
            <Card
              style={style}
              sx={
                itemObj.type === "box"
                  ? {
                      backgroundColor: "#E3F2FD", // Light blue background for box items
                      borderColor: "#2196F3", // Blue border for box items
                      borderWidth: 2,
                      borderStyle: "solid",
                      borderRadius: "8px", // Rounded corners for box items
                      boxShadow: "none", // Remove shadow for box items
                    }
                  : {
                      backgroundColor:
                        tabValue === 0 ||
                        itemObj.quantity === itemObj.packedamount
                          ? "#e8f5e9" // Light green for fully packed items
                          : "#ffebee", // Light red for unpacked items
                      borderColor:
                        tabValue === 0 ||
                        itemObj.quantity === itemObj.packedamount
                          ? "#66bb6a" // Green border for fully packed items
                          : "#ef5350", // Red border for unpacked items
                      borderWidth: 2,
                      borderStyle: "solid",
                    }
              }
            >
              {tabValue === 0 ? (
                <CardContent sx={{ p: 0.5 }}>
                  <FlexWrapper
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <FlexWrapper alignItems="center" gap={1}>
                      <Typography
                        textAlign="center"
                        fontSize="18px"
                        fontWeight="bold"
                      >
                        {itemObj.type === "box"
                          ? `BOX: ${itemObj.item}`
                          : itemObj.item.item}
                      </Typography>
                      <Typography fontSize="18px">
                        <strong>Packed: {itemObj.packed}</strong>
                      </Typography>
                    </FlexWrapper>

                    <FlexWrapper alignItems="center" gap={1.5}>
                      {itemObj.type === "box" ? null : (
                        <PackProcessItemInfoModal
                          itemObj={itemObj}
                          tabValue={tabValue}
                        />
                      )}
                      {tabValue === 0 ? (
                        itemObj.type === "box" ? (
                          <IconButton
                            size="small"
                            onClick={() =>
                              dispatch(toggleCurrentBox(itemObj.item))
                            }
                          >
                            <Preview fontSize="small" color="primary" />
                          </IconButton>
                        ) : (
                          <PackProcessDeleteItemModal itemObj={itemObj} />
                        )
                      ) : null}
                    </FlexWrapper>
                  </FlexWrapper>
                </CardContent>
              ) : (
                <CardContent sx={{ p: 0.5 }}>
                  <FlexWrapper
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="caption" color="text.secondary">
                      {itemObj.boxsize} | {itemObj.iful} | {itemObj.binnumber}
                    </Typography>

                    <FlexWrapper alignItems="center" gap={1.5}>
                      <PackProcessItemInfoModal
                        itemObj={itemObj}
                        tabValue={tabValue}
                      />
                      {tabValue === 0 ? (
                        <IconButton size="small">
                          <Delete color="error" />
                        </IconButton>
                      ) : null}
                    </FlexWrapper>
                  </FlexWrapper>

                  <FlexWrapper alignItems="center" justifyContent="center">
                    <Typography
                      textAlign="center"
                      variant="h6"
                      fontWeight="bold"
                    >
                      {itemObj.item}
                    </Typography>
                  </FlexWrapper>

                  <FlexWrapper
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="body2">
                      <strong>Qty: </strong>
                      {itemObj.quantity}
                    </Typography>
                    <Typography variant="body2">
                      <strong>
                        Packed {tabValue === 0 ? "in Pallet" : ""}:{" "}
                      </strong>
                      {itemObj.packedamount}
                    </Typography>
                    {tabValue === 1 && (
                      <Typography variant="body2">
                        <strong>Remaining: </strong>
                        {itemObj.quantity - itemObj.packedamount}
                      </Typography>
                    )}
                  </FlexWrapper>
                </CardContent>
              )}
            </Card>
          );
        }}
      </FixedSizeList>
    </>
  ) : (
    <Typography
      variant="body2"
      color="text.secondary"
      style={{ textAlign: "center", marginTop: "16px" }}
    >
      No Items {tabValue === 1 ? "Remaining" : "To Display"}
    </Typography>
  );
};

export default PackProcessItemDisplay;
