import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { updatePickLockedBy } from "../../../utils/helper-functions/pick-order/updatePickLockedBy";
import {
  findNextBin,
  pickItemFull,
  pickItemPartial,
  resetPickData,
  updateErrors,
  updateLoading,
} from "../../../redux/features/pick-order/pickOrderSlice";
import {
  Button,
  Container,
  Grid,
  Icon,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import PickOrderSkipItemModal from "./PickOrderSkipItemModal";
import axios from "../../../axios/axios.config";
import PickOrderBinErrorModal from "./PickOrderBinErrorModal";
import PickOrderEmptyBinValidationFullScreenModal from "./PickOrderEmptyBinValidationFullScreenModal";

const PickOrderCurrentView = () => {
  const [binNumber, setBinNumber] = useState("");
  const [itemNumber, setItemNumber] = useState("");
  const [itemQty, setItemQty] = useState("");
  const [open, setOpen] = useState(false);

  const {
    path,
    _id,
    currentindex,
    pick,
    internalid,
    tranid,
    trantype,
    firstpicktime,
    lastpicktime,
    lastpickedby,
    location,
    locationinternalid,
    totalItemQtyCurrentlyBeingPicked,
    totalItemQtyCurrentlyBeingMoved,
    binQtyAvailable, //total virtual qty in bin after subtractions
  } = useSelector((state) => state.pickOrder);
  const { username, currentDepartment, currentSubsidiary } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openBinValidationModal, setOpenBinValidationModal] = useState(false);

  const currentItem = currentindex >= path.length ? null : path[currentindex];

  if (currentItem) {
    var qtyToPick =
      parseInt(currentItem.lineitemqtycommitted) -
      parseInt(currentItem.lineitemqtypicked);
    //the amount to display on screen, depends on bin qty
    var itemQtyRemaining =
      currentItem.binonhandavailable < qtyToPick
        ? currentItem.binonhandavailable
        : qtyToPick;
    var isQtyVerified = parseInt(itemQty) - itemQtyRemaining === 0;
    var isBinVerified = binNumber === currentItem?.binnumber;
    var isItemVerified =
      itemNumber === currentItem?.lineitemname ||
      itemNumber === currentItem?.lineitemupc;
    var isBinShort = currentItem.binonhandavailable < qtyToPick;
    var isOZBIn = currentItem?.binnumber
      ?.toUpperCase()
      .includes("OZ_RECEIVING_");
    var isBinErrorModalOpen =
      Boolean(binNumber) &&
      binNumber.length >= parseInt(currentItem?.binnumber?.length) &&
      !isBinVerified;
  }

  const handlePickItem = async () => {
    const picktime = new Date().toISOString();
    let initialpicktime = null;
    if (!firstpicktime) {
      initialpicktime = picktime;
    }
    const pickedtime = picktime;
    const lastpicktime = picktime;
    const lastpickedby = username;
    const pickedAmount = parseInt(itemQty);
    const pickedItemObj = {
      ...currentItem,
      lineitemqtypicked: parseInt(currentItem.lineitemqtypicked) + pickedAmount,
      pickedtime,
      binqtypicked: pickedAmount,
      pickedby: username,
      qtytopick: itemQtyRemaining,
      ispartial: isOZBIn && !isQtyVerified ? true : false,
    };

    delete pickedItemObj.lineitemqtyremaining;

    if (isBinShort || (isOZBIn && !isQtyVerified)) {
      //item will have leftovers since bin does not have enough qty
      //remove current path item and update following path items with updated pick qty
      await dispatch(
        pickItemPartial({
          pickedItemObj,
          _id,
          pickedAmount,
          lastpicktime,
          lastpickedby,
          firstpicktime: initialpicktime,
        })
      ).unwrap();
    } else {
      //full amount of item was picked
      await dispatch(
        pickItemFull({
          pickedItemObj,
          _id,
          lastpicktime,
          lastpickedby,
          firstpicktime: initialpicktime,
        })
      ).unwrap();
    }
    //validate the next bin in the path and update path if necessary
    await dispatch(findNextBin({ _id })).unwrap();
    clearInputs();
    dispatch(
      openGeneralAlert({
        type: "success",
        message: `Picked (${pickedAmount}) ${currentItem.lineitemname} from ${currentItem.binnumber}`,
        duration: 3000,
      })
    );
  };

  const openBinValidationScreen = () => {};

  const clearInputs = () => {
    setBinNumber("");
    setItemNumber("");
    setItemQty("");
  };

  const handlePartialFulfillAction = async () => {
    dispatch(updateLoading("Fulfilling Order..."));
    try {
      const time = new Date().toISOString();

      const storage = {}; // will be final object we use in api call to NetSuite
      const currentItemBinStorage = {}; //will store bins and quantities for our line ids

      for (const item of pick) {
        if (!storage[item.lineid]) {
          storage[item.lineid] = {
            itemreceive: true,
            orderLine: item.lineid,
          };
        }

        //store bin numbers in binstorage
        //each lineid will have an object containing bins as keys and quantities as values
        if (!currentItemBinStorage[item.lineid]) {
          currentItemBinStorage[item.lineid] = {
            [item.binnumber]: parseInt(item.binqtypicked),
          };
        } else {
          if (!currentItemBinStorage[item.lineid][item.binnumber]) {
            currentItemBinStorage[item.lineid][item.binnumber] = parseInt(
              item.binqtypicked
            );
          } else {
            currentItemBinStorage[item.lineid][item.binnumber] += parseInt(
              item.binqtypicked
            );
          }
        }
      }
      //loop through each line id in bin storage
      for (const lineid of Object.keys(currentItemBinStorage)) {
        const currentBinsObj = currentItemBinStorage[lineid];
        const binsArr = Object.keys(currentBinsObj);

        if (binsArr.length > 1) {
          //loop and create a string to update binnumbers and qty
          let finalString = "";
          let finalQty = 0;

          for (const bin of binsArr) {
            finalString =
              finalString === ""
                ? `${bin}(${currentBinsObj[bin]})`
                : finalString + ` ${bin}(${currentBinsObj[bin]})`;

            finalQty += currentBinsObj[bin];
          }

          storage[lineid].binnumbers = finalString;
          storage[lineid].quantity = finalQty;
        } else {
          //update qty and binnumber in storage since only one bin exists
          storage[lineid].quantity = currentBinsObj[binsArr[0]];
          storage[lineid].binnumbers = binsArr[0];
        }
      }

      const nsResponse = await axios.post(
        `netsuite/post/pick-order/order/${internalid}/partial-fulfill`,
        {
          externalid: `${tranid}_${_id}`,
          username,
          items: Object.values(storage),
          trantype,
          tranid,
          _id,
          lastpicktime,
          lastpickedby,
          firstpicktime,
        }
      );

      const { fulfillmentid } = nsResponse.data;
      //update mongo
      await axios.patch(`pick-order/update/order/${_id}/fulfilled`, {
        fulfillmentid,
        time,
      });
      //update pickLockedBy field in netsuite
      updatePickLockedBy({ username: "", internalid, trantype });

      dispatch(resetPickData());
      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully Fulfilled ${tranid}`,
          duration: 6000,
        })
      );
      navigate(`/portal/${currentDepartment.toLowerCase()}/pick-order`, {
        replace: true,
      });
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Fulfill ${tranid}: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 6000,
        })
      );

      //check if netsuite returned erros data and update order
      if (error.response?.data?.data) {
        dispatch(updateErrors(error.response.data.data));
      }

      dispatch(updateLoading(""));
    }
  };

  if (path.length && !currentItem)
    return (
      <Typography variant="h4" textAlign="center" color="error">
        Couldn't get a current item
      </Typography>
    );

  if (path.length === 0) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h4" textAlign="center" gutterBottom>
          All Items Picked
        </Typography>
        <FlexWrapper justifyContent="center">
          <Button
            variant="contained"
            color="success"
            endIcon={<CheckCircle />}
            onClick={handlePartialFulfillAction}
          >
            Complete Pick
          </Button>
        </FlexWrapper>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      {/* Skip Item Modal */}
      <PickOrderSkipItemModal
        open={open}
        setOpen={setOpen}
        lineid={currentItem.lineid}
        itemQty={itemQty ? parseInt(itemQty) : itemQty}
        itemName={currentItem.lineitemname}
        itemBin={currentItem.binnumber}
        itemQtyRemaining={parseInt(itemQtyRemaining)}
        isBinVerified={isBinVerified}
        isItemVerified={isItemVerified}
        isQtyVerified={isQtyVerified}
        isOZBIn={isOZBIn}
      />
      {/* Bin Scan Error Modal */}
      <PickOrderBinErrorModal
        open={isBinErrorModalOpen}
        setBinNumber={setBinNumber}
      />

      {/* Bin Validation Full Screen Modal */}
      <PickOrderEmptyBinValidationFullScreenModal
        openVal={openBinValidationModal}
        bin={binNumber}
        setOpenVal={setOpenBinValidationModal}
        item={currentItem.lineitemname}
        _id={_id}
        dispatch={dispatch}
        handlePickItem={handlePickItem}
        shortData={{
          lineitemclass: currentItem.lineitemclass,
          lineitemupc: currentItem.lineitemupc,
          locationinternalid: locationinternalid,
          location: location,
          lineiteminternalid: currentItem.lineiteminternalid,
          lineid: currentItem.lineid,
          binonhandavailable: currentItem.binonhandavailable,
          bininternalid: currentItem.bininternalid,
          order: tranid,
          bin: binNumber,
          item: currentItem.lineitemname,
          loggedby: username,
          subsidiary: currentSubsidiary,
          qtytopick: 0,
          shortqty: 0,
        }}
      />

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography gutterBottom>
            Current Bin: {currentItem.binnumber}
          </Typography>
          <TextField
            autoFocus={
              !Boolean(binNumber) ||
              (!isBinVerified && !isItemVerified && !isQtyVerified)
            }
            label="Bin Number"
            fullWidth
            value={binNumber}
            onChange={(e) => setBinNumber(e.target.value.toUpperCase())}
            color={binNumber && isBinVerified ? "success" : ""}
            error={Boolean(binNumber) && !isBinVerified}
            helperText={!isBinVerified && "Please enter the correct bin number"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isBinVerified ? (
                    <Icon>
                      <CheckCircle color="success" />
                    </Icon>
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {isBinVerified && (
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Current Item: {currentItem.lineitemname}
            </Typography>
            <TextField
              autoFocus={!isItemVerified && isBinVerified}
              label="Item Number"
              fullWidth
              value={itemNumber}
              onChange={(e) => setItemNumber(e.target.value.toUpperCase())}
              color={itemNumber && isItemVerified ? "success" : "error"}
              error={Boolean(itemNumber) && !isItemVerified}
              helperText={
                !isItemVerified && "Please enter the correct item number"
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isItemVerified ? (
                      <Icon>
                        <CheckCircle color="success" />
                      </Icon>
                    ) : null}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}

        {isBinVerified && isItemVerified && (
          <>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Qty To Pick: {itemQtyRemaining}
              </Typography>
              <TextField
                autoFocus={isBinVerified && isItemVerified}
                type="number"
                inputMode="numeric"
                label="Item Quantity"
                fullWidth
                value={itemQty}
                onChange={(e) => setItemQty(e.target.value)}
                error={
                  parseInt(itemQty) > itemQtyRemaining ||
                  parseInt(itemQty) <= 0 ||
                  itemQty.toString().includes(".")
                }
                helperText={
                  parseInt(itemQty) > itemQtyRemaining ||
                  parseInt(itemQty) <= 0 ||
                  itemQty.toString().includes(".")
                    ? `Value Error: Value cannot exceed remaining qty or be less than or equal to 0. Value must be an integer.`
                    : ""
                }
                inputProps={{
                  min: 1,
                  max: itemQtyRemaining,
                  step: 1,
                  pattern: /^\d*$/,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isQtyVerified ? (
                        <Icon>
                          <CheckCircle color="success" />
                        </Icon>
                      ) : null}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {(isQtyVerified ||
              (isOZBIn &&
                parseInt(itemQty) > 0 &&
                parseInt(itemQty) <= qtyToPick)) && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    if (
                      parseInt(itemQty) >= parseInt(binQtyAvailable) &&
                      !currentItem.binnumber?.includes("OZ_RECEIVING_")
                    ) {
                      setOpenBinValidationModal(true);
                    } else {
                      handlePickItem();
                    }
                  }}
                >
                  Pick Item
                </Button>
              </Grid>
            )}
          </>
        )}

        {!(
          (isOZBIn && isBinVerified) ||
          parseInt(itemQty) > itemQtyRemaining ||
          parseInt(itemQty) <= 0 ||
          itemQty.toString().includes(".") ||
          isQtyVerified ||
          (isItemVerified && !itemQty) ||
          (!isBinVerified && currentindex === path.length - 1)
        ) && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => setOpen(true)}
              disabled={
                parseInt(itemQty) > itemQtyRemaining ||
                parseInt(itemQty) <= 0 ||
                isQtyVerified ||
                (isItemVerified && !itemQty)
              }
            >
              {isBinVerified && isItemVerified && !isQtyVerified
                ? "Pick Short"
                : "Skip"}
            </Button>
          </Grid>
        )}

        {isOZBIn && isBinVerified && !isItemVerified && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => setOpen(true)}
            >
              SKIP
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
export default PickOrderCurrentView;
