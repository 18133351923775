import { useState, useEffect, useRef } from "react";
import { TextField, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addItem } from "../redux/features/tote/toteSlice";

import InfoModal from "./InfoModal";
import ItemAlert from "../components/ItemAlert";
import { removeLocalStorage } from "../utils/logout/logout";
import { handleErrorBeep } from "../utils/helper-functions/general/errors";

const ItemScan = ({
  openModal,
  setOpenModal,
  modalText,
  setModalText,
  scrollToBottom,
}) => {
  const [itemInfo, setItemInfo] = useState("");
  const [itemQty, setItemQty] = useState(1);
  const [itemError, setItemError] = useState(false);
  const [qtyError, setQtyError] = useState(false);
  const [alertInfo, setAlertInfo] = useState(null);
  const itemInputRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tote = useSelector((state) => state.tote);
  const { rolelevel, currentSubsidiary } = useSelector((state) => state.user);

  useEffect(() => {
    if (tote._id !== "" && itemError === false) {
      itemInputRef.current.focus();
      itemInputRef.current.inputMode = "none";
    } else {
      itemInputRef.current.blur();
    }
  }, [tote, itemError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (itemInfo == "") {
        handleErrorBeep();
        setItemError(true);
        setItemQty(1);
        setOpenModal(true);
        setModalText({
          title: "Item Field Cannot Be Empty",
          description: "Please Provide an Item",
        });
        return;
      }

      if (itemQty <= 0) {
        handleErrorBeep();
        setQtyError(true);
        setOpenModal(true);
        setModalText({
          title: "Invalid Quantity",
          description: "Quantity Must Be At Least 1",
        });
        return;
      }

      if (itemQty.toString().includes(".")) {
        handleErrorBeep();
        setQtyError(true);
        setOpenModal(true);
        setModalText({
          title: "Invalid Quantity",
          description: "Quantity Must Be A Whole Number",
        });
        return;
      }

      const data = await dispatch(
        addItem({
          itemInfo,
          itemQty: parseInt(itemQty),
          memo: tote.memo,
          subsidiary: currentSubsidiary,
        })
      ).unwrap();

      if (!data.found) {
        scrollToBottom();
      }

      setAlertInfo({ item: data.returnItem, qty: itemQty });
      setItemInfo("");
      setItemQty(1);
      setQtyError(false);
      setItemError(false);
    } catch (error) {
      if (error === "Unauthorized, Please Log In") {
        removeLocalStorage();
        navigate("/");
        return;
      }
      handleErrorBeep();
      setItemError(true);
      setModalText({
        title: "Item Error",
        description: error,
      });
      setOpenModal(true);
      setAlertInfo(null);
    }
  };

  return (
    <>
      <ItemAlert alertInfo={alertInfo} variant="success" />

      <Box sx={{ paddingY: 2 }}>
        <InfoModal
          modalText={modalText}
          open={openModal}
          setOpen={setOpenModal}
        />
        <Box
          component="form"
          onSubmit={handleSubmit}
          type="submit"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: { xs: 1, md: 3 },
          }}
        >
          <TextField
            error={qtyError}
            disabled={
              tote.status === "active" ||
              (tote.status !== "active" && rolelevel != null && rolelevel <= 3)
                ? false
                : true
            }
            id="standard-basic"
            type="number"
            min="1"
            inputMode="numeric"
            label="QTY"
            variant="standard"
            size="small"
            value={itemQty}
            onChange={(e) => setItemQty(e.target.value)}
            onFocus={() => setQtyError(false)}
            required
            sx={{ width: { xs: "40px", md: "50px" } }}
          />

          <TextField
            inputRef={itemInputRef}
            id="outlined-basic"
            error={itemError}
            disabled={
              tote.status === "active" ||
              (tote.status !== "active" && rolelevel != null && rolelevel <= 3)
                ? false
                : true
            }
            type="text"
            label="Item"
            variant="outlined"
            size="small"
            value={itemInfo}
            autoFocus
            onChange={(e) => setItemInfo(e.target.value.toUpperCase())}
            onFocus={() => setItemError(false)}
            onClick={() => (itemInputRef.current.inputMode = "text")}
            required
            sx={{ flex: 1 }}
          />
          <Button
            variant="contained"
            disabled={
              tote.status === "active" ||
              (tote.status !== "active" && rolelevel != null && rolelevel <= 3)
                ? false
                : true
            }
            type="submit"
            color="success"
            onClick={handleSubmit}
          >
            Enter
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default ItemScan;
