import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Info } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import GeneralModalV3 from "../../general/GeneralModalV3";
import FlexWrapper from "../../FlexWrapper";
import { updateToteInformation } from "../../../redux/features/tote/toteSlice";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";

const ToteManagerInfoModal = () => {
  const tote = useSelector((state) => state.tote);
  const dispatch = useDispatch();
  const { openAlert } = useGeneralAlert();

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState({
    memo: tote.memo,
    tranref: tote.tranref,
    container: tote.container,
    palletnumber: tote.palletnumber,
    palletdimensions: tote.palletdimensions,
    palletweight: tote.palletweight,
  });

  const handleEditClick = () => {
    setInputValue({
      memo: tote.memo,
      tranref: tote.tranref,
      container: tote.container,
      palletnumber: tote.palletnumber,
      palletdimensions: tote.palletdimensions,
      palletweight: tote.palletweight,
    });
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setInputValue({
      memo: tote.memo,
      tranref: tote.tranref,
      container: tote.container,
      palletnumber: tote.palletnumber,
      palletdimensions: tote.palletdimensions,
      palletweight: tote.palletweight,
    });
    setIsEditing(false);
  };

  const handleEdit = async () => {
    try {
      await dispatch(
        updateToteInformation({ ...inputValue, _id: tote._id })
      ).unwrap();

      openAlert({
        type: "success",
        message: "Successfully Updated Fields",
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Update: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 6000,
      });
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <GeneralModalV3
      openComponent={
        <IconButton>
          <Info color="primary" />
        </IconButton>
      }
      height="50vh"
      onClose={handleCancelClick}
      keepMounted={false}
    >
      <Stack
        spacing={3}
        sx={{
          overflowY: "scroll",
          p: 1,
          maxHeight: "70vh",
          overflowY: "scroll",
        }}
      >
        {Object.keys(inputValue).map((key, index) => (
          <Box width="100%" key={key}>
            <FlexWrapper justifyContent="space-between" alignItems="center">
              <Typography textTransform="capitalize">
                <strong>
                  {key.includes("pallet")
                    ? key.replace("pallet", "Pallet ")
                    : key}
                  :
                </strong>
              </Typography>
            </FlexWrapper>
            <Typography
              sx={{
                width: "100%",
                wordBreak: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {!isEditing && tote[key]}
            </Typography>
            {isEditing &&
              (key === "memo" ? (
                <Select
                  size="small"
                  name="memo"
                  fullWidth
                  value={inputValue[key]}
                  onChange={(event) =>
                    setInputValue({
                      ...inputValue,
                      [event.target.name]: event.target.value,
                    })
                  }
                >
                  <MenuItem value="TrakMotive-CA">TrakMotive-CA</MenuItem>
                  <MenuItem value="TrakMotive-NC">TrakMotive-NC</MenuItem>
                  <MenuItem value="NAPA-CA">NAPA-CA</MenuItem>
                  <MenuItem value="O'Reilly-CA">O'Reilly-CA</MenuItem>
                  <MenuItem value="AutoZone-NC">AutoZone-NC</MenuItem>
                  <MenuItem value="Scrap">Scrap</MenuItem>
                  <MenuItem value="Return To Vendor">Return To Vendor</MenuItem>
                  <MenuItem value="Manual-Adjust">Manual-Adjust</MenuItem>
                  <MenuItem value="Return To Vendor-NWI">
                    Return To Vendor-NWI
                  </MenuItem>
                </Select>
              ) : (
                <TextField
                  multiline={key === "tranref"}
                  fullWidth
                  type={
                    key === "palletweight" || key === "palletnumber"
                      ? "number"
                      : "text"
                  }
                  inputMode={
                    key === "palletweight" || key === "palletnumber"
                      ? "numeric"
                      : "text"
                  }
                  name={key}
                  value={inputValue[key]}
                  onChange={(event) =>
                    setInputValue({
                      ...inputValue,
                      [event.target.name]: event.target.value,
                    })
                  }
                  size="small"
                />
              ))}
          </Box>
        ))}
        {isEditing && (
          <FlexWrapper>
            <Button variant="contained" onClick={handleEdit} color="success">
              Save
            </Button>
            <Button variant="text" onClick={handleCancelClick}>
              Cancel
            </Button>
          </FlexWrapper>
        )}
      </Stack>

      {!isEditing && (
        <Box px={1} py={2}>
          <Button fullWidth variant="contained" onClick={handleEditClick}>
            Edit
          </Button>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default ToteManagerInfoModal;
