import { useState } from "react";
import { Public } from "@mui/icons-material";
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Box,
  Typography,
} from "@mui/material";

import LoopIcon from "@mui/icons-material/Loop";
import FactoryIcon from "@mui/icons-material/Factory";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PaidIcon from "@mui/icons-material/Paid";
import ModeIcon from "@mui/icons-material/Mode";
import HandshakeIcon from "@mui/icons-material/Handshake";
import WorkIcon from "@mui/icons-material/Work";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

const actionsIconMap = {
  WAREHOUSE: <FactoryIcon />,
  PRODUCT: <AssignmentIndIcon />,
  SALES: <PaidIcon />,
  PROCUREMENT: <QueryStatsIcon />,
  CUSTOMER: <HandshakeIcon />,
  "SALES-REP": <WorkIcon />,
  RETURNS: <SwapHorizontalCircleIcon />,
  "REVERSE-LOGISTICS": <ManageHistoryIcon />,
  "CUSTOMER-SERVICE": <SupportAgentIcon />,
  NINGBO: <Public />,
};

const SpeedDialSelector = ({ actions, iconFn }) => {
  const [open, setOpen] = useState(false);
  return (
    <Box>
      <SpeedDial
        sx={{ position: "absolute", bottom: 0, right: 0 }}
        ariaLabel="SpeedDial System Change"
        icon={<SpeedDialIcon icon={<LoopIcon />} openIcon={<ModeIcon />} />}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        {actions.map((action) => (
          <SpeedDialAction
            sx={{
              whiteSpace: "nowrap", // Prevent text from wrapping
            }}
            key={action.replace("-", " ")}
            icon={actionsIconMap[action]}
            tooltipTitle={
              <Typography variant="body1">
                {action.replace("-", " ")}
              </Typography>
            }
            tooltipOpen={true}
            onClick={() => {
              iconFn(action);
              setOpen(false);
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default SpeedDialSelector;
