import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";

import GeneralModalV3 from "../../../general/GeneralModalV3";

const ReviewCycleCountsViewColumnCounts = ({ params }) => {
  const sortedCounts = [...params.value].reverse();

  return (
    <GeneralModalV3
      keepMounted={false}
      openComponent={<Button variant="text">{params.value?.length}</Button>}
      bgcolor="#F8F8F8"
    >
      <Container
        maxWidth="md"
        sx={{
          py: 2,
          maxHeight: "60vh",
          overflowY: "scroll",
        }}
      >
        <Stack>
          {sortedCounts?.length ? (
            sortedCounts.map((count, index) => (
              <Accordion defaultExpanded={index === 0} key={count._id}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6" color="black" fontWeight="bold">
                    {`Count ${sortedCounts?.length - index}`} -{" "}
                    {new Date(count.createdAt).toLocaleString()} (
                    {count.countedby.username})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Card variant="outlined" sx={{ mb: 1 }}>
                    <CardContent>
                      <Typography variant="h6">
                        NetSuite Qty: {count.nsqty}
                      </Typography>
                      <Typography variant="h6">
                        Pick in Progress: {count.pickinprogress}
                      </Typography>
                      <Typography variant="h6">
                        Moves in Progress: {count.movesinprogress}
                      </Typography>
                      <Typography variant="h6">
                        Total Virtual Qty: {count.virtualqty}
                      </Typography>
                      <Typography variant="h6">
                        Physical Count: {count.physicalcount}
                      </Typography>
                      <Typography variant="h6">
                        Variance: {count.variance}
                      </Typography>
                    </CardContent>
                  </Card>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography
              variant="h5"
              textAlign="center"
              fontWeight="bold"
              py={2}
            >
              No Counts To Display
            </Typography>
          )}
        </Stack>
      </Container>
    </GeneralModalV3>
  );
};
export default ReviewCycleCountsViewColumnCounts;
