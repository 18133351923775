import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editItemQty, deleteItem } from "../redux/features/tote/toteSlice";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { removeLocalStorage } from "../utils/logout/logout";

function ActionModal({ open, setOpen, modalInfo }) {
  const [inputState, setInputState] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tote = useSelector((state) => state.tote);

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (inputState == "" || parseInt(inputState) != inputState) {
        setError("Not A Valid Input");
        return;
      }
      if (parseInt(inputState) <= 0) {
        setError("Input Must Be Greater Than 0");
      } else {
        const updatedQty = parseInt(inputState);
        await dispatch(
          editItemQty({
            updatedQty,
            item: modalInfo.item,
            action: modalInfo.action,
            originalQty: modalInfo.originalQty,
          })
        ).unwrap();
        setError("");
        setInputState("");
        handleClose();
      }
    } catch (error) {
      if (error === "Unauthorized, Please Log In") {
        removeLocalStorage();
        navigate("/");
      }
      setError(error);
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      await dispatch(
        deleteItem({
          updatedQty: null,
          item: modalInfo.item,
          action: modalInfo.action,
          originalQty: modalInfo.originalQty,
        })
      ).unwrap();
      setError("");
      setInputState("");
      handleClose();
    } catch (error) {
      if (error === "Unauthorized, Please Log In") {
        removeLocalStorage();
        navigate("/");
      }
    }
  };

  const handleClose = () => {
    setError("");
    setInputState("");
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{modalInfo.title}</DialogTitle>
      <DialogContent>
        {modalInfo.action === "edit" ? (
          <Box component="form" type="submit" onSubmit={handleEditSubmit}>
            <TextField
              helperText={error}
              error={error.length > 0}
              value={inputState}
              type="number"
              inputMode="numeric"
              onChange={(e) => setInputState(e.target.value)}
            />
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        {modalInfo.action === "edit" ? (
          <Button onClick={handleEditSubmit}>Enter</Button>
        ) : (
          <Button onClick={handleDeleteSubmit}>Yes</Button>
        )}
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
export default ActionModal;
