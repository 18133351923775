import { useState } from "react";
import { Menu, MenuItem, Typography, Tooltip, IconButton } from "@mui/material";
import { CSVLink } from "react-csv";
import { exports } from "../utils/exports/exportData";
import { useCSV } from "../hooks/useCSV";
import { useSelector } from "react-redux";

import FileOpenIcon from "@mui/icons-material/FileOpen";

const ExportCSVS = ({ type, arrayToExport }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [exportData, exportFunctions] = useCSV(arrayToExport);
  const { toteid } = useSelector((state) => state.tote);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportClick = (event, name) => {
    const {
      getTOExportArr,
      getExportArr,
      getIADJExportArr,
      getINVTRANExportArr,
      getRMAExportArr,
    } = exportFunctions;

    switch (name) {
      case "TO":
        getTOExportArr();
        break;
      case "IADJ":
        getIADJExportArr();
        break;
      case "INVTRAN":
        getINVTRANExportArr();
        break;
      case "RMA":
        getRMAExportArr();
        break;
      default:
        getExportArr();
    }
    handleClose();
  };

  return (
    <div>
      <MenuItem
        sx={{
          display: { xs: "none", md: "block" },
          p: 0,
        }}
      >
        {type === "button" ? (
          <Typography
            onClick={handleClick}
            id="csv-button"
            aria-controls={open ? "csv-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{ p: 1 }}
          >
            EXPORTS
          </Typography>
        ) : (
          <Tooltip title="Export">
            <IconButton
              onClick={handleClick}
              id="csv-button"
              aria-controls={open ? "csv-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <FileOpenIcon />
            </IconButton>
          </Tooltip>
        )}
        <Menu
          id="csv-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "csv-button",
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {exports.map((exportType) => {
            return (
              <CSVLink
                onClick={(e) => handleExportClick(e, exportType.name)}
                filename={`${exportType.name}-${toteid}.csv`}
                key={exportType.name}
                data={exportData}
                headers={exportType.headers}
              >
                <MenuItem>
                  <Typography variant="body1" color="black">
                    {exportType.name}
                  </Typography>
                </MenuItem>
              </CSVLink>
            );
          })}
        </Menu>
      </MenuItem>
    </div>
  );
};

export default ExportCSVS;
