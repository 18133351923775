import { adminLinksData } from "../../utils/admin/adminLinksPageData";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

const CustomLinks = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Custom Links
      </Typography>

      <Box>
        {adminLinksData?.map((pageData, index) => {
          return (
            <Accordion key={pageData.header} defaultExpanded={index === 0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                id={pageData.header}
              >
                <Typography variant="h6">{pageData.header}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {pageData.links.map((link) => (
                    <ListItem key={link.title}>
                      <ListItemText
                        primary={
                          <Link href={link.to} target={link.target}>
                            {link.title}
                          </Link>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Container>
  );
};
export default CustomLinks;
