import { useSelector } from "react-redux";
import { ArrowRightAlt, OpenInNew } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Icon,
  Link,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import GeneralModalV3 from "../../general/GeneralModalV3";
import PickDiscrepanciesViewCounts from "../../warehouse/pick-discrepancies/PickDiscrepanciesViewCounts";

const BinDataCard = ({
  data,
  setBinData,
  setOriginalData,
  sortOption,
  statusFilter,
}) => {
  const { rolelevel } = useSelector((state) => state.user);
  return (
    <Card>
      <CardContent>
        <FlexWrapper justifyContent="space-between">
          <Link
            href={`https://${process.env.REACT_APP_NS_ACCOUNT_KEY}.app.netsuite.com/app/common/search/searchresults.nl?searchid=customsearch_mern_findinvtrans&IT_Item_NAME=${data.item}&Transaction_BINNUMBER=${data.bin}&style=NORMAL&IT_Item_NAMEtype=IS&Transaction_BINNUMBERtype=IS&report=&grid=&csv=HTML&OfficeXML=F&pdf=&size=300`}
            target="_blank"
          >
            <Typography variant="h6" fontWeight="bold">
              {data.bin} ({data.shorttype.toUpperCase()})
            </Typography>
          </Link>
          <Box>
            <Typography variant="h6">{data.order}</Typography>
            <Typography variant="caption" component="p">
              Logged By: {data.loggedby}
              {" - "}
              {new Date(data.createdAt).toLocaleString()}
            </Typography>
            <Typography variant="caption" component="p">
              Last Update: {new Date(data.updatedAt).toLocaleString()}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            color={data.status === "complete" ? "green" : ""}
          >
            {data.status?.replaceAll("-", " ")?.toUpperCase()}{" "}
            {data.status === "complete" &&
            data.counts[data.counts.length - 1]?.autoapprove
              ? `(Auto Approved)`
              : null}
          </Typography>
        </FlexWrapper>
        <Typography variant="body1" pt={1}>
          <strong>Item: </strong>{" "}
          <Link
            href={`https://${process.env.REACT_APP_NS_ACCOUNT_KEY}.app.netsuite.com/app/common/item/item.nl?id=${data.lineiteminternalid}`}
            target="_blank"
          >
            {data.item} ({data.lineitemclass}){" "}
          </Link>
        </Typography>{" "}
        <Typography variant="body1">
          <strong>Location: </strong> {data.location}
        </Typography>
        <Typography variant="body1">
          <strong>Bin Available: </strong> {data.binonhandavailable}
        </Typography>
        <Typography variant="body1">
          <strong>Qty To Pick: </strong> {data.qtytopick}
        </Typography>
        <Typography variant="body1">
          <strong>Short Qty: </strong> {data.shortqty}
        </Typography>
      </CardContent>
      <CardActions>
        <FlexWrapper flexDirection="column" width="100%">
          {Boolean(data.counts.length) && (
            <Box pl={1}>
              <Typography display="block">
                Number of Counts: {data.counts.length}
              </Typography>
              {data.status !== "need-recount" && (
                <Typography display="block">
                  Adjustment Needed:{" "}
                  {data.counts[data.counts.length - 1]?.adjustment}
                </Typography>
              )}
            </Box>
          )}

          {(data.status === "pending-approval" ||
            data.status === "complete") && (
            <>
              {Boolean(data.counts.length) && (
                <Box py={1}>
                  <GeneralModalV3
                    openComponent={
                      <Button
                        size="large"
                        variant="text"
                        endIcon={<ArrowRightAlt />}
                        sx={{ mb: 2 }}
                      >
                        View Counts
                      </Button>
                    }
                  >
                    {(handleClose) => (
                      <PickDiscrepanciesViewCounts
                        data={data}
                        handleClose={handleClose}
                        setBinData={setBinData}
                        setOriginalData={setOriginalData}
                        sortOption={sortOption}
                        statusFilter={statusFilter}
                      />
                    )}
                  </GeneralModalV3>

                  {data.status === "complete" &&
                    Boolean(data.adjustmentinternalid) && (
                      <Link
                        pl={1}
                        href={`https://${process.env.REACT_APP_NS_ACCOUNT_KEY}.app.netsuite.com/app/accounting/transactions/invadjst.nl?id=${data.adjustmentinternalid}`}
                        target="_blank"
                      >
                        View In NetSuite{"  "}
                        <span>
                          <Icon fontSize="small">
                            <OpenInNew fontSize="small" />
                          </Icon>
                        </span>
                      </Link>
                    )}
                </Box>
              )}
            </>
          )}

          {rolelevel === 0 ? (
            <FlexWrapper justifyContent="flex-end" width="100%">
              <Typography variant="caption" gutterBottom fontWeight="bold">
                {data._id}
              </Typography>
            </FlexWrapper>
          ) : null}
        </FlexWrapper>
      </CardActions>
    </Card>
  );
};
export default BinDataCard;
