import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeFullScreenModal } from "../redux/features/modal/modalSlice";

const FullScreenModal = ({ children }) => {
  const { isFullScreenModalOpen } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  return (
    <Dialog
      open={isFullScreenModalOpen}
      onClose={() => dispatch(closeFullScreenModal())}
      fullScreen
    >
      {children}
    </Dialog>
  );
};

export default FullScreenModal;
