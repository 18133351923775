import { useEffect, useState } from "react";
import {
  LABORCLAIMCOLUMNSPRODUCTLCRECEIVED,
  LABORCLAIMCOLUMNSPRODUCTRMA,
} from "../../utils/columns/product/productColumns";
import { LABORCLAIMCOMPLETECOLUMNS } from "../../utils/columns/returns/returnsColumns";
import { useNavigate } from "react-router-dom";
import { useSalesRepCustomerMatch } from "../../hooks/useSalesRepCustomerMatch";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Link,
  Typography,
} from "@mui/material";
import { Add, ExpandMore } from "@mui/icons-material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import axios from "../../axios/axios.config";
import Loading from "../../components/Loading";
import FlexWrapper from "../../components/FlexWrapper";

function CustomToolbar({ type }) {
  return (
    <GridToolbarContainer>
      <Typography variant="h4" gutterBottom pl={1}>
        {type}
      </Typography>
      <FlexWrapper
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <FlexWrapper gap={1} alignItems="flex-end">
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{
              fileName: `${type}-LC-${Date.now()}`,
            }}
            printOptions={{
              disableToolbarButton: true,
            }}
          />
        </FlexWrapper>
        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const LaborClaimProductPage = () => {
  const [rmaLCRows, setRMALCRows] = useState([]);
  const [lcReceivedRows, setLCReceivedRows] = useState([]);
  const [completedRows, setCompletedRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const { filteredCustomersList } = useSalesRepCustomerMatch();
  const { currentSubsidiary } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (filteredCustomersList?.length > 0) {
      getRows();
    }
  }, [filteredCustomersList]);

  const getRows = async () => {
    try {
      const filterRMAIssuedStatus = {
        type: "labor-claim",
        "form.customer": { $in: filteredCustomersList },
        "form.status": "rmaissued",
        subsidiary: currentSubsidiary,
      };
      const filterLCReceivedStatus = {
        type: "labor-claim",
        "form.customer": { $in: filteredCustomersList },
        "form.status": { $in: ["lcreceived", "lcreceived-pendingdocuments"] },
        subsidiary: currentSubsidiary,
      };
      const filterCompleteStatus = {
        type: "labor-claim",
        "form.customer": { $in: filteredCustomersList },
        "form.status": { $in: ["incomplete", "complete", "processed"] },
        subsidiary: currentSubsidiary,
      };

      const rmaIssuedResponse = await axios.post(
        `/forms/get/filtered-forms`,
        filterRMAIssuedStatus
      );

      const lcReceivedFormsResponse = await axios.post(
        `/forms/get/filtered-forms`,
        filterLCReceivedStatus
      );

      const lcCompletedFormsResponse = await axios.post(
        `/forms/get/filtered-forms`,
        filterCompleteStatus
      );

      setRMALCRows(rmaIssuedResponse.data);
      setLCReceivedRows(lcReceivedFormsResponse.data);
      setCompletedRows(lcCompletedFormsResponse.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not get labor claim forms: ${error.message}`,
          duration: 3000,
        })
      );
    }
  };

  if (loading) {
    return <Loading message="Loading Customer Labor Claim Forms" />;
  }

  return (
    <>
      <Typography variant="h3" textAlign="center" gutterBottom>
        Labor Claim Forms
      </Typography>

      <FlexWrapper flexDirection="column" alignItems="space-between">
        <DataGrid
          autoHeight={rmaLCRows.length == 0}
          sx={{ bgcolor: "white", maxHeight: "40vh", width: "100%", mb: 5 }}
          rows={rmaLCRows}
          columns={[
            {
              field: "_id",
              headerName: "ID",
              width: 250,
              sortable: false,
              renderCell: (params) => {
                return (
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`form/${params.row._id}?status=rmaissued`)
                    }
                  >
                    {params.row._id}
                  </Link>
                );
              },
            },
            ...LABORCLAIMCOLUMNSPRODUCTRMA,
          ]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
              sortModel: [
                {
                  field: "rmaissuedate",
                  sort: "asc", // 'asc' for ascending, 'desc' for descending
                },
              ],
            },
          }}
          pageSizeOptions={[100, 150, 200]}
          density="compact"
          disableColumnMenu
          getRowId={(row) => row._id}
          slots={{ toolbar: () => <CustomToolbar type="RMA ISSUED" /> }}
          disableDensitySelector
          disableColumnSelector
        />

        <DataGrid
          autoHeight={lcReceivedRows.length == 0}
          sx={{ bgcolor: "white", maxHeight: "40vh", width: "100%", mb: 5 }}
          rows={lcReceivedRows}
          columns={[
            {
              field: "_id",
              headerName: "ID",
              width: 250,
              sortable: false,
              renderCell: (params) => {
                return (
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`form/${params.row._id}?status=lcreceived`)
                    }
                  >
                    {params.row._id}
                  </Link>
                );
              },
            },
            ...LABORCLAIMCOLUMNSPRODUCTLCRECEIVED,
          ]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
              sortModel: [
                {
                  field: "datereceived",
                  sort: "asc", // 'asc' for ascending, 'desc' for descending
                },
              ],
            },
          }}
          pageSizeOptions={[100, 150, 200]}
          density="compact"
          disableColumnMenu
          getRowId={(row) => row._id}
          slots={{ toolbar: () => <CustomToolbar type="LC RECEIVED" /> }}
          disableDensitySelector
          disableColumnSelector
        />

        <Accordion elevation={1} sx={{ width: "100%" }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h4">COMPLETE & PROCESSED</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataGrid
              autoHeight={completedRows.length == 0}
              sx={{ bgcolor: "white", maxHeight: "40vh", width: "100%", mb: 5 }}
              rows={completedRows}
              columns={LABORCLAIMCOMPLETECOLUMNS}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
                sorting: {
                  sortModel: [
                    {
                      field: "completeddate",
                      sort: "desc", // 'asc' for ascending, 'desc' for descending
                    },
                  ],
                },
              }}
              pageSizeOptions={[100, 150, 200]}
              density="compact"
              disableColumnMenu
              getRowId={(row) => row._id}
              slots={{ toolbar: () => <CustomToolbar type="" /> }}
              disableDensitySelector
              disableColumnSelector
            />
          </AccordionDetails>
        </Accordion>
      </FlexWrapper>
    </>
  );
};
export default LaborClaimProductPage;
