import { useState } from "react";
import { Link } from "react-router-dom";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Divider,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const NestedMenuListItem = ({
  icon,
  title,
  description,
  submenus,
  index,
  lastIndex,
  rolelevel,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <ListItemButton
        onClick={() => setOpen(!open)}
        sx={{ "&:hover": { background: "initial" } }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} secondary={description} sx={{ pl: 2 }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {submenus.map((menu) => {
            return (
              (!menu.admin || menu.admin(rolelevel)) && (
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to={menu.to}
                  target={menu.target ? menu.target : ""}
                  key={menu.title}
                >
                  <ListItemButton sx={{ pl: 13 }}>
                    <ListItemIcon>
                      <ArrowRightAltIcon />
                    </ListItemIcon>
                    <ListItemText primary={menu.title} />
                  </ListItemButton>
                </Link>
              )
            );
          })}
        </List>
      </Collapse>
      <Divider
        variant="fullWidth"
        component="li"
        sx={{ display: lastIndex === index ? "none" : "block" }}
      />
    </div>
  );
};

export default NestedMenuListItem;
