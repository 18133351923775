import { Box, Divider, Typography } from "@mui/material";

const CreatedByLC = ({ createdby }) => {
  return (
    <Box p={2}>
      {createdby ? (
        <>
          <Typography variant="h4" textAlign="center">
            Labor Claim Created By
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography>
            <strong>Name:</strong> {createdby?.name}
          </Typography>
          <Typography>
            <strong>Username:</strong> {createdby?.username?.toUpperCase()}
          </Typography>
          <Typography>
            <strong>Email:</strong> {createdby?.email?.toUpperCase()}
          </Typography>
          <Typography>
            <strong>Department:</strong> {createdby?.department}
          </Typography>
          <Typography>
            <strong>User Type:</strong> {createdby?.usertype?.toUpperCase()}
          </Typography>
        </>
      ) : (
        <Typography variant="h5" textAlign="center">
          Created By Data Not Available
        </Typography>
      )}
    </Box>
  );
};
export default CreatedByLC;
