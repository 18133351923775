import { useState } from "react";
import { useSelector } from "react-redux";

export const useMultiCSVS = (selected) => {
  const [exportData, setExportData] = useState([]);
  const { totes } = useSelector((state) => state.batches);

  const getFormattedArr = (e, name) => {
    switch (name) {
      case "TO":
        getMultiTOExportArr();
        break;
      case "IADJ":
        getMultiIADJExportArr();
        break;
      case "INVTRAN":
        getMultiINVTRANExportArr();
        break;
      case "RMA":
        getMultiRMAExportArr();
        break;
      default:
        getMultiExportArr();
    }
  };

  const getMultiExportArr = () => {
    const exportArr = [];

    if (totes) {
      const fullSelectedArr = totes.filter((tote) =>
        selected.some((toteid) => tote.toteid === toteid)
      );

      for (let tote of fullSelectedArr) {
        const {
          toteid,
          createdby,
          lastupdatedby,
          tranref,
          container,
          palletweight,
          palletdimensions,
          palletnumber,
          memo,
          subsidiary,
          location,
          createdAt,
          updatedAt,
          items,
        } = tote;

        items.map((item) => {
          let interchangeVal = item.interchange
            ? item.interchange.interchange
            : "";
          const exportItem = {
            ...item,
            toteid,
            createdby,
            lastupdatedby,
            tranref,
            container,
            palletweight,
            palletdimensions,
            palletnumber,
            memo,
            subsidiary,
            interchange: interchangeVal,
            location,
            createdat: new Date(createdAt).toLocaleDateString(),
            updatedat: new Date(updatedAt).toLocaleDateString(),
          };
          exportArr.push(exportItem);
        });
      }
      setExportData(exportArr);
    }
  };

  const getMultiTOExportArr = () => {
    const memoLocationMap = {
      "TrakMotive-CA": "AIT CA : RECEIVING MAIN CA",
      "TrakMotive-NC": "AIT NC : RALEIGH NC",
      "NAPA-CA": "AIT CA : RECEIVING MAIN CA",
      "O'Reilly-CA": "AIT CA : RECEIVING O'REILLY CA",
      "AutoZone-NC": "AIT NC : RALEIGH NC",
    };

    const memoPackagingMap = {
      "TrakMotive-NC": "TRK",
      "AutoZone-NC": "AZO",
    };

    const TOExportArr = [];

    if (totes) {
      const fullSelectedArr = totes.filter((tote) =>
        selected.some((toteid) => tote.toteid === toteid)
      );

      for (let tote of fullSelectedArr) {
        const { toteid, memo, location, items, tranref } = tote;

        items.map((item) => {
          let interchangeVal = item.interchange
            ? item.interchange.interchange
            : "";

          const TOExportItem = {
            externalid: `TO-${toteid}`,
            date: new Date().toLocaleDateString(),
            fromlocation: location === "NEV" ? "RETURNS" : location,
            tolocation: memoLocationMap[memo] ? memoLocationMap[memo] : "",
            item: item.item,
            qty: item.qty,
            interchange: interchangeVal,
            incoterm: "DAP",
            satus: "PENDING FULFILLMENT",
            memo: memo,
            prioritystatus: "OVERSTOCK",
            notes: `${toteid}${tranref ? ` | ${tranref}` : ""}`,
            trandtransactionpackaging: memoPackagingMap[memo]
              ? memoPackagingMap[memo]
              : "",
          };

          TOExportArr.push(TOExportItem);
        });
      }
      setExportData(TOExportArr);
    }
  };

  const getMultiIADJExportArr = () => {
    const IADJExportArr = [];

    if (totes) {
      const fullSelectedArr = totes.filter((tote) =>
        selected.some((toteid) => tote.toteid === toteid)
      );

      for (let tote of fullSelectedArr) {
        const { toteid, location, items } = tote;

        items.map((item) => {
          const IADJExportItem = {
            externalid: `IADJ-${toteid}`,
            date: new Date().toLocaleDateString(),
            adjustmentaccount: "59999 Inventory Adjustment",
            location: location === "NEV" ? "AIT CA : QC CA" : location,
            memo: "STOCK ADDS FOR TOTELABEL",
            inventoryadjustmentlocation:
              location === "NEV" ? "AIT CA : QC CA" : location,
            item: item.item,
            qty: item.qty,
            binnumbers: location === "NEV" ? "OZ_RECEIVING_21" : "",
          };

          IADJExportArr.push(IADJExportItem);
        });
      }

      setExportData(IADJExportArr);
    }
  };

  const getMultiINVTRANExportArr = () => {
    const INVTRANEExportArr = [];

    if (totes) {
      const fullSelectedArr = totes.filter((tote) =>
        selected.some((toteid) => tote.toteid === toteid)
      );

      for (let tote of fullSelectedArr) {
        const { toteid, location, items } = tote;

        items.map((item) => {
          const INVTRANExportItem = {
            externalid: `INVTRAN-${toteid}`,
            date: new Date().toLocaleDateString(),
            fromlocation: location === "NEV" ? "AIT CA : QC CA" : location,
            tolocation: location === "NEV" ? "RETURNS" : location,
            item: item.item,
            qty: item.qty,
            frombins: location === "NEV" ? "OZ_RECEIVING_21" : "",
            tobins: location === "NEV" ? "RAIT" : "",
            binnumbers: location === "NEV" ? "RAIT" : "",
          };

          INVTRANEExportArr.push(INVTRANExportItem);
        });
      }
      setExportData(INVTRANEExportArr);
    }
  };

  const getMultiRMAExportArr = () => {
    const RMAExportArr = [];
    const customerMap = {
      "AutoZone-NC": "AUTOZONE, INC. : AUTOZONE DO",
      " O’Reilly-CA": "O'REILLY AUTOMOTIVE LLC",
    };

    if (totes) {
      const fullSelectedArr = totes.filter((tote) =>
        selected.some((toteid) => tote.toteid === toteid)
      );

      for (let tote of fullSelectedArr) {
        const { toteid, tranref, memo, items } = tote;

        items.map((item) => {
          const RMAExportItem = {
            externalid: `RMA-${toteid}`,
            date: new Date().toLocaleDateString(),
            customer: customerMap[memo] ? customerMap[memo] : "",
            location:
              memo === "AutoZone-NC" ? "AIT NC : RALEIGH NC" : "AIT CA : QC CA",
            status: "Pending Receipt",
            ponum: `${memo} | ${new Date().toLocaleDateString()} | ${tranref}`,
            rmatype: "SA (Stock Adjustment)",
            rmanotes: "Manually Processed RMA",
            item: item.item,
            qty: item.qty,
            pricelevel: "Custom",
            unitprice: 0,
            discount: "0% Discount",
          };

          RMAExportArr.push(RMAExportItem);
        });
      }
      setExportData(RMAExportArr);
    }
  };

  return [exportData, getFormattedArr];
};
