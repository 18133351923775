import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { ExpandMore, Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../components/FlexWrapper";
import InventoryMoveAdminDeleteMoveModal from "../../../components/multi-page/inventory-move-admin/InventoryMoveAdminDeleteMoveModal";
import Loading from "../../../components/Loading";

const InventoryAdminPage = () => {
  const data = useLoaderData();
  const { rolelevel } = useSelector((state) => state.user);

  const [inventoryMoves, setInventoryMoves] = useState(data);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");

  if (!inventoryMoves || !inventoryMoves.length) {
    return (
      <Typography variant="h4" textAlign="center" pt={2}>
        No Inventory Moves In Progress
      </Typography>
    );
  }

  if (loading) return <Loading message={loading} />;

  const filteredMoves = search
    ? inventoryMoves.filter(
        (order) =>
          order.sourcebin?.binnumber?.includes(search) ||
          order.item?.toUpperCase().includes(search) ||
          order.username?.toUpperCase().includes(search)
      )
    : inventoryMoves;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Inventory Moves In Progress
      </Typography>

      <Grid container alignItems="center">
        <Grid item xs={12} md={6} pb={1}>
          <TextField
            fullWidth
            autoFocus
            size="small"
            label="Search"
            value={search}
            onChange={(event) => setSearch(event.target.value.toUpperCase())}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} pb={1}>
          <Typography textAlign="right">
            Total: {inventoryMoves?.length}
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ height: "75vh", overflow: "scroll", py: 1 }}>
        <Stack spacing={2} flexWrap="wrap" alignItems="center">
          {filteredMoves.length ? (
            filteredMoves.map((move) => (
              <Card variant="outlined" key={move._id} sx={{ width: "100%" }}>
                <CardContent>
                  <Stack>
                    <FlexWrapper justifyContent="space-between">
                      <Box>
                        {rolelevel === 0 ? (
                          <FlexWrapper alignItems="baseline" gap={1}>
                            <Typography variant="caption">
                              {move._id}
                            </Typography>
                            <InventoryMoveAdminDeleteMoveModal
                              move={move}
                              setLoading={setLoading}
                              setInventoryMoves={setInventoryMoves}
                            />
                          </FlexWrapper>
                        ) : null}
                      </Box>
                      <Box>
                        <Typography
                          variant="h6"
                          gutterBottom
                          textAlign="center"
                          fontWeight="bold"
                        >
                          <Link
                            href={`https://${process.env.REACT_APP_NS_ACCOUNT_KEY}.app.netsuite.com/app/common/search/searchresults.nl?searchid=customsearch_mern_findinvtrans&IT_Item_NAME=${move.item}&Transaction_BINNUMBER=${move.sourcebin.binnumber}&style=NORMAL&IT_Item_NAMEtype=IS&Transaction_BINNUMBERtype=IS&report=&grid=&csv=HTML&OfficeXML=F&pdf=&size=300`}
                            target="_blank"
                          >
                            {move.sourcebin.binnumber}
                          </Link>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          textAlign="center"
                        >
                          <Link
                            href={`https://${process.env.REACT_APP_NS_ACCOUNT_KEY}.app.netsuite.com/app/common/item/item.nl?id=${move.iteminternalid}`}
                            target="_blank"
                          >
                            {move.item}
                          </Link>{" "}
                          - {move.itemclass}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="caption"
                          gutterBottom
                          textAlign="right"
                        >
                          {move.status?.toUpperCase()}
                        </Typography>
                      </Box>
                    </FlexWrapper>

                    <Typography variant="body1">
                      <strong>Started By:</strong> {move.username}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Qty To Move:</strong> {move.qtytomove}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Created At:</strong>{" "}
                      {new Date(move.createdAt)?.toLocaleString()}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Last Update:</strong>{" "}
                      {new Date(move.updatedAt)?.toLocaleString()}
                    </Typography>
                    <Accordion elevation={0} defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Source Bin Details</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Divider />
                        <Box pt={2}>
                          <FlexWrapper
                            gap={2}
                            width="100%"
                            justifyContent="center"
                          >
                            <Typography variant="body1">
                              <strong>Available:</strong>{" "}
                              {move.sourcebin.available}
                            </Typography>
                            <Typography variant="body1">
                              <strong>Bin Brand: </strong>
                              {move.sourcebin.binbrand}
                            </Typography>
                            <Typography variant="body1">
                              <strong>Bin Location: </strong>
                              {move.sourcebin.binlocation}
                            </Typography>
                            <Typography variant="body1">
                              <strong>Bin Box Size Type: </strong>
                              {move.sourcebin.binboxsizetype}
                            </Typography>
                            <Typography variant="body1">
                              <strong>Item Box Size Type: </strong>
                              {move.sourcebin.itemboxsizetype}
                            </Typography>
                          </FlexWrapper>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography variant="h6" textAlign="center" pt={2}>
              No Inventory Moves In Progress
            </Typography>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default InventoryAdminPage;
