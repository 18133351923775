import { Fragment, useState } from "react";
import { useSalesRepCustomerMatch } from "../../hooks/useSalesRepCustomerMatch";
import { Add, ExitToApp, Remove } from "@mui/icons-material";
import { resizeFile } from "../../utils/files/resizeFile";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import GeneralFileUpload from "../../components/GeneralFileUpload";
import axios from "../../axios/axios.config";
import Loading from "../../components/Loading";
import FlexWrapper from "../../components/FlexWrapper";

const LaborClaimAddMultiPage = () => {
  const [numOfParts, setNumOfParts] = useState(1);
  const [partNumbers, setPartNumbers] = useState([
    { partnumber: "", qty: 1, key: Date.now() },
  ]);
  const [files, setFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [formInput, setFormInput] = useState({
    date: new Date().toLocaleDateString(),
    customer: "",
    rgaNum: "",
    serviceFacilityCompany: "",
    serviceFacilityContactName: "",
    serviceFacilityPhone: "",
    serviceFacilityEmail: "",
    serviceFacilityAddress: "",
    serviceFacilityVehicleOwner: "",
    technicianName: "",
    warehouseCompany: "",
    warehouseContactName: "",
    warehousePhone: "",
    warehouseEmail: "",
    warehouseAddress: "",
    vinNum: "",
    vehicleYear: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleEngineSize: "",
    vehicleEngineType: "",
    vehicleDrive: "",
    vehicleGasType: [],
    manufacturer: "",
    mileageInstallation: "",
    mileageFailure: "",
    dateInstallation: "",
    dateFailure: "",
    failureDescription: "",
    laborCost: "",
    laborHours: "",
    partsCost: "",
    laborRate: 35,
    totalClaim: "",
    comments: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { filteredCustomers } = useSalesRepCustomerMatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      //check to make sure all files are present
      if (files.length === 0) {
        throw new Error(
          "Please check the attatchment list and ensure files are submitted."
        );
      }

      //check phone numbers
      const regex = new RegExp(/[e\-\.].{9,}/);

      if (regex.test(formInput.serviceFacilityPhone)) {
        throw new Error("Not a valid service facility phone number");
      }

      if (regex.test(formInput.warehousePhone)) {
        throw new Error("Not a valid warehouse phone number");
      }

      if (formInput.vinNum.length !== 17) {
        throw new Error("Invalid Vin");
      }

      const installDate = new Date(formInput.dateInstallation);
      const failureDate = new Date(formInput.dateFailure);
      const todayDate = new Date();

      const mileageStart = parseInt(formInput.mileageInstallation);
      const mileageEnd = parseInt(formInput.mileageFailure);

      if (mileageStart === 0 || mileageEnd === 0 || mileageStart > mileageEnd) {
        throw new Error("Invalid mileage entries");
      }

      if (installDate > failureDate || installDate > todayDate) {
        throw new Error("Invalid install date");
      }
      if (failureDate > todayDate) {
        throw new Error("Invalid failure date");
      }

      //create new LC
      const postData = {
        form: {
          ...formInput,
          status: "new",
          rmaNum: "",
          file: files.map((file) => file.name),
          partNumbers: partNumbers.map((part) => {
            return { partnumber: part.partnumber, qty: part.qty };
          }),
        },
        createdby: {
          name: `${user.firstname} ${user.lastname}`,
          username: user.username,
          department: user.currentDepartment,
          usertype: user.usertype,
          email: user.email,
        },
        subsidiary: user.currentSubsidiary,
        type: "labor-claim",
      };

      const newFormResponse = await axios.post(
        "forms/create/labor-claim",
        postData
      );

      //save files to S3 using mongoid
      const formData = new FormData();

      files.forEach((file) => formData.append("files", file));
      formData.append(
        "folder",
        `forms/laborclaim/${newFormResponse.data._id}/`
      );

      await axios.post("/files/upload/multi", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully created new labor claim: ${newFormResponse.data._id} `,
          duration: 3000,
        })
      );
      //go back to main view
      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${error.response?.data?.msg || error.message}`,
          duration: 8000,
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleSaveFiles = async (dropzoneFiles) => {
    const finalFiles = [];

    for (const file of dropzoneFiles) {
      let fileToUpload = file;

      if (file.type.includes("image")) {
        //resize image
        fileToUpload = await resizeFile({
          file: file,
          maxWidth: 2500,
          maxHeight: 2500,
          compressFormat: "JPEG",
          quality: 80,
          outputType: "file",
        });
      }

      finalFiles.push(fileToUpload);
    }

    setFiles([...files, ...finalFiles]);
  };

  const handleRemoveFile = (file) => {
    setFiles(files.filter((f) => f.path !== file.path));
  };

  const handleChanges = (event) => {
    setFormInput({
      ...formInput,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  const handleSumChanges = (event) => {
    let totalClaim = "";

    if (event.target.name === "laborHours") {
      const partsCost = parseFloat(formInput.partsCost) || "";
      const laborHours = parseInt(event.target.value) || "";
      const laborCost = laborHours ? laborHours * formInput.laborRate : "";

      if (laborCost && partsCost) {
        totalClaim = parseFloat(laborCost + partsCost).toFixed(2);
      }

      setFormInput({
        ...formInput,
        laborHours,
        laborCost: laborCost,
        totalClaim,
      });
    } else {
      const partsCost = parseFloat(event.target.value) || "";

      if (formInput.laborCost && partsCost) {
        totalClaim = parseFloat(formInput.laborCost + partsCost).toFixed(2);
      }

      setFormInput({
        ...formInput,
        partsCost,
        totalClaim,
      });
    }
  };

  const handleMultipleSelectChanges = (event) => {
    // On autofill we get a stringified value.
    setFormInput({
      ...formInput,
      vehicleGasType:
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value,
    });
  };

  const handleAddPartRows = () => {
    if (numOfParts >= 4) return;

    const newArray = [...partNumbers];
    newArray.push({ partnumber: "", qty: 1, key: Date.now() });

    setPartNumbers(newArray, "nw array");
    setNumOfParts(numOfParts + 1);
  };

  const handleRemovePartRows = () => {
    if (numOfParts <= 1) return;

    const newArray = [...partNumbers];
    newArray.pop();

    setPartNumbers(newArray);
    setNumOfParts(numOfParts - 1);
  };

  const handleChangePartValues = (event, index) => {
    const partNumbersCopy = [...partNumbers];
    partNumbersCopy[index] = {
      ...partNumbers[index],
      partnumber: event.target.value.toUpperCase(),
    };

    setPartNumbers(partNumbersCopy);
  };

  const handleChangePartQuantities = (event, index) => {
    const partNumbersCopy = [...partNumbers];
    partNumbersCopy[index] = {
      ...partNumbers[index],
      qty: parseInt(event.target.value),
    };

    setPartNumbers(partNumbersCopy);
  };

  const handleAddCustomerData = (c) => {
    setFormInput({
      ...formInput,
      customer: c.companyname,
      customerinternalid: c.internalid,
      warehouseCompany: c.companyname,
    });
  };

  if (submitting) return <Loading message="Submitting Labor Claim" />;

  return (
    <Container maxWidth="md" sx={{ pb: 2 }}>
      <Box>
        <Tooltip title="Back To Labor Claims" placement="top" arrow>
          <IconButton
            onClick={() =>
              navigate(
                `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
              )
            }
          >
            <ExitToApp color="error" />
          </IconButton>
        </Tooltip>

        <Typography variant="h3" textAlign="center" gutterBottom>
          Labor Claim Form
        </Typography>
      </Box>

      <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            label="Date"
            name="date"
            value={formInput.date}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel id="select-label-comapnies">
              {filteredCustomers.length === 0
                ? "Loading Companies..."
                : "Customer Name"}
            </InputLabel>
            <Select
              labelId="select-label-companies"
              id="select-company"
              label={
                filteredCustomers.length === 0
                  ? "Loading Companies..."
                  : "Customer Name"
              }
              disabled={filteredCustomers.length === 0}
              name="customer"
              value={formInput.customer}
            >
              {filteredCustomers.map((c) => (
                <MenuItem
                  key={c._id}
                  value={c.companyname}
                  onClick={() => handleAddCustomerData(c)}
                >
                  {c.companyname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="text"
            name="rgaNum"
            label="Customer Use Only: RGA#"
            value={formInput.rgaNum}
            onChange={handleChanges}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
            Service Facility and Vehicle Owner
          </Typography>
          <TextField
            fullWidth
            required
            type="text"
            name="serviceFacilityCompany"
            label="Company Name"
            value={formInput.serviceFacilityCompany}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="serviceFacilityContactName"
            label="Contact Name"
            value={formInput.serviceFacilityContactName}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            helperText={
              <Typography variant="caption" color="error">
                No Special Characters
              </Typography>
            }
            type="tel"
            inputMode="numeric"
            name="serviceFacilityPhone"
            label="Phone"
            value={formInput.serviceFacilityPhone}
            onChange={handleChanges}
            inputProps={{
              pattern: "[0-9]*",
              title: "Phone number must only contain numbers.",
              type: "tel",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="email"
            name="serviceFacilityEmail"
            label="E-mail"
            value={formInput.serviceFacilityEmail}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            multiline
            minRows={4}
            type="text"
            name="serviceFacilityAddress"
            label="Address"
            value={formInput.serviceFacilityAddress}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="technicianName"
            label="Technician's Name"
            value={formInput.technicianName}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="serviceFacilityVehicleOwner"
            label="Vehicle Owner’s Name"
            value={formInput.serviceFacilityVehicleOwner}
            onChange={handleChanges}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
            Selling Party
          </Typography>
          <TextField
            fullWidth
            required
            type="text"
            name="warehouseCompany"
            label="Company Name"
            value={formInput.warehouseCompany}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="warehouseContactName"
            label="Contact Name"
            value={formInput.warehouseContactName}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            helperText={
              <Typography variant="caption" color="error">
                No Special Characters
              </Typography>
            }
            type="tel"
            inputMode="numeric"
            name="warehousePhone"
            label="Phone"
            value={formInput.warehousePhone}
            onChange={handleChanges}
            inputProps={{
              pattern: "[0-9]*",
              title: "Phone number must only contain numbers.",
              type: "tel",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="email"
            name="warehouseEmail"
            label="E-mail"
            value={formInput.warehouseEmail}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            multiline
            minRows={4}
            type="text"
            name="warehouseAddress"
            label="Address"
            value={formInput.warehouseAddress}
            onChange={handleChanges}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
            Vehicle Information
          </Typography>
          <TextField
            fullWidth
            required
            type="text"
            name="vinNum"
            label="VIN"
            value={formInput.vinNum}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            type="number"
            inputMode="numeric"
            name="vehicleYear"
            label="Year"
            value={formInput.vehicleYear}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            type="text"
            name="vehicleMake"
            label="Make"
            value={formInput.vehicleMake}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            type="text"
            name="vehicleModel"
            label="Model"
            value={formInput.vehicleModel}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="text"
            name="vehicleEngineSize"
            label="Engine Size"
            value={formInput.vehicleEngineSize}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel id="select-label-type">Transmission Type</InputLabel>
            <Select
              labelId="select-label-type"
              id="select-type"
              label="Transmission Type"
              name="vehicleEngineType"
              value={formInput.vehicleEngineType}
              onChange={handleChanges}
            >
              <MenuItem value="AUTOMATIC">AUTOMATIC</MenuItem>
              <MenuItem value="MANUAL">MANUAL</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel id="select-label-drive">Vehicle Drive</InputLabel>
            <Select
              labelId="select-label-drive"
              id="select-drive"
              label="Vehicle Drive"
              name="vehicleDrive"
              value={formInput.vehicleDrive}
              onChange={handleChanges}
            >
              <MenuItem value="FRONT WHEEL DRIVE">FRONT WHEEL DRIVE</MenuItem>
              <MenuItem value="REAR WHEEL DRIVE">REAR WHEEL DRIVE</MenuItem>
              <MenuItem value="FOUR WHEEL DRIVE">FOUR WHEEL DRIVE</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel id="select-label-gas">Engine Sub Type</InputLabel>
            <Select
              multiple
              labelId="select-label-gas"
              id="select-gas"
              label="Engine Sub Type"
              name="vehicleGasType"
              value={formInput.vehicleGasType}
              onChange={handleMultipleSelectChanges}
            >
              <MenuItem value="Turbo">Turbo</MenuItem>
              <MenuItem value="Gas">Gas</MenuItem>
              <MenuItem value="Diesel">Diesel</MenuItem>
              <MenuItem value="Hybrid">Hybrid</MenuItem>
              <MenuItem value="Electric">Electric</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" mt={2}>
            Associated Parts and Labor
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="number"
            inputMode="numeric"
            name="laborHours"
            label="Total Labor Hrs"
            value={formInput.laborHours}
            onChange={handleSumChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="number"
            name="partsCost"
            label="Parts Cost"
            InputProps={{
              step: "0.01", // Set the step value to allow decimals
              min: "0", // Optional: Set a minimum value if needed
            }}
            value={formInput.partsCost}
            onChange={handleSumChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            disabled
            type="number"
            name="laborCost"
            label="Labor Cost"
            value={formInput.laborCost}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            disabled
            type="number"
            name="laborRate"
            label="Labor Rate"
            InputProps={{
              readOnly: true,
            }}
            value={formInput.laborRate}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            disabled
            type="text"
            name="totalClaim"
            label="Total Claim Amount"
            value={formInput.totalClaim}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <Typography gutterBottom variant="h5" sx={{ mt: 2 }}>
              Product
            </Typography>

            <FlexWrapper gap={1}>
              {numOfParts <= 3 && (
                <Tooltip title="Add Product" placement="top" arrow>
                  <IconButton onClick={handleAddPartRows}>
                    <Add />
                  </IconButton>
                </Tooltip>
              )}

              {numOfParts >= 2 && (
                <Tooltip title="Remove Product" placement="top" arrow>
                  <IconButton onClick={handleRemovePartRows}>
                    <Remove />
                  </IconButton>
                </Tooltip>
              )}
            </FlexWrapper>
          </FlexWrapper>
        </Grid>

        <>
          {partNumbers.length &&
            partNumbers.map((partObj, index) => {
              return (
                <Fragment key={partObj.key}>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      required
                      type="text"
                      label="Part Number"
                      value={partObj.partnumber}
                      onChange={(event) => handleChangePartValues(event, index)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      required
                      type="number"
                      inputProps={{ min: 1, max: 4 }}
                      label="Part Qty"
                      value={partObj.qty}
                      onChange={(event) =>
                        handleChangePartQuantities(event, index)
                      }
                    />
                  </Grid>
                </Fragment>
              );
            })}
        </>

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="manufacturer"
            label="Manufacturer"
            value={formInput.manufacturer}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="number"
            inputMode="numeric"
            name="mileageInstallation"
            label="Mileage At Installation"
            value={formInput.mileageInstallation}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="number"
            inputMode="numeric"
            name="mileageFailure"
            label="Mileage At Failure"
            value={formInput.mileageFailure}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="date"
            name="dateInstallation"
            label="Date At Installation"
            InputLabelProps={{
              shrink: true,
            }}
            value={formInput.dateInstallation}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="date"
            name="dateFailure"
            label="Date At Failure"
            InputLabelProps={{
              shrink: true,
            }}
            value={formInput.dateFailure}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            multiline
            minRows={4}
            type="text"
            name="failureDescription"
            label="Description of Failure and Collateral Damage"
            value={formInput.failureDescription}
            onChange={(event) =>
              setFormInput({
                ...formInput,
                [event.target.name]: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            minRows={4}
            type="text"
            name="comments"
            label="Additional Comments"
            value={formInput.comments}
            onChange={(event) =>
              setFormInput({
                ...formInput,
                [event.target.name]: event.target.value,
              })
            }
          />
        </Grid>

        <>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5" sx={{ mt: 2 }}>
              Attatchments
            </Typography>
            <Typography variant="caption">
              Please include the following attatchments:
            </Typography>
            <ul>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Original receipt with date of purchase
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Copy of original work order with parts and labor bill
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Copy of warranty (redo) work order
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Failed parts(s)
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Technician comments/statement
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                  Relevant picture of damage
                </Typography>
              </li>
            </ul>

            <GeneralFileUpload
              multiple
              required
              handleSaveFiles={handleSaveFiles}
              handleRemoveFile={handleRemoveFile}
              files={files}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ py: 1 }}
              disabled={submitting}
            >
              {submitting ? "Submitting Form..." : "Create Labor Claim"}
            </Button>
          </Grid>
        </>
      </Grid>
    </Container>
  );
};

export default LaborClaimAddMultiPage;
