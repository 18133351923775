import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { Cancel } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import GeneralModalV3 from "../../general/GeneralModalV3";
import FlexWrapper from "../../FlexWrapper";
import axios from "../../../axios/axios.config";

const InventoryMoveAdminDeleteMoveModal = ({
  move,
  setLoading,
  setInventoryMoves,
}) => {
  const { openAlert } = useGeneralAlert();
  const { currentSubsidiary, currentNetsuiteLocationIds } = useSelector(
    (state) => state.user
  );

  const handleDelete = async () => {
    setLoading("Deleting Move...");
    try {
      const response = await axios.delete(`inventory-move/delete/${move._id}`);
      await handleGetMoves();
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not delete Move: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  const handleGetMoves = async () => {
    setLoading("Loading Moves...");
    try {
      const response = await axios.get(
        `inventory-move/status/incomplete?subsidiary=${currentSubsidiary}&locations=${JSON.stringify(
          currentNetsuiteLocationIds
        )}`
      );

      setInventoryMoves(response.data);
    } catch (error) {
      throw error;
    }
  };
  return (
    <GeneralModalV3
      openComponent={
        <Tooltip title="Delete Move" placement="top" arrow>
          <IconButton size="small">
            <Cancel color="error" fontSize="12px" />
          </IconButton>
        </Tooltip>
      }
    >
      {(handleClose) => (
        <Box>
          <Typography variant="h4" textAlign="center" py={0.5}>
            Delete {move.sourcebin.binnumber}?
          </Typography>
          <Divider />
          <Typography variant="h6" p={2}>
            Move will no longer be vailable to view after it has been deleted.
          </Typography>
          <Divider />
          <FlexWrapper justifyContent="flex-end" p={1}>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete(handleClose)}
            >
              Delete
            </Button>
          </FlexWrapper>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default InventoryMoveAdminDeleteMoveModal;
