import { Box } from "@mui/material";

const FlexWrapper = ({
  children,
  flexDirection = "row",
  justifyContent = "start",
  alignItems = "start",
  gap = 0,
  flexWrap = "wrap",
  height = "auto",
  border = "none",
  width = "auto",
  p = "",
}) => {
  return (
    <Box
      component="div"
      sx={{
        width,
        display: "flex",
        flexDirection,
        justifyContent,
        alignItems,
        gap,
        flexWrap,
        height,
        border,
        p,
      }}
    >
      {children}
    </Box>
  );
};

export default FlexWrapper;
