import { useState } from "react";
import { Container } from "@mui/material";

import LaborClaimHeader from "../../components/multi-page/labor-claim/LaborClaimHeader";
import LaborClaimFormDetails from "../../components/multi-page/labor-claim/LaborClaimFormDetails";
import Loading from "../../components/Loading";
import SubmitFormReturnsNewView from "../../components/multi-page/labor-claim/SubmitFormReturnsNewView";
import { useLoaderData } from "react-router-dom";

const LaborClaimReturnsNewView = () => {
  const { data, fileURL } = useLoaderData();
  const [loading, setLoading] = useState("");
  const [form, setForm] = useState(data.form);

  if (loading) return <Loading message={loading} />;

  return (
    <Container maxWidth="md" sx={{ pb: 2 }}>
      <LaborClaimHeader
        loading={loading}
        setLoading={setLoading}
        fileURL={fileURL}
        form={form}
        setForm={setForm}
        createdby={data.createdby}
      />
      <LaborClaimFormDetails
        isReadOnly={false}
        isDefaultOpen={false}
        setLoading={setLoading}
        setForm={setForm}
        form={form}
      />
      <SubmitFormReturnsNewView
        setLoading={setLoading}
        form={form}
        fileURL={fileURL}
      />
    </Container>
  );
};
export default LaborClaimReturnsNewView;
