import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { utils, writeFile } from "xlsx";
import { ArrowRightSharp, Description, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../../components/FlexWrapper";
import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";

const ReviewCycleCountsPage = () => {
  const [searchInput, setSearchInput] = useState("");
  const [cycleCountData, setCycleCountData] = useState(null);
  const [tabValue, setTabValue] = useState("pendingapproval");
  const [sortOption, setSortOption] = useState("lastMonth");
  const [loading, setLoading] = useState("");

  const { openAlert } = useGeneralAlert();
  const user = useSelector((state) => state.user);

  const filteredJobs = cycleCountData?.length
    ? cycleCountData.filter(
        (job) =>
          job.status === tabValue &&
          job.name.toLowerCase().includes(searchInput.toLowerCase())
      )
    : null;

  useEffect(() => {
    getData();
  }, [tabValue, sortOption]);

  const getData = async () => {
    setLoading("Getting Data...");
    try {
      if (tabValue === "pendingapproval") {
        const pendingResponse = await axios.get(
          `cycle-counts-jobs/get/status/pendingapproval?location=${user.currentLocation}`
        );
        setCycleCountData(pendingResponse.data);
      } else {
        const completeResponse = await axios.get(
          `cycle-counts-jobs/get/status/complete?sortOption=${sortOption}&location=${user.currentLocation}`
        );
        setCycleCountData(completeResponse.data);
      }
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get data: ${error.message}`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  const handleChanges = (event) => {
    setSearchInput(event.target.value.toUpperCase());
  };

  const handleExport = async () => {
    try {
      const ids = cycleCountData.map((obj) => obj._id);
      //get data from ids
      const response = await axios.get(
        `cycle-counts-jobs/get/multiple?ids=${ids}`
      );

      const finalExportData = [];

      for (const data of response.data) {
        for (const job of data.jobs) {
          const exportObj = {
            "Job Name": data.name,
            "Counted By": data.finishedby?.username,
            "Job Status": data.status,
            Created: new Date(data.createdAt).toLocaleString(),
            "Start Count Date": new Date(data.startedon).toLocaleString(),
            "Finish Count Date": new Date(data.finishedon).toLocaleString(),
            Completed: data.completedon
              ? new Date(data.completedon).toLocaleString()
              : "",
            "Completed By": data.completedby?.username,
            Status: job.status,
            Bin: job.itemData.itembinnumber,
            Description: job.itemData.itemdescription,
            Name: job.itemData.itemname,
            "Manually Added": job.itemData.isnewitem ? "Yes" : "No",
            Counted: job.counts[job.counts.length - 1].physicalcount,
            "On-Hand": job.counts[job.counts.length - 1].virtualqty,
            Variance: job.counts[job.counts.length - 1].variance,
            Counts: job.counts.length,
            "Recount Created": data.recounts.length ? "YES" : "NO",
            Location: data.itemlocation,
          };

          finalExportData.push(exportObj);
        }
      }

      /* create worksheet */
      let ws = utils.json_to_sheet(finalExportData);

      /* create workbook and export */
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Cylce Count Report");
      writeFile(wb, `Cycle-Counts-${sortOption}-${Date.now()}.csv`, {
        bookType: "csv",
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Download CSV: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Review Cycle Counts
      </Typography>

      <TextField
        fullWidth
        label="Search"
        variant="outlined"
        value={searchInput}
        onChange={handleChanges}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <FlexWrapper justifyContent="space-between" alignItems="center">
        <FlexWrapper gap={2} p="5px 0" alignItems="center">
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
          >
            <Tab label="Pending" value="pendingapproval" />
            <Tab label="Complete" value="complete" />
          </Tabs>
          {tabValue === "complete" && (
            <Select size="small" value={sortOption}>
              <MenuItem
                value="lastWeek"
                onClick={() => {
                  setSortOption("lastWeek");
                }}
              >
                Last Week
              </MenuItem>
              <MenuItem
                value="lastTwoWeeks"
                onClick={() => {
                  setSortOption("lastTwoWeeks");
                }}
              >
                Last Two Weeks
              </MenuItem>
              <MenuItem
                value="lastMonth"
                onClick={() => {
                  setSortOption("lastMonth");
                }}
              >
                Last Month
              </MenuItem>
              <MenuItem
                value="lastYear"
                onClick={() => {
                  setSortOption("lastYear");
                }}
              >
                Last Year
              </MenuItem>
              <MenuItem
                value="allTime"
                onClick={() => {
                  setSortOption("allTime");
                }}
              >
                All Time
              </MenuItem>
            </Select>
          )}
        </FlexWrapper>
        <FlexWrapper alignItems="center" gap={1}>
          <Typography>
            Total: <strong>{filteredJobs?.length}</strong>
          </Typography>
          {tabValue === "complete" && (
            <Tooltip title="Export CSV" arrow placement="top">
              <IconButton onClick={handleExport}>
                <Description color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </FlexWrapper>
      </FlexWrapper>
      {filteredJobs?.length ? (
        <Box
          sx={{
            maxHeight: "calc(100vh - 205px)",
            overflowY: "auto",
            py: 1,
          }}
        >
          <Stack spacing={2}>
            {filteredJobs.map((job) => (
              <Card key={job._id} variant="outlined" sx={{ borderRadius: 2 }}>
                <Typography variant="caption" p={1} textTransform="capitalize">
                  <strong>
                    {job.status === "pendingapproval"
                      ? "Pending Approval"
                      : job.status}
                  </strong>
                </Typography>
                {user.rolelevel === 0 && (
                  <Typography variant="caption" component="div" pl={1}>
                    {job._id}
                  </Typography>
                )}
                <CardHeader
                  title={
                    <Typography
                      textAlign="center"
                      variant="h4"
                      fontWeight="bold"
                    >
                      {job.name}
                    </Typography>
                  }
                  subheader={
                    <>
                      <Typography textAlign="center">
                        Location: <strong>{job.itemlocation}</strong>
                      </Typography>
                      <FlexWrapper
                        alignItems="center"
                        gap={1}
                        justifyContent="center"
                      >
                        <Typography textAlign="center">
                          Unique Bins: <strong>{job.uniquebins}</strong>
                        </Typography>
                        -
                        <Typography textAlign="center">
                          Unique Items: <strong>{job.uniqueitems}</strong>
                        </Typography>
                      </FlexWrapper>
                    </>
                  }
                />
                <CardContent>
                  <FlexWrapper flexDirection="column" gap={1}>
                    <Typography variant="body2">
                      Created: {new Date(job.createdAt).toLocaleString()} (
                      {job.createdby?.username})
                    </Typography>
                    <FlexWrapper gap={1} alignItems="center">
                      {job.startedon && (
                        <Typography variant="body2">
                          Start Count Date:{" "}
                          {new Date(job.startedon).toLocaleString()} (
                          {job.startedby?.username})
                        </Typography>
                      )}
                      -
                      {job.finishedon && (
                        <Typography variant="body2">
                          Finish Count Date:{" "}
                          {new Date(job.finishedon).toLocaleString()} (
                          {job.finishedby?.username})
                        </Typography>
                      )}
                    </FlexWrapper>
                    {job.completedon && (
                      <Typography variant="body2">
                        Completed: {new Date(job.completedon).toLocaleString()}{" "}
                        ({job.completedby?.username})
                      </Typography>
                    )}
                  </FlexWrapper>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    fullWidth
                    endIcon={<ArrowRightSharp />}
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={job._id}
                    target="_blank"
                  >
                    View Details
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Stack>
        </Box>
      ) : (
        <Typography variant="h6" textAlign="center" pt={2}>
          No Data To Display
        </Typography>
      )}
    </Container>
  );
};
export default ReviewCycleCountsPage;
