import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { departments } from "../../utils/departments/departments";
import { useDispatch } from "react-redux";
import { sendEmail } from "../../utils/email/emailFunctions";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
} from "@mui/material";

import axios from "../../axios/axios.config";

const Register = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    printer: "",
    timezone: "",
    department: "",
    email: "",
    company: "",
  });
  const [error, setError] = useState(null);
  const printers = useLoaderData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      await axios.post("users/register", userData);
      navigate("/confirmation");
      sendEmail(
        "aitsystems@trakmotive.com",
        "New User Registration",
        "",
        `<p>${userData.firstName} ${userData.lastName} (${userData.userName}) has registered and is waiting account confirmation.</p>`,
        true
      );
    } catch (error) {
      setError(error.response?.data?.msg || "Could Not Register");
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Register: ${error.message}`,
          duration: 5000,
        })
      );
    }
  };

  const handleChanges = (event) => {
    if (event.target.name === "firstName") {
      setUserData({
        ...userData,
        [event.target.name]: event.target.value,
        userName: `${
          event.target.value ? event.target.value[0].toLowerCase() : ""
        }${userData.lastName.toLowerCase()}`,
      });
      return;
    }

    if (event.target.name === "lastName") {
      setUserData({
        ...userData,
        [event.target.name]: event.target.value,
        userName: `${
          userData.firstName[0] ? userData.firstName[0].toLowerCase() : ""
        }${event.target.value.toLowerCase()}`,
      });
      return;
    }

    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h5" gutterBottom textAlign="center">
        Sign Up
      </Typography>
      <form onSubmit={handleRegister}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              value={userData.firstName}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              value={userData.lastName}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="userName"
              label="Username"
              name="userName"
              value={userData.userName}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={userData.password}
              onChange={handleChanges}
              inputProps={{ autoComplete: "new-password" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required={
                userData.department === "SALES" ||
                userData.department === "SALES-REP"
              }
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
              value={userData.email}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label-department">
                Department
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-department"
                id="demo-simple-select"
                label="Department"
                name="department"
                value={userData.department}
                onChange={handleChanges}
              >
                {departments.map((dept) => {
                  return (
                    <MenuItem key={dept} value={dept}>
                      {dept.replace("-", " ")}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {userData.department === "WAREHOUSE" && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="default-printer-select-label">
                  Default Printer
                </InputLabel>
                <Select
                  labelId="default-printer-select-label"
                  id="default-printer-select"
                  name="printer"
                  label="Default Printer"
                  value={userData.printer}
                  onChange={handleChanges}
                >
                  {printers.map((printer) => {
                    return (
                      <MenuItem key={printer._id} value={printer.printername}>
                        {printer.printername}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          {userData.department === "SALES-REP" && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="company"
                label="Rep Agency"
                value={userData.company}
                onChange={handleChanges}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Timezone</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Timezone"
                name="timezone"
                value={userData.timezone}
                onChange={handleChanges}
              >
                <MenuItem value="PST">PST</MenuItem>
                <MenuItem value="EST">EST</MenuItem>
                <MenuItem value="GMT">GMT</MenuItem>
                <MenuItem value="CEST">CEST</MenuItem>
              </Select>
            </FormControl>

            {error && (
              <Typography color="error" variant="caption">
                {error}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained">
              Register
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default Register;
