import axios from "../../axios/axios.config";

export const loadOrders = async () => {
  const subsidiary = localStorage.getItem("subsidiary");
  const locations = localStorage.getItem("netsuitelocations");

  const response = await axios.get(
    `pack-process/view-orders?subsidiary=${subsidiary}&locations=${locations}&status=open`
  );

  if (response.data.length) return response.data;

  return null;
};

export const loadSingleOrder = async ({ params }) => {
  const { orderid } = params;

  const response = await axios.get(`pack-process/view-orders/${orderid}`);

  if (response.data) return response.data;

  return null;
};
