import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowBackIos,
  ArrowForwardIos,
  ErrorOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { clearPackErrorsV2 } from "../../../../redux/features/pack-process-v2/packProcessV2Slice";

const PackProcessErrorModal = () => {
  const [currentErrorIndex, setCurrentErrorIndex] = useState(0);
  const { errors } = useSelector((state) => state.packProcessV2);
  const dispatch = useDispatch();

  if (!errors || !errors.length) return null;

  const handleNext = () => {
    setCurrentErrorIndex((prevIndex) =>
      prevIndex === errors.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentErrorIndex((prevIndex) =>
      prevIndex === 0 ? errors.length - 1 : prevIndex - 1
    );
  };

  const handleClose = () => {
    dispatch(clearPackErrorsV2());
  };

  return (
    <Dialog open={true} maxWidth="xs" fullWidth disableEscapeKeyDown>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <ErrorOutline color="error" sx={{ marginRight: 1 }} />
            <Typography variant="h6" component="div">
              Error {currentErrorIndex + 1} of {errors.length}
            </Typography>
          </Box>
          {errors.length > 1 && (
            <Box display="flex">
              <IconButton size="small" onClick={handlePrev}>
                <ArrowBackIos fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={handleNext}>
                <ArrowForwardIos fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="h6"
          textAlign="center"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {errors[currentErrorIndex].msg}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          fullWidth
          onClick={() => {
            setCurrentErrorIndex(0);
            handleClose();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PackProcessErrorModal;
