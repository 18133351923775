import { useEffect, useState } from "react";
import {
  LABORCLAIMCOLUMNSRETURNS,
  LABORCLAIMCOMPLETECOLUMNS,
  LABORCLAIMINPROGRESSCOLUMNS,
  LABORCLAIMPROCESSEDCOLUMNSRETURNS,
} from "../../utils/columns/returns/returnsColumns";
import { useNavigate } from "react-router-dom";
import { useSalesRepCustomerMatch } from "../../hooks/useSalesRepCustomerMatch";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import axios from "../../axios/axios.config";
import Loading from "../../components/Loading";
import FlexWrapper from "../../components/FlexWrapper";

import "./returns.css";

function CustomToolbar({ type }) {
  return (
    <GridToolbarContainer>
      <Typography variant="h4" gutterBottom pl={1}>
        {type}
      </Typography>
      <FlexWrapper
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <FlexWrapper gap={1} alignItems="flex-end">
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{
              fileName: `${type}-LC-${Date.now()}`,
            }}
            printOptions={{
              disableToolbarButton: true,
            }}
          />
        </FlexWrapper>
        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const LaborCLaimReturnsPage = () => {
  const [lcRows, setLCRows] = useState(null);
  const [loading, setLoading] = useState(true);
  const { filteredCustomersList } = useSalesRepCustomerMatch();
  const { currentSubsidiary } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (filteredCustomersList?.length > 0) {
      getRows();
    }
  }, [filteredCustomersList]);

  const getRows = async () => {
    try {
      const filter = {
        type: "labor-claim",
        "form.customer": { $in: filteredCustomersList },
        subsidiary: currentSubsidiary,
      };

      const formResponse = await axios.post(
        `/forms/get/filtered-forms`,
        filter
      );

      const newLCRows = [];
      const processedLCRows = [];
      const inProgressLCRows = [];
      const completeLCRows = [];

      if (formResponse.data.length) {
        for (const row of formResponse.data) {
          if (
            row.form.status === "new" ||
            row.form.status === "pendingdocuments"
          ) {
            newLCRows.push(row);
          } else if (row.form.status === "processed") {
            processedLCRows.push(row);
          } else if (
            row.form.status === "rmaissued" ||
            row.form.status === "lcreceived" ||
            row.form.status === "lcreceived-pendingdocuments"
          ) {
            inProgressLCRows.push(row);
          } else {
            completeLCRows.push(row);
          }
        }
      }

      setLCRows({
        newLCRows,
        inProgressLCRows,
        processedLCRows,
        completeLCRows,
      });
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not get labor claim forms: ${error.message}`,
          duration: 5000,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getNewRowsClassName = (params) => {
    if (params.row.form.status === "pendingdocuments")
      return "document-error-row";
  };

  if (loading) {
    return <Loading message="Loading Customer Labor Claim Forms" />;
  }

  return (
    <>
      <Typography variant="h3" textAlign="center">
        Labor Claim Forms
      </Typography>
      <FlexWrapper justifyContent="flex-end">
        <Button
          variant="outlined"
          endIcon={<Add />}
          onClick={() => navigate("add")}
          sx={{ mb: 1 }}
        >
          Add New Labor Claim
        </Button>
      </FlexWrapper>
      <FlexWrapper flexDirection="column" gap={1}>
        <DataGrid
          autoHeight={lcRows.newLCRows.length === 0}
          sx={{
            bgcolor: "white",
            maxHeight: "40vh",
            width: "100%",
          }}
          rows={lcRows.newLCRows}
          columns={LABORCLAIMCOLUMNSRETURNS}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[100, 150, 200]}
          density="compact"
          disableColumnMenu
          disableRowSelectionOnClick
          getRowId={(row) => row._id}
          getRowClassName={getNewRowsClassName}
          slots={{ toolbar: () => <CustomToolbar type="New" /> }}
          disableDensitySelector
          disableColumnSelector
        />

        <DataGrid
          autoHeight={lcRows.inProgressLCRows.length === 0}
          sx={{ maxHeight: "40vh", width: "100%" }}
          rows={lcRows.inProgressLCRows}
          columns={LABORCLAIMINPROGRESSCOLUMNS}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
              sortModel: [
                {
                  field: "datereceived",
                  sort: "asc", // 'asc' for ascending, 'desc' for descending
                },
              ],
            },
          }}
          pageSizeOptions={[100, 150, 200]}
          density="compact"
          disableColumnMenu
          getRowId={(row) => row._id}
          slots={{ toolbar: () => <CustomToolbar type="In Progress" /> }}
          disableDensitySelector
          disableColumnSelector
        />

        <DataGrid
          autoHeight={lcRows.processedLCRows.length === 0}
          sx={{ maxHeight: "40vh", width: "100%" }}
          rows={lcRows.processedLCRows}
          columns={LABORCLAIMPROCESSEDCOLUMNSRETURNS}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
              sortModel: [
                {
                  field: "processeddate",
                  sort: "asc", // 'asc' for ascending, 'desc' for descending
                },
              ],
            },
          }}
          pageSizeOptions={[100, 150, 200]}
          density="compact"
          disableColumnMenu
          getRowId={(row) => row._id}
          slots={{ toolbar: () => <CustomToolbar type="Processed" /> }}
          disableDensitySelector
          disableColumnSelector
        />

        <DataGrid
          autoHeight={lcRows.completeLCRows.length === 0}
          sx={{ maxHeight: "40vh", width: "100%" }}
          rows={lcRows.completeLCRows}
          columns={LABORCLAIMCOMPLETECOLUMNS}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
              sortModel: [
                {
                  field: "completeddate",
                  sort: "desc", // 'asc' for ascending, 'desc' for descending
                },
              ],
            },
          }}
          pageSizeOptions={[100, 150, 200]}
          density="compact"
          disableColumnMenu
          getRowId={(row) => row._id}
          slots={{ toolbar: () => <CustomToolbar type="Complete" /> }}
          disableDensitySelector
          disableColumnSelector
        />
      </FlexWrapper>
    </>
  );
};
export default LaborCLaimReturnsPage;
