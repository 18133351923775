import { Link } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";

const MenuListItem = ({ title, description, to, icon, lastIndex, index }) => {
  return (
    <div>
      <Link style={{ textDecoration: "none", color: "inherit" }} to={to}>
        <ListItem
          alignItems="center"
          sx={{
            cursor: "pointer",
            pt: index === 0 ? 0 : 2,
            pb: index === lastIndex ? 0 : 2,
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            sx={{ pl: 2 }}
            primary={title}
            secondary={description}
          />
        </ListItem>
      </Link>
      <Divider
        variant="fullWidth"
        component="li"
        sx={{ display: lastIndex === index ? "none" : "block" }}
      />
    </div>
  );
};

export default MenuListItem;
