import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useLoaderData } from "react-router-dom";
import { BUGREPORTSADMINSCOLUMNS } from "../../utils/columns/admin/adminColumns";

const BugReportsAdmin = () => {
  const data = useLoaderData();

  return (
    <Box flex={1} px={2} py={1} overflow="hidden">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Bug Reports
      </Typography>

      {data?.length ? (
        <DataGrid
          sx={{ maxHeight: "90vh" }}
          rows={data}
          columns={BUGREPORTSADMINSCOLUMNS}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[100, 150, 200]}
          density="compact"
          disableColumnMenu
          getRowId={(row) => row._id}
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              csvOptions: { fileName: `Bug-Reports-${Date.now()}` },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          disableDensitySelector
          disableColumnSelector
        />
      ) : (
        <Typography variant="h6" textAlign="center" py={3}>
          No Data To Display
        </Typography>
      )}
    </Box>
  );
};
export default BugReportsAdmin;
