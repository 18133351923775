import { OpenInNew } from "@mui/icons-material";
import { Link, Typography } from "@mui/material";

const CreditMemoAndRMANetsuiteLinks = ({ type = "RMA", internalid = null }) => {
  if (!internalid)
    throw new Error(
      "Missing Required Internal Id Prop for CreditMemoAndRMANetsuiteLinks component"
    );

  if (type === "RMA") {
    const link =
      process.env.REACT_APP_NODE_ENV === "PRODUCTION"
        ? `https://4058904.app.netsuite.com/app/accounting/transactions/rtnauth.nl?id=${internalid}`
        : `https://4058904-sb1.app.netsuite.com/app/accounting/transactions/rtnauth.nl?id=${internalid}`;

    return (
      <Link href={link} target="_blank">
        <Typography variant="body2">
          View RMA In Netsuite <OpenInNew sx={{ fontSize: "16px" }} />
        </Typography>
      </Link>
    );
  }

  if (type === "CM") {
    const link =
      process.env.REACT_APP_NODE_ENV === "PRODUCTION"
        ? `https://4058904.app.netsuite.com/app/accounting/transactions/custcred.nl?id=${internalid}`
        : `https://4058904-sb1.app.netsuite.com/app/accounting/transactions/custcred.nl?id=${internalid}`;

    return (
      <Link href={link} target="_blank">
        <Typography variant="body2">
          View Credit Memo In Netsuite <OpenInNew sx={{ fontSize: "16px" }} />
        </Typography>
      </Link>
    );
  }
};
export default CreditMemoAndRMANetsuiteLinks;
