import { getUserFromStorage } from "../../redux/features/user/userSlice";
import { store } from "../../redux/store";

import axios from "../../axios/axios.config";

export const pickShortsPageLoader = async () => {
  const currentNetsuiteLocationIds = localStorage.getItem("netsuitelocations");
  const subsidiary = localStorage.getItem("subsidiary");

  if (!currentNetsuiteLocationIds || !subsidiary) {
    store.dispatch(getUserFromStorage());
  }

  const binShortsResponse = await axios.get(
    `pick-shorts?sortOption=lastMonth&locations=${currentNetsuiteLocationIds}&subsidiary=${subsidiary}&status=new`
  );

  return binShortsResponse.data;
};
