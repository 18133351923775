import { store } from "../../redux/store";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { redirect } from "react-router-dom";

import axios from "../../axios/axios.config";

export const equipmentChecklistLoader = async ({ params }) => {
  const { equipmentid } = params;
  try {
    const equipmentResponse = await axios.get(`equipment/${equipmentid}`);

    return equipmentResponse.data;
  } catch (error) {
    const { currentDepartment } = store.getState().user;
    store.dispatch(
      openGeneralAlert({
        type: "error",
        message: `Equipment with ID: ${equipmentid}, Does Not Exist`,
        duration: 5000,
      })
    );
    return redirect(
      `/portal/${currentDepartment.toLowerCase()}/equipment-checklist`,
      {
        replace: true,
      }
    );
  }
};

export const equipmentChecklistMainPageLoader = async () => {
  const currentLocation = localStorage.getItem("location");
  const equipmentResponse = await axios.get(`equipment/all/${currentLocation}`);
  const sortedData = equipmentResponse.data.sort((a, b) => {
    // Compare the alphabetical part of equipmentid
    const alphabeticComparison = a.equipmentid.localeCompare(
      b.equipmentid,
      undefined,
      { numeric: true }
    );

    // If the alphabetical part is equal, compare the numerical part
    if (alphabeticComparison === 0) {
      const numA = parseInt(a.equipmentid.substring(2), 10);
      const numB = parseInt(b.equipmentid.substring(2), 10);
      return numA - numB;
    }

    return alphabeticComparison;
  });

  if (equipmentResponse.data?.length) return sortedData;

  return null;
};

export const equipmentReportsLoader = async () => {
  const { rolelevel, currentDepartment } = store.getState().user;
  //ensure user has access to page
  if (rolelevel > 3)
    return redirect(`/portal/${currentDepartment.toLowerCase()}`, {
      replace: true,
    });

  //get data from mongo
  const mongoResponse = await axios.get("equipment/admin/all");

  if (mongoResponse.data.length) return mongoResponse.data;

  return null;
};

export const equipmentSingleReportLoader = async ({ params }) => {
  const { equipmentid } = params;

  const equipmentResponse = await axios.get(`equipment/${equipmentid}`);
  const equipmentChecklistsResponse = await axios.get(
    `equipment-checklist/get/admin/equipment/${equipmentid}`
  );
  //get all checklists for that specific id
  return {
    equipment: equipmentResponse.data,
    checklists: equipmentChecklistsResponse.data?.checklists,
  };
};
