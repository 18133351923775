import { Close, Info } from "@mui/icons-material";
import { useLoaderData } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  closeFullScreenModal,
  openFullScreenModal,
} from "../../redux/features/modal/modalSlice";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  CRONLOGCOLUMNS,
  LOGINLOGCOLUMNS,
} from "../../utils/columns/admin/adminColumns";
import {
  Box,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../components/FlexWrapper";
import FullScreenModal from "../../components/FullScreenModal";

const AWSSCHEDULERS = [
  {
    name: "Item Last Order Dates",
    time: "Every Day at 3:30am PST",
    async: "itemlastorderdates",
  },
  {
    name: "Valogix SFTP To S3 File Uploads Job",
    time: "Every Day at 5:00am PST (PROD) or 5:10am(DEV)",
    async: "sftp",
  },
  {
    name: "Valogix History And Forecast Job",
    time: "Every Day at 5:15am PST",
    async: "historyandforecast",
  },
  {
    name: "Valogix Replenishments",
    time: "Every Day at 5:30am PST",
    async: "replenishments",
  },
  {
    name: "Open TO and PO By Item",
    time: "Every Day at 4:00am PST",
    async: "opentoandpobyitem",
  },
  {
    name: "Export Process: logomateexports",
    time: "N/A",
    async: "logomateexports",
  },
  {
    name: "Tactical Connect Item Cross Refs",
    time: "N/A",
    async: "itemcrossrefs",
  },
  {
    name: "Tactical Connect Items",
    time: "N/A",
    async: "items",
  },
];
const NSSCHEDULERS = [
  {
    name: "NS Understock Job",
    time: "Every day at 4 pm",
    async: "understock",
  },
  {
    name: "NS Open Purchase Orders By Item",
    time: "Every Day at 11PM",
    async: "openordersbyitem",
  },
  {
    name: "NS Items Job",
    time: "Every Sunday at 2AM PST",
    async: "items",
  },
  {
    name: "NS Item Cross Refs",
    time: "Every Sunday at 2:30AM PST",
    async: "itemcrossrefs",
  },
  {
    name: "NS Customer",
    time: "Every Sunday at 3AM PST",
    async: "customers",
  },
];

function CustomToolbar() {
  const dispatch = useDispatch();

  return (
    <GridToolbarContainer>
      <FlexWrapper
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <FlexWrapper gap={1} alignItems="flex-end">
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{
              fileName: `loginlogs-${Date.now()}`,
            }}
            printOptions={{
              disableToolbarButton: true,
            }}
          />
          <Tooltip title="View Schedule" arrow placement="top">
            <IconButton
              onClick={() => dispatch(openFullScreenModal())}
              disableRipple
            >
              <Info color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
        </FlexWrapper>
        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const Logs = () => {
  const { cronLogs, loginLogs } = useLoaderData();
  const dispatch = useDispatch();

  return (
    <Box flex={1} px={2} py={1} overflow="hidden">
      {/* displays cron log schedule modal*/}
      <FullScreenModal>
        <Box p={2}>
          <IconButton onClick={() => dispatch(closeFullScreenModal())}>
            <Close />
          </IconButton>
          <Typography variant="h3" textAlign="center" gutterBottom>
            Cron Schedules
          </Typography>
          <FlexWrapper gap={1} alignItems="stretch" justifyContent="center">
            <Paper elevation={3} sx={{ padding: "16px" }}>
              <Typography variant="h6" align="center" gutterBottom>
                Cron Aws Schedulers
              </Typography>
              <List>
                {AWSSCHEDULERS.map((scheduler) => (
                  <ListItem key={scheduler.name}>
                    <FlexWrapper flexDirection="column">
                      <Typography variant="h6">{scheduler.name}</Typography>
                      <Typography variant="body1" color="GrayText">
                        {scheduler.time}
                      </Typography>
                      <Typography variant="body1" color="GrayText">
                        Async: node aws-async-schedulers.js {scheduler.async}
                      </Typography>
                    </FlexWrapper>
                  </ListItem>
                ))}
              </List>
            </Paper>

            <Paper elevation={3} sx={{ padding: "16px" }}>
              <Typography variant="h6" align="center" gutterBottom>
                Cron Netsuite Schedulers
              </Typography>
              <List>
                {NSSCHEDULERS.map((scheduler) => (
                  <ListItem key={scheduler.name}>
                    <FlexWrapper flexDirection="column">
                      <Typography variant="h6">{scheduler.name}</Typography>
                      <Typography variant="body1" color="GrayText">
                        {scheduler.time}
                      </Typography>
                      <Typography variant="body1" color="GrayText">
                        Async: node netsuite-async-schedulers.js{" "}
                        {scheduler.async}
                      </Typography>
                    </FlexWrapper>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </FlexWrapper>
        </Box>
      </FullScreenModal>

      <Box>
        <FlexWrapper flexDirection="column" gap={3} alignItems="center">
          <Box width="100%">
            <Typography variant="h3" component="h1" gutterBottom>
              Cron Logs
            </Typography>
            <DataGrid
              sx={{ bgcolor: "white", height: "50vh", width: "100%" }}
              rows={cronLogs}
              columns={CRONLOGCOLUMNS}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              pageSizeOptions={[100, 150, 200]}
              density="compact"
              disableColumnMenu
              getRowId={(row) => row._id}
              slots={{ toolbar: CustomToolbar }}
              disableDensitySelector
              disableColumnSelector
            />
          </Box>

          <Divider flexItem />

          <Box width="100%">
            <Typography variant="h3" component="h1" gutterBottom>
              Login Logs
            </Typography>
            <DataGrid
              sx={{ bgcolor: "white", height: "50vh", width: "100%" }}
              rows={loginLogs}
              columns={LOGINLOGCOLUMNS}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              pageSizeOptions={[100, 150, 200]}
              density="compact"
              disableColumnMenu
              getRowId={(row) => row._id}
              slots={{ toolbar: GridToolbar }}
              disableDensitySelector
              disableColumnSelector
              slotProps={{
                toolbar: {
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  csvOptions: {
                    fileName: `loginlogs-${Date.now()}`,
                  },
                },
              }}
            />
          </Box>
        </FlexWrapper>
      </Box>
    </Box>
  );
};

export default Logs;
