import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { useMemo, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { ExitToApp, Person } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import axios from "../../../axios/axios.config";
import FlexWrapper from "../../../components/FlexWrapper";
import GeneralModal from "../../../components/GeneralModal";
import WarrantyClaimBreakdown from "../../../components/multi-page/warranty-claim/WarrantyClaimBreakdown";
import Loading from "../../../components/Loading";
import CustomerDetailsWarranty from "../../../components/multi-page/warranty-claim/CustomerDetailsWarranty";
import CustomerRateAndRMADetails from "../../../components/multi-page/warranty-claim/CustomerRateAndRMADetails";
import AttachmentsWarranty from "../../../components/multi-page/warranty-claim/AttachmentsWarranty";

import "./warranty.css";

const Modal = ({ open, handleClose, children }) => {
  return (
    <GeneralModal open={open} handleClose={handleClose}>
      {children}
    </GeneralModal>
  );
};

const WarrantyFormViewMulti = () => {
  const {
    data: { form, createdby },
    fileURL,
  } = useLoaderData();

  const [openModal, setOpenModal] = useState({ info: false, file: false });
  const [rmaLoading, setRMALoading] = useState("");
  const [formDetails, setFormDetails] = useState(form);

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formid } = useParams();
  const { memoForm, memoCreatedBy, memoFileURL } = useMemo(() => {
    return { memoForm: form, memoCreatedBy: createdby, memoFileURL: fileURL };
  }, [form, createdby, fileURL]);

  const handleGenerateRMA = async () => {
    setRMALoading("Creating RMA...");
    try {
      if (form.blankpriceitems.length) {
        throw new Error(
          `Prices are missing for the following Item(s): ${form.blankpriceitems
            .map((missingItem) => missingItem.item)
            .join(", ")}`
        );
      }
      const createdTime = new Date().toLocaleString();
      //get the rma number and internal id
      const rmaResponse = await axios.post(
        "netsuite/post/warranty-claim/create/rma",
        {
          externalid: `RMA-WR ${form.customerrefrencenumber}`,
          entity: form.customerinternalid,
          discountitem: form.customerdefaultdiscountid,
          otherrefnum: `WR ${form.customerrefrencenumber}`,
          custbody_ait_rma_return_type: 3,
          custbody11: form.discountedprice,
          custbody10: "",
          location: 21,
          custbody_entered_by: user.netsuiteinternalid,
          item: {
            items: [
              {
                item: 14358,
                custcol_rma_item_number: "DIF",
                description: "DIF",
                quantity: form.totalQty,
                rate: 0,
                price: -1,
              },
            ],
          },
        }
      );

      setRMALoading("Retrieving Rate Details...");

      let warrantyDetails = {
        customergroup: form.group,
        salesqty: "0",
        warrantycreditqty: "0",
        netqty: "0",
        warrantyratebasedonqty: "0",
        pendingrmaqty: "0",
        warrantyrateincludingpendingrma: "0",
      };

      let status = "approved";

      const warrantyDetailsResponse = await axios.get(
        `netsuite/get/warranty-claim/rate-details/${form.customergroup}`
      );

      if (warrantyDetailsResponse.data.length) {
        const {
          salesqty,
          warrantycreditqty,
          netqty,
          warrantyratebasedonqty,
          pendingrmaqty,
          warrantyrateincludingpendingrma,
        } = warrantyDetailsResponse.data[0];

        warrantyDetails = {
          customergroup: form.group,
          salesqty: salesqty || "0",
          warrantycreditqty: warrantycreditqty || "0",
          netqty: netqty || "0",
          warrantyratebasedonqty: warrantyratebasedonqty || "0",
          pendingrmaqty: pendingrmaqty || "0",
          warrantyrateincludingpendingrma:
            warrantyrateincludingpendingrma || "0",
        };

        const percentageNumber = parseFloat(
          warrantyDetails.warrantyrateincludingpendingrma.replace("%", "")
        );

        if (isNaN(percentageNumber) || percentageNumber < -5) {
          status = "pendingapproval";
        }
      }

      setRMALoading("Saving Data...");

      //update mongo
      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.rma": {
            ...rmaResponse.data,
            createdbyname: `${user.firstname} ${user.lastname}`,
            createdbyusername: user.username,
            createdat: createdTime,
          },
          "form.ratedetails": warrantyDetails,
          "form.status": status,
          "form.approvedby": status === "approved" ? user.username : null,
          "form.approveddate": status === "approved" ? createdTime : null,
        },
      });

      setFormDetails(data.form);

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Created RMA",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Create RMA: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 7000,
        })
      );
    } finally {
      setRMALoading("");
    }
  };

  const handleApproveClaim = async () => {
    try {
      //update mongo
      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.status": "approved",
          "form.approvedby": user.username,
          "form.approveddate": new Date().toLocaleString(),
        },
      });

      setFormDetails(data.form);

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Approved Warranty Claim",
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Approve Warranty Claim: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    }
  };

  const handleGenerateCreditMemo = async () => {
    setRMALoading("Creating Credit Memo...Please Wait");
    try {
      //format items for credit memo api call
      const formattedItems = form.items.map((item) => {
        return {
          item: 10026,
          custcol_rma_item_number: item.item,
          description: item.class,
          quantity: item.qty,
          price: -1,
          rate: item.unitprice ? parseFloat(item.unitprice) : 0,
        };
      });
      //create item receipt and credit memo. returns credit memo details
      const response = await axios.post(
        `netsuite/post/warranty-claim/create/credit-memo/${formDetails.rma.rmainternalid}`,
        { username: user.username, items: formattedItems }
      );

      //update mongo
      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.status": "complete",
          "form.creditmemo": {
            ...response.data,
            createdbyname: `${user.firstname} ${user.lastname}`,
            createdbyusername: user.username,
            createdbydate: new Date().toLocaleString(),
          },
        },
      });

      setFormDetails(data.form);
      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Created Credit Memo",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Create Credit Memo : ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    } finally {
      setRMALoading("");
    }
  };

  if (rmaLoading) return <Loading message={rmaLoading} />;

  return (
    <Container maxWidth="xl" sx={{ pb: 2 }}>
      {/* Info Modal */}
      <Modal
        open={openModal.info}
        handleClose={() => setOpenModal({ info: false, file: false })}
      >
        <Box p={2}>
          <Typography gutterBottom variant="h4" textAlign="center">
            Warranty Claim Created By
          </Typography>

          <Typography>
            <strong>Name:</strong> {createdby.name}
          </Typography>
          <Typography>
            <strong>Username:</strong> {createdby.username.toUpperCase()}
          </Typography>
          <Typography>
            <strong>Email:</strong> {createdby.email.toUpperCase()}
          </Typography>
          <Typography>
            <strong>Department:</strong> {createdby.department}
          </Typography>
          <Typography>
            <strong>User Type:</strong> {createdby.usertype.toUpperCase()}
          </Typography>
        </Box>
      </Modal>

      <Container maxWidth="md" sx={{ pb: 3 }}>
        <Box>
          <FlexWrapper justifyContent="space-between">
            <Tooltip title="Back To Labor Claims" placement="top" arrow>
              <IconButton
                onClick={() =>
                  navigate(
                    `/portal/${user.currentDepartment.toLowerCase()}/forms/warranty`
                  )
                }
              >
                <ExitToApp color="error" />
              </IconButton>
            </Tooltip>

            <FlexWrapper alignItems="center">
              {user.currentDepartment === "RETURNS" && !formDetails.rma && (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={handleGenerateRMA}
                  >
                    Generate RMA
                  </Button>
                  <Divider orientation="vertical" flexItem />
                </>
              )}
              {user.currentDepartment === "RETURNS" &&
                !formDetails.blankpriceitems.length &&
                formDetails.status === "approved" && (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      sx={{ mr: 1 }}
                      onClick={handleGenerateCreditMemo}
                    >
                      Generate Credit Memo
                    </Button>
                    <Divider orientation="vertical" flexItem />
                  </>
                )}
              <Tooltip title="Created By User" placement="top" arrow>
                <IconButton
                  onClick={() => {
                    setOpenModal({ file: false, info: true });
                  }}
                >
                  <Person color="info" />
                </IconButton>
              </Tooltip>

              <AttachmentsWarranty form={memoForm} fileURL={memoFileURL} />
            </FlexWrapper>
          </FlexWrapper>

          <Typography variant="h3" textAlign="center" gutterBottom>
            Warranty Claim
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomerDetailsWarranty form={formDetails} />
          </Grid>
        </Grid>
      </Container>

      <WarrantyClaimBreakdown form={memoForm} createdby={memoCreatedBy} />

      {formDetails.rma && formDetails.ratedetails && (
        <Container maxWidth="md" sx={{ pt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomerRateAndRMADetails
                form={formDetails}
                handleApproveClaim={handleApproveClaim}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </Container>
  );
};
export default WarrantyFormViewMulti;
