import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Container } from "@mui/material";

import LaborClaimHeader from "../../../components/multi-page/labor-claim/LaborClaimHeader";
import LaborClaimFormDetails from "../../../components/multi-page/labor-claim/LaborClaimFormDetails";
import SubmitFormNingboProcessedView from "../../../components/multi-page/labor-claim/SubmitFormNingboProcessedView";
import Loading from "../../../components/Loading";

const LaborClaimProcessedViewNingbo = () => {
  const { data, fileURL } = useLoaderData();
  const [loading, setLoading] = useState("");
  const [form, setForm] = useState(data.form);

  if (loading) return <Loading message={loading} />;

  return (
    <Container maxWidth="md" sx={{ pb: 2 }}>
      <LaborClaimHeader
        loading={loading}
        setLoading={setLoading}
        fileURL={fileURL}
        form={form}
        setForm={setForm}
        createdby={data.createdby}
      />
      <LaborClaimFormDetails
        isReadOnly={true}
        isDefaultOpen={false}
        setLoading={setLoading}
        setForm={setForm}
        form={form}
      />
      <SubmitFormNingboProcessedView form={form} setLoading={setLoading} />
    </Container>
  );
};

export default LaborClaimProcessedViewNingbo;
