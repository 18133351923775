import { useDispatch } from "react-redux";
import { openGeneralAlert } from "../redux/features/alert/alertSlice";

export const useGeneralAlert = () => {
  const dispatch = useDispatch();

  const openAlert = ({ type, message, duration }) => {
    dispatch(openGeneralAlert({ type, message, duration }));
  };

  return { openAlert };
};
