export const CRONLOGCOLUMNS = [
  {
    field: "eventname",
    headerName: "Event Name",
    sortable: false,
    width: 350,
  },
  {
    field: "status",
    sortable: false,
    headerName: "Status",
    width: 150,
  },
  {
    field: "results",
    headerName: "Results",
    width: 150,
    type: "number",
  },
  {
    field: "runtime",
    sortable: false,
    headerName: "Runtime",
    width: 120,
  },
  {
    field: "failuremessage",
    sortable: false,
    headerName: "Failure Message",
    width: 380,
  },
  {
    field: "createdAt",
    width: 250,
    headerName: "Execution Date",
    valueGetter: (params) => {
      return new Date(params.row.createdAt).toLocaleString();
    },
    sortComparator: (v1, v2) => {
      // Implement your custom sorting logic here
      const date1 = new Date(v1);
      const date2 = new Date(v2);
      return date1 - date2;
    },
  },
  {
    field: "async",
    sortable: false,
    headerName: "Type",
    valueGetter: (params) => {
      return params.row.async ? "Manual Execution" : "Auto Execution";
    },
    width: 150,
  },
  {
    field: "instanceId",
    sortable: false,
    headerName: "Instance ID",
    sortable: false,
    width: 150,
  },
];

export const LOGINLOGCOLUMNS = [
  {
    field: "username",
    headerName: "User Name",
    width: 200,
    valueGetter: (params) => {
      return params.row.user?.username || "";
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "message",
    headerName: "Failure Message",
    width: 250,
    headerAlign: "center",
  },
  {
    field: "usertype",
    headerName: "User Type",
    width: 100,
    valueGetter: (params) => {
      return params.row.user?.usertype || "";
    },
    align: "center",
    headerAlign: "center",
  },
  {
    field: "rolelevel",
    headerName: "Role Level",
    width: 150,
    valueGetter: (params) => {
      return params.row.user?.rolelevel !== undefined
        ? params.row.user.rolelevel
        : "";
    },
    type: "number",
    align: "center",
    headerAlign: "center",
  },
  {
    field: "ip",
    headerName: "IP Address",
    width: 250,
    headerAlign: "center",
  },
  {
    field: "devicename",
    headerName: "Device Name",
    width: 250,
  },
  {
    field: "loggedInAt",
    headerName: "Logged In At",
    width: 250,
    valueGetter: (params) => {
      return new Date(params.row.loggedInAt).toLocaleString();
    },
    sortComparator: (v1, v2) => {
      // Implement your custom sorting logic here
      const date1 = new Date(v1);
      const date2 = new Date(v2);
      return date1 - date2;
    },
  },
  {
    field: "instanceId",
    sortable: false,
    headerName: "Instance ID",
    sortable: false,
    width: 150,
  },
  {
    field: "version",
    sortable: false,
    headerName: "Version",
    sortable: false,
    width: 150,
  },
];

export const USERCOLUMNS = [
  {
    field: "firstname",
    headerName: "First Name",
    width: 100,
  },
  {
    field: "lastname",
    headerName: "Last Name",
    width: 100,
  },
  {
    field: "username",
    headerName: "User Name",
    width: 100,
  },
  {
    field: "email",
    headerName: "Email",
    width: 100,
  },
  {
    field: "rolelevel",
    headerName: "Role Level",
    width: 100,
    type: "number",
  },
  {
    field: "usertype",
    headerName: "User Type",
    width: 100,
  },
  {
    field: "subsidiary",
    headerName: "Subsidiary",
    width: 100,
    valueGetter: (params) => {
      return params.row.subsidiary.join("-");
    },
  },
  {
    field: "location",
    headerName: "Location",
    width: 100,
    valueGetter: (params) =>
      params.row.location.length && params.row.location[0].location
        ? params.row.location.map((data) => data.location).join("-")
        : "",
  },
  {
    field: "department",
    headerName: "Department",
    width: 390,
    valueGetter: (params) => {
      return params.row.department.join("-");
    },
  },
  {
    field: "printer",
    headerName: "Printer",
    width: 100,
  },
  {
    field: "salesrepcode",
    headerName: "Sales Rep Code",
    width: 100,
    type: "number",
  },
  {
    field: "partnercode",
    headerName: "Partner Code",
    width: 100,
    type: "number",
  },
  {
    field: "customercode",
    headerName: "Customer Code",
    width: 100,
    type: "number",
  },
  {
    field: "manager",
    headerName: "Manager",
    width: 100,
  },
  {
    field: "company",
    headerName: "Company",
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
  },
  {
    field: "version",
    headerName: "Version",
    width: 100,
    sortable: false,
  },
  {
    field: "lastlogin",
    headerName: "Last Login",
    width: 300,
    sortable: true,
    type: "dateTime",
    valueGetter: (params) => {
      return params.row.lastlogin ? new Date(params.row.lastlogin) : "";
    },
  },
];

export const PRINTERCOLUMS = [
  {
    field: "printername",
    headerName: "Printer Name",
    width: 150,
    sortable: false,
  },
  {
    field: "printerdescription",
    headerName: "Printer Description",
    width: 280,
    sortable: false,
  },
  {
    field: "printermodel",
    headerName: "Printer Model",
    width: 150,
    sortable: false,
  },
  {
    field: "printermanufacturer",
    headerName: "Printer Manufacturer",
    width: 200,
    sortable: false,
  },
  {
    field: "printerlocation",
    headerName: "Printer Location",
    width: 250,
    sortable: false,
  },
  {
    field: "printerresolution",
    headerName: "Printer Resolution",
    width: 200,
    sortable: false,
  },
  {
    field: "printeripaddress",
    headerName: "Printer IP Address",
    width: 200,
    sortable: false,
  },
  {
    field: "printnodeid",
    headerName: "Printnode ID",
    width: 200,
    sortable: false,
  },
];

export const INTEGRATIONERROSCOLUMNS = [
  {
    field: "integration",
    headerName: "Integration",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "type",
    headerName: "Type",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "refnumber",
    headerName: "Reference Number",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "user",
    headerName: "User",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "errormessage",
    headerName: "Error Message",
    type: "string",
    sortable: false,
    flex: 3,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "action",
    headerName: "Action",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    type: "date",
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueFormatter: (valueObj) => {
      return new Date(valueObj.value).toLocaleString();
    },
  },
];

export const BUGREPORTSADMINSCOLUMNS = [
  {
    field: "ordernumber",
    headerName: "Order Number",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "issuetitle",
    headerName: "Issue Name",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "description",
    headerName: "Issue Description",
    type: "string",
    flex: 3,
    align: "center",
    headerAlign: "center",
    sortable: false,
  },
  {
    field: "devicename",
    headerName: "Device Name",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "location",
    headerName: "Location",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "physicallocation",
    headerName: "Physical Location",
    type: "string",
    sortable: false,
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "process",
    headerName: "Process",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "createdby",
    headerName: "Created By",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "subsidiary",
    headerName: "Subsidiary",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "dateofissue",
    headerName: "Date of Issue",
    type: "date",
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueFormatter: (valueObj) => {
      return new Date(valueObj.value).toLocaleString();
    },
  },
  {
    field: "createdAt",
    headerName: "Report Created Date",
    type: "date",
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueFormatter: (valueObj) => {
      return new Date(valueObj.value).toLocaleString();
    },
  },
];
