import { Paper, Box, Typography, Tooltip } from "@mui/material";

const ToteItemBasic = ({ item }) => {
  return (
    <Paper variant="outlined" sx={{ px: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ typography: { md: "h5" } }}>
          {item.item}
          {item.interchange &&
          item.interchange.interchange &&
          item.interchange.interchange !== item ? (
            <Tooltip title="NAPA Number">
              <Typography variant="caption" component="span" color="#001489">
                {" "}
                ({item.interchange.interchange})
              </Typography>
            </Tooltip>
          ) : null}
        </Typography>

        <Typography component="span" sx={{ typography: { md: "h5" } }}>
          QTY: {item.qty}
        </Typography>
      </Box>
    </Paper>
  );
};

export default ToteItemBasic;
