import { Link } from "react-router-dom";

export const LABORCLAIMCOLUMNSRETURNS = [
  {
    field: "_id",
    headerName: "ID",
    width: 250,
    sortable: false,
    renderCell: (params) => {
      return (
        <Link style={{ color: "#1976d2" }} to={`form/${params.row._id}/new`}>
          {params.row._id}
        </Link>
      );
    },
  },
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return new Date(params.row.createdAt);
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.customer;
    },
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    width: 180,
    valueGetter: (params) => {
      return params.row.form.status.toUpperCase();
    },
  },
  {
    field: "rgaNum",
    headerName: "RGA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rgaNum;
    },
  },
  {
    field: "serviceFacilityCompany",
    headerName: "Service Facility Company Name",
    type: "string",
    width: 250,
    valueGetter: (params) => {
      return params.row.form.serviceFacilityCompany;
    },
  },
  {
    field: "totalClaim",
    headerName: "Total Claim Amount",
    type: "number",
    width: 180,
    valueGetter: (params) => {
      return params.row.form.totalClaim;
    },
  },
];

export const LABORCLAIMPROCESSEDCOLUMNSRETURNS = [
  {
    field: "_id",
    headerName: "ID",
    width: 250,
    sortable: false,
    renderCell: (params) => {
      return (
        <Link
          style={{ color: "#1976d2" }}
          to={`form/${params.row._id}/processed`}
        >
          {params.row._id}
        </Link>
      );
    },
  },
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return new Date(params.row.createdAt);
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.customer;
    },
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    width: 150,
    valueGetter: (params) => {
      return params.row.form.status.toUpperCase();
    },
  },
  {
    field: "rgaNum",
    headerName: "RGA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rgaNum;
    },
  },
  {
    field: "rmaNum",
    headerName: "RMA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rmaNum;
    },
  },
  {
    field: "processedby",
    headerName: "Processed By",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.processedbyname;
    },
  },
  {
    field: "processedbyemail",
    headerName: "Processed By Email",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.processedbyemail;
    },
  },
  {
    field: "processeddate",
    headerName: "Processed Date",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.processedbydate
        ? new Date(params.row.form.processedbydate).toLocaleDateString()
        : "";
    },
  },
];

export const LABORCLAIMINPROGRESSCOLUMNS = [
  {
    field: "_id",
    headerName: "ID",
    width: 250,
    sortable: false,
    renderCell: (params) => {
      return (
        <Link
          style={{ color: "#1976d2" }}
          to={`form/${params.row._id}/in-progress`}
        >
          {params.row._id}
        </Link>
      );
    },
  },
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return new Date(params.row.createdAt);
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.customer;
    },
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    width: 150,
    valueGetter: (params) => {
      return params.row.form.status.toUpperCase();
    },
  },
  {
    field: "rgaNum",
    headerName: "RGA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rgaNum;
    },
  },
  {
    field: "rmaNum",
    headerName: "RMA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rmaNum;
    },
  },
  {
    field: "rmaissuedby",
    headerName: "RMA Issued By",
    type: "string",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.rmaissuedbyname;
    },
  },
  {
    field: "rmaissuedbyemail",
    headerName: "RMA Issued By Email",
    type: "string",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.rmaissuedbyemail;
    },
  },
  {
    field: "rmaissuedate",
    headerName: "RMA Issued Date",
    type: "date",
    width: 200,
    valueGetter: (params) => {
      return new Date(params.row.form.rmaissuedate);
    },
  },
  {
    field: "rmaissueddepartment",
    headerName: "RMA Issued Dept.",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.rmaissueddepartment;
    },
  },
  {
    field: "receivedby",
    headerName: "Received By",
    type: "string",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.receivedbyname;
    },
  },
  {
    field: "receivedbyemail",
    headerName: "Received By Email",
    type: "string",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.receivedbyemail;
    },
  },
  {
    field: "datereceived",
    headerName: "Date Received",
    type: "date",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.datereceived
        ? new Date(params.row.form.datereceived)
        : "";
    },
  },
  {
    field: "timereceived",
    headerName: "Time Received",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      const timeArr = params.row.form.timereceived
        ? params.row.form.timereceived.split(":")
        : [];

      if (!timeArr.length) return params.row.form.timereceived;

      const minutes = timeArr[1];
      let timeOfDay = "AM";
      let hours = timeArr[0];

      if (parseInt(hours) > 12) {
        timeOfDay = "PM";
        hours = hours - 12;
      }

      return `${hours}:${minutes} ${timeOfDay}`;
    },
  },
];

export const LABORCLAIMCOMPLETECOLUMNS = [
  {
    field: "_id",
    headerName: "ID",
    width: 250,
    sortable: false,
    renderCell: (params) => {
      return (
        <Link
          style={{ color: "#1976d2" }}
          to={`form/${params.row._id}/complete`}
        >
          {params.row._id}
        </Link>
      );
    },
  },
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return new Date(params.row.createdAt);
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.customer;
    },
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    width: 150,
    valueGetter: (params) => {
      return params.row.form.status.toUpperCase();
    },
  },
  {
    field: "rgaNum",
    headerName: "RGA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rgaNum;
    },
  },
  {
    field: "rmaNum",
    headerName: "RMA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rmaNum;
    },
  },
  {
    field: "completedby",
    headerName: "Completed By",
    type: "string",
    width: 150,
    valueGetter: (params) => {
      return params.row.form.completedbyname;
    },
  },
  {
    field: "completedbyemail",
    headerName: "Completed By Email",
    type: "string",
    width: 150,
    valueGetter: (params) => {
      return params.row.form.completedbyemail;
    },
  },

  {
    field: "completeddate",
    headerName: "Completed Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return params.row.form.completeddate ? new Date(params.row.form.completeddate): null;
    },
  },
];
