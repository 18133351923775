import { Box, Button, Divider, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  findNextBin,
  skipBin,
  skipItemFull,
  skipItemPartial,
} from "../../../redux/features/pick-order/pickOrderSlice";

import GeneralModal from "../../GeneralModal";

const PickOrderSkipItemModal = ({
  open,
  setOpen,
  itemQty,
  itemName,
  itemBin,
  itemQtyRemaining,
  isBinVerified,
  isItemVerified,
  isQtyVerified,
  isOZBIn,
}) => {
  const dispatch = useDispatch();

  const {
    currentindex,
    path,
    location,
    locationinternalid,
    tranid,
    _id,
    firstpicktime,
  } = useSelector((state) => state.pickOrder);
  const { username, currentSubsidiary } = useSelector((state) => state.user);

  const handleSkipBin = async () => {
    const {
      binnumber,
      lineitemname,
      bininternalid,
      binonhandavailable,
      lineid,
      lineiteminternalid,
      lineitemupc,
      lineitemclass,
    } = path[0];

    const shortData = {
      status: "complete",
      shorttype: "SKIP",
      lineitemclass,
      lineitemupc,
      locationinternalid,
      location,
      lineiteminternalid,
      lineid,
      binonhandavailable,
      bininternalid,
      order: tranid,
      bin: binnumber,
      item: lineitemname,
      shortqty: itemQtyRemaining,
      qtytopick: itemQtyRemaining,
      loggedby: username,
      subsidiary: currentSubsidiary,
    };
    await dispatch(skipBin({ shortData })).unwrap();
    await dispatch(findNextBin({ _id })).unwrap();
    setOpen(false);
  };

  const handleSkipItem = async () => {
    const {
      binnumber,
      lineitemname,
      bininternalid,
      binonhandavailable,
      lineid,
      lineiteminternalid,
      lineitemupc,
      lineitemclass,
    } = path[0];

    if (isBinVerified && !isItemVerified) {
      //full item qty is missing from bin
      //remove bin and continue
      const shortData = {
        shorttype: "BIN",
        lineitemclass,
        lineitemupc,
        locationinternalid,
        location,
        lineiteminternalid,
        lineid,
        binonhandavailable,
        bininternalid,
        order: tranid,
        bin: binnumber,
        item: lineitemname,
        shortqty: itemQtyRemaining,
        qtytopick: itemQtyRemaining,
        loggedby: username,
        subsidiary: currentSubsidiary,
      };

      await dispatch(
        skipItemFull({ shortData, bininternalid, lineiteminternalid })
      ).unwrap();
    } else {
      const picktime = new Date().toISOString();
      let initialpicktime = null;
      //set initail pick if null
      if (!firstpicktime) {
        initialpicktime = picktime;
      }
      const lastpicktime = picktime;
      const lastpickedby = username;
      //partial item qty is missing
      //remove bin and update pickedamount from all the same lineids
      const shortData = {
        shorttype: "ITEM",
        lineitemclass,
        lineitemupc,
        locationinternalid,
        location,
        lineiteminternalid,
        lineid,
        binonhandavailable,
        bininternalid,
        order: tranid,
        bin: binnumber,
        item: lineitemname,
        shortqty: itemQtyRemaining - itemQty,
        qtytopick: itemQtyRemaining,
        loggedby: username,
        subsidiary: currentSubsidiary,
      };

      const pickedItemObj = {
        ...path[0],
        lineitemqtypicked: parseInt(path[0].lineitemqtypicked) + itemQty,
        pickedtime: new Date().toISOString(),
        binqtypicked: itemQty,
        pickedby: username,
        qtytopick: itemQtyRemaining,
        ispartial: true,
      };
      delete pickedItemObj.lineitemqtyremaining;

      await dispatch(
        skipItemPartial({
          pickedAmount: itemQty,
          lineid,
          shortData,
          pickedItemObj,
          bininternalid,
          lineiteminternalid,
          lastpicktime,
          lastpickedby,
          firstpicktime: initialpicktime,
        })
      ).unwrap();
    }

    await dispatch(findNextBin({ _id })).unwrap();
    setOpen(false);
  };
  // isOZBIn && isBinVerified && !isItemVerified

  return (
    <GeneralModal open={open} handleClose={() => setOpen(false)}>
      <Box py={2}>
        {(!isBinVerified || (isOZBIn && isBinVerified && !isItemVerified)) && (
          <>
            <Typography variant="h4" textAlign="center" px={1} pb={1}>
              Skip{" "}
              <strong>
                {isOZBIn && isBinVerified && !isItemVerified
                  ? itemName
                  : itemBin}
              </strong>
              ?
            </Typography>
            <Divider />
          </>
        )}

        <Box p={1}>
          {isBinVerified &&
          !isItemVerified &&
          !(isOZBIn && isBinVerified && !isItemVerified) ? (
            <Typography variant="h5" textAlign="center" pt={1}>
              <strong>{itemName}</strong> is not in <strong>{itemBin}</strong>{" "}
              and skipping will result in logging this short.
            </Typography>
          ) : null}
          {isBinVerified && isItemVerified && !isQtyVerified && (
            <Typography variant="h5" textAlign="center" pt={1}>
              <strong>{itemBin}</strong> has missing quantity (
              <strong>{itemQtyRemaining - itemQty}</strong>) of{" "}
              <strong>{itemName}</strong>. Skipping will result in logging this
              short.
            </Typography>
          )}
          {(!isBinVerified || (isOZBIn && isBinVerified && !isItemVerified)) &&
            (isOZBIn && isBinVerified && !isItemVerified ? (
              <Typography variant="h5" textAlign="center">
                Verify that item is not in your current pick list for this bin
              </Typography>
            ) : (
              <Typography variant="h5" textAlign="center">
                Skip bin and return at a later time if necessary.
              </Typography>
            ))}
        </Box>

        <Divider />

        <Box pt={2} px={1}>
          {(!isBinVerified || (isOZBIn && isBinVerified && !isItemVerified)) &&
          currentindex < path.length ? (
            <Button
              fullWidth
              variant="contained"
              size="large"
              onClick={handleSkipBin}
            >
              Continue
            </Button>
          ) : (
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="error"
              onClick={handleSkipItem}
            >
              {isBinVerified && !isItemVerified ? "Skip" : "Pick"} and Log Short
            </Button>
          )}
        </Box>
      </Box>
    </GeneralModal>
  );
};
export default PickOrderSkipItemModal;
