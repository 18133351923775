import { isAuthenticated } from "../../utils/auth/verifyAuth";
import { store } from "../../redux/store";
import { getUserFromStorage } from "../../redux/features/user/userSlice";
import { getPrinterObjectFromUser } from "../../redux/features/printers/printerSlice";

export const userProfileLoader = async () => {
  try {
    const tokenResponse = await isAuthenticated();

    if (
      tokenResponse.data === "Token Validated" &&
      localStorage.getItem("user")
    ) {
      store.dispatch(getUserFromStorage());
      //retrieve user from local storage and save to redux
      const user = JSON.parse(localStorage.getItem("user"));

      //retrive printer if user has a printer set
      if (user.printer) {
        store.dispatch(getPrinterObjectFromUser(user.printer));
      }
    }

    return null;
  } catch (error) {
    throw new Response("Error", {
      status: error.response?.status || 500,
      statusText: error.response?.data?.msg || error.message,
    });
  }
};
