import { useState } from "react";
import { useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import { useOutletContext } from "react-router-dom";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import ReceiveTransactionViewActiveOrders from "../../../../components/warehouse/receive-transaction/ReceiveTransactionViewActiveOrders";

const ReceiveTransactionSearchPage = () => {
  const [transaction, setTransaction] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState("");

  const { navigate } = useOutletContext();
  const { username, currentNetsuiteLocationIds } = useSelector(
    (state) => state.user
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("Loading Order");
    try {
      //get data from netsuite
      const response = await axios.get(
        `netsuite/receive-transaction/${transaction?.trim()}`
      );

      if (!response.data.length) {
        throw new Error("Order not found or empty");
      }

      const {
        subsidiary,
        trantype,
        internalid,
        date,
        tranid,
        nsstatus,
        brandpackaging,
        location,
        locationinternalid,
        entity,
        otherrefnum,
        soreference,
        memo,
        warehousenotes,
        rmatype,
        rmaspecialnotes,
      } = response.data[0];

      if (!currentNetsuiteLocationIds.includes(parseInt(locationinternalid))) {
        throw new Error("You do not have access to this order");
      }

      const nsHeaderData = {
        subsidiary,
        trantype,
        internalid,
        date,
        tranid,
        nsstatus,
        brandpackaging,
        location,
        locationinternalid,
        entity,
        otherrefnum,
        soreference,
        memo,
        warehousenotes,
        rmatype,
        rmaspecialnotes,
      };

      const nsItems = response.data.map((item) => {
        return {
          lineid: item.lineid,
          lineitemaddtlupccodes: item.lineitemaddtlupccodes,
          lineitemboxsize: item.lineitemboxsize,
          lineitemclass: item.lineitemclass,
          lineiteminternalid: item.lineiteminternalid,
          lineitemname: item.lineitemname,
          lineitemqty: item.lineitemqty,
          lineitemqtypendingreceipt: item.lineitemqtypendingreceipt,
          lineitemqtyreceived: item.lineitemqtyreceived,
          lineitemsubclass: item.lineitemsubclass,
          lineitemupc: item.lineitemupc,
          lineitemdescription: item.lineitemdescription,
        };
      });

      const totalitemstoreceive = nsItems.reduce(
        (acc, current) => acc + parseInt(current.lineitemqtypendingreceipt),
        0
      );

      //Either create or get data in mongo
      const mongoResponse = await axios.post(
        `receive-transaction/get-or-create/transaction/${tranid}?username=${username}`,
        { nsHeaderData, nsItems, totalitemstoreceive }
      );

      navigate(mongoResponse.data._id);
    } catch (error) {
      setErrorMessage(error.message);
      setLoading("");
    }
  };

  if (loading) return <Loading message="Loading Order..." />;

  return (
    <Container maxWidth="md">
      <Typography textAlign="center" variant="h4" gutterBottom>
        Receive Transaction
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          required
          autoFocus
          label="Enter Transaction"
          name="order"
          value={transaction}
          onChange={(event) => {
            setTransaction(
              event.target.value.toUpperCase()?.replace(/\s+/g, "")
            );
          }}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton type="submit">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <ReceiveTransactionViewActiveOrders setTransaction={setTransaction} />
    </Container>
  );
};

export default ReceiveTransactionSearchPage;
