import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";

const PickOrderLayout = () => {
  return (
    <Container maxWidth="xl">
      <Outlet />
    </Container>
  );
};
export default PickOrderLayout;
