import { useState } from "react";
import { useSelector } from "react-redux";

export const useCSV = (arrayToExport) => {
  const [exportData, setExportData] = useState([]);
  const {
    _id,
    toteid,
    createdby,
    lastupdatedby,
    tranref,
    memo,
    subsidiary,
    location,
    createdAt,
    updatedAt,
  } = useSelector((state) => state.tote);

  const getExportArr = () => {
    if (_id !== "" && arrayToExport != null && arrayToExport.length > 0) {
      let exportArr = arrayToExport.map((item) => {
        let interchangeVal = item.interchange
          ? item.interchange.interchange
          : "";
        const exportItem = {
          ...item,
          toteid,
          createdby,
          lastupdatedby,
          tranref,
          memo,
          subsidiary,
          interchange: interchangeVal,
          location,
          createdat: new Date(createdAt).toLocaleDateString(),
          updatedat: new Date(updatedAt).toLocaleDateString(),
        };
        return exportItem;
      });
      setExportData(exportArr);
    }
  };

  const getTOExportArr = () => {
    const memoLocationMap = {
      "TrakMotive-CA": "AIT CA : RECEIVING MAIN CA",
      "TrakMotive-NC": "AIT NC : RALEIGH NC",
      "NAPA-CA": "AIT CA : RECEIVING MAIN CA",
      "O'Reilly-CA": "AIT CA : RECEIVING O'REILLY CA",
      "AutoZone-NC": "AIT NC : RALEIGH NC",
    };

    const memoPackagingMap = {
      "TrakMotive-NC": "TRK",
      "AutoZone-NC": "AZO",
    };

    if (_id !== "" && arrayToExport != null && arrayToExport.length > 0) {
      const TOExportArr = arrayToExport.map((item) => {
        let interchangeVal = item.interchange
          ? item.interchange.interchange
          : "";
        const TOExportItem = {
          externalid: `TO-${toteid}`,
          date: new Date().toLocaleDateString(),
          fromlocation: location === "NEV" ? "RETURNS" : location,
          tolocation: memoLocationMap[memo] ? memoLocationMap[memo] : "",
          item: item.item,
          qty: item.qty,
          interchange: interchangeVal,
          incoterm: "DAP",
          satus: "PENDING FULFILLMENT",
          memo: memo,
          prioritystatus: "OVERSTOCK",
          notes: `${toteid}${tranref ? ` | ${tranref}` : ""}`,
          trandtransactionpackaging: memoPackagingMap[memo]
            ? memoPackagingMap[memo]
            : "",
        };

        return TOExportItem;
      });
      setExportData(TOExportArr);
    }
  };

  const getIADJExportArr = () => {
    if (_id !== "" && arrayToExport != null && arrayToExport.length > 0) {
      const IADJExportArr = arrayToExport.map((item) => {
        const IADJExportItem = {
          externalid: `IADJ-${toteid}`,
          date: new Date().toLocaleDateString(),
          adjustmentaccount: "59999 Inventory Adjustment",
          location: location === "NEV" ? "AIT CA : QC CA" : location,
          memo: "STOCK ADDS FOR TOTELABEL",
          inventoryadjustmentlocation:
            location === "NEV" ? "AIT CA : QC CA" : location,
          item: item.item,
          qty: item.qty,
          binnumbers: location === "NEV" ? "OZ_RECEIVING_21" : "",
        };

        return IADJExportItem;
      });
      setExportData(IADJExportArr);
    }
  };

  const getINVTRANExportArr = () => {
    if (_id !== "" && arrayToExport != null && arrayToExport.length > 0) {
      const INVTRANExportArr = arrayToExport.map((item) => {
        const INVTRANExportItem = {
          externalid: `INVTRAN-${toteid}`,
          date: new Date().toLocaleDateString(),
          fromlocation: location === "NEV" ? "AIT CA : QC CA" : location,
          tolocation: location === "NEV" ? "RETURNS" : location,
          item: item.item,
          qty: item.qty,
          frombins: location === "NEV" ? "OZ_RECEIVING_21" : "",
          tobins: location === "NEV" ? "RAIT" : "",
          binnumbers: location === "NEV" ? "RAIT" : "",
        };
        return INVTRANExportItem;
      });
      setExportData(INVTRANExportArr);
    }
  };

  const getRMAExportArr = () => {
    const customerMap = {
      "AutoZone-NC": "AUTOZONE, INC. : AUTOZONE DO",
      " O’Reilly-CA": "O'REILLY AUTOMOTIVE LLC",
    };

    if (_id !== "" && arrayToExport != null && arrayToExport.length > 0) {
      const RMAExportArr = arrayToExport.map((item) => {
        const RMAExportItem = {
          externalid: `RMA-${toteid}`,
          date: new Date().toLocaleDateString(),
          customer: customerMap[memo] ? customerMap[memo] : "",
          location:
            memo === "AutoZone-NC" ? "AIT NC : RALEIGH NC" : "AIT CA : QC CA",
          status: "Pending Receipt",
          ponum: `${memo} | ${new Date().toLocaleDateString()} | ${tranref}`,
          rmatype: "SA (Stock Adjustment)",
          rmanotes: "Manually Processed RMA",
          item: item.item,
          qty: item.qty,
          pricelevel: "Custom",
          unitprice: 0,
          discount: "0% Discount",
        };

        return RMAExportItem;
      });
      setExportData(RMAExportArr);
    }
  };

  return [
    exportData,
    {
      getTOExportArr,
      getExportArr,
      getIADJExportArr,
      getINVTRANExportArr,
      getRMAExportArr,
    },
  ];
};
