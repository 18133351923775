import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSalesRepCustomerMatch } from "../../hooks/useSalesRepCustomerMatch";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  closeFullScreenModal,
  openFullScreenModal,
} from "../../redux/features/modal/modalSlice";
import { Button, Container, IconButton, Typography, Link } from "@mui/material";

import FullScreenModal from "../../components/FullScreenModal";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Loading from "../../components/Loading";
import axios from "../../axios/axios.config";
import FlexWrapper from "../../components/FlexWrapper";
import Proposal from "./forms/Proposal";

const ProposalPage = () => {
  const [proposalRows, setProposalRows] = useState([]);
  const [formLoading, setFormLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { filteredCustomersList } = useSalesRepCustomerMatch();
  const { currentSubsidiary, username } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const COLUMNS = [
    {
      field: "_id",
      headerName: "ID",
      width: 250,
      sortable: false,
      renderCell: (params) => (
        <Link
          id={params.row._id}
          sx={{ cursor: "pointer" }}
          onClick={(e) =>
            setSearchParams({
              modal: "open",
              editing: "true",
              proposal: e.target.id,
            })
          }
        >
          {params.row._id}
        </Link>
      ),
    },
    {
      field: "proposalFor",
      headerName: "Proposal For",
      width: 250,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1].proposalFor;
      },
    },
    {
      field: "group",
      headerName: "Group",
      width: 250,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1].group;
      },
    },
    {
      field: "username",
      headerName: "Created By",
      width: 200,
      valueGetter: (params) => {
        return params.row.createdby.username;
      },
    },
    {
      field: "agency",
      headerName: "Sales Agency",
      width: 250,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1].agency;
      },
    },
    {
      field: "date",
      headerName: "Date",
      type: "string",
      width: 100,
      valueGetter: (params) => {
        return new Date(
          params.row.versions[params.row.versions.length - 1].date
        )
          .toISOString()
          .slice(0, 10);
      },
    },
  ];

  useEffect(() => {
    if (filteredCustomersList?.length > 0) {
      getRows();
    }
  }, [filteredCustomersList]);

  useEffect(() => {
    if (searchParams.get("modal") === "open") {
      dispatch(openFullScreenModal());
    }
    if (!searchParams.get("modal")) {
      dispatch(closeFullScreenModal());
    }
  }, [searchParams]);

  const getRows = async () => {
    try {
      const filter = {
        $or: [
          {
            type: "proposal",
            "versions.proposalFor": { $in: filteredCustomersList },
            subsidiary: currentSubsidiary,
          },
          {
            type: "proposal",
            subsidiary: currentSubsidiary,
            "createdby.username": username,
          },
        ],
      };

      const response = await axios.post(`/forms/get/filtered-forms`, filter);
      setProposalRows(response.data);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not get Labor Claim Forms`,
          duration: 5000,
        })
      );
    } finally {
      setFormLoading(false);
    }
  };

  if (formLoading) {
    return <Loading message="Loading Proposal Forms" />;
  }

  return (
    <Container maxWidth="xl">
      <FullScreenModal>
        <Container maxWidth="xl">
          <IconButton
            onClick={() => {
              dispatch(closeFullScreenModal());
              setSearchParams({});
            }}
          >
            <CloseIcon />
          </IconButton>
          <Proposal
            proposalRows={proposalRows}
            setProposalRows={setProposalRows}
          />
        </Container>
      </FullScreenModal>
      <Typography variant="h4" textAlign="center">
        Proposal Forms
      </Typography>
      <FlexWrapper justifyContent="flex-end">
        <Button
          variant="outlined"
          endIcon={<AddIcon />}
          onClick={() => {
            setSearchParams({ modal: "open", editing: "false" });
          }}
          sx={{ mb: 3 }}
        >
          Add New Record
        </Button>
      </FlexWrapper>
      <DataGrid
        rows={proposalRows}
        columns={COLUMNS}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        autoHeight
        density="compact"
        disableColumnMenu
        getRowId={(row) => row._id}
      />
    </Container>
  );
};
export default ProposalPage;
