import axios from "../../axios/axios.config";

export const laborClaimProductLoader = async ({ params }) => {
  try {
    const { formid } = params;

    const formResponse = await axios.get(`forms/labor-claim/${formid}`);
    const fileKeys = formResponse.data.form.file.map(
      (filename) => `forms/laborclaim/${formid}/${filename}`
    );
    const fileURLResponse = await axios.post("files/multi/signedURL", {
      options: { expiresIn: 3600 },
      fileKeys,
    });

    return { data: formResponse.data, fileURL: fileURLResponse.data };
  } catch (error) {
    throw new Response("", {
      status: error.response?.status || 500,
      statusText: error.response?.data?.msg || error.message,
    });
  }
};
