import { Box, IconButton, Modal } from "@mui/material";
import { useState } from "react";

const GeneralModalV2 = ({
  openComponent,
  component,
  closeComponent,
  onClose = null,
  width = null,
  height = null,
  bgcolor = "white",
  disableClick = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width ? width : { xs: 340, md: 600 },
    minHeight: height ? height : { xs: 200 },
    bgcolor: bgcolor,
    borderRadius: "4px",
    boxShadow: 10,
    overflow: "hidden",
  };
  return (
    <>
      <Box onClick={() => setOpen(true)}>{openComponent}</Box>
      <Modal
        sx={{
          pointerEvents: disableClick ? "none" : "initial",
          border: "none",
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {typeof component === "function" ? component(handleClose) : component}
          {closeComponent && <Box onClick={handleClose}>{closeComponent}</Box>}
        </Box>
      </Modal>
    </>
  );
};
export default GeneralModalV2;
