import { useDispatch, useSelector } from "react-redux";
import { closeFullScreenModal } from "../../../redux/features/modal/modalSlice";
import { ArrowForward } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";

import FullScreenModal from "../../FullScreenModal";
import FlexWrapper from "../../FlexWrapper";

const PickOrderDataFullScreenModal = ({
  initial,
  setInitial,
  handleInitialLoad,
}) => {
  const pickOrderData = useSelector((state) => state.pickOrder);
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");

  const renderDetail = (label, data, uppercase = false) => {
    if (!data) return null;
    return (
      <div
        style={{
          marginBottom: "8px",
          borderBottom: "1px solid #ccc",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "baseline",
        }}
      >
        <Typography variant="subtitle1" gutterBottom pr={2}>
          {label}:
        </Typography>
        <Typography variant="body1">
          {uppercase && data ? data.toUpperCase() : data}
        </Typography>
      </div>
    );
  };

  const handleContinue = async () => {
    dispatch(closeFullScreenModal());
    if (initial) {
      await handleInitialLoad();
      setInitial(false);
    }
  };

  return (
    <FullScreenModal>
      <Box sx={{ p: 1 }}>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Order Details
        </Typography>

        <Container maxWidth="sm">
          <Box
            style={{
              backgroundColor: "#1976d2",
              borderRadius: "8px",
              padding: "24px",
              color: "#FFF",
              maxHeight: matches ? "550px" : "700px",
              overflowY: "auto",
            }}
          >
            {renderDetail("Transaction Type", pickOrderData.trantype, true)}
            {renderDetail("Brand Packaging", pickOrderData.brandpackaging)}
            {renderDetail("Transaction ID", pickOrderData.tranid)}
            {renderDetail("Customer", pickOrderData.customer)}
            {renderDetail("Location", pickOrderData.location)}
            {renderDetail("Warehouse Notes", pickOrderData.warehousenotes)}
          </Box>
          <FlexWrapper justifyContent="flex-end">
            <Button
              sx={{ my: 1 }}
              endIcon={<ArrowForward />}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </FlexWrapper>
        </Container>
      </Box>
    </FullScreenModal>
  );
};
export default PickOrderDataFullScreenModal;
