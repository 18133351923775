import axios from "../../axios/axios.config";

export const sendEmail = async (
  to,
  subject,
  cc,
  body,
  isHtml = false,
  templateId = null,
  dynamicTemplateData = null
) => {
  try {
    if (!to || !subject) {
      throw new Error(
        "Missing one or more required fields: 'to', or 'subject'"
      );
    }

    if (templateId && !dynamicTemplateData) {
      throw new Error(
        "A template id exists but no template data provided. Please provide template data or remove template id."
      );
    }

    if (!templateId && !body) {
      throw new Error(
        "No template id is present and no body has been provided. Please provide a body or include a template id with corresponding template data."
      );
    }

    const msg = {
      to,
      subject,
      cc: cc === to || (Array.isArray(to) && to.includes(cc)) ? "" : cc,
      from: "webservices@trakmotive.com",
    };

    if (templateId) {
      msg.templateId = templateId;
      msg.dynamicTemplateData = dynamicTemplateData;
    } else {
      if (isHtml) {
        msg.html = body;
      } else {
        msg.text = body;
      }
    }

    return await axios.post("email/general", msg);
  } catch (error) {
    throw error;
  }
};
