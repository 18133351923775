import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSelected } from "../redux/features/tote/toteSlice";
import { Tab, Tabs, Box, IconButton, Tooltip } from "@mui/material";
import { DoneAll } from "@mui/icons-material";
import {
  TOTESEARCHCOLUMNS,
  TOTESEARCHCOLUMNSRTV,
} from "../utils/columns/tote-search/toteSearchColumns";
import { useGeneralAlert } from "../hooks/useGeneralAlert";
import { getAllTotes } from "../redux/features/batches/batchesSlice";
import {
  closeCloseTotesModal,
  openCloseTotesModal,
} from "../redux/features/modal/modalSlice";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid";

import FlexWrapper from "./FlexWrapper";
import WarningActionModal from "./WarningActionModal";
import ExportMultiCSVS from "./ExportMultiCSVS";
import Loading from "./Loading";

const CustomToolbar = ({ selected, setSelected, getToteRows }) => {
  const { rolelevel } = useSelector((state) => state.user);
  const { isCloseTotesModalOpen } = useSelector((state) => state.modal);
  const { openAlert } = useGeneralAlert();

  const dispatch = useDispatch();

  const modalAction = async () => {
    try {
      await dispatch(closeSelected(selected.map((obj) => obj.toteid))).unwrap();
      setSelected([]);
      getToteRows();
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Update Totes: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    }
  };

  function handleOpenModal() {
    dispatch(openCloseTotesModal());
  }

  const handleCloseModal = () => {
    dispatch(closeCloseTotesModal());
  };

  return (
    <GridToolbarContainer>
      {/* Warning modal for closure */}
      <WarningActionModal
        open={isCloseTotesModalOpen}
        closeFn={handleCloseModal}
        title={`Are you sure you want to close ${selected.length} tote(s)?`}
        body={`You will no longer be able to add or edit items in the following totes: ${selected
          .map((obj) => obj.toteid)
          .join(", ")}`}
        modalAction={modalAction}
      />

      <FlexWrapper justifyContent="flex-end" width="100%" gap={1}>
        {rolelevel <= 3 && (
          <IconButton
            disabled={selected.length === 0}
            onClick={handleOpenModal}
          >
            <Tooltip
              disableTouchListener={selected.length === 0}
              title="Close Selected Totes"
              placement="top"
              arrow
            >
              <DoneAll color={selected.length ? "error" : ""} />
            </Tooltip>
          </IconButton>
        )}

        <ExportMultiCSVS
          type="icon"
          selected={selected.length ? selected.map((obj) => obj.toteid) : []}
          disabled={selected.length === 0}
          color={selected.length === 0 ? "" : "primary"}
        />
      </FlexWrapper>
      <FlexWrapper
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <FlexWrapper gap={2} alignItems="center">
          <GridToolbarFilterButton />
        </FlexWrapper>
        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
};

const ToteResults = () => {
  const [otherSelected, setOtherSelected] = useState([]);
  const [rtvSelected, setRTVSelected] = useState([]);
  const [tabValue, setTabValue] = useState("active");
  const [returnToVendorRows, setReturnToVendorRows] = useState([]);
  const [otherRows, setOtherRows] = useState([]);

  const otherGridApiRef = useGridApiRef();
  const rtvGridApiRef = useGridApiRef();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.batches);
  const { currentSubsidiary } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();

  useEffect(() => {
    getToteRows();
  }, []);

  const getToteRows = async () => {
    try {
      const data = await dispatch(getAllTotes(currentSubsidiary)).unwrap();

      setReturnToVendorRows(
        data.filter((row) => row.memo.startsWith("Return To Vendor"))
      );
      setOtherRows(
        data.filter((row) => !row.memo.startsWith("Return To Vendor"))
      );
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Get Data: ${error.message}`,
        duration: 8000,
      });
    }
  };

  if (loading) return <Loading message={loading} />;

  return (
    <Box>
      <Tabs
        centered
        value={tabValue}
        onChange={(event, newValue) => {
          if (newValue === "rtv") {
            setOtherSelected([]);
          }
          if (newValue === "active") {
            setRTVSelected([]);
          }

          setTabValue(newValue);
        }}
      >
        <Tab value="active" label="Active" />
        <Tab value="rtv" label="Return To Vendor" />
      </Tabs>

      {tabValue === "active" && (
        <DataGrid
          apiRef={otherGridApiRef}
          sx={{ height: "80vh" }}
          checkboxSelection
          rows={otherRows}
          columns={TOTESEARCHCOLUMNS}
          getRowId={(row) => row.toteid}
          slots={{
            toolbar: () => (
              <CustomToolbar
                getToteRows={getToteRows}
                selected={otherSelected}
                setSelected={setOtherSelected}
              />
            ),
          }}
          disableColumnSelector
          disableDensitySelector
          density="compact"
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              csvOptions: {
                disableToolbarButton: true,
              },
            },
          }}
          onRowSelectionModelChange={(rows) => {
            const selectedRowsData = rows.map((id) =>
              otherGridApiRef.current.getRow(id)
            );

            setOtherSelected(selectedRowsData);
          }}
        />
      )}

      {tabValue === "rtv" && (
        <DataGrid
          apiRef={rtvGridApiRef}
          sx={{ height: "80vh" }}
          checkboxSelection
          rows={returnToVendorRows}
          columns={TOTESEARCHCOLUMNSRTV}
          getRowId={(row) => row.toteid}
          slots={{
            toolbar: () => (
              <CustomToolbar
                getToteRows={getToteRows}
                selected={rtvSelected}
                setSelected={setRTVSelected}
              />
            ),
          }}
          disableColumnSelector
          disableDensitySelector
          density="compact"
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              csvOptions: {
                disableToolbarButton: true,
              },
            },
          }}
          onRowSelectionModelChange={(rows) => {
            const selectedRowsData = rows.map((id) =>
              rtvGridApiRef.current.getRow(id)
            );

            setRTVSelected(selectedRowsData);
          }}
        />
      )}
    </Box>
  );
};

export default ToteResults;
