import { Button, Container, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FlexWrapper from "../../components/FlexWrapper";

const NotFound = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        height: "80vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h5" color="GrayText" gutterBottom>
        The page you have requested does not exist. Please navigate back to
        previous page or return to homepage.
      </Typography>
      <FlexWrapper gap={1} flexDirection={matches ? "column" : "row"}>
        <Button
          fullWidth={matches}
          onClick={() => navigate(-1)}
          sx={{ px: 3, py: 1 }}
          variant="contained"
          size="large"
        >
          Go Back
        </Button>
        <Button
          fullWidth={matches}
          onClick={() => navigate("/portal", { replace: true })}
          sx={{ px: 3, py: 1 }}
          variant="contained"
          size="large"
        >
          Return to Homepage
        </Button>
      </FlexWrapper>
    </Container>
  );
};
export default NotFound;
