import { Box, Button, Typography } from "@mui/material";
import GeneralModalV3 from "../../general/GeneralModalV3";
import { Error } from "@mui/icons-material";

const PickOrderBinErrorModal = ({ open, setBinNumber }) => {
  return (
    <GeneralModalV3 openVal={open} onClose={() => setBinNumber("")}>
      {(handleClose) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 3,
          }}
        >
          <Error color="error" sx={{ fontSize: 60 }} />
          <Typography
            variant="h6"
            component="h2"
            fontWeight="bold"
            textAlign="center"
            sx={{ my: 2 }}
          >
            The bin number is incorrect. Please scan the correct bin.
          </Typography>

          <Button
            variant="contained"
            color="error"
            size="large"
            onClick={() => {
              setBinNumber("");

              handleClose();
            }}
          >
            OK
          </Button>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default PickOrderBinErrorModal;
