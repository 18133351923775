import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { PRINTERCOLUMS } from "../../utils/columns/admin/adminColumns";
import { Add, Close, Edit } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import {
  closeFullScreenModal,
  openFullScreenModal,
} from "../../redux/features/modal/modalSlice";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../components/FlexWrapper";
import FullScreenModal from "../../components/FullScreenModal";
import axios from "../../axios/axios.config";

const PrinterForm = ({ selectedPrinter, setSelectedPrinter, setPrinters }) => {
  const dispatch = useDispatch();
  const [printerInput, setPrinterInput] = useState({
    printername: "",
    printerdescription: "",
    printermodel: "",
    printermanufacturer: "",
    printerlocation: "",
    printerresolution: "",
    printeripaddress: "",
    printnodeid: "",
  });

  useEffect(() => {
    if (selectedPrinter) {
      setPrinterInput({
        printername: selectedPrinter.printername,
        printerdescription: selectedPrinter.printerdescription,
        printermodel: selectedPrinter.printermodel,
        printermanufacturer: selectedPrinter.printermanufacturer,
        printerlocation: selectedPrinter.printerlocation,
        printerresolution: selectedPrinter.printerresolution,
        printeripaddress: selectedPrinter.printeripaddress,
        printnodeid: selectedPrinter.printnodeid,
      });
    } else {
      setPrinterInput({
        printername: "",
        printerdescription: "",
        printermodel: "",
        printermanufacturer: "",
        printerlocation: "",
        printerresolution: "",
        printeripaddress: "",
        printnodeid: "",
      });
    }
  }, [selectedPrinter]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let newPrinters;
      if (!selectedPrinter) {
        //create path
        const response = await axios.post("printers", printerInput);
        newPrinters = response.data;
      } else {
        //edit path
        const response = await axios.patch(
          `printers/${selectedPrinter._id}`,
          printerInput
        );
        newPrinters = response.data;
      }

      setPrinterInput({
        printername: "",
        printerdescription: "",
        printermodel: "",
        printermanufacturer: "",
        printerlocation: "",
        printerresolution: "",
        printeripaddress: "",
        printnodeid: "",
      });
      setPrinters(newPrinters);
      setSelectedPrinter(null);
      dispatch(closeFullScreenModal());
      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully ${
            selectedPrinter ? "updated" : "created"
          } printer`,
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${error.response?.data?.msg || error.message}`,
          duration: 5000,
        })
      );
    }
  };

  const handleChanges = (event) => {
    setPrinterInput({
      ...printerInput,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <FullScreenModal>
      <Box p={1}>
        <FlexWrapper>
          <IconButton
            onClick={() => {
              dispatch(closeFullScreenModal());
              setSelectedPrinter(null);
            }}
          >
            <Close />
          </IconButton>
        </FlexWrapper>

        <FlexWrapper justifyContent="center">
          <Container component="form" maxWidth="sm" onSubmit={handleSubmit}>
            <Typography variant="h3" textAlign="center" gutterBottom>
              {selectedPrinter ? "Update" : "Create"} Printer
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  fullWidth
                  required
                  type="text"
                  name="printername"
                  label="Printer Name"
                  value={printerInput.printername}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printerdescription"
                  label="Printer Description"
                  value={printerInput.printerdescription}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printermodel"
                  label="Printer Model"
                  value={printerInput.printermodel}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printermanufacturer"
                  label="Printer Manufacturer"
                  value={printerInput.printermanufacturer}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printerlocation"
                  label="Printer Location"
                  value={printerInput.printerlocation}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printerresolution"
                  label="Printer Resolution"
                  value={printerInput.printerresolution}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printeripaddress"
                  label="Printer IP Address"
                  value={printerInput.printeripaddress}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  name="printnodeid"
                  label="Printnode ID"
                  value={printerInput.printnodeid}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" type="submit" fullWidth>
                  {selectedPrinter ? "Update" : "Create"} Printer
                </Button>
              </Grid>
            </Grid>
          </Container>
        </FlexWrapper>
      </Box>
    </FullScreenModal>
  );
};

const Printers = () => {
  const printerData = useLoaderData();
  const [printers, setPrinters] = useState(printerData);
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const dispatch = useDispatch();

  return (
    <Box flex={1} px={2} py={1} overflow="hidden">
      <PrinterForm
        setPrinters={setPrinters}
        selectedPrinter={selectedPrinter}
        setSelectedPrinter={setSelectedPrinter}
      />

      <FlexWrapper flexDirection="column" gap={1}>
        <FlexWrapper
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="h3" component="h1" gutterBottom>
            Printers
          </Typography>

          <FlexWrapper gap={1}>
            {selectedPrinter && (
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={() => dispatch(openFullScreenModal())}
              >
                Edit Printer
              </Button>
            )}
            <Button
              variant="contained"
              color="success"
              startIcon={<Add />}
              onClick={() => {
                dispatch(openFullScreenModal());
                setSelectedPrinter(null);
              }}
            >
              Create Printer
            </Button>
          </FlexWrapper>
        </FlexWrapper>

        <DataGrid
          sx={{ maxHeight: "60vh", width: "100%" }}
          rows={printers}
          columns={PRINTERCOLUMS}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[100, 150, 200]}
          density="compact"
          disableColumnMenu
          getRowId={(row) => row._id}
          slots={{ toolbar: GridToolbar }}
          disableDensitySelector
          disableColumnSelector
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              csvOptions: { fileName: `printers-${Date.now()}` },
            },
          }}
          onRowSelectionModelChange={(selection) => {
            setSelectedPrinter(
              printers.find((printer) => printer._id === selection[0])
            );
          }}
        />
      </FlexWrapper>
    </Box>
  );
};
export default Printers;
