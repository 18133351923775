import { Container } from "@mui/material";
import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { useSelector } from "react-redux";

const PackProcessLayout = () => {
  const { openAlert } = useGeneralAlert();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const user = useSelector((state) => state.user);

  return <Outlet context={{ navigate, openAlert, navigation, user }} />;
};
export default PackProcessLayout;
