import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { openGeneralAlert } from "../alert/alertSlice";

import axios from "../../../axios/axios.config";

const initialState = {
  packData: null,
  upcStorage: null,
  itemStorage: null,
  currentPallet: null,
};

export const updatePack = createAsyncThunk(
  "pack-process/updatePack",
  async ({ salesOrder, update, shipmentSequence }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `pack-process/${salesOrder}/scan/shipmentsequence/${shipmentSequence}`,
        update
      );
      return {
        data: response.data,
        item: update.item,
        index: update.index,
      };
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.message,
          duration: 5000,
        })
      );
    }
  }
);

export const removeItem = createAsyncThunk(
  "pack-process/removeItem",
  async ({ salesOrder, update, shipmentSequence }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `pack-process/${salesOrder}/shipmentsequence/${shipmentSequence}/remove/item`,
        update
      );
      return {
        data: response.data,
        index: update.index,
        upc: update.upc,
        item: update.itemName,
        quantityToDelete: update.quantityToDelete,
      };
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.message,
          duration: 5000,
        })
      );
    }
  }
);

export const deletePallet = createAsyncThunk(
  "pack-process/deletePallet",
  async ({ salesOrder, pallet, shipmentSequence, _id }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `pack-process/${salesOrder}/shipmentsequence/${shipmentSequence}/remove/pallet`,
        { pallet, _id }
      );
      const keys = Object.keys(response.data.packdetails).sort((a, b) => {
        const numA = parseInt(a.match(/\d+/)[0]);
        const numB = parseInt(b.match(/\d+/)[0]);
        return numA - numB;
      });
      const palletNumber = parseInt(
        keys[keys.length - 1].replace("pallet", "")
      );
      return {
        data: response.data,
        newPalletNumber: palletNumber,
      };
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.message,
          duration: 5000,
        })
      );
    }
  }
);

export const addPallet = createAsyncThunk(
  "pack-process/addPallet",
  async ({ salesOrder, update, shipmentSequence }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `pack-process/${salesOrder}/pallet/shipmentsequence/${shipmentSequence}`,
        update
      );
      return response.data;
    } catch (error) {
      thunkAPI.rejectWithValue(
        error?.reponse?.data?.msg ? error.reponse.data.msg : error.message
      );
    }
  }
);

const packProcessSlice = createSlice({
  name: "pack-process",
  initialState,
  reducers: {
    setPackData: (state, { payload }) => {
      state.packData = payload.data;
      state.upcStorage = payload.upcStorage;
      state.itemStorage = payload.itemStorage;
    },
    setCurrentPallet: (state, { payload }) => {
      state.currentPallet = payload;
    },
    updatePackData: (state, { payload }) => {
      state.packData.items[payload.index] = payload.newItem;
    },
    resetPack: (state, _) => {
      state.packData = null;
      state.upcStorage = null;
      state.itemStorage = null;
      state.currentPallet = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePack.fulfilled, (state, { payload }) => {
        state.packData = payload.data;
        state.itemStorage[payload.item.item][payload.index] = payload.item;
        state.upcStorage[payload.item.upc][payload.index] = payload.item;
      })
      .addCase(removeItem.fulfilled, (state, { payload }) => {
        state.packData = payload.data;
        state.itemStorage[payload.item][payload.index] = {
          ...state.itemStorage[payload.item][payload.index],
          packedamount:
            state.itemStorage[payload.item][payload.index].packedamount -
            payload.quantityToDelete,
        };
        state.upcStorage[payload.upc][payload.index] = {
          ...state.upcStorage[payload.upc][payload.index],
          packedamount:
            state.upcStorage[payload.upc][payload.index].packedamount -
            payload.quantityToDelete,
        };
      })
      .addCase(deletePallet.fulfilled, (state, { payload }) => {
        state.packData = payload.data;
        state.currentPallet = payload.newPalletNumber;
      })
      .addCase(addPallet.fulfilled, (state, { payload }) => {
        state.packData = payload;
      });
  },
});

export const { setPackData, setCurrentPallet, resetPack } =
  packProcessSlice.actions;

export default packProcessSlice.reducer;
