import {
  Document,
  Page,
  Text,
  usePDF,
  PDFViewer,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { IconButton, Tooltip } from "@mui/material";

import FilePresentIcon from "@mui/icons-material/FilePresent";

const BoxBreakdownPDF = ({
  data,
  totalQty,
  address,
  date,
  customer,
  notes,
  id,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={{ textAlign: "right", fontSize: "12px" }}>{id}</Text>
        </View>

        <View>
          <View
            style={{
              paddingVertical: "5px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                textDecoration: "underline",
                fontSize: "18px",
                paddingRight: "10px",
              }}
            >
              Date:
            </Text>
            <Text style={{ fontSize: "14px" }}>{date}</Text>
          </View>

          <View
            style={{
              paddingVertical: "5px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                textDecoration: "underline",
                fontSize: "18px",
                paddingRight: "10px",
              }}
            >
              Customer:
            </Text>
            <Text style={{ fontSize: "14px" }}>{customer}</Text>
          </View>
        </View>

        <View
          style={{
            paddingVertical: "5px",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              textDecoration: "underline",
              fontSize: "18px",
              paddingRight: "10px",
            }}
          >
            Shipping Address:
          </Text>
          <Text style={{ fontSize: "14px" }}>{address}</Text>
        </View>

        <View
          style={{
            paddingVertical: "5px",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              textDecoration: "underline",
              fontSize: "18px",
              paddingRight: "10px",
            }}
          >
            Additional Notes:
          </Text>
          <Text style={{ fontSize: "14px" }}>{notes}</Text>
        </View>
        <View>
          <Text
            style={{
              fontSize: "24px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Box Details
          </Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={{ textAlign: "center" }}>Box Size</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={{ textAlign: "center" }}>Quantity</Text>
            </View>
          </View>
          {data.map((boxData) => {
            return (
              <View key={boxData.boxsize} style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text style={{ textAlign: "center", fontWeight: 800 }}>
                    {boxData.boxsize}
                  </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={{ textAlign: "center" }}>{boxData.qty}</Text>
                </View>
              </View>
            );
          })}
        </View>

        <View>
          <Text>Total Box Quantity: {totalQty}</Text>
        </View>
      </Page>
    </Document>
  );
};

const LabelsAndBoxRequestBoxBreakdownPDF = ({
  data,
  totalQty,
  address,
  date,
  customer,
  title,
  notes,
  id,
}) => {
  const [instance, updateInstance] = usePDF({
    document: (
      <BoxBreakdownPDF
        data={data}
        totalQty={totalQty}
        address={address}
        date={date}
        customer={customer}
        notes={notes}
        id={id}
      />
    ),
  });

  return !instance.loading && !instance.error ? (
    <div>
      <Tooltip title="View PDF">
        <a href={instance.url} target="_blank">
          <IconButton aria-label="download">
            <FilePresentIcon />
          </IconButton>
        </a>
      </Tooltip>
    </div>
  ) : null;

  //RENDER PDF WHILE EVELOPING
  // return (
  //   <PDFViewer width="100%" height="550px" showToolbar>
  //     <BoxBreakdownPDF
  //       data={data}
  //       totalQty={totalQty}
  //       address={address}
  //       date={date}
  //       customer={customer}
  //     />
  //   </PDFViewer>
  // );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: "12px",
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    marginBottom: 10,
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    padding: 1,
    fontSize: "12px",
  },
});

export default LabelsAndBoxRequestBoxBreakdownPDF;
