import { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";

const GeneralModalV3 = ({
  openComponent = null,
  children,
  onClose = null,
  width = null,
  height = null,
  bgcolor = "white",
  disableClick = false,
  openVal = false,
  keepMounted = true,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openVal) {
      setOpen(openVal);
    }
  }, [openVal]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width ? width : { xs: 340, md: 600 },
    minHeight: height ? height : { xs: 200 },
    bgcolor: bgcolor,
    borderRadius: "4px",
    boxShadow: 10,
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <>
      {openComponent && (
        <Box onClick={() => setOpen(true)}>{openComponent}</Box>
      )}
      <Modal
        sx={{
          pointerEvents: disableClick ? "none" : "initial",
          border: "none",
        }}
        keepMounted={keepMounted}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {typeof children === "function" ? children(handleClose) : children}
        </Box>
      </Modal>
    </>
  );
};
export default GeneralModalV3;
