import { utils, writeFile } from "xlsx";
import { Box, IconButton, Tooltip } from "@mui/material";
import { FileDownload } from "@mui/icons-material";

const GeneralExportV2 = ({
  exportData = [],
  triggerComponent = null,
  fileName = "export",
  sheetName = "Sample Export",
  exportTitle = "Export",
  color = "primary",
  exportType = "xlsx",
  dataType = "json", //Either json or Array of Arrays (aoa)
}) => {
  const handleExport = () => {
    /* create worksheet */
    let ws = null;
    if (dataType === "aoa") {
      ws = utils.aoa_to_sheet(exportData);
    } else {
      ws = utils.json_to_sheet(exportData);
    }

    /* create workbook and export */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);
    writeFile(wb, `${fileName}`, { bookType: exportType });
  };

  return (
    <Box onClick={handleExport}>
      <Tooltip title={exportTitle} arrow placement="top">
        {triggerComponent ? (
          triggerComponent
        ) : (
          <IconButton>
            <FileDownload color={color} />
          </IconButton>
        )}
      </Tooltip>
    </Box>
  );
};
export default GeneralExportV2;
