import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExitToApp, ExpandMore, Folder, Person } from "@mui/icons-material";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import {
  COMMONRESPONSES,
  OTHERRESPONSES,
} from "../../../utils/columns/product/productColumns";
import {
  useLoaderData,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../components/FlexWrapper";
import GeneralModal from "../../../components/GeneralModal";
import axios from "../../../axios/axios.config";
import LaborClaimWRMAPDF from "../../../components/PDF/multi-page/LaborClaimWRMAPDF";
import GeneralPDFDownload from "../../../components/PDF/GeneralPDFDownload";
import RMANotes from "../../../components/multi-page/labor-claim/RMANotes";
import MarkAsPendingDocumentsButton from "../../../components/multi-page/labor-claim/MarkAsPendingDocumentsButton";
import Attachments from "../../../components/multi-page/labor-claim/Attachments";
import CreditMemoAndRMANetsuiteLinks from "../../../components/multi-page/netsuite/CreditMemoAndRMANetsuiteLinks";

const Modal = ({ open, handleClose, children }) => {
  return (
    <GeneralModal open={open} handleClose={handleClose}>
      {children}
    </GeneralModal>
  );
};

const LaborClaimProductFormView = () => {
  const {
    data: { form, createdby },
    fileURL,
  } = useLoaderData();
  const user = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();
  const { formid } = useParams();

  const [nsPDFLinks, setNSPDFLinks] = useState({ rma: "" });
  const [openModal, setOpenModal] = useState({ info: false, file: false });
  const [formInput, setFormInput] = useState({
    ...form,
    datereceived: form.datereceived
      ? new Date(form.datereceived).toISOString().slice(0, 10)
      : new Date().toISOString().slice(0, 10),
    timereceived:
      form.timereceived ||
      `${new Date().getHours().toString().padStart(2, "0")}:${new Date()
        .getMinutes()
        .toString()
        .padStart(2, "0")}`,
    receivedby: form.receivedby || user.username,
    receivedbyname: form.receivedbyname || `${user.firstname} ${user.lastname}`,
    receivedbyemail: form.receivedbyemail || user.email,
    processedby:
      form.processedby || searchParams.get("status") === "lcreceived"
        ? user.username
        : "",
    processedbyname:
      form.processedbyname || searchParams.get("status") === "lcreceived"
        ? `${user.firstname} ${user.lastname}`
        : "",
    processedbyemail:
      form.processedbyemail || searchParams.get("status") === "lcreceived"
        ? user.email
        : "",
    processedbydate:
      form.processedbydate || searchParams.get("status") === "lcreceived"
        ? new Date().toLocaleString()
        : "",
    detail: "",
    note: "",
    lcresponse: "",
    finalresponse: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !searchParams.get("status") ||
      (searchParams.get("status") !== "lcreceived" &&
        searchParams.get("status") !== "rmaissued")
    ) {
      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );
    }
  }, []);

  useEffect(() => {
    getNSPDFSLinks();
  }, []);

  const getNSPDFSLinks = async () => {
    let rmaBlobUrl = "";
    let creditMemoBlobUrl = "";
    if (form.rmaNum && !form.rmaNum.startsWith("W")) {
      if (form.rmaNum) {
        // RMA
        const nsRMAPDFResponse = await axios.get(
          `netsuite/labor-claim/get/rma/${form.rmainternalid}/pdf`
        );

        const rmaBase64 = nsRMAPDFResponse.data.data64;

        // Convert base64 to Blob
        const rmaByteCharacters = atob(rmaBase64);
        const rmaByteNumbers = new Array(rmaByteCharacters.length);
        for (let i = 0; i < rmaByteCharacters.length; i++) {
          rmaByteNumbers[i] = rmaByteCharacters.charCodeAt(i);
        }
        const rmaByteArray = new Uint8Array(rmaByteNumbers);
        const rmaBlob = new Blob([rmaByteArray], { type: "application/pdf" });

        // Create a Blob URL
        rmaBlobUrl = URL.createObjectURL(rmaBlob);
      }

      setNSPDFLinks({ creditMemo: creditMemoBlobUrl, rma: rmaBlobUrl });
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      const dateReceivedArr = formInput.datereceived.split("-");

      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          form: {
            ...formInput,
            datereceived:
              form.datereceived ||
              `${dateReceivedArr[1]}/${dateReceivedArr[2]}/${dateReceivedArr[0]}`,
            status:
              searchParams.get("status") === "lcreceived"
                ? "processed"
                : "lcreceived",
          },
        },
      });

      if (data.form.rmainternalid) {
        //update netsuite if not a WRMA
        updateNetsuite(data);
      }

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully updated labor claim to ${
            searchParams.get("status") === "lcreceived"
              ? "Processed"
              : "LC Received"
          }`,
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not update labor claim status: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    }
  };

  const handleChanges = (event) => {
    setFormInput({
      ...formInput,
      [event.target.name]:
        event.target.name === "detail"
          ? event.target.value
          : event.target.value.toUpperCase(),
    });
  };

  const handleProcessedResponsesChanges = (event) => {
    setFormInput({
      ...formInput,
      [event.target.name]: event.target.value,
      detail: COMMONRESPONSES[event.target.value]
        ? COMMONRESPONSES[event.target.value].detail
        : OTHERRESPONSES[event.target.value].detail,
      note: COMMONRESPONSES[event.target.value]
        ? COMMONRESPONSES[event.target.value].note
        : OTHERRESPONSES[event.target.value].note,
    });
  };

  const updateNetsuite = async (data) => {
    try {
      //update Netsuite fields
      await axios.patch(
        `netsuite/patch/rma/${data.form.rmainternalid}/product/update-fields`,
        {
          status: data.form.status,
          bin: data.form.bin,
          date: new Date().toISOString(),
        }
      );

      if (data.form.status.includes("lcreceived")) {
        await axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/product/receive-rma`,
          { username: user.username, bin: data.form.bin }
        );
      }
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not update Netsuite: ${
            error.response?.data?.msg || error.message
          }`,
        })
      );
    }
  };

  return (
    <Container maxWidth="md" sx={{ pb: 2 }}>
      {/* Info Modal */}
      <Modal
        open={openModal.info}
        handleClose={() => setOpenModal({ info: false, file: false })}
      >
        <Box p={2}>
          <Typography gutterBottom variant="h4" textAlign="center">
            Labor Claim Created By
          </Typography>

          <Typography>
            <strong>Name:</strong> {createdby.name}
          </Typography>
          <Typography>
            <strong>Username:</strong> {createdby.username.toUpperCase()}
          </Typography>
          <Typography>
            <strong>Email:</strong> {createdby.email.toUpperCase()}
          </Typography>
          <Typography>
            <strong>Department:</strong> {createdby.department}
          </Typography>
          <Typography>
            <strong>User Type:</strong> {createdby.usertype.toUpperCase()}
          </Typography>
        </Box>
      </Modal>

      <Box>
        <FlexWrapper justifyContent="space-between">
          <Tooltip title="Back To Labor Claims" placement="top" arrow>
            <IconButton
              onClick={() =>
                navigate(
                  `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
                )
              }
            >
              <ExitToApp color="error" />
            </IconButton>
          </Tooltip>

          <FlexWrapper alignItems="center">
            {form.status === "lcreceived" && (
              <>
                <MarkAsPendingDocumentsButton formid={formid} />
                <Divider flexItem orientation="vertical" />
              </>
            )}

            <Tooltip title="Created By User" placement="top" arrow>
              <IconButton
                onClick={() => {
                  setOpenModal({ file: false, info: true });
                }}
              >
                <Person color="info" />
              </IconButton>
            </Tooltip>
            <Attachments
              formid={formid}
              form={form}
              fileURL={fileURL}
              nsPDFLinks={nsPDFLinks}
            />
            <RMANotes
              color="primary"
              formid={formid}
              email={{
                to:
                  process.env.REACT_APP_TEST_EMAIL || "returns@trakmotive.com",
                cc: user.email,
                subject: "Product Updated RMA Notes",
                isHtml: true,
                body: `<p>Product team has updated RMA notes on Netsuite and additional customer action may be required</p>`,
              }}
            />
          </FlexWrapper>
        </FlexWrapper>

        <Typography variant="h3" textAlign="center" gutterBottom>
          Labor Claim Form
        </Typography>
      </Box>

      <Grid container spacing={2} component="form" onSubmit={handleEditSubmit}>
        <Grid item xs={12}>
          <Accordion elevation={1}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Form Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Status"
                    name="date"
                    value={formInput.status.toUpperCase()}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Created Date"
                    name="date"
                    value={formInput.date}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Customer"
                    name="customer"
                    value={formInput.customer}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="text"
                    name="rgaNum"
                    label="Customer Use Only: RGA#"
                    value={formInput.rgaNum}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                    Service Facility and Vehicle Owner
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="serviceFacilityCompany"
                    label="Company Name"
                    value={formInput.serviceFacilityCompany}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="serviceFacilityContactName"
                    label="Contact Name"
                    value={formInput.serviceFacilityContactName}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    helperText={
                      <Typography variant="caption" color="error">
                        No Special Characters
                      </Typography>
                    }
                    type="number"
                    inputMode="numeric"
                    name="serviceFacilityPhone"
                    label="Phone"
                    value={formInput.serviceFacilityPhone}
                    InputProps={{ readOnly: true }}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="email"
                    name="serviceFacilityEmail"
                    label="E-mail"
                    value={formInput.serviceFacilityEmail}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    type="text"
                    name="serviceFacilityAddress"
                    label="Address"
                    value={formInput.serviceFacilityAddress}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="technicianName"
                    label="Technician's Name"
                    value={formInput.technicianName}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="serviceFacilityVehicleOwner"
                    label="Vehicle Owner’s Name"
                    value={formInput.serviceFacilityVehicleOwner}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                    Selling Party
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="warehouseCompany"
                    label="Company Name"
                    value={formInput.warehouseCompany}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="warehouseContactName"
                    label="Contact Name"
                    value={formInput.warehouseContactName}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    helperText={
                      <Typography variant="caption" color="error">
                        No Special Characters
                      </Typography>
                    }
                    type="number"
                    inputMode="numeric"
                    name="warehousePhone"
                    label="Phone"
                    value={formInput.warehousePhone}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="email"
                    name="warehouseEmail"
                    label="E-mail"
                    value={formInput.warehouseEmail}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                {formInput.additionalEmails && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Additional Emails"
                      value={formInput.additionalEmails}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    type="text"
                    name="warehouseAddress"
                    label="Address"
                    value={formInput.warehouseAddress}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                    Vehicle Information
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="vinNum"
                    label="VIN"
                    value={formInput.vinNum}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="number"
                    inputMode="numeric"
                    name="vehicleYear"
                    label="Year"
                    value={formInput.vehicleYear}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="text"
                    name="vehicleMake"
                    label="Make"
                    value={formInput.vehicleMake}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="text"
                    name="vehicleModel"
                    label="Model"
                    value={formInput.vehicleModel}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="text"
                    name="vehicleEngineSize"
                    label="Engine Size"
                    value={formInput.vehicleEngineSize}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth required disabled>
                    <InputLabel id="select-label-type">
                      Transmission Type
                    </InputLabel>
                    <Select
                      labelId="select-label-type"
                      id="select-type"
                      label="Transmission Type"
                      name="vehicleEngineType"
                      value={formInput.vehicleEngineType}
                      InputProps={{ readOnly: true }}
                    >
                      <MenuItem value="AUTOMATIC">Automatic</MenuItem>
                      <MenuItem value="MANUAL">Manual</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth required disabled>
                    <InputLabel id="select-label-drive">
                      Vehicle Drive
                    </InputLabel>
                    <Select
                      labelId="select-label-drive"
                      id="select-drive"
                      label="Vehicle Drive"
                      name="vehicleDrive"
                      value={formInput.vehicleDrive}
                      InputProps={{ readOnly: true }}
                    >
                      <MenuItem value="FRONT WHEEL DRIVE">
                        Front Wheel Drive
                      </MenuItem>
                      <MenuItem value="REAR WHEEL DRIVE">
                        Rear Wheel Drive
                      </MenuItem>
                      <MenuItem value="FOUR WHEEL DRIVE">
                        Four Wheel Drive
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth disabled>
                    <InputLabel id="select-label-gas">
                      Engine Sub Type
                    </InputLabel>
                    <Select
                      multiple
                      labelId="select-label-gas"
                      id="select-gas"
                      label="Engine Sub Type"
                      name="vehicleGasType"
                      value={formInput.vehicleGasType}
                    >
                      <MenuItem value="Turbo">Turbo</MenuItem>
                      <MenuItem value="Gas">Gas</MenuItem>
                      <MenuItem value="Diesel">Diesel</MenuItem>
                      <MenuItem value="Hybrid">Hybrid</MenuItem>
                      <MenuItem value="Electric">Electric</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" mt={2}>
                    Associated Parts and Labor
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    inputMode="numeric"
                    name="laborHours"
                    label="Total Labor Hrs"
                    value={formInput.laborHours}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="partsCost"
                    label="Parts Cost"
                    value={formInput.partsCost}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="laborCost"
                    label="Labor Cost"
                    value={formInput.laborCost}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="laborRate"
                    label="Labor Rate"
                    value={formInput.laborRate}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    name="totalClaim"
                    label="Total Claim Amount"
                    value={formInput.totalClaim}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                    Product
                  </Typography>
                </Grid>
                {formInput.partNumbers?.length &&
                  formInput.partNumbers.map((partObj) => {
                    return (
                      <Fragment key={partObj.partnumber}>
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Part Number"
                            value={partObj.partnumber}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            type="number"
                            label="Part Number Qty"
                            value={partObj.qty}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </Fragment>
                    );
                  })}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="manufacturer"
                    label="Manufacturer"
                    value={formInput.manufacturer}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    inputMode="numeric"
                    name="mileageInstallation"
                    label="Mileage At Installation"
                    value={formInput.mileageInstallation}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    inputMode="numeric"
                    name="mileageFailure"
                    label="Mileage At Failure"
                    value={formInput.mileageFailure}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="date"
                    name="dateInstallation"
                    label="Date At Installation"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formInput.dateInstallation}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="date"
                    name="dateFailure"
                    label="Date At Failure"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formInput.dateFailure}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    type="text"
                    name="failureDescription"
                    label="Description of Failure and Collateral Damage"
                    value={formInput.failureDescription}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                {formInput.comments && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      minRows={4}
                      type="text"
                      name="comments"
                      label="Additional Comments"
                      value={formInput.comments}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <FlexWrapper alignItems="center" gap={1}>
            <Typography variant="h6">RMA</Typography>
            {form.rmainternalid && (
              <CreditMemoAndRMANetsuiteLinks internalid={form.rmainternalid} />
            )}
          </FlexWrapper>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            name="rmaNum"
            label="RMA Number"
            InputProps={{ readOnly: true }}
            value={form.rmaNum}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="text"
            name="rmaissuedby"
            label="RMA Issued By"
            value={`${form.rmaissuedbyname} (${form.rmaissuedby})`}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="text"
            name="rmaissuedbyemail"
            label="RMA Issued By Email"
            value={form.rmaissuedbyemail}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            label="RMA Issued By Department"
            value={form.rmaissueddepartment}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="text"
            label="RMA Issued Date"
            value={new Date(form.rmaissuedate).toLocaleDateString()}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="text"
            label="RMA Issued Time"
            value={new Date(form.rmaissuedate).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {searchParams.get("status") === "lcreceived" && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">LC Received</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                name="receivedby"
                label="Received By"
                value={`${formInput.receivedbyname} (${formInput.receivedby})`}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                name="receivedbyemail"
                label="Received By Email"
                value={formInput.receivedbyemail}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="date"
                name="datereceived"
                label="Date Received"
                value={formInput.datereceived}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="time"
                name="timereceived"
                label="Time Received"
                value={formInput.timereceived}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                label="Bin Number"
                value={formInput.bin}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </>
        )}

        {searchParams.get("status") === "rmaissued" ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom textAlign="center">
                LC Received
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                type="date"
                name="datereceived"
                label="Date Received"
                InputLabelProps={{ shrink: true }}
                value={formInput.datereceived}
                onChange={handleChanges}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                type="time"
                name="timereceived"
                label="Time Received"
                InputLabelProps={{ shrink: true }}
                value={formInput.timereceived}
                onChange={handleChanges}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="bin"
                label="Bin Number"
                value={formInput.bin}
                onChange={handleChanges}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                type="text"
                name="receivedby"
                label="Received By"
                value={formInput.receivedby}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                type="text"
                name="receivedbyemail"
                label="Received By Email"
                value={formInput.receivedbyemail}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom textAlign="center">
                Processed
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="select-label-lc-options">Responses</InputLabel>
                <Select
                  labelId="select-label-lc-options"
                  id="select-drive"
                  label="Responses"
                  name="lcresponse"
                  value={formInput.lcresponse}
                  onChange={handleProcessedResponsesChanges}
                >
                  <ListSubheader>COMMON</ListSubheader>
                  {Object.keys(COMMONRESPONSES).map((response) => (
                    <MenuItem
                      data-group="common"
                      key={response}
                      value={response}
                    >
                      {response}
                    </MenuItem>
                  ))}

                  <ListSubheader>OTHER</ListSubheader>
                  {Object.keys(OTHERRESPONSES).map((response) => (
                    <MenuItem
                      data-group="other"
                      key={response}
                      value={response}
                    >
                      {response}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                multiline
                minRows={4}
                type="text"
                name="detail"
                label="Detail"
                value={formInput.detail}
                onChange={handleChanges}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                name="note"
                label="Note"
                value={formInput.note}
                onChange={handleChanges}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="select-label-lc-options">Result</InputLabel>
                <Select
                  label="Result"
                  name="finalresponse"
                  value={formInput.finalresponse}
                  onChange={handleChanges}
                >
                  <MenuItem value="VOID">VOID</MenuItem>
                  <MenuItem value="DENIED">DENIED</MenuItem>
                  <MenuItem value="PARTIALLY APPROVED">
                    PARTIALLY APPROVED
                  </MenuItem>
                  <MenuItem value="FULLY APPROVED">FULLY APPROVED</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Button variant="contained" fullWidth type="submit">
            {searchParams.get("status") === "rmaissued"
              ? "Update form to LC Received"
              : "Update form to processed"}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LaborClaimProductFormView;
