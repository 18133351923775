import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../axios/axios.config";

const initialState = {
  salesCommunications: [],
};

export const createSalesCommunication = createAsyncThunk(
  "communications/createSalesCommunication",
  async (
    { newCommunication, username, viewType, managedUsers },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        "/sales-communication-logs",
        newCommunication
      );
      await dispatch(
        getAllSalesCommunications({
          username,
          managedUsers,
          viewType,
        })
      );

      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return rejectWithValue("Server Error");
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

export const updateSalesCommunication = createAsyncThunk(
  "communications/updateSalesCommunication",
  async (
    { id, updated, username, viewType, managedUsers },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.patch(`/sales-communication-logs/${id}`, {
        updated,
      });
      await dispatch(
        getAllSalesCommunications({
          username,
          managedUsers,
          viewType,
        })
      );
      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return rejectWithValue("Server Error");
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

export const getAllSalesCommunications = createAsyncThunk(
  "communications/getAllSalesCommunications",
  async ({ username, viewType, managedUsers }, thunkAPI) => {
    try {
      const response = await axios.get(
        `/sales-communication-logs/${username}`,
        {
          params: {
            viewType,
            managedUsers,
          },
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      return response.data;
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

const communicationsSlice = createSlice({
  name: "communications",
  initialState,
  extraReducers(builder) {
    builder.addCase(
      getAllSalesCommunications.fulfilled,
      (state, { payload }) => {
        state.salesCommunications = payload;
      }
    );
  },
});

export default communicationsSlice.reducer;
