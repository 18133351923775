import { useEffect, useRef, useState } from "react";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";

import GeneralModalV3 from "../../general/GeneralModalV3";
import axios from "../../../axios/axios.config";
import { useOutletContext } from "react-router-dom";

const ReceiveTransactionUpdateReferenceModal = ({
  open,
  setOpen,
  reference,
  _id,
  setTransactionData,
}) => {
  const [newReference, setNewReference] = useState("");

  const inputRef = useRef(null);
  const { openAlert } = useGeneralAlert();
  const { setLoading } = useOutletContext();

  useEffect(() => {
    if (inputRef?.current && open) {
      //set timeout ensures that focus is ran after modal is open
      setTimeout(() => {
        setNewReference(reference);
        inputRef.current.focus();
      }, 0);
    }
  }, [open, inputRef]);

  const handleSubmit = async (event, handleClose) => {
    event.preventDefault();
    setLoading("Updating Reference...");
    try {
      //dont update if nothing is entered or changed
      if (newReference === "" || newReference === reference) return;

      const response = await axios.patch(
        `receive-transaction/${_id}/update/reference`,
        { newReference }
      );

      setTransactionData(response.data);
      handleClose();
      openAlert({
        type: "success",
        message: "Successfully Updated Reference Field",
        duration: 1000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not update reference: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  return (
    <GeneralModalV3
      openVal={open}
      openComponent={null}
      onClose={() => {
        setOpen(false);
        setNewReference(reference);
      }}
    >
      {(handleClose) => (
        <Box>
          <Typography variant="h6" py={1} textAlign="center">
            Reference
          </Typography>
          <Divider />
          <Box
            p={2}
            component="form"
            onSubmit={(event) => handleSubmit(event, handleClose)}
          >
            <TextField
              fullWidth
              multiline
              inputRef={inputRef}
              helperText={
                <Typography variant="caption" color="error">
                  Character Limit: 300
                </Typography>
              }
              minRows={4}
              value={newReference}
              onChange={(event) => {
                if (event.target.value.length <= 300) {
                  setNewReference(event.target.value);
                }
              }}
            />
            <Button
              disabled={newReference === "" || newReference === reference}
              fullWidth
              variant="contained"
              type="submit"
              sx={{ my: 1 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default ReceiveTransactionUpdateReferenceModal;
