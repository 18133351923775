import { useState, useEffect } from "react";
import { useSalesRepCustomerMatch } from "../../hooks/useSalesRepCustomerMatch";
import { Button, Link, Toolbar, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { CUSTOMERLABORCLAIMCOLUMNS } from "../../utils/columns/customer/LaborClaimColumns";

import Loading from "../../components/Loading";
import axios from "../../axios/axios.config";
import FlexWrapper from "../../components/FlexWrapper";

const LaborClaimPageMulti = () => {
  const [lcRows, setLCRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filteredCustomersList } = useSalesRepCustomerMatch();
  const { currentSubsidiary } = useSelector((state) => state.user);

  useEffect(() => {
    if (filteredCustomersList?.length > 0) {
      getRows();
    }
  }, [filteredCustomersList]);

  const getRows = async () => {
    try {
      const filter = {
        type: "labor-claim",
        "form.customer": { $in: filteredCustomersList },
        subsidiary: currentSubsidiary,
      };

      const response = await axios.post(`/forms/get/filtered-forms`, filter);
      setLCRows(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not get labor claim forms: ${error.message}`,
          duration: 3000,
        })
      );
    }
  };

  if (loading) {
    return <Loading message="Loading Customer Labor Claim Forms" />;
  }

  return (
    <>
      <Typography variant="h4" textAlign="center">
        Labor Claim Forms
      </Typography>
      <FlexWrapper justifyContent="flex-end">
        <Button
          variant="outlined"
          endIcon={<Add />}
          onClick={() => {
            navigate("add");
          }}
          sx={{ mb: 3 }}
        >
          Add New Labor Claim
        </Button>
      </FlexWrapper>
      <DataGrid
        sx={{ maxHeight: "75vh" }}
        rows={lcRows}
        slots={{ toolbar: GridToolbar }}
        columns={[
          {
            field: "_id",
            headerName: "ID",
            width: 250,
            sortable: false,
            renderCell: (params) => {
              return (
                <Link
                  id={params.row._id}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`form/${params.row._id}`);
                  }}
                >
                  {params.row._id}
                </Link>
              );
            },
          },
          ,
          ...CUSTOMERLABORCLAIMCOLUMNS,
        ]}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        autoHeight={lcRows.length === 0}
        density="compact"
        disableColumnMenu
        disableDensitySelector
        disableColumnSelector
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            csvOptions: { fileName: `LC-forms-${Date.now()}` },
          },
        }}
        getRowId={(row) => row._id}
      />
    </>
  );
};

export default LaborClaimPageMulti;
