import { Modal, Box, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

const GeneralModal = ({
  children,
  bgcolor = "white",
  open,
  handleClose,
  disableClick = false,
  width = null,
  height = null,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width ? width : { xs: 340, md: 600 },
    minHeight: height ? height : { xs: 200 },
    bgcolor: bgcolor,
    borderRadius: "4px",
    boxShadow: 10,
    overflow: "hidden",
  };

  return (
    <Modal
      sx={{ pointerEvents: disableClick ? "none" : "initial", border: "none" }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};
export default GeneralModal;
