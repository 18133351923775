import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSalesRepCustomerMatch } from "../../hooks/useSalesRepCustomerMatch";
import { DataGrid } from "@mui/x-data-grid";
import {
  closeFullScreenModal,
  openFullScreenModal,
} from "../../redux/features/modal/modalSlice";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Link,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import FlexWrapper from "../../components/FlexWrapper";
import axios from "../../axios/axios.config";
import Filter from "../../components/Filter";
import DealSheet from "./forms/DealSheet";
import FullScreenModal from "../../components/FullScreenModal";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GeneralAlert from "../../components/GeneralAlert";

const DealSheetPage = () => {
  const [dealsheetRows, setDealsheetRows] = useState([]);
  const [filteredNewDealSheetRows, setFilteredNewDealSheetRows] = useState([]);
  const [filteredPendingDealSheetRows, setFilteredPendingDealSheetRows] =
    useState([]);
  const [filteredCompletedDealSheetRows, setFilteredCompletedDealSheetRows] =
    useState([]);
  const [dealsheetRowsLoading, setDealsheetRowsLoading] = useState(true);
  const [dealsheetRowsError, setDealsheetRowsError] = useState("");
  const [isNewFiltered, setIsNewFiltered] = useState(false);
  const [isPendingFiltered, setIsPendingFiltered] = useState(false);
  const [isCompletedFiltered, setIsCompletedFiltered] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { filteredCustomersList } = useSalesRepCustomerMatch();
  const { currentSubsidiary } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const COLUMNS = [
    {
      field: "_id",
      headerName: "ID",
      width: 210,
      sortable: false,
      renderCell: (params) => (
        <Link
          id={params.row._id}
          sx={{ cursor: "pointer" }}
          onClick={handleIDClick}
        >
          {params.row._id}
        </Link>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      type: "date",
      width: 120,
      valueGetter: (params) => {
        return new Date(
          params.row.versions[params.row.versions.length - 1].date
        );
      },
    },
    {
      field: "accountName",
      headerName: "Account Name",
      width: 300,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1].accountName;
      },
    },
    {
      field: "repAgency",
      headerName: "Representative Agency",
      width: 220,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1].repAgency;
      },
    },
    {
      field: "accountManager",
      headerName: "Account Manager",
      width: 200,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1]
          .accountManager;
      },
    },
    {
      field: "specialReason",
      headerName: "Reason For Special Program",
      width: 250,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1]
          .specialReason;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1].status;
      },
    },
    {
      field: "approvalStatus",
      headerName: "Approval Status",
      width: 150,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1]
          .approvalStatus;
      },
    },
    {
      field: "reference",
      headerName: "Reference",
      width: 250,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1].reference;
      },
    },
    {
      field: "nextDate",
      headerName: "Next Issue Date",
      type: "date",
      width: 150,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1].nextDate
          ? new Date(
              params.row.versions[params.row.versions.length - 1].nextDate
            )
          : "";
      },
    },
  ];

  const NEWCOLUMNS = [
    {
      field: "_id",
      headerName: "ID",
      width: 210,
      sortable: false,
      renderCell: (params) => (
        <Link
          id={params.row._id}
          sx={{ cursor: "pointer" }}
          onClick={handleIDClick}
        >
          {params.row._id}
        </Link>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      type: "date",
      width: 120,
      valueGetter: (params) => {
        return new Date(
          params.row.versions[params.row.versions.length - 1].date
        );
      },
    },
    {
      field: "accountName",
      headerName: "Account Name",
      width: 300,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1].accountName;
      },
    },
    {
      field: "repAgency",
      headerName: "Representative Agency",
      width: 220,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1].repAgency;
      },
    },
    {
      field: "accountManager",
      headerName: "Account Manager",
      width: 200,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1]
          .accountManager;
      },
    },
    {
      field: "specialReason",
      headerName: "Reason For Special Program",
      width: 250,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1]
          .specialReason;
      },
    },
    {
      field: "approvalStatus",
      headerName: "Approval Status",
      width: 150,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1]
          .approvalStatus;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      valueGetter: (params) => {
        return params.row.versions[params.row.versions.length - 1].status;
      },
    },
  ];

  useEffect(() => {
    if (dealsheetRows.length === 0 && filteredCustomersList?.length > 0) {
      getFilteredFormRows();
    }
  }, [filteredCustomersList]);

  useEffect(() => {
    if (searchParams.get("modal") === "open") {
      dispatch(openFullScreenModal());
    }
    if (!searchParams.get("modal")) {
      dispatch(closeFullScreenModal());
    }
  }, [searchParams]);
  //adds the filter every time dealSheetRows changes
  useEffect(() => {
    if (dealsheetRows.length > 0) {
      setFilteredNewDealSheetRows(
        dealsheetRows.filter((form) => form.versions[0].status === "new")
      );
      setFilteredPendingDealSheetRows(
        dealsheetRows.filter((form) => form.versions[0].status === "pending")
      );
      setFilteredCompletedDealSheetRows(
        dealsheetRows.filter(
          (form) =>
            form.versions[0].status === "complete" ||
            form.versions[0].status === "denied"
        )
      );
    }
  }, [dealsheetRows]);

  function handleIDClick(event) {
    setSearchParams({
      modal: "open",
      editing: "true",
      "deal-sheet": event.target.id,
    });
  }

  const getFilteredFormRows = async () => {
    setDealsheetRowsError("");
    setDealsheetRowsLoading(true);
    try {
      const filter = {
        type: "deal-sheet",
        status: "active",
        "versions.accountName": { $in: filteredCustomersList },
        subsidiary: currentSubsidiary,
      };
      const response = await axios.post(`/forms/get/filtered-forms`, filter);

      setDealsheetRows(response.data);
    } catch (error) {
      setDealsheetRowsError(error.message);
    } finally {
      setDealsheetRowsLoading(false);
    }
  };

  const customFilterFn = (filteredRows, currentValue, currentComparison) => {
    let returnArr = [];

    returnArr = filteredRows.filter((row) => {
      if (currentComparison === "equals") {
        return (
          row.versions[0].accountName.toLowerCase() ===
          currentValue.toLowerCase()
        );
      }
      return row.versions[0].accountName
        .toLowerCase()
        .includes(currentValue.toLowerCase());
    });

    return returnArr;
  };

  if (dealsheetRowsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          height: "80vh",
        }}
      >
        <CircularProgress variant="indeterminate" value={10} />
        <Typography variant="body1" color="primary">
          Loading Deal Sheet Data
        </Typography>
      </Box>
    );
  }

  if (dealsheetRowsError) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Typography color="error">{dealsheetRowsError}</Typography>
      </Box>
    );
  }

  if (!dealsheetRowsLoading && !dealsheetRowsError) {
    return (
      <Container maxWidth="xl" sx={{ pb: 2 }}>
        <FullScreenModal>
          <Container maxWidth="xl">
            <IconButton
              onClick={() => {
                dispatch(closeFullScreenModal());
                setSearchParams({});
              }}
            >
              <CloseIcon />
            </IconButton>
            <DealSheet
              dealsheetRows={dealsheetRows}
              setDealsheetRows={setDealsheetRows}
              setFilteredDealsheetRows={() => {}}
            />
          </Container>
        </FullScreenModal>
        <Typography component="h1" variant="h4" textAlign="center" gutterBottom>
          Deal Sheet Forms
        </Typography>
        <FlexWrapper justifyContent="flex-end">
          <Button
            variant="outlined"
            endIcon={<AddIcon />}
            onClick={() => {
              setSearchParams({ modal: "open", editing: "false" });
            }}
            sx={{ mb: 3 }}
          >
            Add New Record
          </Button>
        </FlexWrapper>
        {/* new */}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">New</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FlexWrapper>
              <Filter
                filters={[{ name: "accountName", label: "Account Name" }]}
                resetFilters={() =>
                  setFilteredNewDealSheetRows(
                    dealsheetRows.filter(
                      (form) => form.versions[0].status === "new"
                    )
                  )
                }
                isFiltered={isNewFiltered}
                setIsFiltered={setIsNewFiltered}
                updateFn={(newArr) => setFilteredNewDealSheetRows(newArr)}
                arrayToFilter={filteredNewDealSheetRows}
                filterTypes={{ accountName: "string" }}
                customFilterFn={customFilterFn}
              />
            </FlexWrapper>

            <DataGrid
              rows={filteredNewDealSheetRows}
              columns={NEWCOLUMNS}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              autoHeight
              density="compact"
              disableColumnMenu
              getRowId={(row) => row._id}
            />
          </AccordionDetails>
        </Accordion>
        {/* pending */}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">Pending</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FlexWrapper>
              <Filter
                filters={[{ name: "accountName", label: "Account Name" }]}
                resetFilters={() =>
                  setFilteredPendingDealSheetRows(
                    dealsheetRows.filter(
                      (form) => form.versions[0].status === "pending"
                    )
                  )
                }
                isFiltered={isPendingFiltered}
                setIsFiltered={setIsPendingFiltered}
                updateFn={(newArr) => setFilteredPendingDealSheetRows(newArr)}
                arrayToFilter={filteredPendingDealSheetRows}
                filterTypes={{ accountName: "string" }}
                customFilterFn={customFilterFn}
              />
            </FlexWrapper>

            <DataGrid
              rows={filteredPendingDealSheetRows}
              columns={COLUMNS}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              autoHeight
              density="compact"
              disableColumnMenu
              getRowId={(row) => row._id}
            />
          </AccordionDetails>
        </Accordion>
        {/* complete */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">Complete</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FlexWrapper>
              <Filter
                filters={[{ name: "accountName", label: "Account Name" }]}
                resetFilters={() =>
                  setFilteredCompletedDealSheetRows(
                    dealsheetRows.filter(
                      (form) => form.versions[0].status === "complete"
                    )
                  )
                }
                isFiltered={isCompletedFiltered}
                setIsFiltered={setIsCompletedFiltered}
                updateFn={(newArr) => setFilteredCompletedDealSheetRows(newArr)}
                arrayToFilter={filteredCompletedDealSheetRows}
                filterTypes={{ accountName: "string" }}
                customFilterFn={customFilterFn}
              />
            </FlexWrapper>

            <DataGrid
              rows={filteredCompletedDealSheetRows}
              columns={COLUMNS}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              autoHeight
              density="compact"
              disableColumnMenu
              getRowId={(row) => row._id}
            />
          </AccordionDetails>
        </Accordion>
      </Container>
    );
  }
};

export default DealSheetPage;
