import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  ORDERPROCESSINGCOLUMNS,
  ORDERPROCESSINGCOLUMNSWGE,
} from "../../../../utils/columns/order-processing/orderProcessingColumns";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";

const OrderProcessing = () => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState("");

  const { currentNetsuiteLocationIds, currentSubsidiary } = useSelector(
    (state) => state.user
  );
  const { openAlert } = useGeneralAlert();

  const columns =
    currentSubsidiary === "WGE"
      ? ORDERPROCESSINGCOLUMNSWGE
      : ORDERPROCESSINGCOLUMNS;

  useEffect(() => {
    getOrderdata();
  }, []);

  const getOrderdata = async () => {
    setLoading("Loading Orders...");
    try {
      const nsResponse = await axios.get(
        `netsuite/order-processing/get/orders?locations=${JSON.stringify(
          currentNetsuiteLocationIds
        )}&subsidiary=${currentSubsidiary}`
      );

      setOrderData(nsResponse.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get data: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  if (loading) {
    return <Loading message={loading} />;
  }

  return (
    <Box p={1}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Orders
      </Typography>

      <DataGrid
        sx={{ height: "80vh" }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: { fileName: `orders-${Date.now()}` },
            printOptions: { disableToolbarButton: true },
          },
        }}
        density="compact"
        rows={orderData}
        columns={columns}
        getRowId={(row) => row["SO #"]}
      />
    </Box>
  );
};
export default OrderProcessing;
