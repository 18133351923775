import { useRef, useState } from "react";
import { NoteAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import GeneralModalV3 from "../../general/GeneralModalV3";
import axios from "../../../axios/axios.config";

const NotesLC = ({
  rmaNotes,
  setForm,
  form,
  formid,
  username,
  handleAlert,
}) => {
  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    try {
      const updatedNote = formData.get("note");
      const updatedTime = new Date().toLocaleString();

      if (!updatedNote) {
        throw new Error("Note Field is Required");
      }

      const { data } = await axios.patch(
        `forms/update/labor-claim/${formid}/notes`,
        {
          updateData: {
            "form.rmanotes": `${
              rmaNotes ? `${rmaNotes}\n` : ""
            }${updatedTime} (${username}) - ${updatedNote}`,
          },
        }
      );

      setForm({ ...form, rmanotes: data.form.rmanotes });

      if (data.form.rmainternalid) {
        //update Netsuite notes
        axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/update-notes`,
          {
            notes: `${data.form.rmanotes}`,
          }
        );
      }

      handleAlert({
        type: "success",
        message: `Successfully Updated Notes`,
        duration: 3000,
      });
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Error, Could Not Update Notes:  ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      if (formRef.current) {
        formRef.current.reset();
      }
    }
  };

  return (
    <GeneralModalV3
      openComponent={
        <Tooltip arrow placement="top" title="Notes">
          <IconButton>
            <NoteAlt color="primary" />
          </IconButton>
        </Tooltip>
      }
    >
      <Box>
        <Typography gutterBottom variant="h4" textAlign="center" py={1}>
          Labor Claim Notes
        </Typography>
        <Box
          pb={2}
          px={2}
          sx={{
            maxHeight: 400,
            overflow: "scroll",
          }}
        >
          {rmaNotes ? (
            rmaNotes.split("\n").map((note, index) => {
              return <Typography key={note + index}>{note}</Typography>;
            })
          ) : (
            <Typography textAlign="center">No Notes To Display</Typography>
          )}
        </Box>

        <Divider />
        <Box component="form" p={2} onSubmit={handleSubmit} ref={formRef}>
          <TextField
            required
            autoFocus
            name="note"
            fullWidth
            multiline
            minRows={4}
            maxRows={6}
            placeholder="Add Note(s) Here"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 1 }}>
            Add Note
          </Button>
        </Box>
      </Box>
    </GeneralModalV3>
  );
};
export default NotesLC;
