import { useSelector } from "react-redux";
import { ExitToApp, Info } from "@mui/icons-material";
import { Form, useNavigate, useNavigation } from "react-router-dom";
import {
  Container,
  Grid,
  TextField,
  Button,
  Box,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import Loading from "../../../components/Loading";

const BugReports = () => {
  const navigation = useNavigation();
  const navigate = useNavigate();
  const { username, currentDepartment, currentLocation, currentSubsidiary } =
    useSelector((state) => state.user);

  if (navigation.state === "submitting") {
    return <Loading message="Submitting Report..." />;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h3" textAlign="center" gutterBottom>
        Bug Report
      </Typography>

      <Box pb={1}>
        <Tooltip title="Go Back">
          <IconButton
            aria-label=""
            onClick={() => {
              navigate(`/portal/${currentDepartment.toLowerCase()}`);
            }}
          >
            <ExitToApp color="error" />
          </IconButton>
        </Tooltip>
      </Box>

      <Box
        bgcolor="#EAF6FF"
        borderRadius={2}
        p={1}
        mb={2}
        width="100%"
        sx={{ display: "flex", gap: 1, alignItems: "center" }}
      >
        <Info color="primary" fontSize="small" />

        <Typography variant="body1" color="textSecondary">
          Enter 'N/A' if not applicable
        </Typography>
      </Box>

      <Form method="post">
        <Grid container spacing={3}>
          <Box display={"none"}>
            <TextField name="createdby" defaultValue={username} />
            <TextField name="department" defaultValue={currentDepartment} />
            <TextField name="location" defaultValue={currentLocation} />
            <TextField name="subsidiary" defaultValue={currentSubsidiary} />
          </Box>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Date and Time of Issue"
              type="datetime-local"
              name="dateofissue"
              defaultValue={new Date().toISOString().slice(0, 16)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Issue Title"
              name="issuetitle"
              placeholder="What is the issue?"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Order / Transaction Number"
              name="ordernumber"
              placeholder="Enter number if applicable"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Device Name"
              name="devicename"
              placeholder="Enter scanner number if applicable"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Physical Location"
              name="physicallocation"
              placeholder="Enter user / warehouse location"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Process"
              name="process"
              placeholder="Pick, Pack, Inventory...etc"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Description"
              name="description"
              placeholder="Please provide brief description of the issue and how it happened. Have you refreshed the page and retried the process? Have you experienced this issue more than once?"
              multiline
              minRows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              Submit Report
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default BugReports;
