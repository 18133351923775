import { store } from "../../redux/store";
import { setInitialPickData } from "../../redux/features/pick-order/pickOrderSlice";
import { redirect } from "react-router-dom";

import axios from "../../axios/axios.config";

export const pickOrderResultsPageLoader = async ({ params }) => {
  const { pickOrder } = store.getState();

  if (pickOrder._id) return null;

  //theres no data in redux so get data using order number
  const orderResponse = await axios.get(
    `pick-order/get/order/${params.orderid}`
  );

  //if no data for order umber then redirect to search page
  if (!orderResponse.data) {
    return redirect(
      `/portal/${localStorage.getItem("department").toLowerCase()}/pick-order`
    );
  }

  store.dispatch(setInitialPickData({ ...orderResponse.data, loading: "" }));

  return null;
};
