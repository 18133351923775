import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  heading: {
    fontSize: "20px",
  },
  container: {
    border: "1px solid black",
    width: "100%",
    fontFamily: "Helvetica",
  },
  title: {
    backgroundColor: "#d9d9d9",
    fontFamily: "Helvetica-Bold",
    fontSize: "18px",
    marginBottom: "10px",
    padding: "4px",
  },
  columnsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    fontSize: "10px",
  },
  staticColumn: {
    flexDirection: "column",
    width: "15%",
  },
  dynamicColumn: {
    flexDirection: "column",
    width: "18%",
  },
  staticText: { marginBottom: "10px", height: "13px" },
  textHeight: { height: "17px", marginBottom: "12px" },
  dynamicText: {
    borderBottom: "1px solid black",
    marginBottom: "10px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    flexWrap: "nowrap",
    height: "13px", //setting height and disallowing wrapping triggers ellipses
  },
});

const LaborClaimCreditMemoPDF = ({
  rma,
  rga,
  claimDetail,
  detail,
  internalUse,
  createdDate,
}) => {
  return (
    <Document>
      <Page
        size="A4"
        style={{
          fontSize: "14px",
          fontFamily: "Helvetica",
          padding: "16px 24px",
        }}
      >
        <View
          id="heading-section"
          style={{
            textAlign: "center",
            marginBottom: "10px",
            fontFamily: "Helvetica-Bold",
            fontSize: "20px",
          }}
        >
          <Text
            style={[
              styles.text,
              {
                fontWeight: "bold",
              },
            ]}
          >
            <Text
              style={
                ([styles.heading],
                { fontFamily: "Helvetica-Bold", fontSize: "40px" })
              }
            >
              A
            </Text>
            dvanced{" "}
            <Text
              style={
                ([styles.heading],
                { fontFamily: "Helvetica-Bold", fontSize: "40px" })
              }
            >
              I
            </Text>
            nnovative{" "}
            <Text
              style={
                ([styles.heading],
                { fontFamily: "Helvetica-Bold", fontSize: "40px" })
              }
            >
              T
            </Text>
            echnology <Text>Corp.</Text>
          </Text>

          <Text
            style={{
              paddingTop: "7px",
              textAlign: "center",
              fontSize: "12px",
              borderBottom: "3px solid red",
              paddingBottom: "15px",
              marginBottom: "15px",
              fontFamily: "Helvetica",
            }}
          ></Text>
          <Text style={{ borderBottom: "2px solid grey" }}></Text>
        </View>
        <View id="body">
          <Text
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontFamily: "Helvetica-Bold",
              marginBottom: "25px",
            }}
          >
            Customer Alleged Defective Response
          </Text>

          <View
            id="body-order-details"
            style={{
              fontSize: "12px",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            <View>
              <Text style={{ fontFamily: "Helvetica-Bold", height: "18px" }}>
                Request received by{" "}
                <Text
                  style={{
                    fontFamily: "Helvetica",
                    textDecoration: "underline",
                  }}
                >
                  {claimDetail.by}
                </Text>
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                  height: "18px",
                }}
              >
                Date Received{" "}
                <Text
                  style={{
                    fontFamily: "Helvetica",
                    textDecoration: "underline",
                  }}
                >
                  {createdDate}
                </Text>
              </Text>
            </View>

            <View>
              <Text style={{ fontFamily: "Helvetica-Bold", height: "18px" }}>
                RGS #{" "}
                <Text
                  style={{
                    fontFamily: "Helvetica",
                    textDecoration: "underline",
                  }}
                >
                  {rga.number}
                </Text>
              </Text>
            </View>
          </View>

          <View
            id="body-claim-detail"
            style={{
              border: "1px solid black",
              width: "100%",
              marginBottom: "25px",
              fontSize: "12px",
            }}
          >
            <View>
              <Text
                style={{
                  padding: "4px",
                  backgroundColor: "#4c4c4c",
                  color: "white",
                  fontFamily: "Helvetica-Bold",
                  fontSize: "18px",
                  marginBottom: "10px",
                }}
              >
                Claim Detail
              </Text>
            </View>

            <View
              id="Claim-Detail-Container"
              style={{ width: "100%", paddingLeft: "10px" }}
            >
              <View
                id="row1"
                style={{
                  width: "100%",
                  flexDirection: "row",
                  paddingBottom: "15px",
                }}
              >
                <View style={{ width: "50%", flexDirection: "row" }}>
                  <Text style={{ paddingRight: "10px" }}>DATE:</Text>
                  <View style={{ flex: 1, borderBottom: "1px solid black" }}>
                    <Text style={{ paddingLeft: "10px" }}>{createdDate}</Text>
                  </View>
                </View>

                <View
                  style={{
                    width: "50%",
                    flexDirection: "row",
                  }}
                >
                  <Text>CLAIM #:</Text>
                  <View style={{ flex: 1, borderBottom: "1px solid black" }}>
                    <Text
                      style={{
                        paddingLeft: "10px",
                        fontFamily: "Helvetica-Bold",
                      }}
                    >
                      {rga.number}
                    </Text>
                  </View>
                </View>
              </View>

              <View
                id="row2"
                style={{
                  paddingBottom: "15px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <View style={{ width: "100%", flexDirection: "row" }}>
                  <Text style={{ paddingRight: "10px" }}>COMPANY:</Text>
                  <View style={{ flex: 1, borderBottom: "1px solid black" }}>
                    <Text style={{ paddingLeft: "10px" }}>
                      {claimDetail.company}
                    </Text>
                  </View>
                </View>
              </View>

              <View
                id="row3"
                style={{
                  paddingBottom: "15px",
                }}
              >
                <View style={{ width: "100%", flexDirection: "row" }}>
                  <Text style={{ paddingRight: "10px" }}>REVIEWED:</Text>
                  <View style={{ flex: 1, borderBottom: "1px solid black" }}>
                    <Text style={{ paddingLeft: "10px" }}>
                      {claimDetail.reviewed}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View
            id="body-detail"
            style={{
              border: "1px solid black",
              width: "100%",
              marginBottom: "25px",
            }}
          >
            <Text
              style={{
                padding: "4px",
                backgroundColor: "#4c4c4c",
                color: "white",
                fontFamily: "Helvetica-Bold",
                fontSize: "18px",
                marginBottom: "10px",
              }}
            >
              Detail
            </Text>

            <View style={{ padding: "8px" }}>
              <View style={{ marginBottom: "15px", minHeight: "40px" }}>
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  {detail.detail}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  **NOTE: {detail.note}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    paddingBottom: "5px",
                    borderBottom: "1px solid black",
                  }}
                ></Text>
              </View>
            </View>
          </View>

          <View id="internal-use-only" style={styles.container}>
            <View style={styles.title}>
              <Text>For Internal Use Only</Text>
            </View>

            <View style={styles.columnsContainer}>
              {/* First column */}
              <View
                style={[
                  styles.staticColumn,
                  { width: "10%", paddingLeft: "4px" },
                ]}
              >
                <Text style={styles.staticText}>RMA#</Text>
                <Text style={styles.staticText}>Issued By</Text>
                <Text style={styles.staticText}>Issued On</Text>
              </View>

              {/* Second column */}
              <View style={styles.dynamicColumn}>
                <Text style={styles.dynamicText}>{rma.number}</Text>
                <Text style={styles.dynamicText}>{rma.name}</Text>
                <Text style={styles.dynamicText}>{rma.date}</Text>
              </View>

              {/* Third column */}
              <View style={styles.staticColumn}>
                <Text style={styles.staticText}>Return Rec'd On</Text>
                <Text style={styles.staticText}>Return Rec'd By</Text>
              </View>

              {/* Fourth column */}
              <View style={styles.dynamicColumn}>
                <Text style={styles.dynamicText}>
                  {internalUse.dateReceived}
                </Text>
                <Text style={styles.dynamicText}>{internalUse.receivedBy}</Text>
              </View>
              {/* Fifth column */}
              <View style={styles.staticColumn}>
                <Text style={styles.staticText}>Result</Text>
                {/* <Text style={styles.staticText}>Credit Issued By</Text> */}
                <Text style={styles.staticText}>Credit Issued On</Text>
                <Text style={styles.staticText}>Credit Memo</Text>
              </View>

              {/* Sixth column */}
              <View style={styles.dynamicColumn}>
                <Text style={styles.dynamicText}>{internalUse.result}</Text>
                <Text style={styles.dynamicText}>
                  {internalUse.creditIssuedDate}
                </Text>
                <Text style={styles.dynamicText}>
                  {internalUse.creditMemoNumber}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default LaborClaimCreditMemoPDF;
