import { useState } from "react";
import { Edit } from "@mui/icons-material";
import { Box, Button, IconButton, TextField } from "@mui/material";

import GeneralModalV3 from "../../../general/GeneralModalV3";
import axios from "../../../../axios/axios.config";

const EditDiscrepanciesModal = ({
  discrepancies,
  setDiscrepanices,
  id,
  openAlert,
}) => {
  const [input, setInput] = useState(discrepancies);

  const handleSubmit = async (event, handleClose) => {
    event.preventDefault();
    try {
      const response = await axios.patch(
        `pack-process/update-pack-order/${id}/discrepancies`,
        {
          discrepancies: parseInt(input),
        }
      );

      setDiscrepanices(response.data.discrepancies);
      handleClose();
      openAlert({
        type: "success",
        message: "Successfully Updated Discrepanices",
        duration: 3000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Update Discrepanices:${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    }
  };

  return (
    <GeneralModalV3
      openComponent={
        <IconButton>
          <Edit color="primary" />
        </IconButton>
      }
    >
      {(handleClose) => (
        <Box
          px={2}
          pt={3}
          component="form"
          onSubmit={(event) => handleSubmit(event, handleClose)}
        >
          <TextField
            autoFocus
            fullWidth
            type="number"
            required
            label="Enter Discrepancies"
            value={input}
            onChange={(event) => setInput(event.target.value)}
            inputProps={{ min: 0 }}
          />

          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default EditDiscrepanciesModal;
