import { redirect } from "react-router-dom";
import axios from "../../axios/axios.config";

export const viewReceivingTransactionsLoader = async () => {
  try {
    const locations = localStorage.getItem("netsuitelocations");
    const response = await axios.get(
      `receive-transaction/get/status/inprogress?locations=${locations}`
    );

    return response.data;
  } catch (error) {
    return null;
  }
};

export const receiveTransactionResultsLoader = async ({ params }) => {
  try {
    const response = await axios.get(
      `receive-transaction/${params.transaction}`
    );

    // if (response.data.status === "received") {
    //   return redirect(
    //     `/portal/${localStorage
    //       .getItem("department")
    //       .toLocaleLowerCase()}/receive-transaction`,
    //     { replace: true }
    //   );
    // }
    return response.data;
  } catch (error) {
    return null;
  }
};
