import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Loading from "../../../components/Loading";
import FlexWrapper from "../../../components/FlexWrapper";
import { equipmentQuestions } from "../../../utils/equipment/equipmentQuestions";
import { useTranslation, useTrsnslation } from "react-i18next";
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import EquipmentChecklistResult from "./EquipmentChecklistResult";

const SingleEquipmentChecklistPage = () => {
  const equipmentData = useLoaderData();
  const actionData = useActionData();
  const navigationData = useNavigation();
  const { t } = useTranslation();

  const checkLastInspectedDateUnder6Hours = () => {
    if (equipmentData.lastinspected) {
      // Get the current time
      const currentTime = new Date();
      // Convert ISO string to Date object
      const lastInspectedDate = new Date(equipmentData.lastinspected);

      // Calculate the difference in milliseconds
      const timeDifference = currentTime - lastInspectedDate;

      // Convert milliseconds to hours
      const hoursDifference = timeDifference / (1000 * 60 * 60);

      if (hoursDifference < 6) {
        return true;
      } else {
        return false;
      }
    }
  };

  if (navigationData.state === "submitting")
    return <Loading message="Submitting Form..." />;

  if (navigationData.state === "idle" && actionData?.mongoData)
    return (
      <EquipmentChecklistResult inspectionPass={actionData.mongoData.result} />
    );

  //if last inspection passed and last inspection was under 10 hours display the machine inspection pass screen
  if (
    equipmentData.lastinspectedresult &&
    checkLastInspectedDateUnder6Hours()
  ) {
    return <EquipmentChecklistResult inspectionPass={true} />;
  }

  return (
    <Box pb={2}>
      <Container maxWidth="md" sx={{ marginBottom: 2 }}>
        <Typography variant="h6" textAlign="center" gutterBottom>
          {t("DAILY MATERIAL HANDLING EQUIPMENT CHECKLIST")}
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          gutterBottom
          fontWeight="bold"
        >
          {t(equipmentData?.checklist)}
        </Typography>
        <FlexWrapper justifyContent="space-between" sx={{ marginBottom: 2 }}>
          <Typography variant="body2">
            Date:{" "}
            <Typography component="span" sx={{ textDecoration: "underline" }}>
              {new Date().toLocaleDateString()}
            </Typography>
          </Typography>
          <Typography variant="body2">
            Equipment ID:{" "}
            <Typography component="span" sx={{ textDecoration: "underline" }}>
              {equipmentData?.equipmentid}
            </Typography>
          </Typography>
        </FlexWrapper>
      </Container>

      <Form method="post" id="equipment-checklist">
        <Container maxWidth="md">
          <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
            <Stack spacing={1} alignItems="baseline">
              {equipmentData
                ? equipmentQuestions[equipmentData.checklist]?.map(
                    (question) => (
                      <FormControl key={question}>
                        <FormControlLabel
                          key={question}
                          control={<Checkbox color="primary" />}
                          label={t(question)}
                          name={question}
                        />
                      </FormControl>
                    )
                  )
                : null}
            </Stack>
          </Paper>
          <TextField
            fullWidth
            multiline
            minRows={3}
            name="comments"
            label={t("comments")}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            name="submit"
            value={equipmentData.checklist}
          >
            {t("submit checklist")}
          </Button>
        </Container>
      </Form>
    </Box>
  );
};

export default SingleEquipmentChecklistPage;
