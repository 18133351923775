import { Document, Link, Page, Text, View } from "@react-pdf/renderer";

const NingboCreditMemoPDF = ({
  creditNumber,
  creditDate,
  rgs,
  totalItemQty,
  items = [],
  creditAmount,
}) => {
  return (
    <Document>
      <Page size="A4" style={{ padding: "30px 30px", fontFamily: "Helvetica" }}>
        <View
          id="top-section"
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <View
            id="left-address"
            style={{
              width: "48%",
              fontFamily: "Helvetica-Bold",
            }}
          >
            <Text style={{ fontSize: "15px", paddingBottom: "5px" }}>
              NINGBO WONH INDUSTRIES CO., LTD
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: "3px" }}>
              Add: No.21, Yan Shan He Road, Beilun,{" "}
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: "3px" }}>
              Ningbo, China P.C.: 315806
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: "3px" }}>
              <Link
                src="http://www.wonhparts.com"
                style={{ textDecoration: "none", color: "black" }}
              >
                http://www.wonhparts.com
              </Link>
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: "3px" }}>
              Tel: 86-574-86236660 Fax: 86-574- 86112876{" "}
            </Text>
          </View>

          <View id="right-address" style={{ width: "45%" }}>
            <Text
              style={{
                fontWeight: 600,
                paddingLeft: "10px",
                paddingBottom: "10px",
                fontSize: "25px",
                fontFamily: "Helvetica-Bold",
              }}
            >
              Credit Memo
            </Text>
            <Text style={{ fontSize: "12px", paddingBottom: "3px" }}>
              Credit Number: {creditNumber}
            </Text>
            <Text style={{ fontSize: "12px", paddingBottom: "3px" }}>
              Credit Date: {creditDate}
            </Text>
          </View>

          <View
            id="bottom-left-address"
            style={{
              width: "48%",
              paddingTop: "30px",
            }}
          >
            <View style={{ border: "1px solid black", width: "60%" }}>
              <Text
                style={{
                  fontSize: "13px",
                  fontFamily: "Helvetica-Bold",
                  width: "100%",
                  backgroundColor: "#d9d9d9",
                  padding: "2px 0",
                }}
              >
                Bill To:
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  padding: "2px 0",
                }}
              >
                O'reilly Auto Parts
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  paddingBottom: "2px",
                }}
              >
                Attention Ap: 87116
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  paddingBottom: "2px",
                }}
              >
                PO Box 1897
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  paddingBottom: "15px",
                }}
              >
                Springfield,MO 65802
              </Text>
            </View>
          </View>
        </View>

        <View id="bottom-section" style={{ marginTop: "30px" }}>
          <View
            id="id-section"
            style={{
              border: "1px solid black",
              borderBottom: "0px solid black",
            }}
          >
            <View
              id="id-headers"
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#d9d9d9",
                fontFamily: "Helvetica-Bold",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  width: "100%",
                  borderRight: "1px solid black",
                }}
              >
                Customer ID
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  width: "100%",
                  borderRight: "1px solid black",
                }}
              >
                Customer PO
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Sales Rep ID
              </Text>
            </View>

            <View
              id="id-headers-values"
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  fontSize: "9px",
                  textAlign: "center",
                  width: "100%",
                  borderRight: "1px solid black",
                }}
              >
                OREAP
              </Text>
              <Text
                style={{
                  fontSize: "9px",
                  textAlign: "center",
                  width: "100%",
                  borderRight: "1px solid black",
                }}
              >
                {rgs}
              </Text>
              <Text
                style={{
                  fontSize: "9px",
                  textAlign: "center",
                  width: "100%",
                }}
              ></Text>
            </View>

            <View
              id="blank"
              style={{
                width: "100%",
                padding: "6px 0",
                borderTop: "1px solid black",
              }}
            ></View>
          </View>

          <View id="item-details-section" style={{ border: "1px solid black" }}>
            <View
              id="item-details-headers"
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#d9d9d9",
                fontFamily: "Helvetica-Bold",
                fontSize: "12px",
                borderBottom: "1px solid black",
              }}
            >
              <Text style={{ flex: 1, width: "100%", textAlign: "center" }}>
                Quantity
              </Text>
              <Text style={{ flex: 1, width: "100%", textAlign: "center" }}>
                Item
              </Text>
              <Text style={{ flex: 2, width: "100%", textAlign: "center" }}>
                Description
              </Text>
              <Text style={{ flex: 1, width: "100%", textAlign: "center" }}>
                Unit Price
              </Text>
              <Text style={{ flex: 1, width: "100%", textAlign: "center" }}>
                Amount (USD)
              </Text>
            </View>

            <View
              id="item-details-values"
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                fontSize: "9px",
              }}
            >
              <Text
                style={{
                  flex: 1,
                  width: "100%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                }}
              >
                {totalItemQty}
              </Text>
              <View
                style={{
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: 1,
                  flex: 1,
                  borderRight: "1px solid black",
                  width: "100%",
                  height: "30vh",
                }}
              >
                {items.length
                  ? items.map((item) => (
                      <Text
                        style={{
                          width: "100%",
                          textAlign: "center",
                          paddingBottom: "2px",
                        }}
                      >
                        {item}
                      </Text>
                    ))
                  : null}
              </View>

              <Text
                style={{
                  flex: 2,
                  width: "100%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                }}
              >
                Labor Claim
              </Text>
              <Text
                style={{
                  flex: 1,
                  width: "100%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                }}
              >
                -{creditAmount}
              </Text>
              <Text
                style={{
                  flex: 1,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                -{creditAmount}
              </Text>
            </View>
          </View>

          <View
            id="total-section"
            style={{
              flexDirection: "row",
              fontSize: "10px",
            }}
          >
            <View
              id="total-left"
              style={{
                width: "50%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text>Applied to Invoice No.:</Text>
            </View>

            <View
              id="total-right"
              style={{
                border: "1px solid black",
                borderTop: "0px solid black",
                width: "50%",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                }}
              >
                <Text style={{ flex: 1, borderRight: "1px solid black" }}>
                  Subtotal
                </Text>
                <Text style={{ flex: 3 }}>-{creditAmount}</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                }}
              >
                <Text style={{ flex: 1, borderRight: "1px solid black" }}>
                  Sales Tax
                </Text>
                <Text style={{ flex: 3 }}></Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                }}
              >
                <Text style={{ flex: 1, borderRight: "1px solid black" }}>
                  Freight
                </Text>
                <Text style={{ flex: 3 }}></Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                <Text style={{ flex: 1, borderRight: "1px solid black" }}>
                  Total
                </Text>
                <Text style={{ flex: 3 }}>-{creditAmount}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default NingboCreditMemoPDF;
