import { Typography } from "@mui/material";
import { usePDF } from "@react-pdf/renderer";

const GeneralPDFDownloadV2 = ({
  pdfComponent = null,
  name = "test.pdf",
  displayComponent = <Typography>Download</Typography>,
}) => {
  const [instance, updateInstance] = usePDF({ document: pdfComponent });

  if (instance.loading) return <Typography>Loading ...</Typography>;

  if (instance.error)
    return <Typography>Something went wrong: {instance.error}</Typography>;

  return (
    <a
      href={instance.url}
      download={name}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      {displayComponent}
    </a>
  );
};
export default GeneralPDFDownloadV2;
