export const CUSTOMERLABORCLAIMCOLUMNS = [
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return new Date(params.row.form.date);
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.customer;
    },
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    width: 150,
    valueGetter: (params) => {
      return params.row.form.status.toUpperCase();
    },
  },
  {
    field: "rgaNum",
    headerName: "RGA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rgaNum;
    },
  },
  {
    field: "rmaNum",
    headerName: "RMA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rmaNum;
    },
  },
  {
    field: "serviceFacilityCompany",
    headerName: "Service Facility Company Name",
    type: "string",
    width: 250,
    valueGetter: (params) => {
      return params.row.form.serviceFacilityCompany;
    },
  },
  {
    field: "warehouseCompany",
    headerName: "Warehouse Company Name",
    type: "string",
    width: 250,
    valueGetter: (params) => {
      return params.row.form.warehouseCompany;
    },
  },
  {
    field: "totalClaim",
    headerName: "Total Claim Amount",
    type: "number",
    width: 180,
    valueGetter: (params) => {
      return params.row.form.totalClaim;
    },
  },
];

//helper date sort function that handles blank values
function customDateSortComparator(a, b) {
  // Handle empty values
  if (a === "" && b === "") return 0;
  if (a === "") return 1;
  if (b === "") return -1;

  const dateA = new Date(a);
  const dateB = new Date(b);

  if (isNaN(dateA) && isNaN(dateB)) return 0; // Both are not valid dates
  if (isNaN(dateA)) return 1; // Handle invalid date format
  if (isNaN(dateB)) return -1; // Handle invalid date format

  return dateA - dateB; // Compare dates by subtracting them
}
