export const convertBase64ToBlobURL = (base64String) => {
  // Convert base64 to Blob
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  // Create a Blob URL
  const blobURL = URL.createObjectURL(blob);
  return blobURL;
};
