export const LABORCLAIMCOLUMNSPRODUCTRMA = [
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return new Date(params.row.form.date);
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "string",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.customer;
    },
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    width: 150,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.status.toUpperCase();
    },
  },
  {
    field: "rgaNum",
    headerName: "RGA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rgaNum;
    },
  },
  {
    field: "rmaNum",
    headerName: "RMA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rmaNum;
    },
  },
  {
    field: "rmaissuedby",
    headerName: "RMA Issued By",
    type: "string",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.rmaissuedby;
    },
  },
  {
    field: "rmaissuedbyemail",
    headerName: "RMA Issued By Email",
    type: "string",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.rmaissuedbyemail;
    },
  },
  {
    field: "rmaissuedate",
    headerName: "RMA Issued Date",
    type: "date",
    width: 200,
    valueGetter: (params) => {
      return new Date(params.row.form.rmaissuedate);
    },
  },
  {
    field: "rmaissueddepartment",
    headerName: "RMA Issued Dept.",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.rmaissueddepartment;
    },
  },
];

export const LABORCLAIMCOLUMNSPRODUCTLCRECEIVED = [
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return new Date(params.row.form.date);
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "string",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.customer;
    },
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    width: 150,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.status.toUpperCase();
    },
  },
  {
    field: "rgaNum",
    headerName: "RGA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rgaNum;
    },
  },
  {
    field: "rmaNum",
    headerName: "RMA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rmaNum;
    },
  },
  {
    field: "receivedby",
    headerName: "Received By",
    type: "string",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.receivedby;
    },
  },
  {
    field: "receivedbyemail",
    headerName: "Received By Email",
    type: "string",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      return params.row.form.receivedbyemail;
    },
  },
  {
    field: "datereceived",
    headerName: "Date Received",
    type: "date",
    width: 200,
    valueGetter: (params) => {
      return new Date(params.row.form.datereceived);
    },
  },
  {
    field: "timereceived",
    headerName: "Time Received",
    width: 200,
    sortable: false,
    valueGetter: (params) => {
      const timeArr = params.row.form.timereceived.split(":");
      const minutes = timeArr[1];
      let timeOfDay = "AM";
      let hours = timeArr[0];

      if (parseInt(hours) > 12) {
        timeOfDay = "PM";
        hours = hours - 12;
      }

      return `${hours}:${minutes} ${timeOfDay}`;
    },
  },
];

//Response options for product team for labor claims
export const COMMONRESPONSES = {
  "LABOR CLAIM RESPONSE BOOT DAMAGE ONLY": {
    detail:
      "The axle received meets all OEM requirements and is the proper fit for the vehicle application given in the claim. Upon inspection of the returned axle, Tech Department found there to be damage to the dust boot on the XXXboard CV joint. As stated in the Limited Lifetime Warranty, “The warranty is void if the boot is damaged in any way.” No Credit. Claim Denied.",
    note: "WARRANTY VOID ",
  },
  "LABOR CLAIM RESPONSE COMMERCIAL VEHICLE": {
    detail:
      "The axle received meets all OEM requirements and is the correct part specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied part. Paperwork states that the vehicle was for commercial use. The warranty does not cover units installed on cars, vans, or light trucks that are used for commercial or governmental use. No Credit. Claim Denied.",
    note: "WARRANTY VOID",
  },
  "LABOR CLAIM RESPONSE LACK OF PAPER WORK": {
    detail:
      "The Tech Department is unable to evaluate the axle supplied on this claim due to missing documentation that is required when sending in a claim. The Tech Department made attempts to get the required documentation from the store but was not successful. Claim Denied.",
    note: "APPROVED PARTS-1 ",
  },
  "LABOR CLAIM RESPONSE NO PROBLEM (MULTI)": {
    detail:
      "The axles received meet all OEM requirements and are the correct parts specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied parts. Tech Department suspects an installation issue or another issue with the vehicle and suggests a full vehicle inspection. Claim Denied.",
    note: "APPROVED PART- 2 ",
  },
  "LABOR CLAIM RESPONSE NO PROBLEM": {
    detail:
      "The axle received meets all OEM requirements and is the correct part specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied part. Tech Department suspects an installation issue or another issue with the vehicle and suggests a full vehicle inspection. Claim Denied.",
    note: "APPROVED PART-1",
  },
  "LABOR CLAIM RESPONSE NOT A WONH AXLE": {
    detail:
      "Upon review of the submitted axle # Tech. Department found that the axle is not a WONH axle. This axle should be returned to the supplying company for warranty purposes. Claim Denied.",
    note: "",
  },
  "LABOR CLAIM RESPONSE PAY IT NO FAULT": {
    detail:
      "For customer satisfaction, Tech Department recommends paying the amount of $35 for one hour of labor plus one axle. Claim Approved.",
    note: "APPROVED HOURS- 1 = 35.00. APPROVED PART-1.",
  },
  "LABOR CLAIM RESPONSE RMA LIFT KIT": {
    detail:
      "The axle received meets all OEM requirements and is the correct part specified for the stated application. Tech Department suspects that vehicle has an aftermarket lift kit. This product is intended for stock replacement only.  Warranty states, “The unit must be installed on vehicles for which the product is intended.” No Credit. Claim Denied.",
    none: "WARRANTY VOID",
  },
  "LABOR CLAIM RESPONSE RMA TORQUE": {
    detail:
      "The axle received meets all OEM requirements. Upon inspection of the returned axle, Tech Department found damage to the axle nut consistent with the use of an impact wrench.  The proper torque spec for this axle is xxx ft∙lbs, which requires specialized tools.  Limited Lifetime Warranty states, “Warranty does not cover products which have been subject to improper installation, abnormal use, misuse, accidental damage, collision, or broken shafts.” Claim Denied",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "LABOR CLAIM RESPONSE TWO AXLES PAY IT": {
    detail:
      "For customer satisfaction, Tech Department recommends paying the amount of $70 for two hours of labor plus two axles. Claim Approved",
    note: "APPROVED HOURS- 2 = 70.00. APPROVED PARTS-2",
  },
  "LABOR CLAIM RESPONSE WRONG PART": {
    detail:
      "Tech Department finds that the returned axle is not the correct part specified for the stated application. The online catalog was checked to verify correct data. Limited Lifetime Warranty states, “The unit must be installed on vehicles for which the product intended.” No Credit. Claim Denied.",
    note: "WARRANTY VOID",
  },
  "LABOR CLAIM RESPONSE IMPROPER INSTALL": {
    detail:
      "The axle received meet all OEM requirements. Tech Department was unable to find any manufacturing defects in the supplied part. The returned axle exhibits damage to the end of the XXXboard joint but shows nearly no evidence of installation on the splines past the retaining clip. This indicates an incomplete installation and a retaining clip that was not fully engaged.  Limited Lifetime Warranty states, “warranty does not cover products which have been subject to improper installation, abnormal use, abuse, misuse, accidental damage, modified suspension travel, oversized tires, and collision.” No Credit. Claim Denied.",
    note: "WARRANTY VOID",
  },
};

export const OTHERRESPONSES = {
  "ESTIMATE RESPONSE": {
    detail:
      "The Tech Department is unable to evaluate the axle supplied on this claim due to the supplied invoice being an estimate (not the actual invoice for the work completed). We do not evaluate labor claims based on estimates. No Credit. Claim Denied.",
    note: "",
  },
  "EXTENDED AXLES": {
    detail:
      "The axle received meets all OEM requirements and is the correct part specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied part. Tech Department suspects that the vehicle has been lifted. In that case the axle is the wrong axle for the application. But we do have extended axles for lifted vehicles. No Credit. Claim Denied.",
    note: "WARRANTY VOID",
  },
  "LABOR CLAIM RESPONSE CIRCLIP DEPTH": {
    detail:
      "Tech Department inspected Cir-Clip depth and location and found that there is no deviation in measurement between the supplied part and the OEM counterpart. Tech Department was unable to find any manufacturing defects in the supplied part. Tech Department suspects an installation issue or another issue with the vehicle and suggests a full vehicle inspection. Claim Denied.",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "LABOR CLAIM RESPONSE FD-8096 T-DAMAGE": {
    detail:
      "The axle received meets all OEM requirements and is the correct part specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied part. Tech Department recommends paying the amount of $250 for customer satisfaction plus one axle. Limited Lifetime Warranty states, “This warranty applies only to the defective part.  Wonh will not be held liable for any labor, incidental, or consequential charges related to the installation of the unit.” Claim Approved.",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "LABOR CLAIM RESPONSE FORM (NEW)": {
    detail: "",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "LABOR CLAIM RESPONSE HONDA CIRCLIP (VAGUE)": {
    detail:
      "The axle received meets all OEM requirements and is the correct part specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied part. Tech Department suspects an installation issue, such as reuse of the set ring or another issue with the vehicle and suggests a full vehicle inspection. Claim Denied.",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "LABOR CLAIM RESPONSE HONDA MOUNT": {
    detail:
      "The axle received meets all OEM requirements and is the correct part specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied part. Tech Department suspects an issue with the vehicle such as the known problem with O.E. Honda transmission mounts. (see supplied technical service bulletin) Recommend inspection. Claim Denied.",
    note: "APPROVED PART-1",
  },
  "LABOR CLAIM RESPONSE MINI COOPER CIRCLIP": {
    detail:
      "The axle received meets all OEM requirements and is the correct part specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied part. Axle has spline/circlip wear that is evidence of rapid engagement/disengagement. This is commonly due to excessive engine/trans movement during operation. This is a known issue with standard transmission Mini coopers, recommend inspection of engine/transmission mounts, claim denied.",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "LABOR CLAIM RESPONSE MISSING CIRCLIP": {
    detail:
      "The axle received meets all OEM requirements and is the correct part specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied part. The cir-clip was not found on the axle, nor was it sent separately so no determination could be made as to whether it was defective or not.  Tech Department suspects an installation issue or another issue with the vehicle and suggests a full vehicle inspection. Claim Denied.",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "LABOR CLAIM RESPONSE MULTIPLE AXLE APPROVED": {
    detail:
      "For customer satisfaction, Tech Department recommends paying replacement axle credit for both axles and $35 for one hour of labor to replace the XX-XXXX axle. Limited Lifetime Warranty states, this warranty applies only to the defective part. Tech Department was unable to find any manufacturing defects or deviations from specifications on XX-XXXX.  Wonh will not be held liable for any labor, incidental, or consequential charges related to the installation of the unit.” Claim Approved.",
    note: "APPROVED HOUR-35.00. APPROVED PART-2",
  },
  "LABOR CLAIM RESPONSE MULTIPLE PARTS DIF OUTCOME": {
    detail:
      "The first axle received HO-8223 batch number 125268 meets all OEM requirements and is the correct part specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied part. Tech Department suspects an installation issue or another issue with the vehicle and suggests a full vehicle inspection. The second axle HO-8246 batch number 125487 received meets all OEM requirements and is the proper fit for the vehicle application given in the claim. Upon inspection of the returned axle, Tech Department found there to be damage to the dust boot on the outboard CV joint. As stated in the Limited Lifetime Warranty, “The warranty is void if the boot is damaged in any way.” NO CREDIT. Claim Denied.",
    note: "",
  },
  "LABOR CLAIM RESPONSE NO INSTALLATION PAPERWORK": {
    detail:
      "The axle received meets all OEM requirements and is the correct part specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied part. The claim was submitted with no proof of installation or shop receipts because the unit was installed by a DIY customer and as such there is no shop labor to claim. Without shop invoices listing mount wear, suspension component damage/wear, collision, or other causes there is no way to make determination as to cause of axle failure. Original installation and failure replacement invoice are required for labor claims. Tech Department suspects an installation issue or another issue with the vehicle and suggests a full vehicle inspection. Claim Denied.",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "LABOR CLAIM RESPONSE OREILLY MPV NOT APPROVED": {
    detail:
      "The CV axle submitted for this claim, does not belong to the Import Direct or Precision lines and is not an MPV axle covered under the WONH warranty. This axle should be returned to the supplying company for warranty purposes. Claim Denied.",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "LABOR CLAIM RESPONSE REMANUFACTURED AXLE": {
    detail:
      "Upon review of the submitted XX-XXXX Tech. Department found that the axle is not a WONH axle and is in fact a remanufactured axle. This axle should be returned to the supplying company for warranty purposes. Claim Denied.",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "LABOR CLAIM RESPONSE SUBARU VIBRATION": {
    detail:
      "The axle received meets all OEM requirements and is the correct part specified for the stated application. Tech Department was unable to find any manufacturing defects in the supplied part. Tech Department suspects an issue with the vehicle such as the known problem with O.E. Subaru transmission mounts. The mount wears causing the transmission to shift to one side and cause binding issues with new shafts only as they are limited to the Subaru O.E. 30 degrees. Recommend inspection and likely replacement of suspected defective transmission or another mount. Claim Denied.",
    note: "APPROVED PART-",
  },
  "LABOR CLAIM RESPONSE ALTERED": {
    detail:
      "The CV axle received meets all OEM requirements. Tech Department was unable to find any manufacturing defects in the supplied part. The returned axle exhibits alteration to the outboard boot clamp. Limited Lifetime Warranty states, “warranty does not cover products which have been subject to improper installation, abnormal use, abuse, misuse, accidental damage, collision, or broken shafts.”  Claim Denied.",
    note: "WARRANTY VOID",
  },
  "LABOR CLAIM RESPONSE CARQUEST PAST 1 YEAR CONTRACT": {
    detail:
      "The axle returned is not a Wonh Axle. The sales contract stated that Wonh would honor its warranty for any pre-existing Car Quest inventory for a period of 1 year (365 days). This period has expired. Claim Denied.",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "LABOR CLAIM RESPONSE DISASSEMBLED": {
    detail:
      "The axle received [XX-XXX] is the correct part specified for the stated application. The [XX-XXXX] meets all OEM requirements. On pre-examination of the CV axle Tech. Department found that the [XXXjoint] was disassembled before being returned to the Tech. Department at AIT. Since the axle has been previously taken apart no determination can be made as to joint contamination, boot integrity or joint damage. As our warranty states the determination as to whether a part is defective rests with Management. Tech Department suspects an installation issue or another issue with the vehicle and suggests a full vehicle inspection. Claim Denied.",
    note: "CREDIT AMOUNT REFLECTS",
  },
  "PROFORMANCE PARTS RESPONSE": {
    detail:
      "Tech Department was unable to find any manufacturing defects in the supplied part. Tech department suspects that the vehicle has been modified with performance parts. Causing the axle to break. Limited Lifetime Warranty states, “warranty does not cover products which have been subject to improper installation, abnormal use, abuse, misuse, accidental damage, modified suspension travel, oversized tires, and collision.” No Credit. Claim Denied.",
    note: "WARRANTY VOID ",
  },
};
