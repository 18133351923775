import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

import GeneralModalV3 from "../../../general/GeneralModalV3";

const PackProcessItemInfoModal = ({ itemObj, tabValue }) => {
  const item = tabValue === 0 ? itemObj.item : itemObj;

  return (
    <GeneralModalV3
      openComponent={
        <IconButton size="small">
          <Info fontSize="small" color="primary" />
        </IconButton>
      }
    >
      <Box p={1}>
        <Stack spacing={1}>
          <Typography>
            <strong>Item: </strong>
            {item.item}
          </Typography>
          <Typography>
            <strong>Line ID: </strong>
            {item.lineid}
          </Typography>
          <Typography>
            <strong>Bin Number: </strong>
            {item.binnumber}
          </Typography>
          <Typography>
            <strong>Box Size: </strong>
            {item.boxsize}
          </Typography>
          <Typography>
            <strong>Item Fulfillment: </strong>
            {item.iful}
          </Typography>
          <Typography>
            <strong>Memo: </strong> {item.memo}
          </Typography>
          <Typography>
            <strong>Total Fulfillment Qty:</strong> {item.quantity}
          </Typography>
          <Typography>
            <strong>Packed: </strong> {item.packedamount}
          </Typography>
          <Typography>
            <strong>Remaining: </strong> {item.quantity - item.packedamount}
          </Typography>
        </Stack>
      </Box>
    </GeneralModalV3>
  );
};
export default PackProcessItemInfoModal;
