import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConsecutiveAlertPack } from "../../redux/features/alert/alertSlice";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import axios from "../../axios/axios.config";
import FlexWrapper from "../../components/FlexWrapper";

const PrintItemLabels = () => {
  const { printer, currentSubsidiary } = useSelector((state) => state.user);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const itemInputRef = useRef(null);
  const [input, setInput] = useState({
    item: "",
    brand: currentSubsidiary === "WGE" ? "TRAKMOTIVE-WGE" : "TRAKMOTIVE",
    printer,
    qty: 1,
    isBrownBox: true,
  });

  const handleChanges = (e) => {
    if (e.target.name === "item") {
      setInput({ ...input, [e.target.name]: e.target.value.toUpperCase() });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitRequest(input);
    setInput({
      item: "",
      brand: input.brand,
      printer,
      qty: 1,
      isBrownBox: input.isBrownBox,
    });

    itemInputRef.current.inputMode = "none";
  };

  const submitRequest = async (input) => {
    try {
      const response = await axios.post(
        "netsuite/print-labels/warehouse",
        input
      );
      dispatch(
        setConsecutiveAlertPack({
          pack: [
            ...alert.consecutiveAlertPack,
            {
              message: response.data.msg
                ? response.data.msg
                : "Successfully Printed Item Label",
              key: new Date().getTime(),
            },
          ],
          severity: "success",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        })
      );
    } catch (error) {
      dispatch(
        setConsecutiveAlertPack({
          pack: [
            ...alert.consecutiveAlertPack,
            {
              message: error?.response?.data?.msg
                ? error.response.data.msg
                : "Error Printing Item Label",
              key: new Date().getTime(),
            },
          ],
          severity: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        })
      );
    }
  };

  return (
    <Container sx={{ maxWidth: { xs: "xl", md: "md" } }}>
      <Typography component="h1" variant="h4" textAlign="center" py={2}>
        Print Item Labels
      </Typography>
      <form onSubmit={handleSubmit}>
        <FlexWrapper flexDirection="column" gap={2}>
          <TextField
            autoComplete="off"
            inputProps={{ autoComplete: "off" }}
            autoFocus
            fullWidth
            required
            inputRef={itemInputRef}
            inputMode="none"
            type="text"
            label="Item"
            name="item"
            value={input.item}
            onChange={handleChanges}
          />
          {currentSubsidiary === "WGE" ? (
            <FormControl fullWidth required>
              <InputLabel id="select-brand">Brand</InputLabel>
              <Select
                labelId="select-brand"
                id="select-brand-id"
                name="brand"
                label="brand"
                value={input.brand}
                onChange={handleChanges}
              >
                <MenuItem value="TRAKMOTIVE-WGE">TRAKMOTIVE</MenuItem>
                <MenuItem value="PLAIN-WGE">PLAIN</MenuItem>
                <MenuItem value="LPR">LPR</MenuItem>
                <MenuItem value="SKF">SKF</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <FormControl fullWidth required>
              <InputLabel id="select-brand">Brand</InputLabel>
              <Select
                labelId="select-brand"
                id="select-brand-id"
                name="brand"
                label="brand"
                value={input.brand}
                onChange={handleChanges}
              >
                <MenuItem value="TRAKMOTIVE">TRAKMOTIVE</MenuItem>
                <MenuItem value="TRAKMOTIVE-BW-LOGO">
                  TRAKMOTIVE-BW-LOGO
                </MenuItem>
                <MenuItem value="TRAKMOTIVE-W-MP">TRAKMOTIVE-W-MP</MenuItem>
                <MenuItem value="OREILLY">OREILLY</MenuItem>
                <MenuItem value="MPV">MPV</MenuItem>
                <MenuItem value="AUTOZONE">AUTOZONE</MenuItem>
                <MenuItem value="NAPA">NAPA</MenuItem>
                <MenuItem value="PLAIN">PLAIN</MenuItem>
                <MenuItem
                  value=""
                  disabled
                  sx={{ bgcolor: "rgba(25, 118, 210, 0.06)" }}
                >
                  ------------------------
                </MenuItem>
                <MenuItem value="SET">SET</MenuItem>
                <MenuItem value="NISSAN">NISSAN</MenuItem>
                <MenuItem value="INFINITI">INFINITI</MenuItem>
                <MenuItem
                  value=""
                  disabled
                  sx={{ bgcolor: "rgba(25, 118, 210, 0.06)" }}
                >
                  ------------------------
                </MenuItem>
                <MenuItem value="ALTUS AUTOMOTIVE">ALTUS AUTOMOTIVE</MenuItem>
                <MenuItem value="PARTS MOUNTAIN">PARTS MOUNTAIN</MenuItem>
                <MenuItem
                  value=""
                  disabled
                  sx={{ bgcolor: "rgba(25, 118, 210, 0.06)" }}
                >
                  ------------------------
                </MenuItem>
                <MenuItem value="FVP">FVP</MenuItem>
                <MenuItem value="PARTS MASTER">PARTS MASTER</MenuItem>
                <MenuItem value="AUTOMATIC DISTRIBUTORS">
                  AUTOMATIC DISTRIBUTORS
                </MenuItem>
              </Select>
            </FormControl>
          )}

          <TextField
            fullWidth
            required
            disabled
            type="text"
            defaultValue={input.printer}
          />
          <TextField
            fullWidth
            required
            type="number"
            inputMode="numeric"
            label="Print Quantity"
            name="qty"
            value={input.qty}
            onChange={handleChanges}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={input.isBrownBox}
                onChange={() => {
                  setInput({ ...input, isBrownBox: !input.isBrownBox });
                }}
                inputProps={{ "aria-label": "brownbox checkbox" }}
              />
            }
            label="Brown Box"
          />
          <Button
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            sx={{ py: 1 }}
          >
            {"Print Label"}
          </Button>
        </FlexWrapper>
      </form>
    </Container>
  );
};
export default PrintItemLabels;
