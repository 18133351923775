import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../redux/features/alert/alertSlice";

import axios from "../axios/axios.config";

export const useSalesRepCustomerMatch = () => {
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [filteredCustomersList, setFilteredCustomersList] = useState([]);
  const dispatch = useDispatch();
  const {
    salesrepcode,
    partnercode,
    customercode,
    usertype,
    currentSubsidiary,
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (salesrepcode && partnercode && customercode && usertype) {
      getFilteredCustomers();
    }
  }, [salesrepcode, partnercode, customercode, usertype]);

  const getFilteredCustomers = async () => {
    try {
      let code = -1;
      let codename = "";
      let sortby = "companyname";

      if (usertype === "employee") {
        code = salesrepcode;
        codename = "salesrepcode";
      } else if (usertype === "partner") {
        code = partnercode;
        codename = "partnercode";
      } else {
        code = customercode;
        codename = "customercode";
      }

      const response = await axios.get("/customers", {
        params: {
          name: codename,
          code,
          usertype,
          subsidiary: currentSubsidiary,
          sortby,
        },
      });
      const customers = await response.data;

      setFilteredCustomers(customers);
      setFilteredCustomersList(customers.map((c) => c.companyname));
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error Getting Customers: ${error.message}`,
          duration: 5000,
        })
      );
    }
  };

  return { filteredCustomersList, filteredCustomers, setFilteredCustomers };
};
