import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonBase,
  Container,
  Divider,
  Grid,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";

import NavBar from "../../components/NavBar";

const ProfileLayout = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <>
      <NavBar />
      <Container maxWidth="xl" sx={{ pb: matches ? 3 : 0 }}>
        <Typography
          variant="h3"
          gutterBottom
          textAlign={matches ? "center" : "left"}
        >
          Settings
        </Typography>

        <Divider sx={{ mb: 2, display: { xs: "none", md: "block" } }} />

        <Grid container>
          <Grid item xs={12} md={1}>
            <Toolbar disableGutters>
              <Stack
                direction={matches ? "row" : "column"}
                alignItems={"start"}
                justifyContent={matches ? "center" : "start"}
                spacing={2}
                sx={{
                  width: matches ? "100%" : "initial",
                }}
              >
                <ButtonBase
                  onClick={() => navigate("/user-profile")}
                  sx={{
                    backgroundColor:
                      location.pathname === "/user-profile" ? "#e6e6e6" : "",
                    borderRadius: "4px",
                    p: 1,
                  }}
                >
                  <Typography>General</Typography>
                </ButtonBase>

                <ButtonBase
                  onClick={() => navigate("printers")}
                  sx={{
                    backgroundColor:
                      location.pathname === "/user-profile/printers"
                        ? "#e6e6e6"
                        : "",
                    borderRadius: "4px",
                    p: 1,
                  }}
                >
                  <Typography>Printers</Typography>
                </ButtonBase>

                <ButtonBase
                  onClick={() => navigate("language")}
                  sx={{
                    backgroundColor:
                      location.pathname === "/user-profile/language"
                        ? "#e6e6e6"
                        : "",
                    borderRadius: "4px",
                    p: 1,
                  }}
                >
                  <Typography>Language</Typography>
                </ButtonBase>
              </Stack>
            </Toolbar>
          </Grid>
          <Grid item xs={12} md={10} pt={matches ? 3 : 0} ml={1}>
            <Outlet context={{ matches, user, dispatch, navigate }} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default ProfileLayout;
