import { Container } from "@mui/material";
import ToteForm from "../components/ToteForm";

const ToteManager = () => {
  return (
    <Container
      sx={{
        maxWidth: { xs: "xl", md: "md" },
        height: { xs: "calc(100vh - 81px)", md: "calc(100vh - 92.5px)" },
      }}
    >
      <ToteForm />
    </Container>
  );
};

export default ToteManager;
