import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isGeneralAlertOpen: false,
  generalAlertType: "success",
  generalAlertMessage: "",
  generalAlertBottomOffset: "",
  generalAlertDuration: null,
  isConsecutiveAlertOpen: false,
  consecutiveAlertPack: [],
  consecutiveAlertDuration: 0,
  consecutiveAlertSeverity: "success",
  consecutiveAlertAnchorOrigin: { vertical: "top", horizontal: "left" },
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    openGeneralAlert: (state, { payload }) => {
      state.generalAlertType = payload.type;
      state.generalAlertMessage = payload.message;
      state.generalAlertDuration = payload.duration;
      state.generalAlertBottomOffset = payload.offset || "";
      state.isGeneralAlertOpen = true;
    },
    closeGeneralAlert: (state) => {
      state.isGeneralAlertOpen = false;
    },
    resetGeneralAlert: (state) => {
      return initialState;
    },
    setConsecutiveAlertPack: (state, { payload }) => {
      state.consecutiveAlertPack = payload.pack;
      state.consecutiveAlertDuration = payload.duration
        ? payload.duration
        : state.consecutiveAlertDuration;
      state.consecutiveAlertSeverity = payload.severity
        ? payload.severity
        : state.consecutiveAlertSeverity;
      state.consecutiveAlertAnchorOrigin = payload.anchorOrigin
        ? payload.anchorOrigin
        : state.consecutiveAlertAnchorOrigin;
    },
  },
});

export const {
  openGeneralAlert,
  closeGeneralAlert,
  setConsecutiveAlertPack,
  resetGeneralAlert,
} = alertSlice.actions;
export default alertSlice.reducer;
