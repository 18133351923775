import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CancelIcon from "@mui/icons-material/Cancel";
import FlexWrapper from "../FlexWrapper";

const FileUpload = ({
  setFiles,
  setFileError,
  validateFileData = null,
  loading,
  setLoading,
  multiple = false,
  filesArr = null,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple,
  });
  const [error, setError] = useState(null);
  //Original File is used just to display original file names before we modify them for S3
  const [originalFiles, setOriginalFiles] = useState([]);

  function handleDrop(acceptedFiles) {
    setLoading(true);

    if (!multiple) {
      setError(null);
    }

    //used for multiple file uploads
    let multipleFilesArr = [];
    let originalFilesArr = [];

    acceptedFiles.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array", raw: true });
        // Process the workbook data as needed
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        //Validate File Data
        validateFileData(jsonData, file)
          .then((result) => {
            //new File name we will save to S3
            const newFile = handleFileChange(file);
            if (!multiple) {
              setFiles([newFile]);
              setOriginalFiles([result]);
              setLoading(false);
              setError(false);
              setFileError(null);
              return;
            }
            //handles multi file uploads
            if (multiple && filesArr) {
              multipleFilesArr.push(newFile);
              originalFilesArr.push(file);
              //checks when its the last file uploaded
              if (index === acceptedFiles.length - 1) {
                setFiles((prevFiles) => [...prevFiles, ...multipleFilesArr]);
                setOriginalFiles((prevOriginalFiles) => [
                  ...prevOriginalFiles,
                  ...originalFilesArr,
                ]);
                setLoading(false);
                setError(false);
                setFileError(null);
              }
            }
          })
          .catch((error) => {
            setOriginalFiles([]);
            setFiles([]);
            setError(error.message);
            setFileError(error.message);
            setLoading(false);
          });
      };
      //triggers onload function
      reader.readAsArrayBuffer(file);
    });
  }

  function handleFileChange(file) {
    //time string to differentiate files
    const time = new Date().valueOf();
    //change name of file to remove whitespace and prepend time string
    const modifiedFile = new File(
      [file],
      `${time}-${file.name.replace(/\s/g, "-")}`
    );
    //return new File
    return modifiedFile;
  }

  function clearFiles(event) {
    event.stopPropagation();
    setOriginalFiles([]);
    setFiles([]);
    setError(false);
    setFileError("");
    setLoading(false);
  }

  return (
    <Box
      {...getRootProps({ onDragEnd: (e) => e.preventDefault() })}
      className={`dropzone ${isDragActive ? "active" : ""}`}
      sx={{
        border: "1px dashed #1E81D8",
        borderRadius: "8px",
        textAlign: "center",
        py: 2,
        px: 1,
      }}
    >
      <input {...getInputProps({ hidden: true })} />
      {originalFiles.length > 0 ? (
        <FlexWrapper justifyContent="center" alignItems="center" gap={1}>
          {/* clear files button */}
          <Box sx={{ position: "relative" }}>
            <InsertDriveFileIcon fontSize="large" />
            <Box sx={{ position: "absolute", top: -12, right: -10 }}>
              <IconButton size="small" onClick={clearFiles}>
                <Tooltip title="Clear Files">
                  <CancelIcon color="error" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </Box>

          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            {originalFiles.map((file) => file.name).join(", ")}
          </Typography>
        </FlexWrapper>
      ) : (
        <>
          {error && (
            <Typography component="p" variant="body1" color="error">
              {error}
            </Typography>
          )}
          {loading && (
            <Typography component="p" variant="body1">
              Processing File ....
            </Typography>
          )}
          {!loading && !error && (
            <Typography component="p" variant="body1">
              {isDragActive
                ? "Drop here..."
                : "Drag file here or click to add file"}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default FileUpload;
