import { IconButton, Typography } from "@mui/material";
import { usePDF } from "@react-pdf/renderer";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

const PDFDoc = ({ component: Component }) => {
  return <Component />;
};

const FormDownload = ({ component, name }) => {
  const [instance] = usePDF({
    document: <PDFDoc component={component} />,
  });

  if (instance.loading)
    return (
      <Typography variant="body2" color="primary">
        Loading PDF...
      </Typography>
    );

  if (instance.error)
    return (
      <Typography variant="body2" color="error">
        PDF Error
      </Typography>
    );

  return !instance.loading && !instance.error ? (
    <a href={instance.url} download={`${name}.pdf`}>
      <IconButton aria-label="download">
        <FileDownloadIcon />
      </IconButton>
    </a>
  ) : null;
};

export default FormDownload;
