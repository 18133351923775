import { redirect } from "react-router-dom";
import { refreshUser } from "../../redux/features/user/userSlice";
import { store } from "../../redux/store";

import axios from "../../axios/axios.config";

export const feedbackLoader = async () => {
  try {
    const token = localStorage.getItem("token");
    const user = store.getState().user;

    if (!token) {
      localStorage.clear();
      redirect("/", { replace: true });
    }

    if (!user._id) {
      //Write code to validate token and get user
      const refreshResponse = await axios.get("users/refresh", {
        headers: { Authorization: `Bearer ${token}` },
      });

      await store.dispatch(refreshUser(refreshResponse.data)).unwrap();
    }

    return null;
  } catch (error) {
    localStorage.clear();
    return redirect("/", { replace: true });
  }
};
