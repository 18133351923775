import { useEffect, useState } from "react";
import { CheckCircle, KeyboardBackspace } from "@mui/icons-material";
import { useLoaderData, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  handleAdjustment,
  handleApproveAndAdjust,
} from "../../../utils/helper-functions/pick-discrepancies/pickDiscrepanciesHelperFunctions";
import {
  Box,
  Button,
  Container,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../components/FlexWrapper";
import GeneralModalV3 from "../../../components/general/GeneralModalV3";
import Loading from "../../../components/Loading";

const AddCycleCountPage = () => {
  const data = useLoaderData();
  const { navigate, openAlert, navigation, axios } = useOutletContext();
  const user = useSelector((state) => state.user);

  const [bin, setBin] = useState("");
  const [item, setItem] = useState("");
  const [count, setCount] = useState("");
  const [loading, setLoading] = useState("");

  const isBinVerified = data.bin === bin;
  const isItemVerified = data.item === item || data.lineitemupc === item;

  useEffect(() => {
    //safeguard against modifying bins that are already approved or are in pending approval
    if (data.status === "complete" || data.status === "pending-approval") {
      navigate(
        `/portal/${user.currentDepartment?.toLowerCase()}/pick-discrepancies-cycle-counts`,
        { replace: true }
      );
    }
  }, []);

  //loading screen when navigating
  if (navigation.state === "loading") {
    return <Loading message="Loading Bins..." />;
  }

  //submission loading
  if (loading) return <Loading message={loading} />;

  const handleSubmitCount = async (qty) => {
    try {
      setLoading("Submitting Count...");

      const binResponse = await axios.post(
        "netsuite/post/pick-order/validate/bin",
        {
          binInternalId: data.bininternalid,
          itemInteralId: data.lineiteminternalid,
        }
      );

      const binQty = parseInt(binResponse.data[0]?.binonhandavailable);

      if (!binQty && binQty !== 0) {
        throw new Error("Could not get valid bin count from netsuite");
      }

      //make call to get qty currently being picked
      const pickInProgressResponse = await axios.get(
        `pick-order/get/total-item-bin-qty?subsidiary=${data.subsidiary}&locationinternalid=${data.locationinternalid}&lineiteminternalid=${data.lineiteminternalid}&bininternalid=${data.bininternalid}`
      );

      const totalPickInProgress = parseInt(
        pickInProgressResponse.data?.totalQty
      );

      if (!totalPickInProgress && totalPickInProgress !== 0) {
        throw new Error("Could not get a valid pick in progress count");
      }

      //make call to get qty currently being picked
      const inventoryMoveInProgressResponse = await axios.get(
        `pick-order/get/total-inventory-move-qty?subsidiary=${data.subsidiary}&locationinternalid=${data.locationinternalid}&lineiteminternalid=${data.lineiteminternalid}&bininternalid=${data.bininternalid}`
      );

      const totalInventoryMoveInProgress = parseInt(
        inventoryMoveInProgressResponse.data?.totalQtyBeingMoved
      );

      if (!totalInventoryMoveInProgress && totalInventoryMoveInProgress !== 0) {
        throw new Error(
          "Could not get a valid inventory move in progress count"
        );
      }

      const totalVirtualQty =
        binQty - totalPickInProgress - totalInventoryMoveInProgress;

      let status = "";
      const countData = {
        usercount: qty,
        countedby: user._id,
        nsqty: binQty,
        totalpickinprogress: totalPickInProgress,
        totalinventorymoveinprogress: totalInventoryMoveInProgress,
        time: new Date().toISOString(),
        totalvirtualqty: totalVirtualQty,
        adjustment: 0,
        autoapprove: false,
      };
      const statusChangeData = {
        from: data.status,
        changedby: user._id,
        time: new Date().toISOString(),
      };

      //user entered 0 and first count
      if (parseInt(qty) === 0 && data.counts.length === 0) {
        status = "complete";
        statusChangeData.to = status;

        if (totalVirtualQty !== 0) {
          if (data.shorttype === "EMPTY-ISSUE") {
            status = "pending-approval";
          }
          const adjustmentAmount = qty - totalVirtualQty;
          countData.adjustment = adjustmentAmount;
        }
      } else if (parseInt(qty) === totalVirtualQty) {
        //user count matches virtual qty but non-zero
        //user count matches virtual count
        // we only want to auto approve on first count
        status = data.counts.length === 0 ? "complete" : "pending-approval";
        statusChangeData.to = status;
      } else {
        //count and virtual qty difer. Update to pending approval and log adjustment
        //adjustment will be made on admin page
        status = "pending-approval";
        statusChangeData.to = status;
        const adjustmentAmount = qty - totalVirtualQty;
        countData.adjustment = adjustmentAmount;
      }

      //if new status is complete then unlock bin
      //status is only complete on first count
      if (status === "complete") {
        //unlock bin and do adjustment api call
        let items = null;
        //mark as autoapprove
        countData.autoapprove = true;
        const adjustment = countData.adjustment;
        if (adjustment !== 0) {
          items = [
            {
              item: data.lineiteminternalid,
              adjustQtyBy: countData.adjustment,
              binnumbers: data.bin,
              location: data.locationinternalid,
              linememo: data.order,
            },
          ];
        }

        await handleApproveAndAdjust({
          adjustment,
          bininternalid: data.bininternalid,
          lineiteminternalid: data.lineiteminternalid,
          username: user.username,
          locationinternalid: data.locationinternalid,
          subsidiaryinternalid: parseInt(user.currentSubsidiaryInternalId),
          _id: data._id,
          items,
        });
      }

      await axios.patch(`pick-shorts/${data._id}/add-count`, {
        status,
        statusChangeData,
        countData,
      });

      navigate(
        `/portal/${user.currentDepartment?.toLowerCase()}/pick-discrepancies-cycle-counts`
      );

      openAlert({
        type: "success",
        message: "Successfully Added Count",
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: error.response?.data?.msg || error.message,
        duration: 5000,
      });
    } finally {
      setLoading("");
    }
  };

  return (
    <Container maxWidth="lg">
      <FlexWrapper gap={1} justifyContent="space-between" alignItems="center">
        <IconButton onClick={() => navigate(-1, { replace: true })}>
          <KeyboardBackspace />
        </IconButton>
        <FlexWrapper gap={1}>
          <Typography>Bin: {data.bin}</Typography>
          <Typography>Item: {data.item}</Typography>
        </FlexWrapper>
      </FlexWrapper>
      <Stack spacing={2} pt={1} component="form">
        <TextField
          autoFocus={!isBinVerified}
          label="Enter Bin Number"
          fullWidth
          value={bin}
          onChange={(event) => setBin(event.target.value.toUpperCase())}
          color={bin && isBinVerified ? "success" : ""}
          error={Boolean(bin) && !isBinVerified}
          helperText={
            Boolean(bin) &&
            !isBinVerified &&
            "Please enter the correct bin number"
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isBinVerified ? (
                  <Icon>
                    <CheckCircle color="success" />
                  </Icon>
                ) : null}
              </InputAdornment>
            ),
          }}
        />
        {isBinVerified && (
          <TextField
            autoFocus={isBinVerified && !isItemVerified}
            label="Enter Item"
            fullWidth
            value={item}
            onChange={(event) => setItem(event.target.value.toUpperCase())}
            color={item && isItemVerified ? "success" : ""}
            error={Boolean(item) && !isItemVerified}
            helperText={
              Boolean(item) && !isItemVerified
                ? "Please enter the correct item"
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isItemVerified ? (
                    <Icon>
                      <CheckCircle color="success" />
                    </Icon>
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
        )}

        {isBinVerified && !Boolean(item) && !isItemVerified && (
          <GeneralModalV3
            openComponent={
              <Button fullWidth variant="contained">
                Complete Count
              </Button>
            }
          >
            {(handleClose) => (
              <Box textAlign="center">
                <Typography variant="h5" py={1} fontWeight="bold">
                  Are you sure you want to submit?
                </Typography>
                <Divider />

                <Typography p={2} fontSize="18px">
                  Ensure you have counted the items in bin correctly before
                  submitting
                </Typography>
                <Typography fontSize="18px">
                  <strong>{data.item} - Counted:</strong> {0}
                </Typography>
                <Divider />

                <Box p={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={() => {
                      handleSubmitCount(0);
                      handleClose();
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            )}
          </GeneralModalV3>
        )}

        {isBinVerified && isItemVerified && (
          <TextField
            autoFocus={isBinVerified && isItemVerified}
            label="Enter Count Qty"
            type="number"
            inputMode="numeric"
            value={count}
            onChange={(event) => setCount(event.target.value)}
            inputProps={{ min: 0 }}
            error={count < 0 || String(count).includes(".")}
            helperText={
              count < 0 || String(count).includes(".")
                ? "Quantity must be an integer greater than or equal to 0"
                : ""
            }
          />
        )}

        {count !== "" &&
          count >= 0 &&
          !String(count).includes(".") &&
          isBinVerified &&
          isItemVerified && (
            <GeneralModalV3
              openComponent={
                <Button fullWidth variant="contained">
                  Complete Count
                </Button>
              }
            >
              {(handleClose) => (
                <Box textAlign="center">
                  <Typography variant="h5" py={1} fontWeight="bold">
                    Are you sure you want to submit?
                  </Typography>
                  <Divider />

                  <Typography p={2} fontSize="18px">
                    Ensure you have counted the items in bin correctly before
                    submitting
                  </Typography>
                  <Typography fontSize="18px">
                    <strong>{data.item} - Counted:</strong> {count}
                  </Typography>
                  <Divider />

                  <Box p={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="success"
                      onClick={() => {
                        handleSubmitCount(count);
                        handleClose();
                      }}
                    >
                      Yes
                    </Button>
                  </Box>
                </Box>
              )}
            </GeneralModalV3>
          )}
      </Stack>
    </Container>
  );
};

export default AddCycleCountPage;
