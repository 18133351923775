import { ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import CreditMemoAndRMANetsuiteLinks from "../netsuite/CreditMemoAndRMANetsuiteLinks";

const CustomerRateAndRMADetails = ({ form, handleApproveClaim }) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      {form.rma && (
        <Accordion elevation={1} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <FlexWrapper gap={1} alignItems="center">
              <Typography variant="h5">RMA Details</Typography>
              {(user.currentDepartment === "RETURNS" ||
                user.currentDepartment === "CUSTOMER-SERVICE" ||
                user.currentDepartment === "PRODUCT") && (
                <CreditMemoAndRMANetsuiteLinks
                  type="RMA"
                  internalid={form.rma.rmainternalid}
                />
              )}
            </FlexWrapper>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="RMA Number"
                  name="rmaNum"
                  type="text"
                  value={form.rma.rmaNum}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Created By"
                  name="createdby"
                  value={`${form.rma.createdbyname} (${form.rma.createdbyusername})`}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Created Date"
                  name="createddate"
                  value={new Date(form.rma.createdat).toLocaleDateString()}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Created Time"
                  name="createdtime"
                  typ="time"
                  value={new Date(form.rma.createdat).toLocaleTimeString()}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      {form.ratedetails && (
        <Accordion elevation={1} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <FlexWrapper
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="h5">
                Customer Warranty Rate Details
              </Typography>
            </FlexWrapper>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Customer Group"
                  name="customergroup"
                  type="text"
                  value={form.customergroup}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Sales Qty"
                  name="salesqty"
                  type="number"
                  value={form.ratedetails.salesqty}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Warranty Credit Qty"
                  name="warrantycreditqty"
                  type="number"
                  value={form.ratedetails.warrantycreditqty}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Net Qty"
                  name="netqty"
                  type="number"
                  value={form.ratedetails.netqty}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Warranty Rate"
                  name="warrantyratebasedonqty"
                  type="text"
                  value={form.ratedetails.warrantyratebasedonqty}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Warranty Rate Including Pending RMA"
                  name="warrantyrateincludingpendingrma"
                  type="text"
                  value={form.ratedetails.warrantyrateincludingpendingrma}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Pending RMA Qty"
                  name="pendingrmaqty"
                  type="number"
                  value={form.ratedetails.pendingrmaqty}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {form.status === "pendingapproval" && (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="medium"
                    color="success"
                    sx={{ mr: 2 }}
                    onClick={handleApproveClaim}
                  >
                    Approve Claim
                  </Button>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      {form.creditmemo && (
        <Accordion elevation={1} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <FlexWrapper alignItems="center" gap={1}>
              <Typography variant="h5">Credit Memo Details</Typography>
              {(user.currentDepartment === "RETURNS" ||
                user.currentDepartment === "CUSTOMER-SERVICE" ||
                user.currentDepartment === "PRODUCT") && (
                <CreditMemoAndRMANetsuiteLinks
                  type="CM"
                  internalid={form.creditmemo.creditmemointernalid}
                />
              )}
            </FlexWrapper>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Credit Memo Number"
                  type="text"
                  value={form.creditmemo.creditmemonumber}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Credit Memo Total"
                  type="number"
                  value={form.creditmemo.total}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Created By"
                  type="text"
                  value={`${form.creditmemo.createdbyname} (${form.creditmemo.createdbyusername})`}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Created Date"
                  type="text"
                  value={new Date(
                    form.creditmemo.createdbydate
                  ).toLocaleDateString()}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Created Time"
                  type="text"
                  value={new Date(
                    form.creditmemo.createdbydate
                  ).toLocaleTimeString()}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
export default CustomerRateAndRMADetails;
