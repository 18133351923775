import { useState } from "react";
import {
  Card,
  CardContent,
  Container,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const PackProcessViewPalletItems = ({ palletData, palletNumbers }) => {
  const [selectedPallet, setSelectedPallet] = useState(palletNumbers[0]);

  const handlePalletChange = (event) => {
    setSelectedPallet(event.target.value);
  };

  return (
    <Container maxWidth="md">
      <FormControl fullWidth>
        <Select value={selectedPallet} onChange={handlePalletChange}>
          {palletNumbers.map((pallet) => (
            <MenuItem key={pallet} value={pallet}>
              {pallet.replace("pallet", "Pallet ")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedPallet && (
        <Card style={{ marginTop: "20px" }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {selectedPallet}
            </Typography>
            <List>
              {palletData[selectedPallet].items.length ? (
                palletData[selectedPallet].items.map((item, index) => (
                  <ListItem key={item.lineid + item.item}>
                    <ListItemText
                      primary={`${item.item}`}
                      secondary={`Bin: ${item.binnumber}, Packed By: ${item.packedby}`}
                    />
                  </ListItem>
                ))
              ) : (
                <Typography>No Pallet Items</Typography>
              )}
            </List>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};
export default PackProcessViewPalletItems;
