import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";
import GeneralModal from "../../GeneralModal";
import FlexWrapper from "../../FlexWrapper";

const PickOrderActionsWarningModal = ({ type, open, closeFn, action }) => {
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <GeneralModal open={open} handleClose={() => closeFn()}>
      <Box>
        <Box>
          <Typography
            variant="h4"
            textAlign="center"
            py={1}
            textTransform="capitalize"
          >
            {type === "partial-fulfill" && "Partially fulfill this order?"}
            {type === "reset-all-items" && "Reset all Items?"}
            {type === "cancel-pick" && "Cancel Pick?"}
            {type === "refresh-bin" && "Refresh Bin Data?"}
          </Typography>
          <Divider />
          <Typography variant="h6" textAlign="center" p={1}>
            {type === "partial-fulfill" &&
              "Item fulfillment will be created in Netsuite and you will be logged out of this order."}
            {type === "reset-all-items" &&
              "All progress will be lost and you will start from the beginning again"}
            {type === "cancel-pick" &&
              "You will be removed from the order, but can resume at a later time. All data will be saved."}
            {type === "refresh-bin" &&
              "Latest bin updates will be applied. Current path may change."}
          </Typography>
          <Divider />
          <Box p={1}>
            <FlexWrapper
              justifyContent={matches ? "center" : "flex-end"}
              flexDirection={matches ? "column" : "row"}
              gap={1}
              height={matches ? 100 : 50}
              alignItems="center"
            >
              <Button
                fullWidth={matches}
                color="error"
                variant="contained"
                onClick={() => closeFn()}
              >
                Cancel
              </Button>
              <Button fullWidth={matches} variant="contained" onClick={action}>
                Confirm
              </Button>
            </FlexWrapper>
          </Box>
        </Box>
      </Box>
    </GeneralModal>
  );
};
export default PickOrderActionsWarningModal;
