import { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLoaderData } from "react-router-dom";

const ViewReceivedTransactionsLayout = () => {
  const data = useLoaderData();
  const [receiveData, setReceiveData] = useState(data);
  const user = useSelector((state) => state.user);

  return <Outlet context={{ receiveData, setReceiveData, user }} />;
};
export default ViewReceivedTransactionsLayout;
