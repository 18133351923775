//Primarily used for speed dial on Systems page and Authentication Page
export const departments = [
  "CUSTOMER",
  "CUSTOMER-SERVICE",
  "PROCUREMENT",
  "PRODUCT",
  "RETURNS",
  "REVERSE-LOGISTICS",
  "SALES",
  "SALES-REP",
  "WAREHOUSE",
  "NINGBO",
];
