import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { USERCOLUMNS } from "../../utils/columns/admin/adminColumns";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Add, Circle, Close, Edit } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import {
  closeFullScreenModal,
  openFullScreenModal,
} from "../../redux/features/modal/modalSlice";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

import UserForm from "../../components/admin/UserForm";
import FlexWrapper from "../../components/FlexWrapper";
import FullScreenModal from "../../components/FullScreenModal";
import axios from "../../axios/axios.config";

const Users = () => {
  const { loadedUsers, subsidiaries, locations, printers } = useLoaderData();
  const [users, setUsers] = useState(loadedUsers);
  const [selectedID, setSelectedID] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { username } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const employees = users.filter(
    (user) => user.manager.toLowerCase() === username.toLowerCase()
  );
  const admins = users.filter(
    (user) => user.rolelevel < 2 && user.status === "approved"
  );
  const pendingUsers = users.filter((user) => user.status === "pending");

  const handleApproveUser = async (pUser) => {
    try {
      const { data } = await axios.patch(
        `users/admin/update/user/${pUser._id}`,
        {
          status: "approved",
        }
      );

      setUsers(data);
      dispatch(
        openGeneralAlert({
          type: "success",
          duration: 3000,
          message: `Successfully approved ${pUser.username}`,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          duration: 5000,
          message: `Couldnt approve ${pUser.username}: ${
            error.response?.data?.msg || error.message
          }. Please try again.`,
        })
      );
    }
  };

  return (
    <Box px={2} py={1} flex={1} overflow="hidden">
      {/* Modal for edit/create user */}
      <FullScreenModal>
        <Box p={1}>
          <FlexWrapper>
            <IconButton
              onClick={() => {
                dispatch(closeFullScreenModal());
                setSelectedUser(null);
              }}
            >
              <Close />
            </IconButton>
          </FlexWrapper>

          <UserForm
            setUsers={setUsers}
            user={selectedUser}
            subsidiaries={subsidiaries}
            printers={printers}
            locations={locations}
          />
        </Box>
      </FullScreenModal>

      <FlexWrapper justifyContent="space-between" alignItems="center">
        <Typography variant="h3" component="h1" gutterBottom>
          Users
        </Typography>
        <FlexWrapper gap={1}>
          {selectedID && (
            <Button
              variant="contained"
              startIcon={<Edit />}
              onClick={() => {
                setSelectedUser(users.find((user) => user._id === selectedID));
                dispatch(openFullScreenModal());
              }}
            >
              Edit User
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={<Add />}
            color="success"
            onClick={() => dispatch(openFullScreenModal())}
          >
            Create User
          </Button>
        </FlexWrapper>
      </FlexWrapper>
      <DataGrid
        sx={{ bgcolor: "white", height: "50vh", width: "auto" }}
        rows={users}
        onRowSelectionModelChange={(selection) => {
          setSelectedID(selection[0]);
        }}
        columns={USERCOLUMNS}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[100, 150, 200]}
        density="compact"
        disableColumnMenu
        getRowId={(row) => row._id}
        slots={{ toolbar: GridToolbar }}
        disableDensitySelector
        disableColumnSelector
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            csvOptions: { fileName: `users-${Date.now()}` },
          },
        }}
      />

      <Divider sx={{ py: 1 }} />

      <FlexWrapper
        alignItems="stretch"
        justifyContent="space-between"
        flexWrap="nowrap"
      >
        <Box sx={{ width: "33%" }}>
          <Typography variant="h6" textAlign="center" gutterBottom>
            Pending Users
          </Typography>
          <Paper
            sx={{
              height: "30vh",
              px: 2,
              py: 1,
              overflow: "scroll",
              "&::-webkit-scrollbar": { display: "none" },
            }}
            elevation={2}
          >
            {pendingUsers.length ? (
              pendingUsers.map((pUser) => (
                <Box key={pUser._id} pb={3}>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                    flexWrap="nowrap"
                  >
                    <FlexWrapper alignItems="center" gap={1} flexWrap="nowrap">
                      <Avatar>
                        {pUser.firstname[0].toUpperCase()}
                        {pUser.lastname[0].toUpperCase()}
                      </Avatar>
                      <Typography
                        variant="body2"
                        sx={{
                          width: 150,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {pUser.username}
                      </Typography>
                    </FlexWrapper>

                    <Button
                      size="small"
                      color="success"
                      variant="contained"
                      onClick={() => handleApproveUser(pUser)}
                    >
                      Approve
                    </Button>
                  </FlexWrapper>
                </Box>
              ))
            ) : (
              <Typography
                textAlign="center"
                fontWeight="bold"
                variant="body1"
                pt={3}
              >
                No users to display.
              </Typography>
            )}
          </Paper>
        </Box>

        <Box sx={{ width: "33%" }}>
          <Typography variant="h6" textAlign="center" gutterBottom>
            Your Employees
          </Typography>
          <Paper
            sx={{
              height: "30vh",
              px: 2,
              py: 1,
              overflow: "scroll",
              "&::-webkit-scrollbar": { display: "none" },
            }}
            elevation={2}
          >
            {employees.length ? (
              employees.map((employee) => (
                <Box key={employee._id} pb={3}>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                  >
                    <FlexWrapper alignItems="center" gap={1} flexWrap="nowrap">
                      <Avatar>
                        {employee.firstname[0].toUpperCase()}
                        {employee.lastname[0].toUpperCase()}
                      </Avatar>
                      <Typography
                        variant="body2"
                        sx={{
                          width: 150,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {employee.username}
                      </Typography>
                    </FlexWrapper>

                    <FlexWrapper alignItems="center" gap={1} flexWrap="nowrap">
                      <Typography variant="body1" fontWeight="bold">
                        Level: {employee.rolelevel}
                      </Typography>
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          employee.status === "approved"
                            ? "Approved"
                            : "Pending"
                        }
                      >
                        <Icon>
                          <Circle
                            color={
                              employee.status === "approved"
                                ? "success"
                                : "warning"
                            }
                          />
                        </Icon>
                      </Tooltip>
                    </FlexWrapper>
                  </FlexWrapper>
                </Box>
              ))
            ) : (
              <Typography
                textAlign="center"
                fontWeight="bold"
                variant="body1"
                pt={3}
              >
                No users to display.
              </Typography>
            )}
          </Paper>
        </Box>

        <Box sx={{ width: "33%" }}>
          <Typography variant="h6" textAlign="center" gutterBottom>
            Admins
          </Typography>
          <Paper
            sx={{
              height: "30vh",
              px: 2,
              py: 1,
              overflow: "scroll",
              "&::-webkit-scrollbar": { display: "none" },
            }}
            elevation={2}
          >
            {admins.length ? (
              admins.map((employee) => (
                <Box key={employee._id} pb={3}>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                  >
                    <FlexWrapper alignItems="center" gap={1} flexWrap="nowrap">
                      <Avatar>
                        {employee.firstname[0].toUpperCase()}
                        {employee.lastname[0].toUpperCase()}
                      </Avatar>
                      <Typography
                        variant="body2"
                        sx={{
                          width: 150,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {employee.username}
                      </Typography>
                    </FlexWrapper>

                    <Typography variant="body1" fontWeight="bold">
                      Level: {employee.rolelevel}
                    </Typography>
                  </FlexWrapper>
                </Box>
              ))
            ) : (
              <Typography
                textAlign="center"
                fontWeight="bold"
                variant="body1"
                pt={3}
              >
                No users to display.
              </Typography>
            )}
          </Paper>
        </Box>
      </FlexWrapper>
    </Box>
  );
};
export default Users;
