import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoaderData, useParams } from "react-router-dom";
import { Add, ExpandMore, Remove } from "@mui/icons-material";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import axios from "../../../axios/axios.config";

const LaborClaimFormDetails = ({
  isReadOnly = true,
  isDefaultOpen = false,
  setForm,
  setLoading,
  form,
}) => {
  const { formid } = useParams();
  //set the initial state of additional emails to be a string
  const [formDetails, setFormDetails] = useState(() =>
    form
      ? {
          ...form,
          additionalEmails:
            form?.additionalEmails && Array.isArray(form?.additionalEmails)
              ? form?.additionalEmails.join(",")
              : form?.additionalEmails,
        }
      : null
  );

  const [partNumbers, setPartNumbers] = useState(
    () =>
      form?.partNumbers?.map((obj) => {
        const key = obj.partnumber + Date.now().toString();
        return { ...obj, key };
      }) || null
  );

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("Updating Form Details...");
    try {
      //check phone numbers
      const regex = new RegExp(/[e\-\.].{9,}/);

      if (regex.test(formDetails.serviceFacilityPhone)) {
        throw new Error("Not a valid service facility phone number");
      }

      if (regex.test(formDetails.warehousePhone)) {
        throw new Error("Not a valid warehouse phone number");
      }

      if (formDetails.vinNum.length !== 17) {
        throw new Error("Invalid Vin");
      }

      const installDate = new Date(formDetails.dateInstallation);
      const failureDate = new Date(formDetails.dateFailure);
      const todayDate = new Date();

      const mileageStart = parseInt(formDetails.mileageInstallation);
      const mileageEnd = parseInt(formDetails.mileageFailure);

      if (mileageStart === 0 || mileageEnd === 0 || mileageStart > mileageEnd) {
        throw new Error("Invalid mileage entries");
      }

      //check dates
      if (installDate > failureDate || installDate > todayDate) {
        throw new Error("Invalid install date");
      }

      if (failureDate > todayDate) {
        throw new Error("Invalid failure date");
      }
      //update object so we cna update mongo db
      const transformedObject = {};
      for (const key in formDetails) {
        if (Object.hasOwnProperty.call(formDetails, key)) {
          transformedObject[`form.${key}`] = formDetails[key];
        }
      }
      const newParts = partNumbers.map((partObj) => {
        return { partnumber: partObj.partnumber, qty: partObj.qty };
      });
      transformedObject["form.partNumbers"] = newParts;
      transformedObject["form.additionalEmails"] = formDetails.additionalEmails
        ? formDetails.additionalEmails.split(",")
        : formDetails.additionalEmails;

      const response = await axios.patch(
        `forms/update/${formid}/labor-claim/form-details`,
        {
          updateData: transformedObject,
        }
      );
      //set additional emails back to a string
      setFormDetails({
        ...response.data.form,
        additionalEmails:
          response.data.form?.additionalEmails &&
          Array.isArray(response.data.form?.additionalEmails)
            ? response.data.form.additionalEmails.join(",")
            : response.data.form?.additionalEmails,
      });
      setForm(response.data.form);
      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Edited Form Details",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Edit Form Details: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    } finally {
      setLoading("");
    }
  };

  const handleChanges = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };
  //handles the parts and labor section
  const handleSumChanges = (event) => {
    let totalClaim = "";

    if (event.target.name === "laborHours") {
      const partsCost = parseFloat(formDetails.partsCost) || "";
      const laborHours = parseInt(event.target.value) || "";
      const laborCost = laborHours ? laborHours * formDetails.laborRate : "";

      if (laborCost && partsCost) {
        totalClaim = parseFloat(laborCost + partsCost).toFixed(2);
      }

      setFormDetails({
        ...formDetails,
        laborHours,
        laborCost: laborCost,
        totalClaim,
      });
    } else {
      const partsCost = parseFloat(event.target.value) || "";

      if (formDetails.laborCost && partsCost) {
        totalClaim = parseFloat(formDetails.laborCost + partsCost).toFixed(2);
      }

      setFormDetails({
        ...formDetails,
        partsCost,
        totalClaim,
      });
    }
  };

  const handleMultipleSelectChanges = (event) => {
    // On autofill we get a stringified value.
    setFormDetails({
      ...formDetails,
      vehicleGasType:
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value,
    });
  };

  const handleChangePartValues = (event, index) => {
    const partNumbersCopy = [...partNumbers];
    partNumbersCopy[index] = {
      ...partNumbers[index],
      partnumber: event.target.value.toUpperCase(),
    };

    setPartNumbers(partNumbersCopy);
  };

  const handleChangePartQuantities = (event, index) => {
    const partNumbersCopy = [...partNumbers];
    partNumbersCopy[index] = {
      ...partNumbers[index],
      qty: parseInt(event.target.value),
    };

    setPartNumbers(partNumbersCopy);
  };

  const handleChangePartNumbers = (type) => {
    if (type === "Add") {
      setPartNumbers([
        ...partNumbers,
        { partnumber: "", qty: 1, key: Date.now() },
      ]);
    } else {
      //remove part
      const partsCopy = [...partNumbers];
      partsCopy.pop();
      setPartNumbers(partsCopy);
    }
  };

  if (!formDetails)
    return (
      <Typography variant="h5" textAlign="center" py={2}>
        No Form Data To Display
      </Typography>
    );

  return (
    <>
      {form.status === "rmaissued" && form.rmawarningemail && (
        <Grid item xs={12}>
          <Typography variant="body2" color="error">
            Closure warning email has been sent on {form.rmawarningemail}
          </Typography>
        </Grid>
      )}

      <Accordion elevation={1} sx={{ my: 1 }} defaultExpanded={isDefaultOpen}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Form Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  label="Status"
                  value={formDetails?.status?.toUpperCase()}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  label="Created Date"
                  name="date"
                  value={formDetails.date}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Customer"
                  name="customer"
                  value={formDetails?.customer}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="text"
                  name="rgaNum"
                  label="Customer Use Only: RGA#"
                  value={formDetails.rgaNum}
                  onChange={handleChanges}
                  InputProps={{ readOnly: isReadOnly }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                  Service Facility and Vehicle Owner
                </Typography>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="text"
                  name="serviceFacilityCompany"
                  label="Company Name"
                  value={formDetails.serviceFacilityCompany}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="text"
                  name="serviceFacilityContactName"
                  label="Contact Name"
                  value={formDetails.serviceFacilityContactName}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  helperText={
                    <Typography variant="caption" color="error">
                      No Special Characters
                    </Typography>
                  }
                  type="tel"
                  inputMode="numeric"
                  name="serviceFacilityPhone"
                  label="Phone"
                  value={formDetails.serviceFacilityPhone}
                  onChange={handleChanges}
                  inputProps={{
                    inputMode: "numeric",
                    type: "tel",
                    pattern: "[0-9]*",
                  }}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="email"
                  name="serviceFacilityEmail"
                  onChange={handleChanges}
                  label="E-mail"
                  value={formDetails.serviceFacilityEmail}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={!isReadOnly}
                  fullWidth
                  multiline
                  minRows={4}
                  type="text"
                  name="serviceFacilityAddress"
                  label="Address"
                  value={formDetails.serviceFacilityAddress}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="text"
                  name="technicianName"
                  label="Technician's Name"
                  value={formDetails.technicianName}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="text"
                  name="serviceFacilityVehicleOwner"
                  label="Vehicle Owner’s Name"
                  value={formDetails.serviceFacilityVehicleOwner}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                  Selling Party
                </Typography>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="text"
                  name="warehouseCompany"
                  label="Company Name"
                  value={formDetails.warehouseCompany}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="text"
                  name="warehouseContactName"
                  label="Contact Name"
                  value={formDetails.warehouseContactName}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  helperText={
                    <Typography variant="caption" color="error">
                      No Special Characters
                    </Typography>
                  }
                  type="tel"
                  inputMode="numeric"
                  name="warehousePhone"
                  label="Phone"
                  value={formDetails.warehousePhone}
                  onChange={handleChanges}
                  inputProps={{
                    inputMode: "numeric",
                    type: "tel",
                    pattern: "[0-9]*",
                  }}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="email"
                  name="warehouseEmail"
                  label="E-mail"
                  value={formDetails.warehouseEmail}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  label="Additional Emails"
                  name="additionalEmails"
                  value={formDetails.additionalEmails}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  required={!isReadOnly}
                  minRows={4}
                  type="text"
                  name="warehouseAddress"
                  label="Address"
                  value={formDetails.warehouseAddress}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                  Vehicle Information
                </Typography>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="text"
                  name="vinNum"
                  label="VIN"
                  value={formDetails.vinNum}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="number"
                  inputMode="numeric"
                  name="vehicleYear"
                  label="Year"
                  value={formDetails.vehicleYear}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="text"
                  name="vehicleMake"
                  label="Make"
                  value={formDetails.vehicleMake}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required={!isReadOnly}
                  fullWidth
                  type="text"
                  name="vehicleModel"
                  label="Model"
                  value={formDetails.vehicleModel}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="text"
                  name="vehicleEngineSize"
                  label="Engine Size"
                  value={formDetails.vehicleEngineSize}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth required={!isReadOnly}>
                  <InputLabel id="select-label-type">
                    Transmission Type
                  </InputLabel>
                  <Select
                    labelId="select-label-type"
                    id="select-type"
                    label="Transmission Type"
                    name="vehicleEngineType"
                    value={formDetails.vehicleEngineType}
                    onChange={handleChanges}
                    inputProps={{
                      readOnly: isReadOnly,
                    }}
                  >
                    <MenuItem value="AUTOMATIC">Automatic</MenuItem>
                    <MenuItem value="MANUAL">Manual</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth required={!isReadOnly}>
                  <InputLabel id="select-label-drive">Vehicle Drive</InputLabel>
                  <Select
                    labelId="select-label-drive"
                    id="select-drive"
                    label="Vehicle Drive"
                    name="vehicleDrive"
                    value={formDetails.vehicleDrive}
                    onChange={handleChanges}
                    inputProps={{
                      readOnly: isReadOnly,
                    }}
                  >
                    <MenuItem value="FRONT WHEEL DRIVE">
                      Front Wheel Drive
                    </MenuItem>
                    <MenuItem value="REAR WHEEL DRIVE">
                      Rear Wheel Drive
                    </MenuItem>
                    <MenuItem value="FOUR WHEEL DRIVE">
                      Four Wheel Drive
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth required={!isReadOnly}>
                  <InputLabel id="select-label-gas">Engine Sub Type</InputLabel>
                  <Select
                    multiple
                    labelId="select-label-gas"
                    id="select-gas"
                    label="Engine Sub Type"
                    name="vehicleGasType"
                    value={formDetails.vehicleGasType}
                    onChange={handleMultipleSelectChanges}
                    inputProps={{
                      readOnly: isReadOnly,
                    }}
                  >
                    <MenuItem value="Turbo">Turbo</MenuItem>
                    <MenuItem value="Gas">Gas</MenuItem>
                    <MenuItem value="Diesel">Diesel</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                    <MenuItem value="Electric">Electric</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" mt={2}>
                  Associated Parts and Labor
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="number"
                  inputMode="numeric"
                  name="laborHours"
                  label="Total Labor Hrs"
                  value={formDetails.laborHours}
                  onChange={handleSumChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="number"
                  name="partsCost"
                  label="Parts Cost"
                  value={formDetails.partsCost}
                  onChange={handleSumChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  name="laborCost"
                  label="Labor Cost"
                  value={formDetails.laborCost}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  name="laborRate"
                  label="Labor Rate"
                  value={formDetails.laborRate}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="totalClaim"
                  label="Total Claim Amount"
                  value={formDetails.totalClaim}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FlexWrapper justifyContent="space-between">
                  <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                    Product
                  </Typography>
                  {!isReadOnly && (
                    <FlexWrapper gap={1}>
                      <Tooltip title="Add Product" placement="top" arrow>
                        <span>
                          <IconButton
                            disabled={partNumbers.length === 4}
                            onClick={() => handleChangePartNumbers("Add")}
                          >
                            <Add />
                          </IconButton>
                        </span>
                      </Tooltip>

                      <Tooltip title="Remove Product" placement="top" arrow>
                        <span>
                          <IconButton
                            disabled={partNumbers.length === 1}
                            onClick={() => handleChangePartNumbers("Remove")}
                          >
                            <Remove />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </FlexWrapper>
                  )}
                </FlexWrapper>
              </Grid>
              {partNumbers.length &&
                partNumbers.map((partObj, index) => {
                  return (
                    <Fragment key={partObj.key}>
                      <Grid item xs={10}>
                        <TextField
                          fullWidth
                          required={!isReadOnly}
                          type="text"
                          label="Part Number"
                          value={partObj.partnumber}
                          onChange={(event) =>
                            handleChangePartValues(event, index)
                          }
                          InputProps={{
                            readOnly: isReadOnly,
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          required={!isReadOnly}
                          type="number"
                          label="Part Number Qty"
                          value={partObj.qty}
                          onChange={(event) =>
                            handleChangePartQuantities(event, index)
                          }
                          InputProps={{
                            readOnly: isReadOnly,
                          }}
                          inputProps={{ min: 1, max: 4 }}
                        />
                      </Grid>
                    </Fragment>
                  );
                })}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="text"
                  name="manufacturer"
                  label="Manufacturer"
                  value={formDetails.manufacturer}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="number"
                  inputMode="numeric"
                  name="mileageInstallation"
                  label="Mileage At Installation"
                  value={formDetails.mileageInstallation}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                  inputProps={{
                    min: 0,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="number"
                  inputMode="numeric"
                  name="mileageFailure"
                  label="Mileage At Failure"
                  value={formDetails.mileageFailure}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                  inputProps={{
                    min: parseInt(formDetails.mileageInstallation) + 1,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="date"
                  name="dateInstallation"
                  label="Date At Installation"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDetails.dateInstallation}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={!isReadOnly}
                  type="date"
                  name="dateFailure"
                  label="Date At Failure"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formDetails.dateFailure}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  required={!isReadOnly}
                  minRows={4}
                  type="text"
                  name="failureDescription"
                  label="Description of Failure and Collateral Damage"
                  value={formDetails.failureDescription}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  minRows={4}
                  type="text"
                  name="comments"
                  label="Additional Comments"
                  value={formDetails.comments || ""}
                  onChange={handleChanges}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Grid>

              {!isReadOnly && (
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" type="submit">
                    Edit Form Details
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default LaborClaimFormDetails;
