import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openMemoModalOnCreate } from "../redux/features/modal/modalSlice";
import { setToteError } from "../redux/features/errors/errorsSlice";
import {
  InputAdornment,
  Typography,
  Button,
  Box,
  TextField,
} from "@mui/material";

import RadioModal from "./RadioModal";
import SearchIcon from "@mui/icons-material/Search";

const ToteForm = () => {
  const [toteID, setToteID] = useState("");
  const toteInputRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { printer } = useSelector((state) => state.user);
  const { toteError } = useSelector((state) => state.errors);

  const createTote = () => {
    if (!printer) {
      dispatch(setToteError("No Default Printer"));
      return;
    }

    dispatch(openMemoModalOnCreate());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (toteID.length < 8 || !toteID.startsWith("TL")) {
      dispatch(setToteError("Invalid Tote Label"));
      setToteID("");
      return;
    }

    navigate(`/portal/reverse-logistics/tote-manager/${toteID}`);
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center", pt: 3 }}>
      <RadioModal />
      <Box
        component="form"
        type="submit"
        onSubmit={handleSubmit}
        sx={{ flex: 1 }}
      >
        <TextField
          autoFocus
          inputRef={toteInputRef}
          fullWidth
          id="component-outlined"
          error={toteError.length > 0}
          helperText={toteError}
          label="Search Tote"
          value={toteID}
          onChange={(e) => setToteID(e.target.value.toUpperCase())}
          required
          onFocus={() => {
            setToteID("");
            dispatch(setToteError(""));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={handleSubmit}
                sx={{ cursor: "pointer", height: "100%" }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ height: "56px" }}
        />
      </Box>
      <Typography px={{ xs: "5px", md: 2 }}>OR</Typography>
      <span>
        <Button
          color="success"
          variant="contained"
          onClick={createTote}
          size="large"
          sx={{ alignSelf: { md: "stretch" }, px: { xs: "10px", md: "24px" } }}
        >
          Create Label
        </Button>
      </span>
    </Box>
  );
};

export default ToteForm;
