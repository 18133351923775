import { Link } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";

export const TOTESEARCHCOLUMNS = [
  {
    field: "toteid",
    headerName: "Tote ID",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <MuiLink
        component={Link}
        to={`/portal/reverse-logistics/tote-manager/${params.value}`}
      >
        {params.value}
      </MuiLink>
    ),
  },
  {
    field: "tranref",
    headerName: "Tranref",
    type: "string",
    flex: 2,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "totalitemsqty",
    headerName: "QTY",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "memo",
    headerName: "Memo",
    type: "string",
    flex: 2,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  // {
  //   field: "subsidiary",
  //   headerName: "Subsidiary",
  //   type: "string",
  //   flex: 1,
  //   headerAlign: "center",
  //   align: "center",
  //   sortable: false,
  // },
  {
    field: "location",
    headerName: "Location",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: "createdby",
    headerName: "CreatedBy",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    type: "date",
    flex: 1,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => new Date(params.row.createdAt),
  },
  {
    field: "lastupdatedby",
    headerName: "Last Updated By",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    type: "date",
    flex: 1,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => new Date(params.row.updatedAt),
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
];
export const TOTESEARCHCOLUMNSRTV = [
  {
    field: "toteid",
    headerName: "Tote ID",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <MuiLink
        component={Link}
        to={`/portal/reverse-logistics/tote-manager/${params.value}`}
      >
        {params.value}
      </MuiLink>
    ),
  },
  {
    field: "container",
    headerName: "Container",
    type: "string",
    flex: 2,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "palletnumber",
    headerName: "Pallet Number",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "totalitemsqty",
    headerName: "QTY",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "memo",
    headerName: "Memo",
    type: "string",
    flex: 2,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  // {
  //   field: "subsidiary",
  //   headerName: "Subsidiary",
  //   type: "string",
  //   flex: 1,
  //   headerAlign: "center",
  //   align: "center",
  //   sortable: false,
  // },
  {
    field: "location",
    headerName: "Location",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: "createdby",
    headerName: "CreatedBy",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    type: "date",
    flex: 1,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => new Date(params.row.createdAt),
  },
  {
    field: "lastupdatedby",
    headerName: "Last Updated By",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    type: "date",
    flex: 1,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => new Date(params.row.updatedAt),
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
];
