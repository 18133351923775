import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  resetPack,
  setPackData,
} from "../../redux/features/pack-process/packProcessSlice";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import PackResults from "../../components/warehouse/PackResults";
import axios from "../../axios/axios.config";
import PackProcessViewActiveOrders from "../../components/warehouse/pack-process/pack-process-page/PackProcessViewActiveOrders";

const PackProcess = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [orderInput, setOrderInput] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { username, currentSubsidiary, currentNetsuiteLocationIds } =
    useSelector((state) => state.user);
  const { packData } = useSelector((state) => state.packProcess);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(false);
      dispatch(resetPack());

      if (!orderInput) {
        throw new Error("Field Cannot Be Empty");
      }

      //We dont query for shipment sequence here because we always want latest shipment sequence
      const { data } = await axios.post(`pack-process/${orderInput}`, {
        username,
        subsidiary: currentSubsidiary,
        currentNetsuiteLocationIds,
      });

      if (data.status === "packed") {
        throw new Error("Order has already been completely packed.");
      }
      //create objects for fast lookups during pack process
      const { upcStorage, itemStorage } = createDataObjects(data.items);

      dispatch(setPackData({ data, upcStorage, itemStorage }));
      setSearchParams({
        order: data.createdfrom,
        shipsequence: data.shipmentsequence,
      });
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
      setSearchParams({});
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSearchParams({ order: orderInput });
  };

  const createDataObjects = (items) => {
    const itemStorage = {};
    const upcStorage = {};
    for (const obj of items) {
      //made storages into array since there can be multiple fulfillments with same item and theres no way to enter fulfillment during pack process
      if (!upcStorage[obj.upc]) {
        upcStorage[obj.upc] = [];
      }
      upcStorage[obj.upc].push(obj);

      if (!itemStorage[obj.item]) {
        itemStorage[obj.item] = [];
      }
      itemStorage[obj.item].push(obj);
    }

    return { upcStorage, itemStorage };
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          height: "80vh",
        }}
      >
        <CircularProgress variant="indeterminate" />
        <Typography variant="body1" color="primary" textAlign="center">
          Loading Order...
        </Typography>
      </Box>
    );

  return (
    <Box>
      {!packData || !searchParams.get("order") ? (
        <Container
          onSubmit={handleSubmit}
          maxWidth="xl"
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            pt: 1,
            flexDirection: "column",
          }}
        >
          <Typography variant="h4" textAlign="center" pb={3}>
            Pack Order
          </Typography>
          <TextField
            autoFocus
            label="Enter Sales Order or Transfer Order"
            size="large"
            variant="outlined"
            error={Boolean(error)}
            helperText={error}
            value={orderInput}
            onChange={(e) => setOrderInput(e.target.value.toUpperCase())}
            sx={{ width: { xs: "100%", md: "50%" } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* View Active Orders */}
          <PackProcessViewActiveOrders setOrderInput={setOrderInput} />
        </Container>
      ) : (
        <PackResults setOrderInput={setOrderInput} />
      )}
    </Box>
  );
};

export default PackProcess;
