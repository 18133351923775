import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FlexWrapper from "../../FlexWrapper";
import { ArrowForward } from "@mui/icons-material";

const ReceiveTransactionInitialScreen = ({
  order,
  trantype,
  location,
  condition,
  customer,
  brandpackaging,
  setInitial,
  date,
  memo,
  warehousenotes,
  reference,
}) => {
  const matches = useMediaQuery("(max-width:600px)");

  const details = [
    { label: "Transaction ID", detail: order },
    { label: "Transaction Type", detail: trantype },
    { label: "Transaction Date", detail: date },
    { label: "Brand", detail: brandpackaging },
    { label: "Location", detail: location },
    { label: "Entity", detail: customer },
    { label: "Memo", detail: memo },
    { label: "Warehouse Notes", detail: warehousenotes },
    { label: "Reference", detail: reference },
    { label: "Condition", detail: condition },
  ];

  return (
    <>
      <Typography textAlign="center" variant="h5" py={1}>
        Order Details
      </Typography>
      <Container maxWidth="sm">
        <Box
          style={{
            backgroundColor: "#1976d2",
            borderRadius: "8px",
            padding: "24px",
            color: "#FFF",
            maxHeight: matches ? "550px" : "700px",
            overflowY: "auto",
          }}
        >
          {details.map((detail) =>
            detail.detail ? (
              <Box
                key={detail.label}
                sx={{ borderBottom: "1px solid white", py: 1 }}
              >
                <FlexWrapper justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle1" gutterBottom pr={2}>
                    {detail.label}:
                  </Typography>
                  <Typography variant="body1" textTransform="uppercase">
                    {detail.detail}
                  </Typography>
                </FlexWrapper>
              </Box>
            ) : null
          )}
        </Box>

        <FlexWrapper justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            endIcon={<ArrowForward />}
            onClick={() => setInitial(false)}
          >
            Continue
          </Button>
        </FlexWrapper>
      </Container>
    </>
  );
};
export default ReceiveTransactionInitialScreen;
