import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleNav } from "../../../redux/features/nav/navBarSlice";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { ExitToApp, KeyboardBackspace } from "@mui/icons-material";
import {
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";

import ItemScanInventoryMove from "../../../components/multi-page/inventory-move/ItemScanInventoryMove";
import FlexWrapper from "../../../components/FlexWrapper";
import Loading from "../../../components/Loading";
import axios from "../../../axios/axios.config";
import ItemBinDataView from "../../../components/multi-page/inventory-move/ItemBinDataView";

const InventoryMovePage = () => {
  const [itemBinData, setItemBinData] = useState(null);
  const [loading, setLoading] = useState("");
  const [binMoveError, setBinMoveError] = useState("");
  const [mongoData, setMongoData] = useState({
    sourcebin: "",
    qty: "",
    _id: "",
    status: "new",
  });
  const [filteredBins, setFilteredBins] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { username, currentDepartment } = useSelector((state) => state.user);
  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    dispatch(toggleNav(true));

    return () => {
      dispatch(toggleNav(false));
    };
  }, []);

  const transferInventory = async (transferData, destinationbin) => {
    setLoading("Completing Transfer");
    try {
      //do the transfer
      const transferResponse = await axios.post(
        "netsuite/post/inventory-move/transfer",
        transferData
      );
      //save to mongo
      await axios.patch(`inventory-move/${mongoData._id}`, {
        destinationbin,
        status: "complete",
        transferrecord: {
          transferinternalid: transferResponse.data?.message,
          type: transferResponse.data?.trantype,
        },
      });

      setItemBinData(null);
      setMongoData({
        sourcebin: "",
        qty: "",
        _id: "",
        status: "new",
      });
      setBinMoveError("");
      setFilteredBins(null);
      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully Completed ${transferResponse.data?.trantype?.toUpperCase()}: ${
            transferResponse.data?.message || ""
          }`,
          duration: 5000,
        })
      );
    } catch (error) {
      setBinMoveError(error.response?.data?.msg || error.message);
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could Not Complete Transfer: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 8000,
        })
      );
    } finally {
      setLoading("");
    }
  };

  if (loading) return <Loading message={loading} />;

  return (
    <Container maxWidth={matches ? "xl" : "md"} sx={{ height: "100vh" }}>
      <FlexWrapper justifyContent="space-between" alignItems="center">
        <FlexWrapper alignItems="center" gap={1}>
          {itemBinData ? (
            <IconButton
              onClick={() => {
                setItemBinData(null);
                setMongoData({
                  sourcebin: "",
                  qty: "",
                  _id: "",
                  status: "new",
                });
                setBinMoveError("");
                setFilteredBins(null);
              }}
            >
              <KeyboardBackspace />
            </IconButton>
          ) : (
            <IconButton
              onClick={() =>
                navigate(`/portal/${currentDepartment.toLowerCase()}`, {
                  replace: true,
                })
              }
            >
              <ExitToApp color="error" />
            </IconButton>
          )}

          {itemBinData?.length && (
            <Typography variant="body2">
              {itemBinData[0].itemname} - {itemBinData[0].itemclass}
            </Typography>
          )}
        </FlexWrapper>

        {!itemBinData && (
          <Typography py={1} sx={{ typography: { xs: "h5", md: "h4" } }}>
            Inventory Move
          </Typography>
        )}

        <Typography variant="body2" py={1}>
          {username}
        </Typography>
      </FlexWrapper>

      {!itemBinData && (
        <ItemScanInventoryMove
          setItemBinData={setItemBinData}
          setMongoData={setMongoData}
          filteredBins={filteredBins}
          setFilteredBins={setFilteredBins}
        />
      )}

      {itemBinData?.length && (
        <ItemBinDataView
          itemBinData={itemBinData}
          transferFn={transferInventory}
          setMongoData={setMongoData}
          mongoData={mongoData}
          binMoveError={binMoveError}
          setBinMoveError={setBinMoveError}
          setFilteredBins={setFilteredBins}
          filteredBins={filteredBins}
        />
      )}
    </Container>
  );
};
export default InventoryMovePage;
