import { useState } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";
import { updatePrinter } from "../../redux/features/user/userSlice";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const ProfilePrinters = () => {
  const { user, dispatch } = useOutletContext();
  const printers = useLoaderData();
  const [printer, setPrinter] = useState(user.printer);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await dispatch(
        updatePrinter({ printerid: printer, userid: user.username })
      ).unwrap();

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully updated printer",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h5" gutterBottom>
        Printer Information
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="body1" pb={3}>
        Current Printer: <strong>{user.printer}</strong>
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} md={8}>
            <FormControl fullWidth required>
              <InputLabel id="select-label-printers">Select Printer</InputLabel>
              <Select
                labelId="select-label-printers"
                id="select-printer"
                label="Select Printer"
                name="printer"
                value={printer}
                onChange={(event) => setPrinter(event.target.value)}
              >
                {printers.map((printer) => (
                  <MenuItem key={printer._id} value={printer.printername}>
                    {printer.printername}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1.5}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{ height: "100%" }}
            >
              Change
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default ProfilePrinters;
