import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Search } from "@mui/icons-material";
import {
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import Loading from "../../../components/Loading";
import axios from "../../../axios/axios.config";

const PackProcessSearchPage = () => {
  const {
    navigate,
    openAlert,
    navigation,
    user: { username, currentNetsuiteLocationIds, currentSubsidiary },
  } = useOutletContext();

  const [order, setOrder] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      if (!order) {
        throw new Error("Order number is required");
      }

      //We dont query for shipment sequence here because we always want latest shipment sequence
      const { data } = await axios.post(`pack-process-v2/${order}`, {
        username,
        subsidiary: currentSubsidiary,
        currentNetsuiteLocationIds,
      });

      if (data.status === "packed") {
        throw new Error("Order has already been completely packed.");
      }

      setLoading(false);
      navigate(data._id);
      openAlert({
        type: "success",
        message: "Successfully loaded order",
        duration: 3000,
      });
    } catch (error) {
      setLoading(false);
      setError(error.response?.data?.msg || error.message);
    }
  };

  if (loading || navigation.state === "loading")
    return (
      <Loading
        message={
          navigation.state === "loading" ? "Loading Order..." : "Searching..."
        }
      />
    );

  return (
    <Container maxWidth="md">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Pack Process
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          autoFocus
          required
          label="Enter Order"
          name="order"
          type="text"
          value={order}
          onChange={(event) =>
            setOrder(event.target.value.toUpperCase().trim())
          }
          error={Boolean(error)}
          helperText={error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton disabled={loading} type="submit">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Container>
  );
};
export default PackProcessSearchPage;
