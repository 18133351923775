import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  text: {
    textAlign: "center",
    fontSize: "25px",
  },
});

const LaborClaimRMAPlacard = ({ rmaNumber }) => {
  return (
    <Document>
      <Page size="A4" style={{ fontSize: "14px" }}>
        <View
          style={{
            flexDirection: "column",
            height: "50vh",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Text style={[styles.text, { fontSize: "40px" }]}>
            Advanced Innovative Technology
          </Text>
          <Text style={styles.text}>Attn: TECH DEPT.</Text>
          <Text style={styles.text}>350 Nevada St</Text>
          <Text style={styles.text}>Redlands, CA 92373</Text>
          <Text style={styles.text}>{rmaNumber}</Text>
          <Text style={styles.text}>Operating Hours: Mon-Fri. 7am to 2pm</Text>
        </View>
      </Page>
    </Document>
  );
};

export default LaborClaimRMAPlacard;
