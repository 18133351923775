import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { Button, Grid, TextField, Typography } from "@mui/material";

import CreditMemoAndRMANetsuiteLinks from "../netsuite/CreditMemoAndRMANetsuiteLinks";
import FlexWrapper from "../../FlexWrapper";
import axios from "../../../axios/axios.config";

const SubmitFormLC = ({ isReadOnly = true, form, setForm, setLoading }) => {
  const { currentDepartment } = useSelector((state) => state.user);
  const { formid } = useParams();
  const dispatch = useDispatch();

  const [formDetails, setFormDetails] = useState(form);

  const handleChanges = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value?.toUpperCase(),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("Updating Labor Claim...");
    try {
      //update object so we can update mongo db
      const transformedObject = {};
      for (const key in formDetails) {
        if (Object.hasOwnProperty.call(formDetails, key)) {
          transformedObject[`form.${key}`] = formDetails[key];
        }
      }
      const response = await axios.patch(
        `forms/update/${formid}/labor-claim/form-details`,
        {
          updateData: transformedObject,
        }
      );
      //this will re render the component
      setForm(response.data?.form);

      handleAlert({
        type: "success",
        message: "Updated Labor Claim Details",
        duration: 3000,
      });
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Coul Not Update: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 3000,
      });
    } finally {
      setLoading("");
    }
  };

  const handleAlert = ({ type, message, duration }) => {
    dispatch(openGeneralAlert({ type, message, duration }));
  };

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
      {form?.status === "incomplete" && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">Incomplete</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Closed By"
              InputProps={{ readOnly: true }}
              value={`${formDetails?.closedbyname} (${formDetails?.closedbyusername})`}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Closed By Email"
              InputProps={{ readOnly: true }}
              value={formDetails?.closedbyemail}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Closed Date"
              value={new Date(formDetails?.closeddate).toLocaleDateString()}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Closed Time"
              value={new Date(formDetails?.closeddate).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              required
              minRows={4}
              type="text"
              label="Reason For Closure"
              value={formDetails?.closedreason}
              InputProps={{ readOnly: isReadOnly }}
              onChange={handleChanges}
            />
          </Grid>
        </>
      )}

      {form.status === "new" && !isReadOnly && (
        <>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              name="rgaNum"
              label="Customer Use Only: RGA#"
              value={formDetails.rgaNum}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              autoFocus
              type="text"
              name="rmaNum"
              label="RMA Number"
              disabled={
                Boolean(formDetails.rmaNum) ||
                formDetails.status === "pendingdocuments"
              }
              value={formDetails.rmaNum}
              onChange={handleChanges}
            />
          </Grid>
        </>
      )}

      {form?.rmaNum && form.status !== "new" && (
        <>
          <Grid item xs={12}>
            <FlexWrapper alignItems="center" gap={1}>
              <Typography variant="h6">RMA</Typography>
              {(currentDepartment === "RETURNS" ||
                currentDepartment === "CUSTOMER-SERVICE" ||
                currentDepartment === "PRODUCT") &&
                form?.rmainternalid && (
                  <CreditMemoAndRMANetsuiteLinks
                    internalid={form?.rmainternalid}
                  />
                )}
            </FlexWrapper>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              name="rmaNum"
              label="RMA Number"
              InputProps={{ readOnly: true }}
              value={formDetails?.rmaNum}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              name="rmaissuedby"
              label="RMA Issued By"
              value={`${formDetails?.rmaissuedbyname} (${formDetails?.rmaissuedby})`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              name="rmaissuedbyemail"
              label="RMA Issued By Email"
              value={formDetails?.rmaissuedbyemail}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              label="RMA Issued By Department"
              value={formDetails?.rmaissueddepartment}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="RMA Issued Date"
              value={new Date(formDetails?.rmaissuedate).toLocaleDateString()}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="RMA Issued Time"
              value={new Date(formDetails?.rmaissuedate).toLocaleTimeString(
                [],
                {
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </>
      )}

      {form?.receivedby && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5">LC Received</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              name="receivedby"
              label="Received By"
              value={`${formDetails?.receivedbyname} (${formDetails?.receivedby})`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              name="receivedbyemail"
              label="Received By Email"
              value={formDetails?.receivedbyemail}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              name="datereceived"
              label="Date Received"
              value={formDetails?.datereceived}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="time"
              name="timereceived"
              label="Time Received"
              value={formDetails?.timereceived}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="bin"
              label="Bin Number"
              value={formDetails?.bin}
              InputProps={{ readOnly: isReadOnly }}
              onChange={handleChanges}
            />
          </Grid>
        </>
      )}

      {form?.status === "processed" && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5">Processed</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label="Response"
              name="lcresponse"
              value={formDetails?.lcresponse}
              InputProps={{ readOnly: isReadOnly }}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              required
              minRows={4}
              type="text"
              name="detail"
              label="Detail"
              value={formDetails.detail}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: isReadOnly }}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="note"
              label="Note"
              value={formDetails.note}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: isReadOnly }}
              onChange={handleChanges}
            />
          </Grid>
          {formDetails.finalresponse && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                label="Result"
                value={formDetails.finalresponse}
                InputProps={{ readOnly: isReadOnly }}
                onChange={handleChanges}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Processed By"
              value={`${formDetails.processedbyname} (${formDetails.processedby})`}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Processed By Email"
              value={formDetails.processedbyemail}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          {form.processedbydate && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="text"
                  label="Processed Date"
                  value={new Date(
                    formDetails.processedbydate
                  ).toLocaleDateString()}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="text"
                  label="Processed Time"
                  value={new Date(
                    formDetails.processedbydate
                  ).toLocaleTimeString("en", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Internal Use Only</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  name="creditamountfee"
                  label="Credit Amount"
                  value={formDetails.creditamountfee}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="creditmemonumber"
                  label="Credit Memo Number"
                  value={formDetails.creditmemonumber}
                  onChange={handleChanges}
                />
              </Grid>
            </>
          )}
        </>
      )}

      {!isReadOnly && form.status !== "rmaissued" && (
        <Grid item xs={12}>
          <Button type="submit" variant="contained" fullWidth>
            Edit Other Details
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default SubmitFormLC;
