import { useState } from "react";
import { useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import {
  Box,
  Container,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";

const PickOrderScannedView = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const { pick } = useSelector((state) => state.pickOrder);
  const matches = useMediaQuery("(max-width:600px)");

  if (!pick?.length)
    return (
      <FlexWrapper height="50vh" justifyContent="center" alignItems="center">
        <Typography variant="h5" textAlign="center">
          No Items To Display
        </Typography>
      </FlexWrapper>
    );

  const totalPicked = pick.reduce((accumulator, currentValue) => {
    // Check if binqtypicked exists in the current object
    if (currentValue.binqtypicked) {
      // Add binqtypicked value to accumulator
      accumulator += currentValue.binqtypicked;
    }
    return accumulator;
  }, 0);

  // Filter items based on search query
  const filteredItems = pick.filter(
    (item) =>
      item.binnumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.lineitemname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.lineitemupc.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container maxWidth="lg">
      <FlexWrapper justifyContent={matches ? "center" : "space-between"}>
        <TextField
          fullWidth
          size="small"
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value.toUpperCase())}
          sx={{ maxWidth: matches ? "none" : 300, mb: matches ? 0.5 : 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FlexWrapper gap={1}>
          <Typography variant={matches ? "caption" : "subtitle2"}>
            Number of Picks: <strong>{pick.length}</strong>
          </Typography>
          <Typography variant={matches ? "caption" : "subtitle2"}>
            Total Picked: <strong>{totalPicked}</strong>
          </Typography>
        </FlexWrapper>
      </FlexWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxHeight: matches ? "68vh" : "77vh",
          overflowY: "scroll",
        }}
      >
        <Stepper alternativeLabel={false} orientation="vertical" sx={{ py: 2 }}>
          {filteredItems
            .slice()
            .reverse()
            .map((step) => (
              <Step key={step.lineid + step.pickedtime} completed>
                <StepLabel
                  optional={
                    <Box>
                      <Typography>
                        {step.lineitemname} - {step.binqtypicked} Picked
                      </Typography>
                      <Typography variant="body2">
                        Picked By: {step.pickedby}
                      </Typography>
                      <Typography variant="body2">
                        {new Date(step.pickedtime).toLocaleString()}
                      </Typography>
                    </Box>
                  }
                >
                  <Typography variant="h5">{step.binnumber}</Typography>
                </StepLabel>
              </Step>
            ))}
        </Stepper>
      </Box>
    </Container>
  );
};
export default PickOrderScannedView;
