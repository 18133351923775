import { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { RECEIVINGCOLUMNS } from "../../utils/columns/procurement/procurementColumns";

import axios from "../../axios/axios.config";
import Loading from "../../components/Loading";

const Receiving = () => {
  const [isReceivingDataLoading, setIsReceivingDataLoading] = useState(true);
  const [receivingData, setReceivingData] = useState([]); //will save the result from S3 call
  const [lastModifiedDate, setLastModifiedDate] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getReceivingData();
  }, []);

  const getReceivingData = async () => {
    try {
      const { data } = await axios.get(
        "procurement/aws/tactical-connect/file/customsearch_ait_mern_clog_intrans_po.csv"
      );

      let lineid = 0;
      const newData = data.data
        ? data.data.map((item) => {
            lineid++;
            return { ...item, lineid };
          })
        : [];
      setReceivingData(newData);
      setLastModifiedDate(data.LastModified);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error getting data: ${error.message}`,
          duration: 5000,
        })
      );
    } finally {
      setIsReceivingDataLoading(false);
    }
  };

  if (isReceivingDataLoading && receivingData.length === 0) {
    return <Loading message="Loading Receiving Data..." />;
  }

  return (
    <Container maxWidth="xl">
      <Typography component="h1" variant="h3" textAlign="center" pb={2}>
        Receving
      </Typography>
      <Typography variant="body2" gutterBottom>
        {lastModifiedDate
          ? `Data Last Updated On: ${new Date(
              lastModifiedDate
            ).toLocaleString()}`
          : "No Date Available"}
      </Typography>

      <DataGrid
        rows={receivingData}
        columns={RECEIVINGCOLUMNS}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            printOptions: {
              disableToolbarButton: true,
            },
            showQuickFilter: true,
            csvOptions: { fileName: `Receiving-${Date.now()}` },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        disableColumnSelector
        disableDensitySelector
        density="standard"
        getRowId={(row) => {
          return row.lineid;
        }}
        sx={{ maxHeight: { xs: "70vh", md: 750 } }}
      />
    </Container>
  );
};
export default Receiving;
