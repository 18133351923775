import { useState, useEffect } from "react";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";

const ItemAlert = ({ alertInfo, variant }) => {
  const [open, setOpen] = useState(false);
  const [itemInfo, setItemInfo] = useState({ item: null, qty: null });
  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (alertInfo == null) {
      setOpen(false);
    } else {
      setOpen(true);
      setItemInfo({ item: alertInfo.item, qty: alertInfo.qty });
    }
  }, [alertInfo]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      key={alertInfo ? alertInfo.item : undefined}
      anchorOrigin={{
        vertical: matches ? "bottom" : "top",
        horizontal: "left",
      }}
      sx={{
        bottom: matches ? "93px" : "initial",
        top: { md: "70px" },
      }}
    >
      <Alert onClose={handleClose} severity={variant}>
        Added QTY: {itemInfo.qty} to {itemInfo.item}
      </Alert>
    </Snackbar>
  );
};

export default ItemAlert;
