import { Box, Container, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useLoaderData } from "react-router-dom";
import { CHECKINADMINCOLUMNS } from "../../utils/columns/check-ins/checkInColumns";
import FlexWrapper from "../../components/FlexWrapper";

const CheckListAdmin = () => {
  const { data, allowed } = useLoaderData();

  if (!allowed) {
    return (
      <FlexWrapper height="80vh" justifyContent="center" alignItems="center">
        <Box>
          <Typography variant="h4" textAlign="center" gutterBottom>
            Access Denied
          </Typography>
          <Typography variant="body2" textAlign="center">
            You must be within LAN network to access this page
          </Typography>
        </Box>
      </FlexWrapper>
    );
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" textAlign="center" py={2}>
        Driver Check In History
      </Typography>
      <DataGrid
        sx={{ height: "80vh" }}
        columns={CHECKINADMINCOLUMNS}
        rows={data}
        slots={{ toolbar: GridToolbar }}
        disableColumnSelector
        disableRowSelectionOnClick
        disableDensitySelector
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            csvOptions: {
              fileName: `Driver_Checkins_${new Date()
                .toDateString()
                .replaceAll(" ", "_")}`,
            },
          },
        }}
        getRowId={(row) => row._id}
      />
    </Container>
  );
};
export default CheckListAdmin;
