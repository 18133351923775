import axios from "../../../axios/axios.config";

export const printerLoader = async () => {
  try {
    const printerResponse = await axios.get("printers");

    return printerResponse.data;
  } catch (error) {
    throw new Response("", {
      status: error.response?.status || 500,
      statusText: `Could not load printer data: ${error.message}`,
    });
  }
};
