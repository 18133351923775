import { useEffect, useState } from "react";
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import Loading from "../../components/Loading";
import GeneralModal from "../../components/GeneralModal";
import FlexWrapper from "../../components/FlexWrapper";

const DriverCheckin = () => {
  const [open, setOpen] = useState(false);
  const [licensePlate, setLicensePlate] = useState(""); //need to use state to control length

  const loadData = useLoaderData();
  const navigation = useNavigation();
  const data = useActionData();

  const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  useEffect(() => {
    if (data?.checkInData) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setLicensePlate("");
      }, 10000);
    }
  }, [data]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  if (!loadData.allowed) {
    return (
      <FlexWrapper height="80vh" justifyContent="center" alignItems="center">
        <Box>
          <Typography variant="h4" textAlign="center" gutterBottom>
            Access Denied
          </Typography>
          <Typography variant="body2" textAlign="center">
            You must be within LAN network to access this page
          </Typography>
        </Box>
      </FlexWrapper>
    );
  }

  if (navigation.state === "submitting")
    return <Loading message="Submitting Form..." />;

  return (
    <Container maxWidth="md" sx={{ py: 1 }}>
      <GeneralModal
        open={open}
        onClose={handleCloseModal}
        width={600}
        height={250}
      >
        {data?.checkInData && (
          <Box p={2} width="100%">
            <FlexWrapper alignItems="center" flexDirection="column">
              <Box>
                <CheckCircle
                  color="success"
                  fontSize="large"
                  sx={{ mb: 2, fontSize: "60px" }}
                />
              </Box>

              <Typography variant="h4" textAlign="center">
                Thank You <strong>{data?.checkInData?.drivername}</strong>!
                <br /> Somebody will be with you shortly.
              </Typography>
            </FlexWrapper>
          </Box>
        )}
      </GeneralModal>
      <Typography
        textAlign="center"
        variant="h3"
        gutterBottom
        fontWeight="bold"
      >
        Driver Check-In
      </Typography>
      <Typography gutterBottom variant="subtitle2" pb={5} fontWeight="bold">
        Welcome to Advanced Innovative Technologies. Please compete the below
        check-in form and someone will be with you shortly.
      </Typography>

      <Form method="post">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            fullWidth
            required
            autoFocus
            size="small"
            label="Driver Name / Nombre del Conductor"
            variant="outlined"
            name="driverName"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            required
            size="small"
            label="Company Name / Nombre de la Empresa"
            variant="outlined"
            name="companyName"
            sx={{ mb: 2 }}
          />

          <FlexWrapper justifyContent="space-between" width="100%">
            <TextField
              fullWidth
              required
              size="small"
              label="License Plate Number / Numero de Placa"
              variant="outlined"
              name="licensePlate"
              value={licensePlate}
              onChange={(event) => {
                if (event.target.value.length <= 10) {
                  setLicensePlate(event.target.value?.toUpperCase());
                }
              }}
              sx={{ mb: 2, width: "60%" }}
            />
            <FormControl fullWidth required sx={{ width: "39%" }}>
              <InputLabel>License Plate State / Estado de Placa</InputLabel>
              <Select
                required
                size="small"
                label="License Plate State / Estado de Placa"
                variant="outlined"
                name="licensePlateState"
                defaultValue="CA"
                sx={{ mb: 2 }}
              >
                {states.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FlexWrapper>

          <FormControl required fullWidth sx={{ pb: 3 }}>
            <FormLabel>Fuel Type / Tipo de Combustible</FormLabel>
            <RadioGroup
              aria-label="fuelType"
              name="fuelType"
              sx={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <FormControlLabel
                value="Gasoline"
                control={<Radio required />}
                label="Gasoline / Gasolina"
              />
              <FormControlLabel
                value="Diesel"
                control={<Radio required />}
                label="Diesel"
              />
              <FormControlLabel
                value="Natural Gas"
                control={<Radio required />}
                label="Natural Gas / Gas Natural"
              />
              <FormControlLabel
                value="Propane"
                control={<Radio required />}
                label="Propane"
              />
              <FormControlLabel
                value="Hybrid"
                control={<Radio required />}
                label="Hybrid / Hybrido"
              />
              <FormControlLabel
                value="Electric"
                control={<Radio required />}
                label="Electric / Electrico"
              />
            </RadioGroup>
          </FormControl>
          <FormControl required fullWidth sx={{ pb: 1 }}>
            <FormLabel>Truck Type / Tipo de Camión</FormLabel>
            <RadioGroup
              aria-label="truckType"
              name="truckType"
              sx={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <FormControlLabel
                value="Tractor Trailer"
                control={<Radio required />}
                label="Tractor Trailer / Camión con Remolque"
              />
              <FormControlLabel
                value="Other"
                control={<Radio required />}
                label="Other / Otro"
              />
            </RadioGroup>
          </FormControl>
          <FormControl required fullWidth sx={{ pb: 1 }}>
            <FormLabel>Delivery or Pickup / Entrega o Recolección</FormLabel>
            <RadioGroup
              aria-label="deliveryType"
              name="deliveryType"
              sx={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <FormControlLabel
                value="Delivery"
                control={<Radio required />}
                label="Delivery / Entrega"
              />
              <FormControlLabel
                value="Pickup"
                control={<Radio required />}
                label="Pickup / Recolección"
              />
            </RadioGroup>
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 1 }}
          >
            Submit
          </Button>
        </Box>
      </Form>
    </Container>
  );
};
export default DriverCheckin;
