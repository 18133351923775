import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBoxFromPalletV2,
  removeItemFromPalletBoxV2,
  scanItemToPalletBoxV2,
  toggleCurrentBox,
} from "../../../../redux/features/pack-process-v2/packProcessV2Slice";
import { Cancel, Delete, QrCodeScanner } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../FlexWrapper";
import PackProcessItemInfoModal from "./PackProcessItemInfoModal";
import GeneralModalV3 from "../../../general/GeneralModalV3";

const BoxDetailsInput = ({ box }) => {
  const [itemInput, setItemInput] = useState("");
  const [quantity, setQuantity] = useState(1);

  const itemInputRef = useRef(null);
  const dispatch = useDispatch();
  const { username } = useSelector((state) => state.user);
  const { firstpacktime, _id, packdetails, palletnumber } = useSelector(
    (state) => state.packProcessV2
  );

  useEffect(() => {
    handleItemRefFocus();
  }, [box.boxpackedamount]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      scanItemToPalletBoxV2({
        _id,
        item: itemInput,
        quantity,
        boxId: box._id,
        firstpacktime,
        username,
        boxNumber: box.boxId,
        palletId: packdetails[palletnumber]?.palletId,
      })
    );
    setItemInput("");
    setQuantity(1);
  };

  //handles input focus (hides keyboard when focused)
  const handleItemRefFocus = () => {
    if (itemInputRef.current !== null) {
      itemInputRef.current.readOnly = true;
      itemInputRef.current.focus();
      setTimeout(() => {
        itemInputRef.current.focus();
        itemInputRef.current.readOnly = false;
      });
    }
  };

  return (
    <Grid container component="form" onSubmit={handleSubmit}>
      <Grid item xs={3}>
        <TextField
          fullWidth
          required
          inputMode="numeric"
          type="number"
          size="small"
          label="Qty"
          value={quantity}
          onChange={(event) => setQuantity(event.target.value)}
          inputProps={{ min: 1 }}
        />
      </Grid>
      <Grid item xs={9}>
        <TextField
          fullWidth
          required
          autoFocus
          size="small"
          label="Scan Item"
          placeholder={`Enter Item in Box ${box.boxId}`}
          value={itemInput}
          onChange={(event) => setItemInput(event.target.value.toUpperCase())}
          inputRef={itemInputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <QrCodeScanner />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

const PackProcessBoxDetailsModal = () => {
  const { packdetails, palletnumber, tabValue, isBoxModalOpen, _id } =
    useSelector((state) => state.packProcessV2);
  const dispatch = useDispatch();

  const box = packdetails[palletnumber]?.boxes?.find(
    (boxObj) => boxObj.boxId === isBoxModalOpen
  );

  if (!box) {
    return (
      <Container>
        <FlexWrapper>
          <IconButton onClick={() => dispatch(toggleCurrentBox(null))}>
            <Cancel />
          </IconButton>
        </FlexWrapper>
        <Typography variant="h4" textAlign="center">
          Box Not Found
        </Typography>
      </Container>
    );
  }

  const handleDeleteBoxItem = ({
    item,
    itemId,
    removeQty,
    palletId,
    boxId,
    boxNumber,
    palletItemId,
  }) => {
    dispatch(
      removeItemFromPalletBoxV2({
        _id,
        item,
        itemId,
        removeQty,
        palletId,
        boxId,
        boxNumber,
        palletItemId,
      })
    );
  };

  const handleDeleteBox = ({ boxId, palletId, boxNumber }) => {
    dispatch(deleteBoxFromPalletV2({ _id, palletId, boxId, boxNumber }));
  };

  return (
    <Dialog fullScreen open={true} disableEscapeKeyDown>
      <Container maxWidth="md">
        <FlexWrapper justifyContent="space-between" alignItems="center">
          <IconButton onClick={() => dispatch(toggleCurrentBox(null))}>
            <Cancel />
          </IconButton>
          {box.items?.length === 0 ? (
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() =>
                handleDeleteBox({
                  _id,
                  palletId: packdetails[palletnumber]?.palletId,
                  boxId: box._id,
                  boxNumber: box.boxId,
                })
              }
            >
              Delete Box
            </Button>
          ) : null}
        </FlexWrapper>
        <FlexWrapper justifyContent="space-between" alignItems="baseline">
          <Typography py={1} variant="h5" fontWeight="bold">
            Pallet {packdetails[palletnumber]?.palletId} Box {box.boxId}
          </Typography>
          <Typography>Total Qty: {box.boxpackedamount}</Typography>
        </FlexWrapper>

        <BoxDetailsInput box={box} />

        <Box sx={{ height: "80vh", overflowY: "scroll" }}>
          {box.items?.length ? (
            box.items.map((itemObj) => (
              <Card
                key={itemObj._id}
                sx={{
                  backgroundColor: "#e8f5e9",
                  borderColor: "#66bb6a",
                  borderWidth: 2,
                  borderStyle: "solid",
                  p: 0,
                }}
              >
                <CardContent key={itemObj._id} sx={{ p: 0.5 }}>
                  <FlexWrapper
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="caption" color="text.secondary">
                      {itemObj.item.boxsize} | {itemObj.item.iful} |{" "}
                      {itemObj.item.binnumber}
                    </Typography>

                    <FlexWrapper alignItems="center" gap={1.5}>
                      <PackProcessItemInfoModal
                        itemObj={itemObj}
                        tabValue={tabValue}
                      />

                      <GeneralModalV3
                        openComponent={
                          <IconButton size="small">
                            <Delete color="error" />
                          </IconButton>
                        }
                      >
                        {(handleClose) => (
                          <Box>
                            <Typography
                              py={1}
                              variant="h5"
                              textAlign="center"
                              fontWeight="bold"
                            >
                              Delete {itemObj.item.item}?
                            </Typography>
                            <Divider />
                            <Box>
                              <Typography
                                py={1}
                                variant="h6"
                                textAlign="center"
                              >
                                You will delete a quantity of{" "}
                                <strong>{itemObj.palletpackedamount}</strong>{" "}
                                from{" "}
                                <strong>
                                  {" "}
                                  Pallet {
                                    packdetails[palletnumber]?.palletId
                                  }{" "}
                                  Box {box.boxId}
                                </strong>
                                .Action cannot be undone.
                              </Typography>
                            </Box>
                            <Divider />
                            <FlexWrapper justifyContent="flex-end" p="10px">
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                  handleClose();
                                  handleDeleteBoxItem({
                                    item: itemObj.item.item,
                                    itemId: itemObj.item._id,
                                    removeQty: parseInt(
                                      itemObj.palletpackedamount
                                    ),
                                    palletId: parseInt(
                                      packdetails[palletnumber]?.palletId
                                    ),
                                    boxId: box._id,
                                    boxNumber: box.boxId,
                                    palletItemId: itemObj._id, //fix
                                  });
                                }}
                              >
                                Delete
                              </Button>
                            </FlexWrapper>
                          </Box>
                        )}
                      </GeneralModalV3>
                    </FlexWrapper>
                  </FlexWrapper>

                  <FlexWrapper alignItems="center" justifyContent="center">
                    <Typography
                      textAlign="center"
                      variant="h6"
                      fontWeight="bold"
                    >
                      {itemObj.item.item}
                    </Typography>
                  </FlexWrapper>

                  <FlexWrapper
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="body2">
                      <strong>Qty: </strong>
                      {itemObj.item.quantity}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Packed in Box:</strong>{" "}
                      {itemObj.palletpackedamount}
                    </Typography>
                  </FlexWrapper>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography
              variant="body2"
              color="text.secondary"
              style={{ textAlign: "center", marginTop: "16px" }}
            >
              No Items to Display
            </Typography>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};
export default PackProcessBoxDetailsModal;
