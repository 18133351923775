import { useEffect, useState } from "react";
import { Folder } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Link,
  Divider,
} from "@mui/material";

import GeneralModal from "../../GeneralModal";
import axios from "../../../axios/axios.config";

const AttachmentsWarranty = ({ form, fileURL }) => {
  const [open, setOpen] = useState(false);
  const [nsPDFLinks, setNSPDFLinks] = useState({ creditMemo: "", rma: "" });

  useEffect(() => {
    getNSPDFSLinks();
  }, []);

  const getNSPDFSLinks = async () => {
    let rmaBlobUrl = "";
    let creditMemoBlobUrl = "";

    if (form.creditmemo) {
      //Credit Memo
      const nsCreditMemoPDFResponse = await axios.get(
        `netsuite/labor-claim/get/rma/${form.creditmemo.creditmemointernalid}/pdf`
      );
      const creditMemoBase64PDF = nsCreditMemoPDFResponse.data.data64;

      // Convert base64 to Blob
      const cmByteCharacters = atob(creditMemoBase64PDF);
      const cmByteNumbers = new Array(cmByteCharacters.length);
      for (let i = 0; i < cmByteCharacters.length; i++) {
        cmByteNumbers[i] = cmByteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(cmByteNumbers);
      const cmBlob = new Blob([byteArray], { type: "application/pdf" });

      // Create a Blob URL
      creditMemoBlobUrl = URL.createObjectURL(cmBlob);
    }

    if (form.rma) {
      // RMA
      const nsRMAPDFResponse = await axios.get(
        `netsuite/labor-claim/get/rma/${form.rma.rmainternalid}/pdf`
      );

      const rmaBase64 = nsRMAPDFResponse.data.data64;

      // Convert base64 to Blob
      const rmaByteCharacters = atob(rmaBase64);
      const rmaByteNumbers = new Array(rmaByteCharacters.length);
      for (let i = 0; i < rmaByteCharacters.length; i++) {
        rmaByteNumbers[i] = rmaByteCharacters.charCodeAt(i);
      }
      const rmaByteArray = new Uint8Array(rmaByteNumbers);
      const rmaBlob = new Blob([rmaByteArray], { type: "application/pdf" });

      // Create a Blob URL
      rmaBlobUrl = URL.createObjectURL(rmaBlob);

      setNSPDFLinks({ creditMemo: creditMemoBlobUrl, rma: rmaBlobUrl });
    }
  };

  return (
    <>
      <Tooltip title="Attachments" placement="top" arrow>
        <IconButton onClick={() => setOpen(true)}>
          <Folder color="info" />
        </IconButton>
      </Tooltip>

      {/* Attachments Modal */}
      <GeneralModal open={open} handleClose={() => setOpen(false)}>
        <Box p={2}>
          <Typography variant="h4" textAlign="center">
            Attachments
          </Typography>

          <ul>
            <li key={fileURL[0]}>
              <Link href={fileURL[0]} target="_blank">
                {form.file}
              </Link>
            </li>

            {(form.rma || form.creditMemo) && <Divider sx={{ my: 2 }} />}

            {form.rma &&
              (nsPDFLinks.rma ? (
                <li>
                  <Link href={nsPDFLinks.rma} target="_blank">
                    Netsuite Return Authorization
                  </Link>
                </li>
              ) : (
                <Typography>
                  Loading Netsuite Return Authorization...
                </Typography>
              ))}

            {form.creditmemo &&
              (nsPDFLinks.creditMemo ? (
                <li>
                  <Link href={nsPDFLinks.creditMemo} target="_blank">
                    Netsuite Credit Memo
                  </Link>
                </li>
              ) : (
                <Typography>Loading Netsuite Credit Memo...</Typography>
              ))}
          </ul>
        </Box>
      </GeneralModal>
    </>
  );
};
export default AttachmentsWarranty;
