import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const Loading = ({ message, height = "80vh" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        height: height,
      }}
    >
      <CircularProgress variant="indeterminate" value={10} />
      <Typography variant="body1" color="primary">
        {message}
      </Typography>
    </Box>
  );
};

export default Loading;
