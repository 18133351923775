import { useState } from "react";
import { read, utils } from "xlsx";
import { Cancel } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

import GeneralFullScreenModalV3 from "../../../general/modals/GeneralFullScreenModalV3";
import GeneralFileUpload from "../../../GeneralFileUpload";
import FlexWrapper from "../../../FlexWrapper";

const CreateCycleCountUploadCSVModal = ({
  handleMenuClose,
  user,
  openAlert,
  axios,
  setLoading,
  setJobsData,
}) => {
  const [files, setFiles] = useState([]);
  const [jobDataFromFile, setJobDataFromFile] = useState({});

  const handleSaveFiles = async (loadedFiles) => {
    try {
      let fileData = { ...jobDataFromFile };
      const allowedMimeTypes = [
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      for (const file of loadedFiles) {
        if (!allowedMimeTypes.includes(file.type)) {
          throw new Error(
            `Invalid file type: ${file.name}. All files must be CSV or Excel files.`
          );
        }

        //get file data as JSON
        const { name, data } = await getJSONFromFile(file);

        const newFileName = name.replace(/\.csv|\.xlsx/, "");

        //check column headers
        const headers = {
          "Internal ID": true,
          Name: true,
          Description: true,
          "UPC Code": true,
          "Bin Number": true,
          "Internal ID_1": true, //The file has two internal ids column headers
        };

        for (const fileObjData of data) {
          //check header
          for (const header of Object.keys(fileObjData)) {
            if (!headers[header]) {
              throw new Error(`Invalid Column: ${header}`);
            }
          }
        }

        fileData[newFileName] = data.map((obj) => {
          return {
            itemData: {
              iteminternalid: obj["Internal ID"],
              itemname: obj["Name"],
              itemdescription: obj["Description"],
              itemupc: obj["UPC Code"],
              itembinnumber: obj["Bin Number"],
              itemlocationinternalid: obj["Internal ID_1"],
            },
          };
        });
      }

      setJobDataFromFile(fileData);
      setFiles([...files, ...loadedFiles]);
    } catch (error) {
      setFiles([]);
      throw error;
    }
  };

  const handleRemoveFile = (fileToRemove) => {
    const newFiles = files.filter((file) => file.name !== fileToRemove.name);
    const newData = { ...jobDataFromFile };
    delete newData[fileToRemove.name];

    setFiles(newFiles);
    setJobDataFromFile(newData);
  };

  const getJSONFromFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const workbook = read(arrayBuffer, { type: "array" });

        // Process the workbook
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = utils.sheet_to_json(worksheet);

        resolve({ name: file.name, data: jsonData });
      };

      reader.onerror = () => {
        reject(new Error(`Failed to read file: ${file.name}`));
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleCreateJob = async () => {
    setLoading("Creating New Job...");
    try {
      for (const fileKey of Object.keys(jobDataFromFile)) {
        const itemsSet = new Set();
        const binsSet = new Set();
        //create overall jobs data
        const jobsResponse = await axios.post("cycle-counts-jobs/create", {
          jobsData: {
            createdby: user._id,
            name: fileKey,
            whselocation: user.currentLocation,
            itemlocation: parseInt(
              jobDataFromFile[fileKey][0]["itemData"]["itemlocationinternalid"]
            ),
            creationtype: "csv",
          },
        });

        const individualJobArr = jobDataFromFile[fileKey].map((data) => {
          //save item name and bin number to set to get unique count
          itemsSet.add(data.itemData["itemname"]);
          binsSet.add(data.itemData["itembinnumber"]);

          return {
            ...data,
            originaljob: jobsResponse.data._id,
            currentlinkedjob: jobsResponse.data._id,
          };
        });

        //create line item job data and get the ids
        const lineItemsResponse = await axios.post(
          "cycle-counts-line-items/create-many",
          {
            jobData: individualJobArr,
          }
        );

        //add individual job ids to overall jobs and sum unique bins/items
        await axios.patch(
          `cycle-counts-jobs/update/${jobsResponse.data._id}/jobs`,
          {
            jobsArray: lineItemsResponse.data, //array of job ids
            uniqueBins: binsSet.size, //count of unique bins to add
            uniqueItems: itemsSet.size, //count of unique items to add
          }
        );
      }

      //get new data and update UI
      const newDataResponse = await axios.get(
        `cycle-counts-jobs/get/status/new?location=${user.currentLocation}`
      );

      setJobsData(newDataResponse.data);
      openAlert({
        type: "success",
        duration: 3000,
        message: "Successfully Created Jobs",
      });
    } catch (error) {
      openAlert({
        type: "error",
        duration: 6000,
        message: `Could Not Create Jobs: ${
          error.response?.data?.msg || error.message
        }`,
      });
    } finally {
      setLoading("");
    }
  };

  return (
    <GeneralFullScreenModalV3
      openComponent={<MenuItem>Create by CSV/Excel</MenuItem>}
      onClose={handleMenuClose}
    >
      {(handleClose) => (
        <Container maxWidth="md">
          <FlexWrapper justifyContent="flex-end">
            <Tooltip title="Cancel" arrow placement="top" sx={{ my: 1 }}>
              <IconButton
                onClick={() => {
                  handleClose();
                  setFiles([]);
                  setJobDataFromFile({});
                }}
                color="error"
              >
                <Cancel />
              </IconButton>
            </Tooltip>
          </FlexWrapper>

          <Typography variant="h4" textAlign="center" gutterBottom pb={2}>
            Create Job By CSV/Excel
          </Typography>

          <Box component="ul" mb={5}>
            <li>
              <Typography textTransform="capitalize" fontSize="18px">
                Upload CSV/Excel File To Add New Job
              </Typography>
            </li>
            <li>
              <Typography textTransform="capitalize" fontSize="18px">
                Multiple Files Can Be Uploaded. Each File Will be its own Job.
              </Typography>
            </li>
            <li>
              <Typography
                component="div"
                textTransform="capitalize"
                fontSize="18px"
              >
                Files should have the following columns: <br />{" "}
                <Typography variant="h6" fontWeight="bold">
                  Internal ID, Name, Description, UPC Code, Bin Number, Internal
                  ID
                </Typography>
              </Typography>
            </li>
          </Box>

          <Typography
            variant="body2"
            fontWeight="bold"
            textAlign="center"
            color="error"
            gutterBottom
          >
            Jobs will be cycle counted in the order they appear in the file
          </Typography>

          <GeneralFileUpload
            accept={["text/csv"]}
            files={files}
            handleSaveFiles={handleSaveFiles}
            multiple
            handleRemoveFile={handleRemoveFile}
          />

          {files.length ? (
            <Button
              size="large"
              fullWidth
              variant="contained"
              sx={{ my: 2 }}
              onClick={() => {
                handleClose();
                handleCreateJob();
                setFiles([]);
                setJobDataFromFile({});
              }}
            >
              Create Job{files.length > 1 ? "s" : ""}
            </Button>
          ) : null}
        </Container>
      )}
    </GeneralFullScreenModalV3>
  );
};
export default CreateCycleCountUploadCSVModal;
