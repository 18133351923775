export const CHECKINADMINCOLUMNS = [
  {
    field: "drivername",
    headerName: "Driver Name",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "companyname",
    headerName: "Company Name",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "licenseplate",
    headerName: "License Plate",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "licenseplatestate",
    headerName: "License Plate State",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "deliverytype",
    headerName: "Delivery Type",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "type",
    headerName: "Type",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "fueltype",
    headerName: "Fuel Type",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "truckType",
    headerName: "Truck Type",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "createdAt",
    headerName: "Check In Date",
    type: "datetime",
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueFormatter: (valueObj) => {
      return new Date(valueObj.value).toLocaleString();
    },
  },
];
