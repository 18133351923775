import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { APP_VERSION } from "../../utils/constants/version";
import { getPrinterObjectFromUser } from "../../redux/features/printers/printerSlice";
import {
  login,
  loginLog,
  setUserInfo,
} from "../../redux/features/user/userSlice";

import axios from "../../axios/axios.config";

const Login = () => {
  const [userData, setUserData] = useState({
    userName: "",
    password: "",
  });
  const [error, setError] = useState(null);

  const { instanceId } = useSelector((state) => state.ec2);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const user = await dispatch(
        login({
          username: userData.userName,
          password: userData.password,
          version: APP_VERSION,
          lastlogin: new Date().toISOString(),
        })
      ).unwrap();

      var {
        location,
        subsidiary,
        department,
        username,
        _id,
        usertype,
        rolelevel,
        printer,
      } = user.user;
      //get printer after logging in
      if (printer) {
        dispatch(getPrinterObjectFromUser(printer));
      }

      dispatch(
        loginLog({
          user: { username, _id, usertype, rolelevel },
          status: "success",
          instanceId,
          version: APP_VERSION,
        })
      );

      if (location.length === 1 && subsidiary.length === 1) {
        //get netsuite location internal ids
        const { data } = await axios.get(
          `locations/get/netsuite-locations/internalids/${location[0].location}`
        );

        //get subsidiary internalid
        const subsidiaryResponse = await axios.get(
          `/subsidiaries/get/internalid/${subsidiary[0]}`
        );

        dispatch(
          setUserInfo({
            location: location[0].location,
            subsidiary: subsidiary[0],
            subsidiaryinternalid: subsidiaryResponse.data?.internalid || null,
            department: department[0],
            netsuiteLocationIds: data?.netsuitelocationinternalids || [],
          })
        );
        navigate(searchParams.get("redirect-path") || `/portal`);
        return;
      }
      navigate(
        searchParams.get("redirect-path")
          ? `/session?redirect-path=${encodeURIComponent(
              searchParams.get("redirect-path")
            )}&department=${searchParams.get("department")}`
          : "/session"
      );
    } catch (error) {
      dispatch(
        loginLog({
          status: "failure",
          message: error,
          user: { username: userData.userName, _id: "", rolelevel: "" },
          version: APP_VERSION,
        })
      );
      setError(error);
    }
  };

  const handleChanges = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  return (
    <Box
      sx={{
        marginBottom: 2,
      }}
    >
      <Typography variant="h5" textAlign="center" gutterBottom>
        Sign in
      </Typography>
      <form onSubmit={handleLogin}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              required
              fullWidth
              error={Boolean(error)}
              name="userName"
              label="Username or Email"
              type="text"
              id="userName"
              value={userData.userName}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              error={Boolean(error)}
              name="password"
              label="Password"
              type="password"
              id="password"
              value={userData.password}
              onChange={handleChanges}
            />
            {error && (
              <Typography variant="caption" color="error">
                {error}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained">
              Sign In
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default Login;
