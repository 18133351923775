import axios from "../../axios/axios.config";

export const inventoryAdminPageLoader = async () => {
  const response = await axios.get(
    `inventory-move/status/incomplete?subsidiary=${localStorage.getItem(
      "subsidiary"
    )}&locations=${localStorage.getItem("netsuitelocations")}`
  );

  return response.data;
};

export const inventoryAdminViewCountsPageLoader = async ({ params }) => {
  const response = await axios.get(`pick-shorts/get/counts/${params.id}`);
  console.log(response.data);
  return response.data;
};
