import axios from "../../axios/axios.config";

export const updatePackResultsPageLoader = async ({ params }) => {
  try {
    const { orderid } = params;
    const response = await axios.get(
      `pack-process/update-pack-order/${orderid}`
    );
    const packDetails = {};
    const palletKeys = Object.keys(response.data.packdetails);

    //ensure packdetails have images and discrepanices field
    for (const pallet of palletKeys) {
      if (!packDetails[pallet]) {
        packDetails[pallet] = { ...response.data.packdetails[pallet] };
      }

      const palletData = packDetails[pallet];

      if (palletData.items) {
        delete palletData.items;
      }

      if (!palletData.images) {
        palletData.originalImages = [];
      } else {
        palletData.originalImages = palletData.images;
      }

      palletData.newImages = [];
      palletData.isupdated = false;
    }

    return {
      palletKeys,
      currentPallet: palletKeys[0],
      packDetails,
      order: response.data.createdfrom,
      isImagesRequired: response.data.palletimages,
      discrepancies: response.data.discrepancies,
      palletimages: response.data.palletimages,
    };
  } catch (error) {
    const newError = new Error(error.response?.data?.msg || error.message);
    throw newError;
  }
};
//loader for pack process results page (no longer being used)
export const packProcessResultsPageLoader = async ({ params }) => {
  try {
    console.log(params);
    const response = await axios.get(`pack-process-v2/${params.orderid}`);

    return response.data;
  } catch (error) {
    return null;
  }
};
