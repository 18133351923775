import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { getFormRows } from "../../../redux/features/forms/formsSlice";
import { useSalesRepCustomerMatch } from "../../../hooks/useSalesRepCustomerMatch";
import { sendEmail } from "../../../utils/email/emailFunctions";
import {
  addNewVersion,
  createForm,
} from "../../../redux/features/forms/formsSlice";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  Chip,
  Checkbox,
  FormControlLabel,
  Typography,
  FormGroup,
  Link,
  Tooltip,
} from "@mui/material";

import axios from "../../../axios/axios.config";
import FileUpload from "../../../components/sales/FileUpload";
import WarningActionModal from "../../../components/WarningActionModal";
import Loading from "../../../components/Loading";
import SplitActionGeneral from "../../../components/SplitActionGeneral";
import FormDownload from "../../../components/PDF/sales/FormDownload";
import ProposalFormPDF from "../../../components/PDF/sales/ProposalFormPDF";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const groupMap = {
  PRONTO: {
    pricing: ["THE GROUP"],
    terms: ["3% Net 15", "2% 90 Days Net 121 Days", "1% 120 Days Net 121 Days"],
  },
  FEDERATED: {
    pricing: ["THE GROUP"],
    terms: ["3% Net 15", "2% 90 Days Net 120 Days", "1% 120 Days Net 121 Days"],
  },
  ALLIANCE: {
    pricing: ["WD", "WDB", "WDX"],
    terms: ["1% Net 60"],
  },
  APA: {
    pricing: ["WD", "WDB", "WDX"],
    terms: ["1% Net 60"],
  },
  None: {
    pricing: [""],
    terms: ["Net 30"],
  },
  ADN: {
    pricing: [""],
    terms: ["Net 30"],
  },
  NAPA: {
    pricing: ["NAPA"],
    terms: ["Net 30"],
  },
};

const Proposal = ({ proposalRows, setProposalRows }) => {
  const [editing, setEditing] = useState(false);
  const [form, setForm] = useState({});
  const [version, setVersion] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [products, setProducts] = useState([]);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileURL, setFileURL] = useState(null);
  const [isAttatchFile, setIsAttatchFile] = useState(false);
  const [file, setFile] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [termsOptions, setTermsOptions] = useState([]);
  const [formattedInput, setFormattedInput] = useState(null);
  const [input, setInput] = useState({
    date: new Date().toISOString().slice(0, 10),
    proposalFor: "",
    group: "None",
    groupInvoiceDiscounts: "",
    terms: "",
    pricing: "",
    product: [],
    packaginglabel: "",
    privateLabelBrand: "",
    salesManager: "",
    agency: "none",
    orderProcedure: `PHONE: (800) 567-1608 / (909) 253-0077 \nFAX: (417) 831-9447\nE-MAIL: customerservice@trakmotive.com\nEDI: TrueCommerce, Corcentric, Gcommerce`,
    representativeagent: "",
    discounts: "",
    rebates: "",
    groupRebates: "",
    annualSales: "",
    directShip: "70 pieces or more",
    willCall:
      "800 units (10 pallets). 20 ft (1,700 units) and 40 ft (2,400 units) containers.",
    specialOrders: "$3.50 handling charge.",
    customerNameAndTitle: "",
    vendorNameAndTitle: "",
    backorders: "Ship & Cancel",
    inLieuWarranty: false,
    percentage: "",
    openingOrderDiscount: "",
    warranty:
      "Limited Lifetime / Original Owner / See TrakMotive's complete warranty statement for details.",
    changeover:
      "A.I.T. will re-box customer's existing GSP, Protech and Heri axle inventory to TrakMotive part numbers and packaging and lift any questionable interchanges for credit at customer's acquisition cost.",
    returnedWarranty: `Defectives of new TrakMotive and the customer's prior supplier, are cover by AIT, for one year after the ship date of the customer's first order. No reman units are eligible for warranty.`,
    stockAdjustment:
      "4% of the customer's previous 12 months net purchases thereafter. The return freight cost for the stock adjustment shall be prepaid by the customer. An offsetting order of equal or greater value to be placed by the customer. No reman units are eligible for return.",
  });

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector((state) => state.user);
  const { filteredCustomers } = useSalesRepCustomerMatch();

  useEffect(() => {
    if (filteredCustomers?.length > 0) {
      getGroupsAndCompanies();
      getAgencies();
      getProducts();
    }
  }, [filteredCustomers]);

  useEffect(() => {
    if (
      searchParams.get("proposal") &&
      searchParams.get("editing") === "true"
    ) {
      getFormData();
    } else if (searchParams.get("editing") === "false") {
      setInput({
        ...input,
        salesManager: `${user.firstname} ${user.lastname}`,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (form?.versions && version !== null) {
      setInput({ ...form.versions[version] });
    }
  }, [version, form]);

  const getFormData = async () => {
    try {
      setFormLoading(true);
      const formResponse = await axios.get(
        `/forms/proposal/${searchParams.get("proposal")}`
      );

      const form = formResponse.data;
      const version = formResponse.data.versions.length - 1;
      setIsNewCustomer(form.versions[version].newCustomer);
      //set correct pricing and terms options before setting input
      const groupData = form.versions[version].group;
      let pricing = [...groupMap[groupData].pricing];
      let terms = [...groupMap[groupData].terms];

      if (!pricing.find((el) => el === form.versions[version].pricing)) {
        pricing = [...pricing, form.versions[version].pricing];
      }

      if (!terms.find((el) => el === form.versions[version].terms)) {
        terms = [...terms, form.versions[version].terms];
      }

      setPricingOptions([...pricing]);
      setTermsOptions([...terms]);

      //set file url if exists
      if (form.versions[version].file) {
        axios
          .post("files/signedURL", {
            Key: "forms/proposal/" + form.versions[version].file,
          })
          .then((response) => setFileURL(response.data))
          .catch((error) => console.log(error));
      }

      //set input with data from db
      setFormattedInput(formatData(form.versions[version]));
      setForm(form);
      setVersion(version);
      setEditing(true);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
    }
  };

  const getGroupsAndCompanies = async () => {
    const res = await axios.get("customers", {
      params: {
        name: "",
        code: -1,
        usertype: user.usertype,
        subsidiary: user.currentSubsidiary,
        sortby: "",
      },
    });

    const groupResponseArr = await res.data;
    const uniqueCategories = groupResponseArr.reduce((acc, curr) => {
      if (curr.category && !acc[curr.category]) {
        acc[curr.category] = true;
      }
      return acc;
    }, {});
    const groups = Object.keys(uniqueCategories);

    setGroups(groups);
  };

  const getAgencies = async () => {
    const uniqueAgencies = filteredCustomers.reduce((acc, curr) => {
      if (curr.partner && !acc[curr.partner]) {
        acc[curr.partner] = true;
      }
      return acc;
    }, {});
    const set = new Set();
    filteredCustomers.map((c) => set.add(c.partner));
    const agencies = Object.keys(uniqueAgencies);
    setAgencies(agencies);
  };

  const getProducts = async () => {
    try {
      const response = await axios.get("/items/active");
      const items = await response.data;
      const uniqueClasses = items.reduce((acc, curr) => {
        if (curr.class && !acc[curr.class]) {
          acc[curr.class] = true;
        }
        return acc;
      }, {});
      const products = Object.keys(uniqueClasses);
      setProducts(products);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not get product list: ${error}`,
          duration: 3000,
        })
      );
    }
  };

  const handleChanges = (e) => {
    if (e.target.name === "group") {
      setPricingOptions(groupMap[e.target.value].pricing);
      setTermsOptions(groupMap[e.target.value].terms);
      setInput({
        ...input,
        [e.target.name]: e.target.value,
        terms: "",
        pricing: "",
      });
    } else if (e.target.name === "proposalFor") {
      //Existing Customer has prefilled data
      if (!isNewCustomer) {
        const customer = filteredCustomers.find(
          (c) => c.companyname === e.target.value
        );
        const groupVal = customer.category ? customer.category : "None";
        let pricingOptions = groupMap[groupVal].pricing;
        let termsOptions = groupMap[groupVal].terms;

        if (!pricingOptions.includes(customer.pricelevel)) {
          pricingOptions = [...pricingOptions, customer.pricelevel];
        }

        if (!termsOptions.includes(customer.terms)) {
          termsOptions = [...termsOptions, customer.terms];
        }

        setPricingOptions(pricingOptions);
        setTermsOptions(termsOptions);
        setInput({
          ...input,
          [e.target.name]: e.target.value,
          agency: customer.partner,
          group: groupVal,
          terms: customer.terms,
          pricing: customer.pricelevel,
        });
      } else {
        //New Customer (No Prefill)
        setPricingOptions(groupMap["None"].pricing);
        setTermsOptions(groupMap["None"].terms);
        setInput({
          ...input,
          [e.target.name]: e.target.value,
          agency: "",
          group: "",
          pricing: "",
          terms: "",
        });
      }
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  const handleMultiSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setInput(
      // On autofill we get a stringified value.
      {
        ...input,
        product: typeof value === "string" ? value.split(",") : value,
      }
    );
  };

  const handleCheckBoxChanges = (e) => {
    if (!editing || (editing && form.versions.length - 1 === version)) {
      setInput({
        ...input,
        inLieuWarranty: !input.inLieuWarranty,
        returnedWarranty: e.target.checked
          ? ""
          : `Defectives of new TrakMotive and the customer's prior supplier, are cover by AIT, for one year after the ship date of the customer's first order. No reman units are eligible for warranty.`,
      });
    }
  };

  const sendEmailWithAttatchments = async (_id) => {
    const dynamicTemplateData = {
      ...input,
      _id,
      message: `${user.username} uploaded signed proposal file for
       ${input.proposalFor} on ${new Date().toISOString().slice(0, 10)}`,
      newCustomer: isNewCustomer,
      product: input.product.join(", "),
    };
    const email = {
      dynamicTemplateData,
      templateId: "d-97126ccbfa524d3fbf314542bcc200a6",
      cc:
        process.env.REACT_APP_TEST_EMAIL ||
        ["evelin@trakmotive.com", "bcrawford@trakmotive.com"].includes(
          user.email
        )
          ? ""
          : user.email,
      to: process.env.REACT_APP_TEST_EMAIL || [
        "evelin@trakmotive.com",
        "bcrawford@trakmotive.com",
      ],
    };
    const formData = new FormData(); //files sent to server must use formData object

    formData.append("files", file[0]);
    formData.append("email", JSON.stringify(email));

    try {
      await axios.post("/email/attatchments", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${
            error.response.data ? error.response.data.msg : error.message
          }`,
          duration: 5000,
        })
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const form = await dispatch(
        createForm({
          newForm: {
            version: {
              ...input,
              newCustomer: isNewCustomer,
              approvalStatus: "pending",
            },
            createdby: {
              username: user.username,
              department: user.currentDepartment,
            },
            subsidiary: user.currentSubsidiary,
          },
          type: "proposal",
        })
      ).unwrap();

      //send email on create
      await sendEmail(
        process.env.REACT_APP_TEST_EMAIL || user.email,
        "New Proposal Created. Needs Approval",
        "",
        `<p style="font-size:18px;">New proposal has been created by <strong>${
          user.username
        }</strong> for ${input.proposalFor}. You can view it <a href=${
          window.location.origin +
          window.location.pathname +
          "?modal=open&editing=true&proposal=" +
          form._id
        }>here.</a></p>`,
        true
      );

      setProposalRows([...proposalRows, form]);
      setSearchParams({});
      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully added new form",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${error}`,
          duration: 3000,
        })
      );
    }
  };

  const handleNewVersionSubmit = async (event) => {
    event.preventDefault();
    try {
      const newVersion = await dispatch(
        addNewVersion({
          id: searchParams.get("proposal"),
          version: {
            ...input,
            newCustomer: form.versions[version].newCustomer,
            file: null,
            approvalStatus: "pending",
          },
          type: "proposal",
        })
      ).unwrap();

      await sendEmail(
        process.env.REACT_APP_TEST_EMAIL || "erodriguez@trakmotive.com",
        "Proposal has been updated. Approval Required",
        "",
        `<p style="font-size:18px;">Proposal ${searchParams.get(
          "proposal"
        )} has been updated by <strong>${user.username}</strong> for ${
          input.proposalFor
        }. You can view it <a href=${window.location.href}>here.</a></p>`,
        true
      );
      //update the old object with new version object
      const updatedProposalRows = [...proposalRows];
      const index = updatedProposalRows.findIndex(
        (row) => row._id === searchParams.get("proposal")
      );
      updatedProposalRows[index] = newVersion;

      setProposalRows(updatedProposalRows);
      setSearchParams({});
      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully added new version",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not add version: ${error}`,
          duration: 3000,
        })
      );
    }
  };

  const submitFile = async () => {
    try {
      //return if no file is present
      if (file.length === 0) {
        return;
      }

      const formData = new FormData();
      formData.append("file", file[0]);
      formData.append("folder", "forms/proposal/");
      //Save File to S3
      await axios.post("/files/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      //Update DB with file name
      await axios.patch(
        `forms/update-version/${searchParams.get("proposal")}/${
          form.versions.length - 1
        }`,
        {
          newVersion: {
            ...form.versions[form.versions.length - 1],
            file: file[0].name,
          },
        }
      );
      sendEmailWithAttatchments(searchParams.get("proposal"));
      dispatch(getFormRows({ type: "proposal" }));
      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Updated File",
          duration: 4000,
        })
      );
      setSearchParams({});
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not update file: ${error.message}`,
          duration: 5000,
        })
      );
    }
  };

  const handleApproveForm = async () => {
    setApprovalLoading(true);
    try {
      //approve form
      await axios.patch(
        `forms/update-version/${searchParams.get("proposal")}/${
          form.versions.length - 1
        }`,
        {
          newVersion: {
            ...form.versions[form.versions.length - 1],
            approvalStatus: "approved",
            approvedBy: user.username,
            approvalDate: new Date().toISOString(),
          },
        }
      );

      //send email on approve
      await sendEmail(
        process.env.REACT_APP_TEST_EMAIL || [
          "evelin@trakmotive.com",
          "bcrawford@trakmotive.com, ktalley@trakmotive.com",
        ],
        `Proposal for ${
          form.versions[form.versions.length - 1].proposalFor
        } Has Been Approved By ${user.firstname.toUpperCase()} ${user.lastname.toUpperCase()}`,
        "",
        `<p style="font-size:18px;">Proposal has been approved for ${input.proposalFor}. You can view it <a href=${window.location.href}>here.</a></p>`,
        true
      );
      //retrieve latest form data and exit
      await dispatch(getFormRows({ type: "proposal" })).unwrap();

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully approved form.",
          duration: 4000,
        })
      );
      setSearchParams({});
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not approve form: ${error.message}`,
          duration: 5000,
        })
      );
    } finally {
      setApprovalLoading(false);
    }
  };

  //generate new array with input data for proposal pdf
  const formatData = (input) => {
    return [
      {
        key: "proposal date",
        value: new Date(input.date).toLocaleDateString(),
      },
      { key: "proposal prepared for", value: input.proposalFor },
      {
        key: input.product.length > 1 ? "products" : "product",
        value: input.product.join(", "),
      },
      { key: "packaging label", value: input.packaginglabel },
      { key: "private label brand", value: input.privateLabelBrand },
      { key: "sales manager", value: input.salesManager },
      { key: "sales agency", value: input.agency },
      { key: "sales agency rep", value: input.representativeagent },
      { key: "group", value: input.group },
      {
        key: "group off invoice disocunts",
        value: input.groupInvoiceDiscounts,
      },
      {
        key: "group rebates",
        value: input.groupRebates,
      },
      {
        key: "pricing",
        value: input.pricing,
      },
      {
        key: "terms",
        value: input.terms,
      },
      {
        key: "Customer Direct Discounts",
        value: input.discounts,
      },
      {
        key: "Customer Direct Rebates",
        value: input.rebates,
      },
      {
        key: "Expected Annual Sales",
        value: input.annualSales,
      },
      {
        key: "Direct or Drop Ship Program",
        value: input.directShip,
      },
      {
        key: "special orders",
        value: input.specialOrders,
      },
      {
        key: "FastTrak Program",
        value: input.willCall,
      },
      {
        key: "opening order discount",
        value: input.openingOrderDiscount,
      },
      {
        key: "Changeover",
        value: input.changeover,
      },
      {
        key: "in lieu of warranty",
        value: input.inLieuWarranty ? "YES" : "",
      },
      {
        key: "Percentage",
        value: input.percentage,
      },
      {
        key: "Product returned as warranty",
        value: input.returnedWarranty,
      },
      {
        key: "stock adjustment request",
        value: input.stockAdjustment,
      },
      {
        key: "order procedure",
        value: input.orderProcedure,
      },
      {
        key: "backorders",
        value: input.backorders,
      },
      {
        key: "warranty",
        value: input.warranty,
      },
      {
        vendorNameAndTitle: input.vendorNameAndTitle,
        customerNameAndTitle: input.customerNameAndTitle,
      },
    ];
  };

  if (formLoading && searchParams.get("editing") === "true") {
    return <Loading message="Loading Proposal Form" />;
  }

  if (approvalLoading) {
    return <Loading message="Approving Form..." />;
  }

  if (
    (searchParams.get("editing") === "true" && form?.versions) ||
    searchParams.get("editing") === "false"
  ) {
    return (
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={editing ? handleNewVersionSubmit : handleSubmit}
        sx={{ py: 3 }}
        noValidate
      >
        <WarningActionModal
          title="Attach Signed Proposal File"
          open={isModalOpen}
          modalAction={submitFile}
          closeFn={() => {
            setIsModalOpen(false);
            setFile([]);
          }}
          disabled={file.length === 0}
          body={
            <FileUpload
              setFiles={setFile}
              setFileError={setFileError}
              validateFileData={async (data, file) => file}
              setLoading={setLoading}
              loading={loading}
            />
          }
        />
        {searchParams.get("editing") === "true" &&
          searchParams.get("proposal") && (
            <Grid item xs={12}>
              <Box
                display="flex"
                pb={2}
                justifyContent="end"
                alignItems="center"
                gap={1}
              >
                {version === form.versions.length - 1 &&
                  (!form.versions[form.versions.length - 1].approvalStatus ||
                    form.versions[form.versions.length - 1].approvalStatus ===
                      "pending") &&
                  user.username === "KST-7971" && (
                    <Button
                      type="button"
                      color="success"
                      variant="contained"
                      size="small"
                      onClick={handleApproveForm}
                    >
                      Approve Form
                    </Button>
                  )}
                {version === form.versions.length - 1 &&
                  form.versions[form.versions.length - 1].approvalStatus ===
                    "approved" && (
                    <Box>
                      <Tooltip
                        title={
                          <Typography variant="caption">
                            Approved{" "}
                            {form.versions[form.versions.length - 1].approvedBy
                              ? `By ${
                                  form.versions[form.versions.length - 1]
                                    .approvedBy
                                }`
                              : ""}
                          </Typography>
                        }
                      >
                        <CheckCircleIcon color="success" fontSize="medium" />
                      </Tooltip>
                    </Box>
                  )}
                <SplitActionGeneral
                  disableMainClick
                  options={form.versions.map((version, index) => {
                    if (index === form.versions.length - 1) {
                      return `Latest Version - ${version.date}`;
                    }
                    return `Version ${index + 1} - ${version.date}`;
                  })}
                  menuItemCustomFn={(index) => setVersion(index)}
                />
                {version === form.versions.length - 1 && formattedInput && (
                  <FormDownload
                    component={() => <ProposalFormPDF data={formattedInput} />}
                    name="proposal"
                  />
                )}
              </Box>
            </Grid>
          )}
        {editing === false && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNewCustomer}
                  onChange={(e) => {
                    setIsNewCustomer(!isNewCustomer);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="New Customer"
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="date"
            label="Date"
            name="date"
            value={input.date}
            onChange={handleChanges}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {editing || isNewCustomer ? (
            <TextField
              fullWidth
              required
              type="text"
              label="Proposal Prepared For"
              name="proposalFor"
              disabled={editing}
              value={input.proposalFor}
              onChange={(e) =>
                setInput({
                  ...input,
                  proposalFor: e.target.value.toUpperCase(),
                })
              }
            />
          ) : (
            <FormControl fullWidth required>
              <InputLabel id="select-label-comapnies">
                {filteredCustomers.length === 0
                  ? "Loading Companies..."
                  : "Proposal Prepared For"}
              </InputLabel>
              <Select
                labelId="select-label-companies"
                id="select-company"
                label={
                  filteredCustomers.length === 0
                    ? "Loading Companies..."
                    : "Proposal Prepared For"
                }
                disabled={filteredCustomers.length === 0 || editing}
                name="proposalFor"
                value={input.proposalFor}
                onChange={handleChanges}
              >
                {filteredCustomers.map((c) => (
                  <MenuItem key={c._id} value={c.companyname}>
                    {c.companyname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel id="select-label-product">
              {products.length === 0
                ? "Loading Products..."
                : "Select Products"}
            </InputLabel>
            <Select
              labelId="select-label-product"
              id="select-product"
              label={
                products.length === 0
                  ? "Loading Products..."
                  : "Select Products"
              }
              multiple
              disabled={products.length === 0}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              value={input.product}
              onChange={handleMultiSelectChange}
              readOnly={
                editing === true && form.versions.length - 1 !== version
              }
            >
              {products.sort().map((product) => (
                <MenuItem key={product} value={product}>
                  {product}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel id="select-packaging-label">Packaging Label</InputLabel>
            <Select
              labelId="select-packaging-label"
              id="select-packaging-label"
              label="Packaging Label"
              name="packaginglabel"
              value={input.packaginglabel}
              onChange={handleChanges}
              readOnly={
                editing === true && form.versions.length - 1 !== version
              }
            >
              <MenuItem value="TrakMotive">TrakMotive</MenuItem>
              <MenuItem value="Private Label">Private Label</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {input.packaginglabel === "Private Label" && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              required
              label="Private Label Brand"
              name="privateLabelBrand"
              value={input.privateLabelBrand}
              onChange={handleChanges}
              InputProps={{
                readOnly:
                  editing === true && form.versions.length - 1 !== version,
              }}
            />
          </Grid>
        )}
        {input.salesManager && (
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              required
              label="Sales Manager"
              name="salesmanager"
              defaultValue={input.salesManager}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel id="select-label-agency">Sales Agency</InputLabel>
            <Select
              labelId="select-label-agency"
              id="select-agency"
              name="agency"
              label="Sales Agency"
              value={input.agency}
              onChange={handleChanges}
              readOnly={
                editing === true && form.versions.length - 1 !== version
              }
            >
              <MenuItem value="none">None</MenuItem>
              {agencies.length > 0 &&
                agencies.sort().map((agency) => (
                  <MenuItem key={agency} value={agency}>
                    {agency}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            required
            label="Sales Agency Rep"
            name="representativeagent"
            value={input.representativeagent}
            onChange={handleChanges}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel id="select-label-group">Group</InputLabel>
            <Select
              labelId="select-label-group"
              id="select-group"
              label="Group"
              name="group"
              disabled={groups.length === 0}
              value={input.group}
              onChange={handleChanges}
              readOnly={
                editing === true && form.versions.length - 1 !== version
              }
            >
              <MenuItem value="None">None</MenuItem>
              {groups.length > 0 &&
                groups.sort().map((group) => (
                  <MenuItem key={group} value={group}>
                    {group}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            fullWidth
            minRows={4}
            name="groupInvoiceDiscounts"
            label="Group Off Invoice Discounts"
            value={input.groupInvoiceDiscounts}
            onChange={handleChanges}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            minRows={4}
            type="text"
            label="Group Rebates"
            name="groupRebates"
            value={input.groupRebates}
            onChange={handleChanges}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel id="select-label-pricing">Pricing</InputLabel>
            <Select
              labelId="select-label-pricing"
              id="select-group-pricing"
              label="Pricing"
              name="pricing"
              value={input.pricing}
              onChange={handleChanges}
              readOnly={
                editing === true && form.versions.length - 1 !== version
              }
            >
              {/*Based On Group selection */}
              {pricingOptions.length > 0 &&
                pricingOptions.map((option) => (
                  <MenuItem key={option} value={option ? option : "None"}>
                    {option ? option : "None"}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel id="select-label-terms">Terms</InputLabel>
            <Select
              labelId="select-label-terms"
              id="select-group-terms"
              label="Terms"
              name="terms"
              value={input.terms}
              onChange={handleChanges}
              readOnly={
                editing === true && form.versions.length - 1 !== version
              }
            >
              {/*Based On Group selection */}
              {termsOptions.length > 0 &&
                termsOptions.map((option) => (
                  <MenuItem key={option} value={option ? option : "None"}>
                    {option ? option : "None"}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            multiline
            minRows={5}
            label="Customer Direct Discounts"
            name="discounts"
            value={input.discounts}
            onChange={handleChanges}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            minRows={5}
            type="text"
            label="Customer Direct Rebates"
            name="rebates"
            value={input.rebates}
            onChange={handleChanges}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            inputMode="numeric"
            required
            label="Expected Annual Sales"
            name="annualSales"
            value={input.annualSales}
            onChange={handleChanges}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant="h5" textAlign="center" gutterBottom>
            Freight
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                type="text"
                required
                label="Direct or Drop Ship Program"
                name="directShip"
                value={input.directShip}
                onChange={handleChanges}
                InputProps={{
                  readOnly:
                    editing === true && form.versions.length - 1 !== version,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                type="text"
                required
                label="Special Orders"
                name="specialOrders"
                value={input.specialOrders}
                onChange={handleChanges}
                InputProps={{
                  readOnly:
                    editing === true && form.versions.length - 1 !== version,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                type="text"
                required
                label="FastTrak Program"
                name="willCall"
                value={input.willCall}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            fullWidth
            minRows={4}
            name="openingOrderDiscount"
            label="Opening Order Discount"
            value={input.openingOrderDiscount}
            onChange={handleChanges}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            label="Changeover"
            multiline
            minRows={5}
            name="changeover"
            value={input.changeover}
            onChange={handleChanges}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="inLieuWarranty"
                  checked={input.inLieuWarranty}
                  onChange={handleCheckBoxChanges}
                />
              }
              label="In Lieu of Warranty"
            />
          </FormGroup>
        </Grid>
        {input.inLieuWarranty && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              required
              label="Percentage"
              name="percentage"
              value={input.percentage}
              onChange={handleChanges}
              InputProps={{
                readOnly:
                  editing === true && form.versions.length - 1 !== version,
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            required={!input.inLieuWarranty}
            type="text"
            label="Product Returned as Warranty"
            multiline
            minRows={5}
            name="returnedWarranty"
            value={input.returnedWarranty}
            onChange={handleChanges}
            InputProps={{
              readOnly:
                (editing === false && input.inLieuWarranty) ||
                (editing === true && form.versions.length - 1 !== version),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            label="Stock Adjustment Request"
            multiline
            minRows={5}
            name="stockAdjustment"
            value={input.stockAdjustment}
            onChange={handleChanges}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            InputProps={{ readOnly: true }}
            multiline
            disabled
            label="Order Procedure"
            name="orderProcedure"
            value={input.orderProcedure}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            disabled
            InputProps={{ readOnly: true }}
            label="Backorders"
            name="backorders"
            value={input.backorders}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            label="Warranty"
            name="warranty"
            disabled
            value={input.warranty}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            label="CUSTOMER PRINTED NAME AND TITLE"
            name="customerNameAndTitle"
            onChange={handleChanges}
            value={input.customerNameAndTitle}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            label="AIT REPRESENTATIVE NAME AND TITLE"
            name="vendorNameAndTitle"
            onChange={handleChanges}
            value={input.vendorNameAndTitle}
            InputProps={{
              readOnly:
                editing === true && form.versions.length - 1 !== version,
            }}
          />
        </Grid>
        {/* Signed Proposal Section */}
        {editing && form && (
          <Grid item xs={12}>
            {fileURL && form.versions[version].file ? (
              <Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1">Signed Proposal File:</Typography>
                  <Link href={fileURL}>{input.file}</Link>
                </Box>
                {form.versions.length - 1 === version &&
                  form.versions[form.versions.length - 1].approvalStatus ===
                    "approved" && (
                    <Button size="small" onClick={() => setIsModalOpen(true)}>
                      (Change File)
                    </Button>
                  )}
              </Box>
            ) : (
              form.versions.length - 1 === version &&
              form.versions[form.versions.length - 1].approvalStatus ===
                "approved" && (
                <Button onClick={() => setIsModalOpen(true)}>
                  Attach Signed Proposal
                </Button>
              )
            )}
          </Grid>
        )}
        {editing === false || (form && form.versions.length - 1 === version) ? (
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              size="large"
              sx={{ py: { md: 1 } }}
            >
              {editing ? "Edit & Add New Version" : "Add New Proposal"}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    );
  }
};

export default Proposal;
