import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Divider, Typography } from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import GeneralModal from "../../GeneralModal";
import axios from "../../../axios/axios.config";
import Loading from "../../Loading";

const ItemBinTransferWarningModal = ({
  open,
  setOpen,
  destinationBin,
  setBinMoveError,
  existingBin,
  itemQty,
  item,
  transferFn,
  setFilteredBins,
  mongo_id,
}) => {
  const [loading, setLoading] = useState(false);

  const { currentNetsuiteLocationIds, username, currentSubsidiaryInternalId } =
    useSelector((state) => state.user);

  const getDestinationBinInfoAndTransfer = async () => {
    setLoading(true);
    try {
      const binDataResponse = await axios.post(
        `netsuite/post/inventory-move/bin/${destinationBin}/bin-data`,
        { nsIds: currentNetsuiteLocationIds }
      );

      const toBinData = binDataResponse.data[0];

      if (
        toBinData.binbrand &&
        existingBin.itembrand &&
        !toBinData.binbrand.includes(existingBin.itembrand)
      ) {
        throw new Error(
          `Incorrect Bin Brand: Item Brand is ${existingBin.itembrand} and Destination Brand is ${toBinData.binbrand}`
        );
      }

      if (
        toBinData.binboxsizetype &&
        existingBin.itemboxsizetype &&
        !toBinData.binboxsizetype?.includes(existingBin.itemboxsizetype)
      ) {
        throw new Error(
          `Incorrect Bin Box Size Type: Item Box Size is ${existingBin.itemboxsizetype} and Destination Box Size is ${toBinData.binboxsizetype}`
        );
      }

      //first paramter is for the ns call and second parameter is for mongo call
      await transferFn(
        {
          username,
          currentSubsidiaryInternalId,
          fromBin: existingBin.binnumber,
          toBin: toBinData.binnumber,
          fromLocation: existingBin.binlocationid,
          toLocation: toBinData.binlocationid,
          item,
          itemQty,
          externalid: `INVMOV_${mongo_id}`,
        },
        {
          available: toBinData.available,
          binid: toBinData.binid,
          binlocation: toBinData.binlocation,
          binlocationid: toBinData.binlocationid,
          binboxsizetype: toBinData.binboxsizetype,
          binbrand: toBinData.binbrand,
          itembrand: toBinData.itembrand,
          itemboxsizetype: toBinData.itemboxsizetype,
          preferredbin: toBinData.preferredbin,
          binnumber: toBinData.binnumber,
        }
      );
    } catch (error) {
      setBinMoveError(error.response?.data?.msg || error.message);
      setOpen(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <GeneralModal
      open={open}
      handleClose={() => setOpen(false)}
      disableClick={loading}
    >
      {loading ? (
        <Loading message="Getting Bin Data" height={200} />
      ) : (
        <Box>
          <Typography textAlign="center" variant="h5" py={3} px={2}>
            This is a new bin for this item. Are you sure it goes here?
          </Typography>

          <Divider />
          <FlexWrapper justifyContent="flex-end" gap={1}>
            <Button
              variant="contained"
              sx={{ mt: 1 }}
              onClick={getDestinationBinInfoAndTransfer}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 1, mr: 1 }}
              color="error"
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </FlexWrapper>
        </Box>
      )}
    </GeneralModal>
  );
};
export default ItemBinTransferWarningModal;
