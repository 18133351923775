import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../../axios/axios.config";

const initialState = {
  instanceId: "",
};

export const getEc2InstanceId = createAsyncThunk(
  "ec2/getEc2InstanceId",
  async (_, thunkAPI) => {
    const response = await axios.get("ec2/instance-id");

    console.log(response.data);
    return response.data.instanceId;
  }
);

const alertSlice = createSlice({
  name: "ec2",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getEc2InstanceId.fulfilled, (state, { payload }) => {
      state.instanceId = payload;
    });
  },
});

export default alertSlice.reducer;
