import axios from "../../axios/axios.config";

export const driverCheckinLoader = async () => {
  //make API call before page loads to ensure ip address is in allowed list
  try {
    const allowResponse = await axios.get("ip");

    return { allowed: allowResponse.data.allowed };
  } catch (error) {
    return { allowed: false };
  }
};

export const driverCheckinAdminLoader = async () => {
  try {
    const allowResponse = await axios.get("ip");
    const response = await axios.get("check-in/admin");

    return { data: response.data, allowed: allowResponse.data.allowed };
  } catch (error) {
    return null;
  }
};
