import { Page, Text, View, Document, Image } from "@react-pdf/renderer";

const ProposalFormPDF = ({ data }) => {
  if (data)
    return (
      <Document>
        <Page size="A3" style={{ fontSize: "14px" }}>
          <View
            id="header"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottom: "2px solid black",
            }}
          >
            <View
              style={{
                padding: "10px",
                flexDirection: "row",
                justifyContent: "center",
                width: "35%",
              }}
            >
              <Image
                src="https://www.trakmotive.com/wp-content/uploads/2017/02/TrakMotive_Logo_275px.png"
                style={{ height: "55px", width: "auto" }}
              />
            </View>
            <View
              style={{
                backgroundColor: "#1E81D8",
                width: "65%",
                flexDirection: "row",
                alignItems: "flex-end",
                paddingLeft: "5px",
              }}
            >
              <Text style={{ color: "white" }}>
                TrakMotive 1675 W Park Ave, Redlands, CA 92373 909.253.007
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "column" }}>
            {data.map((row, index) => {
              return row.value && row.value.toLowerCase() != "none" ? (
                <View
                  key={row.key}
                  wrap={false}
                  style={{
                    flexDirection: "row",
                    alignItems: "stretch",
                  }}
                >
                  <View
                    style={{
                      width: "35%",
                      alignItems: "flex-end",
                    }}
                  >
                    <Text
                      style={{
                        paddingRight: "5px",
                        paddingTop: index === 0 ? "1px" : "7px",
                        textTransform: "capitalize",
                      }}
                    >
                      {row.key}
                    </Text>
                  </View>

                  <View
                    style={{
                      border: "1px solid black",
                      height: "100%",
                    }}
                  ></View>

                  <View
                    style={{
                      width: "65%",
                    }}
                  >
                    <Text
                      style={{
                        paddingHorizontal: "5px",
                        paddingBottom: index === data.length - 1 ? 0 : "7px", //align items stretch make both flex items same height so pb only needed on one
                        paddingTop: index === 0 ? "1px" : "7px",
                      }}
                    >
                      {row.value}
                    </Text>
                  </View>
                </View>
              ) : null;
            })}
          </View>

          <View
            id="signature"
            wrap={false}
            style={{
              border: "1px solid black",
              flexDirection: "column",
              width: "100%",
              borderTop: "2px solid black",
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "stretch" }}>
              <View
                style={{
                  width: "35%",
                  border: "1px solid black",
                  height: "25px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontWeight: "bold" }}>
                  CUSTOMER PRINTED NAME & TITLE
                </Text>
              </View>
              <View
                style={{
                  width: "65%",
                  border: "1px solid black",
                  justifyContent: "flex-end",
                  paddingLeft: "5px",
                }}
              >
                <Text style={{}}>
                  {data[data.length - 1].customerNameAndTitle}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              <View
                style={{
                  width: "35%",
                  border: "1px solid black",
                  height: "25px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontWeight: "bold" }}>
                  SIGNATURE & DATE of PROPOSAL
                </Text>
              </View>
              <View
                style={{
                  width: "65%",
                  border: "1px solid black",
                  alignItems: "flex-end",
                  paddingLeft: "5px",
                  flexDirection: "row",
                }}
              >
                <View style={{ width: "50%" }}>
                  <Text style={{}}>x</Text>
                </View>
                <View style={{ width: "50%" }}>
                  <Text style={{}}>Date</Text>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: "row", alignItems: "stretch" }}>
              <View
                style={{
                  width: "35%",
                  border: "1px solid black",
                  height: "25px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontWeight: "bold" }}>
                  AIT REPRESENTATIVE NAME & TITLE
                </Text>
              </View>
              <View
                style={{
                  width: "65%",
                  border: "1px solid black",
                  justifyContent: "flex-end",
                  paddingLeft: "5px",
                }}
              >
                <Text style={{}}>
                  {data[data.length - 1].vendorNameAndTitle}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", alignItems: "stretch" }}>
              <View
                style={{
                  width: "35%",
                  border: "1px solid black",
                  height: "25px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontWeight: "bold" }}>
                  SIGNATURE & DATE of PROPOSAL
                </Text>
              </View>
              <View
                style={{
                  width: "65%",
                  border: "1px solid black",
                  alignItems: "flex-end",
                  paddingLeft: "5px",
                  flexDirection: "row",
                }}
              >
                <View style={{ width: "50%" }}>
                  <Text style={{}}>x</Text>
                </View>
                <View style={{ width: "50%" }}>
                  <Text style={{}}>Date</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
};

export default ProposalFormPDF;
