import { Link } from "react-router-dom";
import { Link as MUILink } from "@mui/material";

export const laborClaimProcessedNingboColumns = [
  {
    field: "_id",
    headerName: "ID",
    width: 250,
    sortable: false,
    renderCell: (params) => {
      return (
        <MUILink component={Link} to={`${params.row._id}/processed`}>
          {params.row._id}
        </MUILink>
      );
    },
  },
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return new Date(params.row.createdAt);
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.customer;
    },
    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    width: 180,
    valueGetter: (params) => {
      return params.row.form.status.toUpperCase();
    },
  },
  {
    field: "rmaNum",
    headerName: "RMA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rmaNum;
    },
  },
  {
    field: "rgaNum",
    headerName: "RGA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rgaNum;
    },
  },
  {
    field: "totalClaim",
    headerName: "Total Claim Amount",
    type: "number",
    width: 180,
    valueGetter: (params) => {
      return params.row.form.totalClaim;
    },
  },
];

export const laborClaimCompleteNingboColumns = [
  {
    field: "_id",
    headerName: "ID",
    width: 250,
    sortable: false,
    renderCell: (params) => {
      return (
        <MUILink component={Link} to={`${params.row._id}/complete`}>
          {params.row._id}
        </MUILink>
      );
    },
  },
  {
    field: "date",
    headerName: "Created Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return new Date(params.row.createdAt);
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    type: "string",
    width: 300,
    valueGetter: (params) => {
      return params.row.form.customer;
    },
    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    width: 180,
    valueGetter: (params) => {
      return params.row.form.status.toUpperCase();
    },
  },
  {
    field: "rmaNum",
    headerName: "RMA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rmaNum;
    },
  },
  {
    field: "rgaNum",
    headerName: "RGA Number",
    type: "string",
    width: 200,
    valueGetter: (params) => {
      return params.row.form.rgaNum;
    },
  },
  {
    field: "totalClaim",
    headerName: "Total Claim Amount",
    type: "number",
    width: 180,
    valueGetter: (params) => {
      return params.row.form.totalClaim;
    },
  },
];
