import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import GeneralModalV3 from "../../general/GeneralModalV3";
import FlexWrapper from "../../FlexWrapper";
import axios from "../../../axios/axios.config";

const PickOrderAdminDeleteOrderModal = ({
  order,
  setIsLoading,
  getPickOrderData,
}) => {
  const { openAlert } = useGeneralAlert();

  const handleDelete = async ({ handleClose }) => {
    setIsLoading("Deleting Order...");
    try {
      await axios.delete(`pick-order/delete/${order._id}`);
      await getPickOrderData();

      handleClose();
      openAlert({
        type: "success",
        message: `Successfully Deleted ${order.tranid}`,
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not delete order: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setIsLoading("");
    }
  };

  return (
    <GeneralModalV3
      openComponent={
        <Tooltip title="Delete Order" placement="top" arrow>
          <IconButton size="small">
            <Delete fontSize="12px" color="error" />
          </IconButton>
        </Tooltip>
      }
    >
      {(handleClose) => (
        <Box>
          <Typography textAlign="center" variant="h4" py={0.5}>
            Delete <strong>{order.tranid}</strong>?
          </Typography>
          <Divider />
          <Typography textAlign="center" p={2} variant="h6">
            Order will be permanently removed and you will no longer be able to
            view it.
          </Typography>
          <Divider />
          <FlexWrapper justifyContent="flex-end" p={1}>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete({ handleClose })}
            >
              Delete
            </Button>
          </FlexWrapper>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default PickOrderAdminDeleteOrderModal;
