import { useDispatch } from "react-redux";
import { createPalletBoxV2 } from "../../../../redux/features/pack-process-v2/packProcessV2Slice";
import { DashboardCustomize } from "@mui/icons-material";
import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";

import GeneralModalV3 from "../../../general/GeneralModalV3";
import FlexWrapper from "../../../FlexWrapper";

const PackProcessCreateBoxModal = ({
  palletNumber,
  createdby,
  boxes,
  handleItemRefFocus,
  _id,
}) => {
  const dispatch = useDispatch();

  const handleCreateBox = () => {
    dispatch(
      createPalletBoxV2({
        _id,
        createdby,
        palletId: palletNumber,
        boxId: boxes?.length ? boxes.length + 1 : 1,
      })
    );
  };

  return (
    <GeneralModalV3
      onClose={() => handleItemRefFocus()}
      openComponent={
        <Tooltip arrow placement="top" title="Add Box">
          <DashboardCustomize />
        </Tooltip>
      }
    >
      <Box>
        <Typography py={1} variant="h4" textAlign="center">
          Create Box?
        </Typography>
        <Divider />

        <Box p={1}>
          <Typography py={1} variant="h6" textAlign="center">
            Box will be created in <strong>Pallet {palletNumber}</strong>. Boxes
            are be used to store small items in one container.
          </Typography>
        </Box>
        <Divider />
        <FlexWrapper p="10px 5px" justifyContent="flex-end">
          <Button variant="contained" color="success" onClick={handleCreateBox}>
            Create
          </Button>
        </FlexWrapper>
      </Box>
    </GeneralModalV3>
  );
};

export default PackProcessCreateBoxModal;
