import axios from "../../../axios/axios.config";

export const logsLoader = async () => {
  try {
    const cronLogsResponse = await axios.get("cron-logs/all");
    const loginLogsResponse = await axios.get("login-log/all");

    if (cronLogsResponse.data && loginLogsResponse.data) {
      return {
        cronLogs: cronLogsResponse.data,
        loginLogs: loginLogsResponse.data,
      };
    }

    return null;
  } catch (error) {
    throw new Response("", {
      status: 500,
      statusText: "Could not load log data",
    });
  }
};
