import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

import GeneralModalV2 from "../../general/GeneralModalV2";

const BinDataInfoModal = () => {
  return (
    <GeneralModalV2
      openComponent={
        <IconButton>
          <Info color="primary" />
        </IconButton>
      }
      component={
        <Box pt={1}>
          <Typography variant="h4" textAlign="center" gutterBottom>
            Definitions
          </Typography>
          <Divider />
          <Box p={2}>
            <Typography variant="h6">
              <strong>ITEM</strong> - Bin had insufficient amounts of the item.
            </Typography>
            <Typography variant="h6">
              <strong>BIN </strong>- Bin had no amounts of the item.
            </Typography>
            {/* <Typography variant="h6">
              <strong>EMPTY-OK</strong>- Bin was confirmed to be empty by picker
              when virtual quantity is 0. No further action needed.
            </Typography> */}
            <Typography variant="h6">
              <strong>EMPTY-ISSUE </strong>- Bin was confirmed to be non-empty
              by picker when virtual quantity is 0. Bin needs to be counted.
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};
export default BinDataInfoModal;
