export const adminLinksData = [
  {
    header: "Driver Check Ins",
    links: [
      {
        title: "Driver Check In Form",
        to: "/check-in/drivers",
        target: "_blank",
      },
      {
        title: "View Driver Check Ins",
        to: "/check-in/view-all",
        target: "_blank",
      },
    ],
  },
  {
    header: "EC2",
    links: [
      {
        title: "View Instance ID",
        to: "/ec2/instance-id",
        target: "_blank",
      },
    ],
  },
];
