import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axios/axios.config";

const initialState = {
  totes: [],
  batches: [],
  loading: "",
};

export const getAllTotes = createAsyncThunk(
  "batches/getAllTotes",
  async (subsidiary, thunkAPI) => {
    try {
      const response = await axios.get(`batches?subsidiary=${subsidiary}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const batchesSlice = createSlice({
  name: "batches",
  initialState,
  reducers: {
    toggleLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllTotes.pending, (state, { payload }) => {
      state.loading = "Loading Tote Data...";
    });
    builder.addCase(getAllTotes.fulfilled, (state, { payload }) => {
      state.totes = payload;
      state.loading = "";
    });
  },
});

export default batchesSlice.reducer;
