import { useMediaQuery } from "@mui/material";
import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";

import axios from "../../../axios/axios.config";

const CycleCountsLayout = () => {
  const navigation = useNavigation();
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:600px)");
  const { openAlert } = useGeneralAlert();

  return (
    <Outlet
      context={{
        navigation,
        navigate,
        matches,
        openAlert,
        axios,
      }}
    />
  );
};
export default CycleCountsLayout;
