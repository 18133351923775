import axios from "../../axios/axios.config";

export const driverCheckInAction = async ({ request }) => {
  try {
    const formData = Object.fromEntries(await request.formData());

    //save checkin to mongo
    const mongoResponse = await axios.post("/check-in/driver", formData);

    return { checkInData: mongoResponse.data };
  } catch (error) {
    return { error: error.response?.data?.msg || error.message };
  }
};
